var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import "./SelectionModal.less";
import { BrandButton } from "../../../shared/components/buttons/Buttons";
import { Modal } from "antd";
import { showDialog } from "../../../utils/dialog";
export function SelectionModal({ width = "80%", wrapClassName = "absolute", bodyStyle = {
    height: 256,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rec",
}, title, visible = true, closable = true, destroyOnClose = true, onOk, okText = "Apply", okButtonProps, onCancel, cancelText = "Cancel", cancelButtonProps, children, }) {
    const customTitle = _jsx("div", Object.assign({ className: "text-base font-medium" }, { children: title }));
    const customFooter = (_jsxs("div", Object.assign({ className: "flex space-x-2 justify-end" }, { children: [_jsx(BrandButton, Object.assign({ onClick: e => onCancel(e) }, cancelButtonProps, { children: cancelText })), _jsx(BrandButton, Object.assign({ onClick: e => onOk(e), type: "primary" }, okButtonProps, { children: okText }))] })));
    return (_jsx(Modal, Object.assign({ mask: true, maskClosable: false, className: "SelectionModal", width: width, wrapClassName: wrapClassName, bodyStyle: bodyStyle, title: customTitle, closable: closable, open: visible, destroyOnClose: destroyOnClose, footer: customFooter, onCancel: onCancel }, { children: children })));
}
export function showSelectionModal(coraStateUpdater, createModal) {
    return __awaiter(this, void 0, void 0, function* () {
        const update = yield showDialog(onValue => createModal(onValue));
        update && coraStateUpdater(update);
    });
}
