import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { layoutGraph } from "./layoutNodes";
import { getNodesBounds, isActive, offsetToOrigin, replaceIds, setActive, setSelected, } from "./diagramUtils";
import { ORIGIN } from "./geometry";
import { useDiagramState } from "./DiagramState";
export const DEFAULT_STAGE_CONFIG = {
    layout: undefined,
    newKeys: false,
    select: true,
    active: undefined,
    onCommit: undefined,
    offset: ORIGIN,
};
export function createStage(setStagingParams) {
    function stage({ nodes = [], edges = [], nodesToRemove = [], edgesToRemove = [], }, config = {}) {
        const { layout, newKeys, select, active, onCommit, offset } = Object.assign(Object.assign({}, DEFAULT_STAGE_CONFIG), config);
        layoutGraph({ nodes, edges }, layout || "SIZE_ONLY").then(nodes => {
            if (newKeys) {
                const { nodes: n, edges: e } = replaceIds({ nodes, edges });
                nodes = n;
                edges = e;
            }
            setStagingParams({
                nodes,
                edges,
                nodesToRemove,
                edgesToRemove,
                select,
                active,
                onCommit,
                offset,
            });
        });
    }
    stage.dismiss = () => setStagingParams(undefined);
    return stage;
}
const StageInstallerContext = React.createContext(undefined);
export function useInstallStage(setStagingParams) {
    const installStage = React.useContext(StageInstallerContext);
    React.useEffect(() => {
        if (!installStage) {
            throw new Error("useInstallStage can only be used if the component that uses it is wrapped with StageProvider");
        }
        installStage(createStage(setStagingParams));
        return () => installStage(null);
    }, []);
}
const StageContext = React.createContext(undefined);
export function StageProvider({ children }) {
    const [state, dUpdater] = useDiagramState();
    const [providedStage, setProvidedStage] = React.useState();
    const stage = React.useMemo(() => providedStage || headlessStage(dUpdater, state), [providedStage, dUpdater, state]);
    const installer = React.useCallback((stage) => setProvidedStage(() => stage), []);
    return (_jsx(StageInstallerContext.Provider, Object.assign({ value: installer }, { children: _jsx(StageContext.Provider, Object.assign({ value: stage }, { children: children })) })));
}
export function useStage() {
    const stage = React.useContext(StageContext);
    // React.useEffect(() => {
    //   if (!stage) {
    //     throw new Error(
    //       "useStage can only be used if the component that uses it is wrapped with StageProvider"
    //     )
    //   }
    // }, [stage])
    return stage;
}
export function project({ x, y }, { x: tx, y: ty, zoom: tScale }) {
    return {
        x: (x - tx) / tScale,
        y: (y - ty) / tScale,
    };
}
export function commitStaged(commit, state, params, { x, y }) {
    if (!params)
        return;
    const { nodesToRemove, edgesToRemove, select, active, onCommit = ({ all }) => commit(all), } = params;
    const nToRemove = new Set(nodesToRemove);
    const eToRemove = new Set(edgesToRemove);
    const activeIds = getActiveIds();
    const committedNodes = offsetToOrigin(params.nodes, params.offset).map(i => {
        i = select !== undefined ? setSelected(i, select) : i;
        i = active !== undefined ? setActive(i, activeIds.has(i.id)) : i;
        const position = project({
            x: x + i.position.x * state.viewport.zoom,
            y: y + i.position.y * state.viewport.zoom,
        }, state.viewport);
        return Object.assign(Object.assign({}, i), { position, positionAbsolute: position });
    });
    const committedEdges = params.edges.map(i => {
        i = select !== undefined ? setSelected(i, select) : i;
        i =
            active !== undefined
                ? setActive(i, activeIds.has(i.source) && activeIds.has(i.target))
                : i;
        return i;
    });
    const nodes = [
        ...state.nodes.filter(i => !nToRemove.has(i.id)).map(updateNotStaged),
        ...committedNodes,
    ];
    const edges = [
        ...state.edges.filter(i => !eToRemove.has(i.id)).map(updateNotStaged),
        ...committedEdges,
    ];
    const all = { nodes, edges };
    const committedNodeIds = new Set(committedNodes.map(i => i.id));
    const committedEdgeIds = new Set(committedEdges.map(i => i.id));
    onCommit({
        all,
        committed: {
            nodes: all.nodes.filter(i => committedNodeIds.has(i.id)),
            edges: all.edges.filter(i => committedEdgeIds.has(i.id)),
        },
    });
    function getActiveIds() {
        return !params.active
            ? new Set(state.nodes.filter(isActive).map(i => i.id))
            : new Set(params.nodes.map(i => i.id));
    }
    function updateNotStaged(i) {
        i = select !== undefined ? setSelected(i, false) : i;
        i = active !== undefined ? setActive(i, false) : i;
        return i;
    }
}
export function headlessStage(dUpdater, state) {
    const stage = createStage(params => {
        if (params) {
            const [x, y, w, h] = getNodesBounds(state.nodes);
            commitStaged(dUpdater.commit, state, params, { x, y: y + h + 24 });
            stage.dismiss();
        }
    });
    return stage;
}
