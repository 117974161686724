import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { calcBounds, Rect } from "../geometry";
import simplify from "simplify-js";
import { QTree } from "../../../../utils/QTree";
import { getBounds } from "../collisions";
import { rectPolygon } from "../intersection";
import { toDiagram } from "../diagramUtils";
import { rounder } from "../../../../utils/rounder";
import { getSelectionMode, SelectionMode } from "../NodeSelector";
import { addWindowEventHandlers, getPosition } from "../../../../utils/events";
import { Bounds } from "../../../../utils/Bounds";
const round = rounder();
export function SelectionLayer({ rectangleSelection, onHide, onChange, initial, }) {
    const ref = React.useRef(null);
    const [polygon, setPolygon] = React.useState([initial]);
    React.useEffect(() => {
        let cancelled = false;
        let moved = false;
        return addWindowEventHandlers({
            mousemove(e) {
                moved = true;
                !cancelled &&
                    setPolygon(current => {
                        const p = getPosition(ref.current, e);
                        const points = rectangleSelection
                            ? toPoints(Rect(initial.x, initial.y, p.x, p.y))
                            : simplify([...current, p]);
                        onChange(points);
                        return points;
                    });
                function toPoints(r) {
                    return [
                        { x: r.x, y: r.y },
                        { x: r.xMax, y: r.y },
                        { x: r.xMax, y: r.yMax },
                        { x: r.x, y: r.yMax },
                        { x: r.x, y: r.y },
                    ];
                }
            },
            keydown(e) {
                if (e.key === "Escape") {
                    cancelled = true;
                    onHide(true);
                }
            },
            mouseup(e) {
                e.stopPropagation();
                e.preventDefault();
                !cancelled && onHide(!moved);
            },
        });
    }, []);
    return (_jsx("svg", Object.assign({ className: "selection-area absolute w-full h-full z-20", ref: ref }, { children: _jsx("polyline", { points: polygon.map(({ x, y }) => `${round(x)} ${round(y)}`).join(" ") }) })));
}
export function getIntersection(flow, polygon) {
    const within = QTree(flow.getNodes().map(n => n.id), id => getBounds(flow.getNode(id))).getCollisions(calcBounds(polygon, ({ x, y }) => Bounds(x, y)));
    const inter = [];
    within.forEach(n => {
        const node = flow.getNode(n);
        const { positionAbsolute, width, height } = node;
        const { x, y } = positionAbsolute;
        if (rectPolygon(Rect(x, y, x + width, y + height), polygon)) {
            inter.push(node);
        }
    });
    return inter;
}
export function selectionActivation(event, flow, selector, element, setSelectionLayer) {
    const position = getPosition(element, event);
    const mode = getSelectionMode(event);
    if (mode === SelectionMode.set)
        selector.clear();
    setSelectionLayer(_jsx(SelectionLayer, { rectangleSelection: !event.metaKey, onHide: cancel => {
            if (cancel) {
                selector.cancel();
            }
            else {
                selector.commit();
            }
            setSelectionLayer(null);
        }, onChange: polygon => {
            const sel = getIntersection(flow, polygon.map(p => toDiagram(p, flow.getViewport()))).map(n => n.id);
            selector.update(mode, sel);
        }, initial: position }));
}
