import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import Lazy from "lazy.js";
import { mergeObjects } from "../../utils/collections";
export function Progress({ count, current, onSelect, className, height = 8, width = 160, style, }) {
    return (_jsx("div", Object.assign({ className: classes("flex flex-row items-stretch space-x-[1px]", onSelect && "cursor-pointer", className), style: mergeObjects(style, { width }) }, { children: Lazy.range(count)
            .map(idx => (_jsx("div", { className: classes("flex-1", idx <= current ? "bg-accent-600" : "bg-gray-300"), style: mergeObjects({ height }, idx == 0 && {
                borderTopLeftRadius: height / 2,
                borderBottomLeftRadius: height / 2,
            }, idx === count - 1 && {
                borderTopRightRadius: height / 2,
                borderBottomRightRadius: height / 2,
            }, { width: width / count }), onClick: () => onSelect === null || onSelect === void 0 ? void 0 : onSelect(idx) }, idx)))
            .toArray() })));
}
