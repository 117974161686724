var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import React from "react";
import { Down } from "./Icons";
import "./SelectionPicker.less";
import { deleteSession, isUnnamedSession, saveSessionAs, setNewSession, switchSession, UNNAMED_SESSION_ID, useCurrentSession, useSessionsDirectory, } from "../utils/sessionManagement";
import { confirmation } from "../../utils/dialogs";
import { Spin } from "antd";
import { TabButton } from "../../shared/components/Tabs";
import { Tooltip } from "../../shared/components/Tooltip";
import { limitLength } from "../../utils/util";
import { TABS_BG } from "./lf";
import { useCollectionActions } from "../../shared/actions/collectionManagementActions";
import { updateNLQuestionText } from "./queryAnalysis/useNLQuestion";
import { useQueryState } from "../utils/queryState/queryState";
const CONFIRM_CHANGE_TEXT = `You have unsaved work. Please confirm you want to change sessions, or cancel and 'Save Session As'`;
export function SessionPicker() {
    const [loading, setLoading] = React.useState(false);
    const directory = useSessionsDirectory();
    const currentSession = useCurrentSession();
    const [, queryStateUpdater] = useQueryState();
    const { id: sessionId } = currentSession;
    const isUnnamed = isUnnamedSession(sessionId);
    const unsavedInfo = isUnnamed && currentSession.findings.length > 0;
    const selected = React.useCallback((item) => sessionId === item.id, [sessionId]);
    const entries = useCollectionActions({
        items: directory,
        name: item => item.name,
        emptyMessage: "No sessions",
        selected,
        newCaption: "New Session",
        saveCaption: `Save ${isUnnamed ? "" : "Copy "}As`,
        onSelect({ id, name }) {
            return __awaiter(this, void 0, void 0, function* () {
                if (yield confirmOp()) {
                    try {
                        setLoading(true);
                        updateNLQuestionText("");
                        yield switchSession(id, name);
                    }
                    finally {
                        setLoading(false);
                    }
                }
            });
        },
        onRemove({ id }) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    setLoading(true);
                    if (sessionId === id) {
                        yield setNewSession();
                    }
                    yield deleteSession(id);
                }
                finally {
                    setLoading(false);
                }
            });
        },
        onCopy(name) {
            return __awaiter(this, void 0, void 0, function* () {
                yield save(name);
            });
        },
        onReplace(name) {
            return __awaiter(this, void 0, void 0, function* () {
                const entry = directory.find(s => s.name === name);
                yield save(name);
                yield deleteSession(entry.id);
            });
        },
        onNew() {
            return __awaiter(this, void 0, void 0, function* () {
                if (yield confirmOp()) {
                    try {
                        setLoading(true);
                        updateNLQuestionText("");
                        yield setNewSession(queryStateUpdater);
                    }
                    finally {
                        setLoading(false);
                    }
                }
            });
        },
    });
    return (_jsx(DropDownMenu, Object.assign({ title: "Session", hasClose: true, disabled: loading, maxHeight: "70vh", entries: entries }, { children: _jsx(Tooltip, Object.assign({ content: "Session", placement: "bottom-start" }, { children: _jsxs(TabButton, Object.assign({ className: TABS_BG }, { children: [_jsx("div", { children: limitLength(currentSession.name, 24) }), loading ? _jsx(Spin, { size: "small" }) : _jsx(Down, { className: "text-xs" })] })) })) })));
    function confirmOp() {
        return __awaiter(this, void 0, void 0, function* () {
            return (!unsavedInfo ||
                (yield confirmation({
                    title: CONFIRM_CHANGE_TEXT,
                    okText: "Confirm",
                })));
        });
    }
    function save(name) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                setLoading(true);
                yield saveSessionAs(currentSession, name, queryStateUpdater);
                if (isUnnamed) {
                    yield deleteSession(UNNAMED_SESSION_ID);
                }
            }
            finally {
                setLoading(false);
            }
        });
    }
}
