import { MetadataType, } from "../api/types";
import { invalidCorpusIds, useCurrentSource } from "./sourceManagement";
import { getCorpusFilter, validateTextFilter, } from "./semanticSearchUtil";
import { useChangeCounter, useMemoIfEqual } from "../../utils/hooks";
import React from "react";
import { keyBy, pick, take } from "lodash";
import { upsertOrRemove } from "../../utils/collections";
import { toBoolean } from "../../utils/util";
import { rangeToString } from "../components/facet/metadata/DatePicker";
export function useMetadataParamsGetter(corpusFilterParams, field) {
    const { corpus_ids, keywordMetadata, rangeMetadata } = corpusFilterParams;
    const disabled = invalidCorpusIds(corpus_ids);
    const corpus_filter = useMemoIfEqual(disabled
        ? undefined
        : getCorpusFilter(Object.assign(Object.assign({}, pick(corpusFilterParams, "corpus_ids", "concepts1", "concepts2Override", "concepts2", "concepts1Override", "relations", "crcDirection", "required_arguments", "argClauses", "booleanMetadata", "queries", "extraConcepts", "extraConceptsOverride", "contextConcepts", "contextConceptsOverride", "aperture")), { keywordMetadata: keywordMetadata.filter(m => m.id != field), rangeMetadata: rangeMetadata.filter(m => m.id != field) })));
    const paramsGetter = React.useCallback((count, prefix = "") => {
        prefix = validateTextFilter(prefix);
        return !corpus_filter
            ? undefined
            : Object.assign({ corpus_filter,
                count,
                field }, ((prefix === null || prefix === void 0 ? void 0 : prefix.length) && { prefix }));
    }, [useMemoIfEqual(corpus_filter)]);
    const [change] = useChangeCounter(corpus_filter);
    return { paramsGetter, change, disabled: !corpus_filter };
}
export function getSelectedMetadata(filterParams, source, maxCount = Number.MAX_SAFE_INTEGER) {
    const metadata = (source === null || source === void 0 ? void 0 : source.supported_metadata_fields) || [];
    const { rangeMetadata, keywordMetadata, rangeMetadataOverride, booleanMetadata, } = filterParams;
    const rangeById = keyBy(rangeMetadata, r => r.id);
    const keywordById = keyBy(keywordMetadata, r => r.id);
    const overrideById = keyBy(rangeMetadataOverride, r => r.id);
    const booleanById = keyBy(booleanMetadata, r => r.id);
    return metadata
        .map(m => m.type === MetadataType.DATE
        ? overrideById[m.id] || rangeById[m.id]
        : m.type === MetadataType.BOOLEAN
            ? booleanById[m.id]
            : keywordById[m.id])
        .filter(Boolean)
        .map(i => ({
        title: i.name,
        value: i.type === MetadataType.DATE
            ? rangeToString(i.value)
            : i.type === MetadataType.BOOLEAN
                ? toYesNo(toBoolean(i.value))
                : keywordValueAsStr(i, maxCount),
    }));
    function keywordValueAsStr({ value }, maxCount) {
        const suffix = value.length > maxCount ? "…" : "";
        return (take(value, maxCount)
            .map(v => v.name)
            .join(", ") + suffix);
    }
}
export function useSelectedMetadata(filterParams) {
    return getSelectedMetadata(filterParams, useCurrentSource(filterParams.corpus_ids));
}
export function upsertCoraStateMetadata(coraState, e, coraStateUpdater) {
    const field = e.type === MetadataType.KEYWORD
        ? "keywordMetadata"
        : e.type === MetadataType.BOOLEAN
            ? "booleanMetadata"
            : "rangeMetadata";
    const value = e.type === MetadataType.KEYWORD
        ? Array.isArray(e.value)
            ? e.value
            : [e.value]
        : e.type === MetadataType.BOOLEAN
            ? toBoolean(e.value)
            : { start: e.value, end: e.value };
    coraStateUpdater({
        [field]: upsertOrRemove(coraState.keywordMetadata, i => i.id, e.id, Object.assign(Object.assign({}, e), { value })),
    });
}
export function toYesNo(value) {
    return value ? "Yes" : "No";
}
export function filterMetadata(e, dropFalseBools = false) {
    if (!e)
        return [];
    return e.filter(ev => {
        const value = metadataValue(ev);
        if (Array.isArray(value))
            return value.length > 0;
        if (typeof value === "boolean" && dropFalseBools)
            return value;
        return ev.value !== undefined;
    });
}
export function sortMetadata(source) {
    const metadataInfo = (source === null || source === void 0 ? void 0 : source.display_configuration.metadata) || [];
    return (metadata) => {
        return [...metadata].sort((a, b) => {
            var _a, _b;
            const aVal = ((_a = metadataInfo.find(x => x.id === a.id)) === null || _a === void 0 ? void 0 : _a.order) || -1;
            const bVal = ((_b = metadataInfo.find(x => x.id === b.id)) === null || _b === void 0 ? void 0 : _b.order) || -1;
            if (aVal === -1 || (bVal !== -1 && aVal > bVal))
                return 1;
            if (bVal === -1 || (aVal !== -1 && aVal < bVal))
                return -1;
            return 0;
        });
    };
}
export function metadataValue({ value, type }, onlyFirst = true) {
    if (Array.isArray(value)) {
        return !value.length
            ? ""
            : onlyFirst
                ? value[0] + (value.length > 1 ? ` (+${value.length - 1})` : "")
                : value.join(", ");
    }
    if (type === MetadataType.BOOLEAN &&
        (value === "True" || value === "False"))
        return value === "True";
    return value;
}
