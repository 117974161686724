// this global registry allows different parts of the app to share state without
// having to have dependencies on each other.
function RegistryEntry(name) {
    let registered;
    return (value) => {
        if (value !== undefined) {
            registered = value;
        }
        else if (registered === undefined) {
            const error = new Error(`Registry entry ${name} accessed before initialization`);
            console.error(error);
            throw error;
        }
        return registered;
    };
}
export const IGNORED_SESSION_IDS = RegistryEntry("IGNORED_SESSION_IDS");
