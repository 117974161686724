import React from "react";
import { SortOrder, } from "../../api/types";
export function useDocumentItems(evidenceSearch, filter, sortField, sortOrder) {
    var _a;
    const { evidenceAndTotals, noSelection } = evidenceSearch;
    const evidences = ((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.evidence) || [];
    const filteredEvidence = React.useMemo(() => evidences.filter(filter), [evidences, filter]);
    const orderedDocuments = React.useMemo(() => filteredEvidence.reduce((acc, item) => {
        const firstDocId = item.instances[0].document_id;
        const firstTitle = item.instances[0].title;
        const allIdsMatch = item.instances.every(i => i.document_id === firstDocId);
        if (!allIdsMatch) {
            console.warn("Evidence with multiple documents not supported");
        }
        const allTitlesMatch = item.instances.every(i => i.title === firstTitle);
        if (!allTitlesMatch) {
            console.warn("Evidence with multiple titles not supported");
        }
        if (!acc.documents[firstDocId]) {
            const doc = {
                document_id: firstDocId,
                title: firstTitle,
                primary_evidence: item,
                additional_evidence: [],
            };
            acc.documents[firstDocId] = doc;
            acc.idOrder.push(firstDocId);
        }
        else {
            acc.documents[firstDocId].additional_evidence.push(item);
        }
        return acc;
    }, { idOrder: [], documents: {} }), [evidenceAndTotals]);
    const documentsInOrder = orderedDocuments.idOrder
        .map(id => orderedDocuments.documents[id])
        .sort((a, b) => {
        if (sortField === "evidence_count" && sortOrder === SortOrder.ASC) {
            return a.additional_evidence.length - b.additional_evidence.length;
        }
        else if (sortField === "evidence_count" &&
            sortOrder === SortOrder.DESC) {
            return b.additional_evidence.length - a.additional_evidence.length;
        }
        else {
            return 0;
        }
    });
    return {
        filteredEvidence,
        items: documentsInOrder,
        noSelection,
        evidenceAndTotals,
    };
}
