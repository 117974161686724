import { alg, Graph } from "graphlib";
import { uniq } from "lodash";
export function createDependencies() {
    const graph = new Graph();
    return {
        define(item, dependencies) {
            const arr = Array.isArray(item) ? item : [item];
            arr.forEach(item => {
                dependencies === null || dependencies === void 0 ? void 0 : dependencies.forEach(d => {
                    graph.setEdge(item, d);
                });
            });
            return this;
        },
        dependencies(...items) {
            items.forEach(i => graph.setNode(i));
            return uniq(alg.preorder(graph, items));
        },
    };
}
