import { classifyNodes, getActiveRelationOrConcept, getNodeValues, } from "../diagramUtils";
import { GraphAnalysis } from "../GraphAnalysis";
import { partition } from "lodash";
import { ElementType, isRelation } from "../model";
import { getActualActiveConstraintId } from "../../../utils/queryState/DMParams";
import { getConstraintFromNodes } from "./constraintsFromNodes";
import { getSize } from "../../../../utils/util";
export function diagramToQueryState({ nodes, edges, viewport }, state) {
    const ga = GraphAnalysis(nodes, edges);
    const comps = ga.components().map(ids => ids.map(id => ga.node(id)));
    const [complex, lone] = partition(comps, c => c.length > 1);
    const nodesByType = classifyNodes(nodes);
    const concepts = Object.fromEntries(nodesByType[ElementType.CONCEPT].map(n => [n.id, getNodeValues(n, false)]));
    const overrides = Object.fromEntries(nodesByType[ElementType.CONCEPT]
        .map(n => [n.id, n.data.selection])
        .filter(([_, v]) => getSize(v)));
    const relations = Object.fromEntries(nodesByType[ElementType.RELATION].map(n => [n.id, getNodeValues(n, false)]));
    const clauses = Object.fromEntries(nodesByType[ElementType.QUALIFIER].map(n => [n.id, getNodeValues(n, false)]));
    const orderedComps = [...sortComponents(complex), ...sortComponents(lone)];
    const constraints = orderedComps.reduce((acc, nodes) => {
        acc.push(...componentToConstraints({ nodes, edges }));
        return acc;
    }, []);
    const dmParams = {
        concepts,
        relations,
        clauses,
        constraints,
    };
    const active = getNodeOrderId(getActiveRelationOrConcept(nodes));
    return Object.assign(Object.assign({}, state), { viewport,
        dmParams, activeConstraintId: getActualActiveConstraintId(dmParams, active !== null && active !== void 0 ? active : state.activeConstraintId), overrides, nodesInfo: Object.fromEntries(nodes.map(({ id, positionAbsolute: { x, y } = { x: 0, y: 0 }, data: { expanded, resized, order }, }) => [
            id,
            Object.assign(Object.assign({ x,
                y, order: order }, (expanded && { expanded })), (resized && { resized })),
        ])) });
    function sortComponents(comps) {
        return comps
            .map(c => [
            c.sort((a, b) => b.data.order - a.data.order),
            Math.max(...c.map(n => { var _a; return (_a = n.data.order) !== null && _a !== void 0 ? _a : 0; })),
        ])
            .sort((a, b) => b[1] - a[1])
            .map(c => c[0]);
    }
    function componentToConstraints({ nodes, edges }) {
        return (nodes
            // if component has only one node use node, otherwise use relations
            .filter(n => nodes.length === 1 || isRelation(n))
            .map(n => getConstraintFromNodes(ga.nodeAndNeighborIds(n.id), getNodeOrderId(n), nodes, edges)));
    }
    function getNodeOrderId(n) {
        const order = n === null || n === void 0 ? void 0 : n.data.order;
        return (order !== null && order !== void 0 ? order : "") + "";
    }
}
