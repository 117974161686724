var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
export function Banner(_a) {
    var { prefix, suffix, className, style, children } = _a, props = __rest(_a, ["prefix", "suffix", "className", "style", "children"]);
    return (_jsxs("div", Object.assign({ className: classes("flex-1 rounded px-2 font-semibold text-xs flex justify-start items-center space-x-1", className), style: Object.assign({ fontFamily: "Inter" }, style) }, props, { children: [prefix, _jsx("div", Object.assign({ className: "flex-1 flex items-center space-x-1" }, { children: children })), suffix] })));
}
