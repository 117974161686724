import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Lazy from "lazy.js";
import { Beacon, useBeacon } from "../../utils/Beacon";
import { isEqual, uniq } from "lodash";
import { Select } from "antd";
import { textComparator } from "../../utils/comparators";
import { useCurrentSession } from "./sessionManagement";
const allTagsBeacon = Beacon({
    initial: [],
    isEqual,
});
export function useSynchronizeAllTags() {
    const { findings } = useCurrentSession();
    const beacon = useBeacon(allTagsBeacon);
    React.useEffect(() => {
        const allTags = getAllTags(findings, f => f.payload.tags);
        allTagsBeacon.value(allTags);
    }, [findings]);
    return beacon;
}
export function useAllTagComponents(allTags) {
    return React.useMemo(() => allTags.sort(textComparator).map(tag => (_jsx(Select.Option, Object.assign({ value: tag }, { children: tag }), tag))), [allTags]);
}
export function useAllTags(extraTags) {
    const allTags = useBeacon(allTagsBeacon)[0];
    return useMergeTags(allTags, extraTags);
}
export function getAllTags(items, itemTags) {
    return !itemTags ? [] : Lazy(items.map(itemTags)).flatten().uniq().toArray();
}
export function useMergeTags(allTags, extraTags) {
    return React.useMemo(() => (extraTags ? uniq([...allTags, ...extraTags]) : allTags), [allTags, extraTags]);
}
