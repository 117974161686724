import React from "react";
import { flushCurrentSession } from "./sessionManagement";
export function useWarnReload(enabled) {
    React.useEffect(() => {
        if (!enabled)
            return;
        const handler = (event) => {
            const e = event || window.event;
            e.preventDefault();
            event.returnValue = "Are you sure you want to exit?";
            setTimeout(() => {
                flushCurrentSession();
            }, 1);
            return "";
        };
        window.onbeforeunload = handler;
        return () => {
            window.onbeforeunload = null;
        };
    }, [enabled]);
}
