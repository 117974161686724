var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { FeatureIdentifier, } from "../../api/types";
import { Tooltip } from "../../../shared/components/Tooltip";
import { classes } from "../../../utils/classes";
import { useParams } from "../../../utils/hooks";
import { SummaryCard } from "./SummaryCard";
import { selectSingle } from "../../../utils/collections";
import { Empty } from "../../../shared/components/Empty";
import { Scroller } from "../../../shared/components/Scroller";
import { FeedbackWidget, sendFeedback } from "../../utils/feedback";
import { showNotification } from "../../../shared/components/notifications";
import { ActionButton } from "../../../shared/components/buttons/Buttons";
import { Copy } from "../Icons";
import { copyToClipboard } from "../../../utils/clipboard";
import { CorpusCapability } from "../../../utils/capabilities/capabilities";
function summariesMessage(summaries, loadingEvidence) {
    if (!summaries) {
        return "No Summaries";
    }
    if (loadingEvidence) {
        return "Looking for relevant evidence to summarize.";
    }
    if (summaries.length === 0) {
        return "Not enough relevant evidence to summarize. Please revise your selections.";
    }
}
export function Summaries({ evidence, summaries, loading, loadingEvidence, corpus_filter, selected, setSelected, evidenceStateUpdater, }) {
    const [{ feedback }, updateParams] = useParams({
        feedback: {},
    });
    function sendSummaryFeedback(item, type) {
        return __awaiter(this, void 0, void 0, function* () {
            yield sendFeedback(FeatureIdentifier.SUGGEST_EVIDENCE_SUMMARY, CorpusCapability.SUGGEST_SUMMARY, type, item, corpus_filter);
            updateParams({ feedback: Object.assign(Object.assign({}, feedback), { [item.id]: type }) });
            showNotification("Feedback sent");
        });
    }
    function footerContent(summary, selected) {
        return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx(Tooltip, Object.assign({ trigger: "click", content: "Copied to clipboard", delay: 150 }, { children: _jsx(ActionButton, { className: classes("cursor-pointer border-0 p-2 text-neutral-800"), sizeClass: "text-sm", icon: _jsx(Copy, {}), onClick: () => copyToClipboard(summary.summary) }) })), _jsx("div", { children: _jsx(FeedbackWidget, { item: summary, feedback: feedback, sendFeedbackFn: sendSummaryFeedback, bgClass: isSelected(summary) ? undefined : "bg-accent-50", expanded: true }) })] })));
    }
    const isSelected = React.useCallback((summary) => !!selected.find(s => s.id == summary.id), [selected]);
    const selectedSummary = summaries.find(s => isSelected(s));
    const getEvidencePosition = React.useCallback((evidenceId) => {
        const idx = evidence === null || evidence === void 0 ? void 0 : evidence.findIndex(e => e.id === evidenceId);
        if (idx === undefined || idx === -1)
            return undefined;
        return idx + 1;
    }, [evidence]);
    return (_jsx("div", Object.assign({ className: "ml-2 flex flex-col flex-1" }, { children: _jsxs(Empty, Object.assign({ loading: !evidence ? "Loading…" : loading && "Summarizing…", empty: summariesMessage(summaries, loadingEvidence), showIcon: false }, { children: [selectedSummary && (_jsx(_Fragment, { children: _jsx(SummaryCard, { summary: selectedSummary, summaryIndex: 0, summaryCount: 1, selected: true, onSelect: () => setSelected([]), footer: footerContent(selectedSummary, true), evidenceStateUpdater: evidenceStateUpdater, getEvidencePosition: getEvidencePosition }, 0) })), _jsx(Scroller, { children: summaries.map((summary, idx) => (_jsx(SummaryCard, { summary: summary, summaryIndex: idx, summaryCount: summaries.length, hidden: isSelected(summary), onSelect: () => setSelected(selectSingle(selected, summary)), footer: footerContent(summary, isSelected(summary)), evidenceStateUpdater: evidenceStateUpdater, getEvidencePosition: getEvidencePosition }, idx))) })] })) })));
}
