var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../utils/classes";
import { useUniqId } from "../../../utils/hooks";
import { uuidv4 } from "../../../utils/util";
import { useSelectionChange } from "../../utils/useSelectionChange";
import { AnnotatedString } from "../../../shared/components/AnnotatedString";
import { uniq } from "lodash";
import Immutable from "immutable";
import { BorderlessButton } from "../../redesign/generic/BorderlessButton";
export function SummaryCard(_a) {
    var { summary, summaryIndex, summaryCount, onSelect, className, selected, disabled, hidden, 
    //urlOpenerFn,
    filterText, actions, extraItemActions, 
    //summaryArgColorGetter,
    renderAsHtml, onRemove, showSurrounding, 
    //onMetadataClick,
    onSummaryTextSelected, actionsTooltip, footer, evidenceStateUpdater, getEvidencePosition } = _a, props = __rest(_a, ["summary", "summaryIndex", "summaryCount", "onSelect", "className", "selected", "disabled", "hidden", "filterText", "actions", "extraItemActions", "renderAsHtml", "onRemove", "showSurrounding", "onSummaryTextSelected", "actionsTooltip", "footer", "evidenceStateUpdater", "getEvidencePosition"]);
    const containerId = useUniqId(uuidv4);
    useSelectionChange(!summary, containerId, summary.summary, onSummaryTextSelected &&
        (({ text, rect }) => onSummaryTextSelected({ summary, rect, text })));
    const select = () => onSelect && !selected && onSelect(summary, true);
    const uniqueReferenceIds = uniq(summary.references.map(r => r.evidence_id));
    const uniqueReferenceCount = uniq(summary.references.map(r => r.evidence_id)).length;
    const referenceAnnotations = summary.references.map(r => ({
        span: r.span,
        annotation: r.evidence_id,
    }));
    if (hidden)
        return null;
    return (_jsx("div", Object.assign({ className: classes("flex flex-col items-stretch rounded-lg text-base bg-white border px-4 py-3 font-sans mb-1 mr-1", disabled
            ? "opacity-50"
            : selected
                ? "bg-accent-50 border-accent-300"
                : "bg-white border-neutral-300 hover:border-accent-50 cursor-pointer", className), style: {
            position: selected ? "sticky" : "relative",
            top: 0,
            bottom: 0,
            zIndex: selected ? 1 : undefined,
        } }, props, { onClick: select }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-between" }, { children: [_jsx("div", Object.assign({ className: classes("font-bold grow", onSelect && "cursor-pointer") }, { children: _jsx("div", { children: summary.cluster_name }) })), selected && (_jsx(BorderlessButton, Object.assign({ className: "text-sm underline", onClick: () => onSelect && onSelect(summary, false) }, { children: "Clear" })))] })), _jsx(ReferenceQualifier, { disableInteractions: !selected, referenceCount: uniqueReferenceCount, highlightRefernces: () => evidenceStateUpdater &&
                        evidenceStateUpdater.update({
                            highlight: Immutable.Set(uniqueReferenceIds),
                        }), selectReferences: () => evidenceStateUpdater &&
                        evidenceStateUpdater.updateEvidence(uniqueReferenceIds, evidenceStateUpdater.everySelected(uniqueReferenceIds)
                            ? "remove"
                            : "add"), considerationCount: summary.evidence_ids.length, highlightConsiderations: () => evidenceStateUpdater &&
                        evidenceStateUpdater.update({
                            highlight: Immutable.Set(summary.evidence_ids),
                        }), selectConsiderations: () => evidenceStateUpdater &&
                        evidenceStateUpdater.updateEvidence(summary.evidence_ids, evidenceStateUpdater.everySelected(summary.evidence_ids)
                            ? "remove"
                            : "add"), clearHighlights: () => evidenceStateUpdater && evidenceStateUpdater.clearHighlight() }), _jsx("div", Object.assign({ className: classes("flex flex-col items-stretch", "mt-2", "pb-1") }, { children: _jsx("div", Object.assign({ id: containerId }, { children: renderAsHtml ? (_jsx("span", { dangerouslySetInnerHTML: renderAsHtml ? { __html: summary.summary } : undefined })) : (_jsx(AnnotatedString, { text: summary.summary, annotations: referenceAnnotations, renderAnnotation: ({ text, annotations }) => {
                                if (annotations.length) {
                                    const evidencePosition = getEvidencePosition && getEvidencePosition(annotations[0]);
                                    return selected && evidencePosition !== undefined ? (_jsx("sup", Object.assign({ className: "font-bold cursor-pointer select-none", onMouseEnter: () => evidenceStateUpdater &&
                                            evidenceStateUpdater.update({
                                                highlight: Immutable.Set([annotations[0]]),
                                            }), onMouseOut: () => evidenceStateUpdater &&
                                            evidenceStateUpdater.clearHighlight(), onClick: () => evidenceStateUpdater &&
                                            evidenceStateUpdater.updateEvidence(annotations, evidenceStateUpdater.itemSelected(annotations[0])
                                                ? "remove"
                                                : "add") }, { children: `[${evidencePosition}]` }))) : undefined;
                                }
                                return _jsx("span", { children: text });
                            } })) })) })), footer && (_jsx("div", Object.assign({ className: "flex w-full border-t border-neutral-300 mt-2 pt-1" }, { children: footer })))] })) })));
}
function ReferenceQualifier({ disableInteractions, clearHighlights, referenceCount, highlightRefernces, selectReferences, considerationCount, highlightConsiderations, selectConsiderations, }) {
    return (_jsxs("span", Object.assign({ className: "text-sm select-none" }, { children: ["references", " ", _jsx("span", Object.assign({ className: classes(!disableInteractions ? "underline" : undefined, "cursor-pointer"), onMouseEnter: !disableInteractions ? highlightRefernces : undefined, onMouseOut: !disableInteractions ? clearHighlights : undefined, onClick: !disableInteractions ? selectReferences : undefined }, { children: `${referenceCount} evidence items` })), ` out of `, _jsx("span", Object.assign({ className: classes(!disableInteractions ? "underline" : undefined, "cursor-pointer"), onMouseEnter: !disableInteractions ? highlightConsiderations : undefined, onMouseOut: !disableInteractions ? clearHighlights : undefined, onClick: !disableInteractions ? selectConsiderations : undefined }, { children: `${considerationCount} considered` }))] })));
}
