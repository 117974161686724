import React from "react";
import { useCurrentSource } from "../../utils/sourceManagement";
export function useEvidenceSort(coraState, coraStateUpdater) {
    const { sortFieldId, sortOrder } = coraState;
    const source = useCurrentSource(coraState);
    const sortFieldOptions = (source === null || source === void 0 ? void 0 : source.sortable_fields) || [];
    const defaultSortField = (source === null || source === void 0 ? void 0 : source.sorting_default_field) || "";
    const defaultSortOrder = source === null || source === void 0 ? void 0 : source.sorting_default_order;
    const updateSort = React.useCallback((sortFieldId, sortOrder) => coraStateUpdater({
        sortFieldId,
        sortOrder,
    }), [coraStateUpdater]);
    return {
        sortFieldOptions,
        sortField: sortFieldId || defaultSortField,
        sortOrder: sortOrder || defaultSortOrder,
        updateSort,
    };
}
