import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../utils/classes";
import { EvidenceType } from "../../api/types";
import { ConfigHorizontal } from "../Icons";
import { DropDownMenu } from "../../../shared/components/DropDownMenu";
import { showRefutingSelectionPopover } from "./RefutingSelectionPopover";
import { SelectionButton } from "../../../shared/components/buttons/SelectionButton";
import { SelectableMenuItem } from "../../../shared/actions/SelectableMenuItem";
function roundOffThousands(n) {
    if (n < 1000) {
        return n;
    }
    return Math.round((n / 1000) * 10) / 10 + "k";
}
export function useRelationsTypeMenuEntry(selectedEvidenceType, onSelect) {
    const options = [EvidenceType.SUPPORTING, EvidenceType.REFUTING];
    return options.map(evidenceType => {
        const caption = (_jsx(SelectableMenuItem, { selected: selectedEvidenceType === evidenceType, label: evidenceType }));
        return {
            caption,
            action() {
                onSelect(evidenceType);
            },
        };
    });
}
export function RelationsTypePicker({ setSelectedEvidenceType, selectedEvidenceType, coraState, coraStateUpdater, noSelection, showingCount, }) {
    const [visibleType, setVisibleType] = React.useState(false);
    const [visibleSelection, setVisibleSelection] = React.useState(false);
    const menuEntry = useRelationsTypeMenuEntry(selectedEvidenceType, evidenceType => {
        setSelectedEvidenceType(evidenceType);
        setVisibleType(false);
    });
    return (_jsx(DropDownMenu, Object.assign({ title: "Show", hasClose: true, entries: menuEntry, open: visibleType, onVisibleChange: e => setVisibleType(!visibleType), entryClassName: classes("p-2.5 text-base hover:bg-accent-50 font-normal") }, { children: _jsx(SelectionButton, { className: "flex items-center text-tiny", onClick: e => setVisibleType(!visibleType), selected: selectedEvidenceType === EvidenceType.REFUTING, isDropdown: true, dropDownCallBack: e => setVisibleType(!visibleType), caption: _jsxs("div", Object.assign({ className: "flex items-center whitespace-nowrap" }, { children: [_jsxs("div", { children: ["Show ", selectedEvidenceType] }), Boolean(showingCount) && (_jsxs("div", Object.assign({ className: "ml-1" }, { children: ["(", showingCount ? roundOffThousands(showingCount) : "", showingCount && showingCount === 10000 ? "+" : "", ")"] }))), selectedEvidenceType === EvidenceType.REFUTING && !noSelection && (_jsx(ConfigHorizontal, { className: "ml-3 mr-2 hover:bg-neutral-200 rounded py-0.5 flex items-center", onClick: e => {
                            e.preventDefault();
                            e.stopPropagation();
                            showRefutingSelectionPopover(coraState, coraStateUpdater);
                        } }))] })) }) })));
}
