import { getReferredIds } from "./DMParams";
import { partition } from "lodash";
import { getSize } from "../../../utils/util";
export function toComplexAndLoneConstraints(params) {
    const refIds = getReferredIds(params);
    const { constraints } = params;
    const [complex, lone] = partition(constraints, c => !isLoneConcept(c));
    return complex.length === 0 && lone.length === 1
        ? [lone, []]
        : [complex, lone];
    function isLoneConcept(c) {
        var _a;
        return (c.source &&
            getSize(refIds.get(c.source)) === 1 &&
            !c.relation &&
            !c.target &&
            !((_a = c.context) === null || _a === void 0 ? void 0 : _a.length));
    }
}
export function implyActiveConstraintId(params) {
    var _a, _b;
    const [complex, lone] = toComplexAndLoneConstraints(params);
    return ((_a = complex[0]) === null || _a === void 0 ? void 0 : _a.id) || ((_b = lone[0]) === null || _b === void 0 ? void 0 : _b.id) || "";
}
export function getConstraintsContaining(constraints, memberId) {
    return constraints
        .filter(c => c.source === memberId ||
        c.target === memberId ||
        c.context.includes(memberId) ||
        Object.values(c.qualifiers).includes(memberId))
        .map(c => c.id);
}
export const EMPTY_CONSTRAINT = {
    id: "",
    source: null,
    relation: null,
    target: null,
    context: [],
    qualifiers: {},
    text: "",
};
