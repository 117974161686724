import { getUrl, openUrl } from "../../utils/url";
import { highlightWordsOutsideOfParse } from "../../utils/coraUtil";
import { sortMetadata } from "../../utils/metatada";
import { showSelectionMenu } from "../contextMenuEntries";
export function getUrlOpener(coraState, queryState, coraStateUpdater, findings, structuredQuery, source) {
    return (evidence, instance, highlights, disableScrollToHighlight) => {
        openUrl(getUrl(instance), instance.corpus_id, instance.document_id, highlights || [...highlightWordsOutsideOfParse(evidence), evidence.text], instance.title, ({ rect, text, docText }) => showSelectionMenu(rect, {
            text,
            coraState,
            coraStateUpdater,
            findings,
            evidence,
            instance,
            context: docText,
            structuredQuery,
            queryState,
        }), instance.metadata, sortMetadata(source), instance.external_url, disableScrollToHighlight);
    };
}
