var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { isConcept, isQualifier } from "../model";
import { API } from "../../../api/api";
import { diagramToCorpusFilter } from "../adapters/corpusFilterAdapters";
import { invalidCorpusIds } from "../../../utils/sourceManagement";
import { useCurrentSession } from "../../../utils/sessionManagement";
import { IdReplacer } from "../../../../utils/IdReplacer";
import { normalizeConjunctiveQuery } from "../../../utils/normalizeConjunctiveQuery";
import { useReactFlow } from "reactflow";
import { useQueryState } from "../../../utils/queryState/queryState";
import { diagramToConjunctive } from "../adapters/diagramToConjunctive";
import { usePossibleValues } from "../../../utils/possibleValues";
const DEF_COUNT = 30;
export function useLoader({ node, }) {
    const flow = useReactFlow();
    const { conjunctionBindingsSearchWidth } = useCurrentSession();
    const replacer = React.useRef(IdReplacer()).current;
    const [qs] = useQueryState();
    const { corpus_ids, aperture } = qs;
    if (isConcept(node)) {
        const loaderParams = (prefix) => {
            replacer.reset();
            return invalidCorpusIds(corpus_ids)
                ? undefined
                : {
                    corpus_ids,
                    count: DEF_COUNT,
                    search_width: conjunctionBindingsSearchWidth,
                    aperture,
                    query: normalizeConjunctiveQuery(diagramToConjunctive(qs, node, flow.getNodes(), flow.getEdges(), prefix), replacer),
                };
        };
        return {
            loaderParams,
            loader(params) {
                return __awaiter(this, void 0, void 0, function* () {
                    const bindings = yield API.conjunctionBindings(params);
                    return bindings[replacer(node.id)] || [];
                });
            },
        };
    }
    const possible = usePossibleValues();
    const corpus_filter = invalidCorpusIds(corpus_ids)
        ? undefined
        : diagramToCorpusFilter(qs, node, flow.getNodes(), flow.getEdges(), possible, [node.id]);
    if (isQualifier(node)) {
        const loaderParams = (prefix) => {
            return (corpus_filter && Object.assign(Object.assign({}, (prefix.length && { prefix })), { corpus_filter, count: DEF_COUNT, argument_name: node.data.argName }));
        };
        return {
            loaderParams,
            loader(params) {
                return __awaiter(this, void 0, void 0, function* () {
                    return API.searchArgumentClauses(params);
                });
            },
        };
    }
    else {
        const loaderParams = (prefix) => {
            return (corpus_filter && Object.assign(Object.assign({}, (prefix.length && { prefix })), { corpus_filter, count: DEF_COUNT }));
        };
        return {
            loaderParams,
            loader(params) {
                return __awaiter(this, void 0, void 0, function* () {
                    return API.relations(params);
                });
            },
        };
    }
}
