import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useCoraStateUpdater } from "../utils/coraState";
import { merge } from "../../utils/collections";
import { useQueryState } from "../utils/queryState/queryState";
import { coraStateToQueryState } from "../utils/queryState/coraStateToQueryState";
import { queryStateToCoraState } from "../utils/queryState/queryStateToCoraState";
import { getPossibleValues, usePossibleValues } from "../utils/possibleValues";
const CoraStateContext = React.createContext(undefined);
export function useCoraStateFromQueryState() {
    const [state, updateState] = useQueryState();
    const possible = usePossibleValues();
    const coraState = React.useMemo(() => queryStateToCoraState(state, possible), [state, possible]);
    const updater = React.useCallback((updateOrFn) => updateState(state => {
        const currentCoraState = queryStateToCoraState(state, getPossibleValues());
        const update = typeof updateOrFn === "function"
            ? updateOrFn(currentCoraState)
            : updateOrFn;
        const coraState = merge(currentCoraState, update);
        return coraStateToQueryState(coraState, state);
    }), []);
    return [coraState, useCoraStateUpdater(updater)];
}
export function CoraStateProvider({ children }) {
    return (_jsx(CoraStateContext.Provider, Object.assign({ value: useCoraStateFromQueryState() }, { children: children })));
}
export function useCoraState() {
    return React.useContext(CoraStateContext);
}
