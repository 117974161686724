var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Beacon, useBeacon } from "../../utils/Beacon";
import { useMemoIfEqual } from "../../utils/hooks";
export const InfoType = {
    ALL(info) {
        return true;
    },
    MODAL(info) {
        return info.modal;
    },
    NOT_MODAL(info) {
        return !info.modal;
    },
};
const infoStreamBeacon = Beacon({
    initial: [],
});
export function useInfoStream(type = "ALL") {
    const elements = useBeacon(infoStreamBeacon)[0].filter(InfoType[type]);
    return useMemoIfEqual(elements[elements.length - 1]);
}
export function infoTask(info, task) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            infoStreamBeacon.value(v => [...v, info]);
            return yield task();
        }
        finally {
            infoStreamBeacon.value(v => {
                return v.slice(0, v.length - 1);
            });
        }
    });
}
