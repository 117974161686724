import * as go from "gojs";
import { getConceptNodeKey, getItemsName } from "./diagramUtils";
import { uuidv4 } from "../../../utils/util";
export var NodeType;
(function (NodeType) {
    NodeType["CONCEPT"] = "CONCEPT";
    NodeType["STATEMENT"] = "STATEMENT";
    NodeType["TYPE"] = "TYPE";
    NodeType["SUB_GRAPH"] = "SUB_GRAPH";
})(NodeType || (NodeType = {}));
export var LinkType;
(function (LinkType) {
    LinkType["LINE"] = "LINE";
    LinkType["ARROW"] = "ARROW";
    LinkType["COMPLETE"] = "COMPLETE";
    LinkType["QUALIFIER"] = "QUALIFIER";
    LinkType["CONTEXT"] = "QUALIFIER";
})(LinkType || (LinkType = {}));
export class DiagramModel extends go.GraphLinksModel {
    constructor(nodes, links, init) {
        super(nodes, links, Object.assign({ linkKeyProperty: "key" }, init));
        this.gen = 0;
    }
    newUpdate() {
        this.gen++;
    }
    removePreviousGens() {
        this.removeLinkDataCollection(this.linkDataArray.filter(i => i._gen !== this.gen));
        this.removeNodeDataCollection(this.nodeDataArray.filter(i => i._gen !== this.gen));
    }
    updateGen(item) {
        this.setDataProperty(item, "_gen", this.gen);
    }
    get nodeDataArray() {
        return super.nodeDataArray;
    }
    set nodeDataArray(value) {
        super.nodeDataArray = value;
    }
    get linkDataArray() {
        return super.linkDataArray;
    }
    set linkDataArray(value) {
        super.linkDataArray = value;
    }
    findNodeDataForKey(key) {
        return super.findNodeDataForKey(key) || null;
    }
    findLinkDataForKey(key) {
        return super.findLinkDataForKey(key) || null;
    }
    addNodeData(node) {
        super.addNodeData(node);
    }
    addLinkData(link) {
        super.addLinkData(link);
    }
    getOrCreateTypeNode(concepts, key) {
        if (!key)
            key = DiagramModel.typeNodeKey(concepts);
        let node = this.findNodeDataForKey(key);
        if (!node) {
            node = {
                key,
                _gen: this.gen,
                text: getItemsName(concepts),
                type: NodeType.TYPE,
                isGroup: true,
                concepts: concepts,
                overrideConcepts: [],
                topConcepts: null,
            };
            this.addNodeData(node);
        }
        else {
            this.updateGen(node);
        }
        return node;
    }
    getOrCreateConceptNode(concept, key, groupKey) {
        if (!key)
            key = getConceptNodeKey(concept, groupKey);
        let node = this.findNodeDataForKey(key);
        if (!node) {
            node = Object.assign({ key, _gen: this.gen, text: concept.name, type: NodeType.CONCEPT, concept }, (groupKey && { group: groupKey }));
            this.addNodeData(node);
        }
        else {
            this.setDataProperty(node, "groupKey", groupKey || null);
            this.updateGen(node);
        }
        return node;
    }
    getOrCreateLink(from, to, type, directed, payload, text) {
        const key = DiagramModel.linkKey(from, to, text);
        let link = this.findLinkDataForKey(key);
        if (!link) {
            link = {
                key: key,
                _gen: this.gen,
                from: from.key,
                to: to.key,
                directed,
                fromNode: from,
                toNode: to,
                type,
                payload,
                text,
            };
            this.addLinkData(link);
        }
        else {
            this.setDataProperty(link, "directed", directed);
            this.updateGen(link);
        }
        return link;
    }
    getOrCreateStatementNode(key, relationIds, relations, from, to, context, directed, hidden, question) {
        let node = this.findNodeDataForKey(key);
        node && this.removeNodeData(node);
        const text = getItemsName(relations);
        node = {
            key,
            _gen: this.gen,
            text,
            type: NodeType.STATEMENT,
            relationIds,
            relations,
            from,
            to,
            directed,
            qualifiers: [],
            context,
            hidden,
            question,
        };
        this.addNodeData(node);
        return node;
    }
    getOrCreateSubGraphNode(key, text) {
        let node = this.findNodeDataForKey(key);
        if (!node) {
            node = {
                key,
                _gen: this.gen,
                text,
                type: NodeType.SUB_GRAPH,
                isGroup: true,
                selectable: false,
            };
            this.addNodeData(node);
        }
        else {
            this.updateGen(node);
        }
        return node;
    }
    setGroup(n, sub) {
        this.setDataProperty(n, "group", sub.key);
    }
    getNodesForKeys(keys) {
        return keys.map(k => this.findNodeDataForKey(k)).filter(Boolean);
    }
    getStatementNodes() {
        return this.nodeDataArray.filter(n => n.type === NodeType.STATEMENT);
    }
    getStatementNode(key) {
        return key ? this.findNodeDataForKey(key) : null;
    }
    static getModel(diagram) {
        return diagram.model;
    }
    static typeNodeKey(concepts) {
        return DiagramModel.typeNodeKeyFromNames(concepts.map(c => c.name));
    }
    static typeNodeKeyFromNames(names) {
        const name = names.length ? names.sort().join(",") : uuidv4();
        return "[TYPE " + name + "]";
    }
    static linkKey(from, to, text) {
        return `${from.key}:${to.key}${text ? ":" + text : ""}`;
    }
}
export function getSelectedNodeKeys(diagram) {
    return diagram.selection
        .toArray()
        .map(p => diagram.model.findNodeDataForKey(p.key))
        .filter(Boolean)
        .map(n => n.key);
}
