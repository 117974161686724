import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useReactFlow, useViewport } from "reactflow";
import { focusModelBuilder, offsetToOrigin } from "../diagramUtils";
import { ElementType } from "../model";
import { useShortcuts } from "../../../../utils/keyboard";
import { ORIGIN } from "../geometry";
import { commitStaged, useInstallStage, useStage } from "../Stage";
import { addWindowEventHandlers, getPosition } from "../../../../utils/events";
import { Mode, useDiagramMode } from "../DiagramMode";
import { useDiagramStateUpdater } from "../DiagramState";
const KEY_STEP = 20;
export function StagingLayer({ element }) {
    const mode = useDiagramMode();
    const [dropLocation, setDropLocation] = React.useState(ORIGIN);
    const location = React.useRef(ORIGIN);
    const dUpdater = useDiagramStateUpdater();
    const flow = useReactFlow();
    const [params, setParams] = React.useState();
    const staging = !!params;
    useInstallStage(params => {
        setDropLocation(location.current);
        setParams(params);
        mode(params ? Mode.staging : Mode.normal);
    });
    const viewport = useViewport();
    const stage = useStage();
    const bindKeys = React.useMemo(() => {
        const arrows = {
            up: [0, -1],
            down: [0, 1],
            left: [-1, 0],
            right: [1, 0],
        };
        return Object.assign({ enter: () => commit(dropLocation), escape: stage.dismiss }, Object.fromEntries(Object.entries(arrows)
            .map(([key, [mx, my]]) => [
            [key, move(KEY_STEP, mx, my)],
            ["shift+" + key, move(1, mx, my)],
        ])
            .flat()));
        function move(inc, mx, my) {
            return () => setDropLocation(({ x, y }) => ({ x: x + inc * mx, y: y + inc * my }));
        }
    }, [params, dropLocation]);
    useShortcuts(bindKeys, !staging);
    React.useEffect(() => {
        return addWindowEventHandlers({
            mousemove(e) {
                location.current = getPosition(element, e);
                params && setDropLocation(location.current);
            },
            mousedown(e) {
                commit(getPosition(element, e));
            },
        });
    }, [params, element, viewport]);
    const zoom = viewport.zoom;
    return React.useMemo(() => {
        return !params ? null : (_jsx("svg", Object.assign({ className: "absolute w-full h-full z-20" }, { children: offsetToOrigin(params.nodes, params.offset).map((n, idx) => {
                return (_jsx("rect", { x: dropLocation.x + n.position.x * zoom, y: dropLocation.y + n.position.y * zoom, width: n.width * zoom, height: n.height * zoom, rx: (n.type !== ElementType.RELATION ? 8 : 2) * zoom, strokeDasharray: `${zoom * 4}`, strokeWidth: zoom, fill: "none", stroke: "black" }, idx));
            }) })));
    }, [params === null || params === void 0 ? void 0 : params.nodes, zoom, dropLocation]);
    function commit(dropLocation) {
        if (!params)
            return;
        commitStaged(dUpdater.commit, { nodes: flow.getNodes(), edges: flow.getEdges(), viewport }, params, dropLocation);
        stage.dismiss();
        setTimeout(() => focusModelBuilder(), 50);
    }
}
