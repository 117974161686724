import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ClusteringMode, CRCDirection } from "../api/types";
import { CONCEPT_1_COLOR_VALS, CONCEPT_2_COLOR_VALS, CONTEXT_CONCEPT_COLOR_VALS, RELATION_COLOR_VALS, } from "./lf";
import { concept1argumentName, concept2argumentName, useCorpusFilters, } from "../utils/semanticSearchUtil";
import { DirectionPickerToggle } from "./DirectionPicker";
import { HeaderButton } from "../../shared/components/buttons/Buttons";
import { useSuggestQuestionsFromConcepts } from "../../shared/components/SuggestedQuestions";
import { invalidCorpusIds } from "../utils/sourceManagement";
import { ConceptsPicker, RelationsPicker } from "./picker/PickerFields";
import { updateCurrentSession, useCurrentSession, } from "../utils/sessionManagement";
import { CONCEPT_1_CAPTION, CONCEPT_2_CAPTION, CONTEXT_CAPTION, getPopupMenuEntries, } from "../utils/coraUtil";
import { useEffectIfDifferent, useParams } from "../../utils/hooks";
import { Close, ConfigHorizontal, NL, RightArrow } from "./Icons";
import { landmarkIdTag } from "../../shared/components/assist/Tour";
import { ApertureSlider } from "./aperture/ApertureSlider";
import { showSearchApertureModal } from "./aperture/SearchAperture";
import { QuestionInput } from "./queryAnalysis/QuestionInput";
import { classes } from "../../utils/classes";
import { ListToggle } from "../redesign/generic/ListToggle";
import { useQueryState } from "../utils/queryState/queryState";
import { useCoraState } from "./CoraStateProvider";
import { useOnQuestionPost, useQuestionAnalysisHelper, } from "./queryAnalysis/queryAnalysis";
import { isEmptyParams } from "../utils/queryState/DMParams";
import { removeStateConstraint } from "../utils/queryState/mutations";
export function QueryBar() {
    const [queryState, stateUpdater] = useQueryState();
    const [coraState, coraStateUpdater] = useCoraState();
    const { concepts1, relations, concepts2, crcDirection, contextConcepts, corpus_ids, } = coraState;
    const { conceptClustering, structuredQuery, showStructuredQuery, searchAperture, modelBuilderAvailable, } = useCurrentSession();
    const analyze = useQuestionAnalysisHelper();
    useOnQuestionPost(text => {
        if (showStructuredQuery)
            updateCurrentSession({ showStructuredQuery: false });
        analyze(text);
    });
    const readOnly = false;
    const disabled = invalidCorpusIds(corpus_ids) || readOnly;
    const corpusFilters = useCorpusFilters(coraState);
    const suggest = useSuggestQuestionsFromConcepts(coraState);
    const [selectedConceptClusters, updateSelectedConceptClusters] = useParams({
        concepts1: [],
        concepts2: [],
        contextConcepts: [],
    });
    useEffectIfDifferent(() => {
        const conceptClusters = selectedConceptClusters.concepts1.concat(selectedConceptClusters.concepts2);
        if (conceptClusters.length) {
            suggest(conceptClusters);
        }
    }, [], [selectedConceptClusters], true);
    const arrow = crcDirection !== CRCDirection.BOTH ? (_jsx("div", Object.assign({ className: "z-10 text-xs absolute border border-neutral-800 rounded-full flex items-center px-1 py-1 bg-accent-900", style: { top: -5, left: -10 } }, { children: _jsx(RightArrow, { style: { fontSize: 11 } }) }))) : null;
    const nothingSelected = relations.length + concepts1.length + concepts2.length === 0;
    const searchApertureSlider = searchAperture && (_jsx(ApertureSlider, Object.assign({ coraState: coraState, coraStateUpdater: coraStateUpdater }, { children: _jsx(HeaderButton, Object.assign({ disabled: nothingSelected, onMouseDown: e => {
                showSearchApertureModal(coraState, coraStateUpdater);
            } }, { children: _jsx(ConfigHorizontal, { className: "text-base text-neutral-500" }) })) })));
    const showCRC = structuredQuery && showStructuredQuery;
    const structuredQueryInput = (_jsxs(_Fragment, { children: [_jsx(ConceptsPicker, { corpus_ids: corpus_ids, disabled: disabled, clustering_mode: conceptClustering, corpus_filter: corpusFilters.concepts1, title: CONCEPT_1_CAPTION, selected: concepts1, setSelected: (concepts1, concept_clusters) => {
                    coraStateUpdater({ concepts1, text: "" });
                    updateSelectedConceptClusters({ concepts1: concept_clusters });
                }, placeholder: "select concept(s)\u2026", tagColorVals: CONCEPT_1_COLOR_VALS, tagColorBase: "notification_blue", argument_name: concept1argumentName(crcDirection), popupMenuEntries: getPopupMenuEntries("concepts1", coraState, coraStateUpdater), landmarkId: "concept1Picker", entityType: "PrimaryConcept" }), _jsx("div", Object.assign({ className: "relative" }, { children: arrow })), _jsx(RelationsPicker, { corpus_ids: corpus_ids, concepts1: concepts1, concepts2: concepts2, disabled: disabled, clustering_mode: ClusteringMode.NONE, corpus_filter: corpusFilters.relations, title: "Relation", selected: relations, setSelected: relations => coraStateUpdater({ relations, text: "" }), placeholder: "select relation(s)\u2026", tagColorVals: RELATION_COLOR_VALS, tagColorBase: "alert_green", landmarkId: "relationPicker" }), _jsx("div", Object.assign({ className: "relative" }, { children: arrow })), _jsx(ConceptsPicker, { corpus_ids: corpus_ids, disabled: disabled, clustering_mode: conceptClustering, corpus_filter: corpusFilters.concepts2, title: CONCEPT_2_CAPTION, selected: concepts2, setSelected: (concepts2, concept_clusters) => {
                    coraStateUpdater({ concepts2, text: "" });
                    updateSelectedConceptClusters({ concepts2: concept_clusters });
                }, placeholder: "select concept(s)\u2026", tagColorVals: CONCEPT_2_COLOR_VALS, tagColorBase: "alert_yellow", argument_name: concept2argumentName(crcDirection), popupMenuEntries: getPopupMenuEntries("concepts2", coraState, coraStateUpdater), landmarkId: "concept2Picker", entityType: "LinkedConcept", placement: "bottomRight" }), _jsx(ConceptsPicker, { corpus_ids: corpus_ids, disabled: disabled, clustering_mode: conceptClustering, corpus_filter: corpusFilters.context, title: CONTEXT_CAPTION, tooltip: "Find anywhere in the document", selected: contextConcepts, setSelected: (contextConcepts, concept_clusters) => {
                    coraStateUpdater({ contextConcepts, text: "" });
                    updateSelectedConceptClusters({ contextConcepts: concept_clusters });
                }, placeholder: "select concept(s)\u2026", tagColorVals: CONTEXT_CONCEPT_COLOR_VALS, tagColorBase: "accent", argument_name: undefined, popupMenuEntries: getPopupMenuEntries("contextConcepts", coraState, coraStateUpdater), landmarkId: "contextPicker", entityType: "ContextConcept", placement: "bottomRight" }), _jsx(DirectionPickerToggle, { disabled: disabled, direction: crcDirection, setDirection: crcDirection => coraStateUpdater({ crcDirection }), className: "text-white " }), " ", _jsx(HeaderButton, Object.assign({ className: showCRC ? "mt-2" : "", tooltip: "Clear current query", onMouseDown: () => {
                    stateUpdater(removeStateConstraint(queryState, queryState.activeConstraintId));
                }, disabled: isEmptyParams(queryState.dmParams) ||
                    queryState.activeConstraintId === "" }, { children: _jsx(Close, { className: "text-lg text-neutral-500" }) }))] }));
    const queryInput = showCRC ? (structuredQueryInput) : (_jsx(QuestionInput, { analyze: analyze }));
    return (_jsx("div", Object.assign({ className: "flex flex-col space-y-2 items-stretch w-full" }, landmarkIdTag("tour-crcOverview"), { children: _jsxs("div", Object.assign({ className: "flex flex-row space-x-2 items-center" }, { children: [structuredQuery && (_jsx("div", Object.assign({ className: classes("flex self-stretch items-center text-tiny space-x-1") }, { children: _jsx(ListToggle, { style: { padding: "1px" }, toggleEntries: [
                            {
                                key: "nl",
                                icon: _jsx(NL, { className: "text-md" }),
                                onClick: () => {
                                    updateCurrentSession({
                                        showStructuredQuery: !showStructuredQuery,
                                    });
                                },
                                tooltip: "Natural Language Query",
                            },
                            {
                                key: "structured",
                                icon: _jsx("span", Object.assign({ className: "text-xs font-bold" }, { children: "CRC" })),
                                onClick: () => {
                                    updateCurrentSession({
                                        showStructuredQuery: !showStructuredQuery,
                                    });
                                },
                                tooltip: "Structured Query",
                            },
                        ], activeToggle: showStructuredQuery ? "structured" : "nl", direction: "row" }) }))), _jsxs("div", Object.assign({ className: "flex-1 flex items-center space-x-1" }, { children: [queryInput, searchApertureSlider] }))] })) })));
}
