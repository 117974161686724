var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { textComparator } from "../../utils/comparators";
import { toggle } from "../../utils/collections";
import { FeatureIdentifier, } from "../api/types";
import { getEvidenceCorpusFilter } from "./evidence";
import { useRemoteQuery } from "../../utils/query/remoteQuery";
import { FeedbackWidget, sendFeedback } from "./feedback";
import { showNotification } from "../../shared/components/notifications";
import { CorpusCapability } from "../../utils/capabilities/capabilities";
export function useRefutingSuggestions(coraState, disabled, loader, mapper) {
    const corpus_filter = getEvidenceCorpusFilter(coraState);
    const params = disabled
        ? undefined
        : {
            corpus_filter,
            count: 10,
        };
    const refutingSuggestions = useRemoteQuery({
        loader,
        params,
        mapper,
    });
    return {
        refutingSuggestions,
        params,
    };
}
export function getItemKey(item, nameGetter) {
    return nameGetter(item);
}
export function sendRefutingFeedback({ nameGetter, setFeedback, identifier, capability, params, }) {
    return (item, type, context) => __awaiter(this, void 0, void 0, function* () {
        if (!type)
            return;
        setFeedback((f) => (Object.assign(Object.assign({}, f), { [nameGetter(item)]: type })));
        yield sendFeedback(identifier, capability, type, item, params);
        showNotification("Feedback sent");
    });
}
export function getItemRenderer(_a) {
    var { item, nameGetter, feedback, action } = _a, refutingSelectionProps = __rest(_a, ["item", "nameGetter", "feedback", "action"]);
    return (_jsxs("div", Object.assign({ className: "flex w-full py-1" }, { children: [_jsx("div", Object.assign({ className: "flex flex-1 items-center", style: { minWidth: 100 }, onClick: action }, { children: nameGetter(item) })), _jsx(FeedbackWidget, { item: Object.assign({ id: nameGetter(item) }, item), expanded: false, sendFeedbackFn: sendRefutingFeedback(Object.assign({ nameGetter: item => nameGetter(item) }, refutingSelectionProps)), feedback: feedback })] })));
}
export function getSelectionOption(_a) {
    var { item, nameGetter, selected, updateSelection, checkBoxClassName } = _a, renderProps = __rest(_a, ["item", "nameGetter", "selected", "updateSelection", "checkBoxClassName"]);
    function action() {
        updateSelection(toggle(selected, item));
    }
    const caption = getItemRenderer
        ? getItemRenderer(Object.assign(Object.assign({}, renderProps), { item,
            nameGetter,
            action }))
        : getItemKey(item, nameGetter);
    return _jsx("div", Object.assign({ className: "flex flex-1" }, { children: caption }));
}
export function getConceptItemRenderer(params) {
    const renderWithoutFeedback = (selected, updateSelection, feedback, setFeedback) => (item) => getSelectionOption({
        item,
        nameGetter: i => i.refuting_concept_cluster.name,
        identifier: FeatureIdentifier.SUGGEST_CONCEPTS,
        capability: CorpusCapability.SEARCH_CONCEPTS_REFUTING,
        params,
        selected,
        updateSelection,
        feedback,
        setFeedback,
    });
    return renderWithoutFeedback;
}
export function getRelationItemRenderer(params) {
    const renderWithoutFeedback = (selected, updateSelection, feedback, setFeedback) => (item) => getSelectionOption({
        item,
        nameGetter: i => i.name,
        identifier: FeatureIdentifier.SUGGEST_RELATIONS,
        capability: CorpusCapability.SEARCH_RELATIONS_REFUTING,
        params,
        selected,
        updateSelection,
        feedback,
        setFeedback,
    });
    return renderWithoutFeedback;
}
export function getRefutingConceptTuples(suggestions) {
    return suggestions === null || suggestions === void 0 ? void 0 : suggestions.map((s) => s.refuting_concept_clusters.map(rcc => ({
        concept_cluster: s.concept_cluster,
        refuting_concept_cluster: rcc,
    }))).flat();
}
function conceptSorter(a, b) {
    return textComparator(a.refuting_concept_cluster.name, b.refuting_concept_cluster.name);
}
export function sameName(a, b) {
    return a.name === b.name;
}
export function areRefutingConceptsEquivalent(a, b) {
    return (sameName(a.concept_cluster, b.concept_cluster) &&
        sameName(a.refuting_concept_cluster, b.refuting_concept_cluster));
}
export function refutingConceptResponseMapper(suggestions) {
    const options = getRefutingConceptTuples(suggestions) || [];
    return options.sort(conceptSorter);
}
export function getRelevantSelections(suggestions, selectedConcepts) {
    if (!suggestions || !selectedConcepts)
        return [];
    return suggestions.filter((c) => selectedConcepts.map(x => x.name).includes(c.concept_cluster.name));
}
