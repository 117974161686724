import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Empty } from "./Empty";
import React from "react";
import { Beacon, useBeacon } from "../../utils/Beacon";
import { classes } from "../../utils/classes";
import { Button } from "antd";
export function Loading({ loading, style, }) {
    const onCancel = typeof loading === "object" ? loading.onCancel : undefined;
    const message = typeof loading !== null && typeof loading === "object"
        ? loading.message
        : loading;
    const empty = _jsx(Empty, { loading: message });
    return !loading ? null : (_jsx("div", Object.assign({ className: classes("flex flex-col items-stretch absolute w-full h-full overflow-clip"), style: Object.assign({ zIndex: 1000 }, style) }, { children: onCancel ? (_jsxs("div", Object.assign({ className: "rounded-md shadow-2xl self-center bg-white bg-white flex flex-col items-center space-y-4", style: { padding: 32, minWidth: 384 } }, { children: [empty, _jsx(Button, Object.assign({ type: "primary", onClick: onCancel }, { children: "Cancel" }))] }))) : (empty) })));
}
const loadingBeacon = Beacon({ initial: [] });
export function useLoading() {
    const [loading] = useBeacon(loadingBeacon);
    return (_jsx(Loading, { loading: loading.length > 0 ? loading[loading.length - 1] : false, style: { backgroundColor: "rgba(0,0,0, .3)" } }));
}
export function setLoading(loading) {
    loadingBeacon.value(v => !loading ? v.slice(0, v.length - 1) : [...v, loading]);
}
export function clearLoading() {
    loadingBeacon.value([]);
}
