var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { Table as AntTable } from "antd";
export function Table(_a) {
    var props = __rest(_a, []);
    return _jsx(AntTable, Object.assign({}, props));
}
export function useColumns(columns, { sort }) {
    return useMemo(() => !sort
        ? columns
        : columns.map(c => sort.key == c.key ? Object.assign(Object.assign({}, c), { sortOrder: sort.direction }) : c), [sort]);
}
