import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../utils/classes";
import { ActionButton } from "../../../shared/components/buttons/Buttons";
import { Trash } from "../Icons";
import Tippy from "@tippyjs/react";
import { Checkbox } from "antd";
import { ExtendedEvidenceText } from "./ExtendedEvidenceText";
export function EvidenceRow({ evidence, className, documentId, renderEvidenceActions, filterText, renderAsHtml, showSurrounding, isSelected, isHighlighted, selectEvidence, removeEvidence, openEvidence, onEvidenceTextSelected, annotationProvider, }) {
    function addAndRemoveButtons(evidence) {
        return (_jsxs("div", Object.assign({ className: "" }, { children: [removeEvidence && (_jsx(ActionButton, { onClick: e => removeEvidence(evidence), icon: _jsx(Trash, {}) })), selectEvidence && (_jsx(Tippy, Object.assign({ content: "Select" }, { children: _jsx("div", { children: _jsx(Checkbox, { className: "ml-2 mr-2", checked: isSelected && isSelected(evidence.id), onChange: () => selectEvidence(evidence) }) }) })))] })));
    }
    return (_jsxs("div", Object.assign({ className: classes("flex justify-between py-2 flex items-start border-t border-gray-200", isHighlighted && isHighlighted(evidence.id)
            ? "bg-accent-50"
            : isSelected && isSelected(evidence.id)
                ? "bg-accent-100 hover:bg-accent-100"
                : "hover:bg-gray-100", className) }, { children: [_jsxs("div", Object.assign({ className: "flex flex-start items-start" }, { children: [_jsx("div", Object.assign({ className: "w-[24px] min-w-[24px] mr-2" }, { children: addAndRemoveButtons(evidence) })), _jsx(ExtendedEvidenceText, { evidence: evidence, truncationThreshold: 500, filterText: filterText, renderAsHtml: renderAsHtml, annotationProvider: annotationProvider, onEvidenceTextSelected: onEvidenceTextSelected, showSurrounding: showSurrounding })] })), _jsx("div", Object.assign({ className: "flex flex-col items-center px-2 space-y-1" }, { children: renderEvidenceActions && renderEvidenceActions(evidence, documentId) }))] })));
}
