var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../utils/classes";
import { ItemsPicker } from "../picker/ItemsPicker";
import { useEffectIfDifferent } from "../../../utils/hooks";
export function RefutingPicker(_a) {
    var { className, refutingSuggestions, selection, updateSelection, getItemRenderer } = _a, props = __rest(_a, ["className", "refutingSuggestions", "selection", "updateSelection", "getItemRenderer"]);
    const [selected, setSelected] = React.useState([]);
    const [feedback, setFeedback] = React.useState({});
    const itemRenderer = React.useMemo(() => (item) => getItemRenderer(selected, updateSelection, feedback, setFeedback)(item), [selected, updateSelection, feedback, setFeedback]);
    useEffectIfDifferent(() => {
        if (!selection) {
            if (refutingSuggestions.value) {
                setSelected(refutingSuggestions.value);
            }
        }
        else {
            setSelected(selection);
        }
    }, [], [refutingSuggestions.value, selection]);
    return (_jsx("div", Object.assign({ className: classes(className, "relative h-full flex-1") }, { children: _jsx(ItemsPicker, Object.assign({ selected: selected, updateSelection: updateSelection, entries: refutingSuggestions.value, loading: refutingSuggestions.loading, headerCheckbox: true, renderItem: item => itemRenderer(item), showIndeterminate: false, showIcon: false, wrapperClassName: "h-full" }, props)) })));
}
