import { EvidenceType, } from "../api/types";
import { useDebouncedValue } from "../../utils/hooks";
import { getCorpusFilter, isEmptyQuery, mergeNamedMembers, validateTextFilter, } from "./semanticSearchUtil";
import { API } from "../api/api";
import { pickFromObject } from "../../utils/collections";
import { usePaginatedRemoteQuery } from "../../utils/query/paginatedRemoteQuery";
import { useCurrentSession } from "./sessionManagement";
import { getSize } from "../../utils/util";
export const EVIDENCE_COUNT = 100;
export function getEvidenceCorpusFilter(corpusFilterParams, refuting = false) {
    const { corpus_ids, refuting_relations, refuting_concepts } = corpusFilterParams;
    return getCorpusFilter(Object.assign(Object.assign(Object.assign({ corpus_ids }, pickFromObject(corpusFilterParams, "concepts1", "concepts1Override", "concepts2", "concepts2Override", "relations", "crcDirection", "required_arguments", "argClauses", "keywordMetadata", "rangeMetadata", "rangeMetadataOverride", "booleanMetadata", "queries", "extraConcepts", "extraConceptsOverride", "contextConcepts", "contextConceptsOverride", "aperture", "text")), (refuting && refuting_relations && { refuting_relations })), (refuting && refuting_concepts && { refuting_concepts })));
}
export function evidenceTypeFromRefuting(refuting) {
    return refuting ? EvidenceType.REFUTING : EvidenceType.SUPPORTING;
}
export function useEvidence(filterParams, disabled) {
    const { evidenceFilter = "", refuting, sortFieldId, sortOrder, concepts1, concepts2, concepts1Possible, concepts2Possible, concepts1Override, concepts2Override, } = filterParams;
    const noSelection = isEmptyQuery(filterParams);
    const search_text = validateTextFilter(useDebouncedValue(evidenceFilter));
    const noResults = (concepts1Possible && !concepts1Possible.length) ||
        (concepts2Possible && !concepts2Possible.length);
    const { rank_with_vector_search, rankWithVectorSearchPossible } = useCurrentSession();
    const corpus_filter = getEvidenceCorpusFilter(Object.assign(Object.assign({}, filterParams), { concepts1: concepts1Possible && !getSize(concepts1Override)
            ? mergeNamedMembers(concepts1Possible)
            : concepts1, concepts2: concepts2Possible && !getSize(concepts2Override)
            ? mergeNamedMembers(concepts2Possible)
            : concepts2 }), refuting);
    const evidenceRequestParams = disabled || noSelection || noResults
        ? undefined
        : Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ corpus_filter, count: EVIDENCE_COUNT }, (search_text.length && { search_text })), { evidence_type: refuting
                ? EvidenceType.REFUTING
                : EvidenceType.SUPPORTING }), (sortFieldId && { sort_field: sortFieldId })), (sortFieldId && sortOrder && { sort_order: sortOrder })), { rank_with_vector_search: rank_with_vector_search && rankWithVectorSearchPossible });
    const evidenceAndTotals = usePaginatedRemoteQuery({
        loader: API.searchEvidence,
        params: evidenceRequestParams,
        pageAppender(currentValue, toAppend) {
            return {
                value: !currentValue
                    ? toAppend
                    : Object.assign(Object.assign({}, toAppend), { evidence: currentValue.evidence.concat(toAppend.evidence) }),
                lastPage: toAppend.evidence.length < EVIDENCE_COUNT,
            };
        },
    });
    return {
        evidenceAndTotals,
        noSelection,
        corpus_filter,
        pageSize: EVIDENCE_COUNT,
        evidenceRequestParams,
    };
}
