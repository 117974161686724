var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getURLParams, setURLParams } from "../../../utils/routing";
import { getCoraStateFromUrl } from "../friendlyParams";
import { API } from "../../api/api";
import { isEmptyQuery } from "../semanticSearchUtil";
import { EMPTY_QUERY_STATE } from "./queryState";
import { isEqual } from "lodash";
import { coraStateToQueryState } from "./coraStateToQueryState";
export const QueryId = (function () {
    return {
        get(params = getURLParams()) {
            var _a;
            return ((_a = params.queryId) === null || _a === void 0 ? void 0 : _a.trim()) || "";
        },
        set(queryId) {
            queryId = queryId.trim();
            if (QueryId.get() !== queryId) {
                setURLParams(queryId ? { queryId } : {});
            }
        },
        getQueryIdOrLegacyFromUrl(urlParams, corpus_ids = []) {
            return __awaiter(this, void 0, void 0, function* () {
                const queryId = QueryId.get(urlParams);
                if (queryId)
                    return queryId;
                const { coraState } = yield getCoraStateFromUrl(urlParams, corpus_ids);
                return yield QueryId.getQueryIdForCoraState(coraState);
            });
        },
        getQueryIdForCoraState(coraState) {
            return __awaiter(this, void 0, void 0, function* () {
                return isEmptyQuery(coraState)
                    ? ""
                    : yield API.getUrlId({
                        data: coraStateToQueryState(coraState),
                    });
            });
        },
    };
})();
export function loadQueryState(id, ignoreErrorHandler = false) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield API.getUrlData({ id }, ignoreErrorHandler);
    });
}
export function saveQueryState(state) {
    return __awaiter(this, void 0, void 0, function* () {
        return isEqual(state, EMPTY_QUERY_STATE)
            ? ""
            : yield API.getUrlId({ data: state });
    });
}
