import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FormBuilder from "antd-form-builder";
import React, { useCallback } from "react";
import { Button, Form, InputNumber, Modal } from "antd";
import { pick } from "lodash";
import { newSession, updateCurrentSession, useCurrentSession, } from "../utils/sessionManagement";
import { useParams } from "../../utils/hooks";
import "./Config.less";
import { DEFAULT_LOCAL_CONFIG, useLocalConfig } from "../utils/localConfig";
import { closeModal, ModalType, openModal } from "./modals/useModalState";
const SESSION_CONFIG_KEYS = [
    "conceptClustering",
    "conjunctionBindingsSearchWidth",
    "expandedEvidence",
    "hierarchicalFacets",
    "rankWithVectorSearchPossible",
    "queryGraph",
    "structuredQuery",
    "searchAperture",
    "modelBuilderAvailable",
    "expandedFacetCount",
    "autoExpandEvidence",
];
function useConfigFromSession() {
    return pick(useCurrentSession(), ...SESSION_CONFIG_KEYS);
}
const guruModeFields = new Set([
    "rankWithVectorSearchPossible",
    "autoExpandEvidence",
]);
const clusteringModes = ["NONE", "MINIMAL", "MODERATE", "AGGRESSIVE"];
export function useConfigDialog({ coraStateUpdater, }) {
    const [form] = Form.useForm();
    const [{ visible, guruMode }, update] = useParams({
        visible: false,
        guruMode: false,
    });
    const [localConfig, updateLocalConfig] = useLocalConfig();
    const [inProgressConfig, setInProgressConfig] = React.useState(useConfigFromSession());
    const sessionConfig = useConfigFromSession();
    const configValues = Object.assign(Object.assign({}, localConfig), sessionConfig);
    function validExpandedFacetCount(value) {
        return value >= 15 && value <= 100;
    }
    const fields = [
        {
            key: "conceptClustering",
            label: "Concept Clustering Mode",
            widget: "select",
            options: clusteringModes,
        },
        {
            key: "expandedEvidence",
            label: "Expanded Evidence",
            widget: "checkbox",
        },
        {
            key: "conjunctionBindingsSearchWidth",
            label: "Conjunction Bindings Count",
            widget: InputNumber,
        },
        {
            key: "expandedFacetCount",
            label: "Expanded Filter Count",
            widget: InputNumber,
            rules: [
                {
                    validator: (rule, value) => {
                        return new Promise((resolve, reject) => {
                            if (!validExpandedFacetCount(value)) {
                                reject(new Error(`Value must be between 15 and 100`));
                            }
                            else {
                                resolve(true);
                            }
                        });
                    },
                },
            ],
        },
        {
            key: "hierarchicalFacets",
            label: "Hierarchical Concept Aspects (BETA)",
            widget: "checkbox",
        },
        {
            key: "rankWithVectorSearchPossible",
            label: "Allow Rank with vector search",
            widget: "checkbox",
        },
        {
            key: "autoExpandEvidence",
            label: "Long evidence never collapses",
            widget: "checkbox",
        },
        {
            key: "structuredQuery",
            label: "Structured Query",
            widget: "checkbox",
        },
        {
            key: "searchAperture",
            label: "Search Aperture",
            widget: "checkbox",
        },
        {
            key: "queryGraph",
            label: "Query Graph (BETA)",
            widget: "checkbox",
        },
        {
            key: "modelBuilderAvailable",
            label: "Model Builder (BETA)",
            widget: "checkbox",
        },
    ].filter(({ key }) => guruMode || !guruModeFields.has(key));
    const showModal = (guruMode) => {
        openModal(ModalType.CONFIG);
        form.setFieldsValue(configValues);
        update({ visible: true, guruMode });
    };
    const hideModal = useCallback(() => {
        closeModal(ModalType.CONFIG);
        update({ visible: false });
    }, []);
    const updateConfigProgress = useCallback(() => {
        const fieldsValues = form.getFieldsValue();
        const newConfig = pick(fieldsValues, ...SESSION_CONFIG_KEYS);
        setInProgressConfig(newConfig);
    }, [form]);
    const updateConfig = useCallback(() => {
        const fieldsValues = form.getFieldsValue();
        console.log("Config:", fieldsValues);
        const localConfig = pick(fieldsValues, ...Object.keys(DEFAULT_LOCAL_CONFIG));
        updateLocalConfig(localConfig);
        updateCurrentSession(inProgressConfig);
    }, [form, inProgressConfig, updateLocalConfig]);
    const { suggestedQuestionsHistory } = useCurrentSession();
    const modalContainer = (_jsx("div", { children: visible && (_jsx(Modal, Object.assign({ width: 700, title: `Configuration${guruMode ? " (Guru Mode)" : ""}`, closable: false, open: visible, destroyOnClose: true, footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ disabled: !suggestedQuestionsHistory.length, onClick: () => {
                            Modal.confirm({
                                title: "Clear Suggested Questions History?",
                                onOk() {
                                    updateCurrentSession(s => ({
                                        suggestedQuestionsHistory: [],
                                    }));
                                },
                            });
                        } }, { children: "Clear Suggested Questions History" }), "clear"), _jsx(Button, Object.assign({ onClick: () => {
                            form.setFieldsValue(Object.assign(Object.assign({}, pick(newSession(), ...SESSION_CONFIG_KEYS)), DEFAULT_LOCAL_CONFIG));
                            updateLocalConfig({});
                        } }, { children: "Reset" })), _jsx(Button, Object.assign({ onClick: hideModal }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "primary", onClick: () => {
                            updateConfig();
                            hideModal();
                        }, disabled: !validExpandedFacetCount(inProgressConfig.expandedFacetCount) }, { children: "Ok" }))] }) }, { children: _jsx(Form, Object.assign({ className: "Config", form: form, initialValues: configValues, onValuesChange: updateConfigProgress }, { children: _jsx(FormBuilder, { meta: {
                        // formItemLayout: {labelCol: "ant-col-16"},
                        // viewMode: true,
                        fields,
                    }, form: form }) })) }))) }));
    return {
        showModal,
        modalContainer,
    };
}
