var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState, useMemo } from "react";
import FormBuilder from "antd-form-builder";
import { Button, Form, Modal, Spin } from "antd";
import { API } from "../../api/api";
import { SelectionButton } from "../../../shared/components/buttons/SelectionButton";
import { Export } from "../Icons";
import { pushError } from "../../../utils/errorStream";
import { useFlags } from "launchdarkly-react-client-sdk";
function getEvidenceData(downloadRequest, setCurrentlyDownloading) {
    return __awaiter(this, void 0, void 0, function* () {
        console.info("Download Config:", downloadRequest);
        setCurrentlyDownloading(true);
        const res = yield API.exportEvidence(downloadRequest);
        setCurrentlyDownloading(false);
        if (res) {
            console.error("Error Downloading Evidence: ", res);
            if (res instanceof Error) {
                pushError({ error: { status: 401, message: res.message } });
            }
        }
    });
}
const META = [
    {
        key: "filename",
        label: "File Name",
        widget: "input",
    },
];
export function useExportEvidenceAddOn({ coraState, corpus_filter, index, }) {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [currentlyDownloading, setCurrentlyDownloading] = useState(false);
    const { exportEvidence } = useFlags();
    const showModal = useCallback(() => {
        setVisible(true);
    }, [setVisible]);
    const hideModal = useCallback(() => setVisible(false), [setVisible]);
    const requestParams = useMemo(() => {
        const { evidenceFilter, aperture } = coraState;
        return { corpus_filter, search_text: evidenceFilter, aperture };
    }, [coraState, corpus_filter]);
    const handleSubmit = useCallback(() => {
        const { filename } = form.getFieldsValue();
        if (!filename)
            return;
        if (requestParams) {
            const request = Object.assign(Object.assign({}, requestParams), { link_to_application: window.location.href });
            getEvidenceData({ filename, exportParams: request }, setCurrentlyDownloading);
        }
        hideModal();
    }, [hideModal, requestParams]);
    return {
        name: "export-evidence",
        index,
        selector(key) {
            return exportEvidence ? (_jsxs("div", { children: [_jsx(SelectionButton, { tooltip: "Export your evidence", className: "px-2 text-tiny", onClick: showModal, disabled: currentlyDownloading, icon: _jsx(Export, { className: "text-lg" }), caption: _jsx(_Fragment, { children: currentlyDownloading && (_jsx(Spin, { size: "small", className: "py-auto ml-1" })) }) }), _jsx(Modal, Object.assign({ width: 700, title: "Export Evidence", closable: false, open: visible, destroyOnClose: true, onCancel: hideModal, okText: "Ok", footer: [
                            _jsx(Button, Object.assign({ type: "primary", onClick: hideModal }, { children: "Cancel" }), "cancel"),
                            _jsx(Button, Object.assign({ type: "primary", onClick: handleSubmit }, { children: "Ok" }), "submit"),
                        ] }, { children: _jsx(Form, Object.assign({ form: form, onFinish: handleSubmit }, { children: _jsx(FormBuilder, { meta: META, form: form }) })) }))] }, key)) : null;
        },
    };
}
