var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactPlayer from "react-player";
import React from "react";
import "./VideoPlayer.less";
import { classes } from "../../utils/classes";
export function VideoPlayer(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ className: classes("VideoPlayer relative", className), style: { overflow: "hidden", width: props.width, height: props.height } }, { children: _jsx(ReactPlayer, Object.assign({}, props, { playing: true, controls: true, style: {
                position: "absolute",
                top: 0,
                left: 0,
            } })) })));
}
