// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import config from "config";
export function getConfig(key, def) {
    const value = config[key];
    if (!value) {
        if (def !== undefined)
            return def;
        throw Error(`Required Configuration value missing: [${key}]`);
    }
    return value;
}
