import { defaultTheme, // Default theme
 } from "@ant-design/compatible";
import colors from "./assets/colors";
export const coraTheme = Object.assign(Object.assign({}, defaultTheme), { token: Object.assign(Object.assign({}, defaultTheme.token), { colorPrimary: colors.accent[500], colorPrimaryHover: colors.accent[500], colorTextDisabled: colors.neutral[500], colorInfo: colors.accent[500], fontFamily: "Inter, sans-serif", borderRadius: 4 }), components: {
        Button: {
            colorBorder: colors.neutral[500],
        },
        Layout: {
            colorBgHeader: colors.accent[500],
        },
    } });
