import { CONTEXT, ElementType } from "./model";
import { GraphAnalysis } from "./GraphAnalysis";
export function trimGraph(flow, toRemove, removeQualifiers) {
    const edges = flow.getEdges();
    const edgesByNodeId = GraphAnalysis.edgesByNodeId(edges);
    const edgesInToRemove = toRemove.map(n => edgesByNodeId[n.id] || []).flat();
    const relsToRemove = toRemove
        .filter(n => n.type === ElementType.RELATION)
        .concat(edgesInToRemove
        .filter(e => e.type === ElementType.RELATION)
        .map(e => {
        var _a;
        return ((_a = flow.getNode(e.source)) === null || _a === void 0 ? void 0 : _a.type) === ElementType.RELATION
            ? flow.getNode(e.source)
            : flow.getNode(e.target);
    }));
    //find qualifiers for relations to remove that only have one edge
    const qualifiersToRemove = !removeQualifiers
        ? []
        : relsToRemove
            .map(n => edgesByNodeId[n.id])
            .flat()
            .filter(e => e.type === ElementType.QUALIFIER &&
            e.data.label !== CONTEXT &&
            (edgesByNodeId[e.target] || []).length < 2)
            .map(e => flow.getNode(e.target));
    const nodesToRemove = new Set([
        ...toRemove.map(n => n.id),
        ...relsToRemove.map(n => n.id),
        ...qualifiersToRemove.map(n => n.id),
    ]);
    const edgesToRemove = new Set(Array.from(nodesToRemove)
        .map(id => edgesByNodeId[id] || [])
        .flat()
        .map(e => e.id));
    return {
        nodes: flow.getNodes().filter(n => !nodesToRemove.has(n.id)),
        edges: flow.getEdges().filter(e => !edgesToRemove.has(e.id)),
    };
}
