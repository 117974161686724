import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useFlags } from "launchdarkly-react-client-sdk";
import LogRocket from "logrocket";
import { getConfig } from "../utils/serverConfig";
export function useLogRocket() {
    const { user } = useAuth0();
    const { enableLogrocketIntegration } = useFlags();
    React.useEffect(() => {
        try {
            const lrAppId = getConfig("LOGROCKET_APP_ID");
            if (enableLogrocketIntegration && lrAppId.length > 0) {
                console.log("LogRocket enabled");
                LogRocket.init(lrAppId, {
                    console: {
                        shouldAggregateConsoleErrors: true,
                    },
                });
                return;
            }
        }
        catch (e) {
            // unable to initialize LogRocket
            // most likely not set in config
        }
        console.log("LogRocket disabled");
    }, [user, enableLogrocketIntegration]);
}
