var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { bgEvidence500, bgNeutral300, borderBrand500, selectedCardClass, } from "../lf";
import { FilteredCardList, } from "../../../shared/components/CardsList";
import { classes } from "../../../utils/classes";
import { EvidenceCard } from "./cards/EvidenceCard";
export function FilteredEvidenceCardList(_a) {
    var { onRemove, extraItemActions, isSelected, className, onClick, urlOpener, filtering, renderAsHtml, showSurrounding, metadataSorter, onEvidenceTextSelected } = _a, props = __rest(_a, ["onRemove", "extraItemActions", "isSelected", "className", "onClick", "urlOpener", "filtering", "renderAsHtml", "showSurrounding", "metadataSorter", "onEvidenceTextSelected"]);
    return (_jsx(FilteredCardList, Object.assign({ brandingColorClass: bgEvidence500, title: "Evidence", itemTexts: filtering ? item => [item.text] : undefined, itemRender: (item, idx, items, filterText) => (_jsx(EvidenceCard, { filterText: filterText, className: classes(className, onClick && "cursor-pointer", (isSelected === null || isSelected === void 0 ? void 0 : isSelected(item)) &&
                classes(bgNeutral300, borderBrand500, selectedCardClass)), evidence: [item], onClick: e => onClick === null || onClick === void 0 ? void 0 : onClick(item), urlOpenerFn: urlOpener, renderAsHtml: renderAsHtml, onRemove: onRemove, showSurrounding: showSurrounding, metadataSorter: metadataSorter, onEvidenceTextSelected: onEvidenceTextSelected })) }, props)));
}
