import { FixedArgs } from "../../utils/coraUtil";
import { DiagramModel, NodeType } from "./DiagramModel";
import { groupBy } from "lodash";
import { diagramTransaction, setLinkProperties, setNodeProperties, } from "../../../shared/components/gojs/gojs";
import { allLoneConstraints, getTypeNodes } from "./diagramUtils";
import { getTagStyle } from "../lf";
export function colorizeNodes(diagram, argsInfo, activeStatementNode) {
    const model = DiagramModel.getModel(diagram);
    const nodesByGroup = groupBy(model.nodeDataArray, "group");
    diagramTransaction(diagram, () => {
        setNodeProperties(model, { fill: "", stroke: "" });
        setLinkProperties(model, { selected: false });
        if (allLoneConstraints(model)) {
            model.nodeDataArray
                .filter(n => [NodeType.CONCEPT, NodeType.TYPE].includes(n.type))
                .forEach(n => colorize(n, FixedArgs.CONTEXT_CONCEPT));
        }
        else if (activeStatementNode) {
            colorize(activeStatementNode, "RELATION");
            getTypeNodes(activeStatementNode, model).forEach(e => colorize(e.node, e.role));
        }
        function colorize(node, role) {
            var _a;
            const { backgroundColor, borderColor } = getTagStyle(argsInfo.argColors[role]);
            const nodes = node.type === NodeType.TYPE && ((_a = nodesByGroup[node.key]) === null || _a === void 0 ? void 0 : _a.length)
                ? nodesByGroup[node.key]
                : [node];
            nodes.forEach(n => setNodeProperties(model, {
                fill: backgroundColor,
                stroke: borderColor,
            }, n));
        }
    });
}
