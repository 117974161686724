import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { PillButton } from "../../../shared/components/buttons/Buttons";
import { TitledContainer } from "../../../shared/components/TitledContainer";
import { classes } from "../../../utils/classes";
import { useCoraState } from "../CoraStateProvider";
export function ReportPanelHack({ onClose }) {
    const [selected, setSelected] = React.useState(-1);
    const [{ corpus_ids }, coraStateUpdater] = useCoraState();
    return (_jsx("div", Object.assign({ className: "absolute flex flex-col items-stretch", style: {
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 10,
        } }, { children: _jsx(TitledContainer, Object.assign({ bgClassName: "bg-white", className: "flex-1", headerLeft: "Best Solution", footer: _jsx("div", Object.assign({ className: " space-x-2 flex items-center" }, { children: _jsx(PillButton, Object.assign({ onClick: onClose, className: "bg-gray-300" }, { children: "Close" })) })) }, { children: _jsx("div", Object.assign({ className: "flex flex-col items-stretch" }, { children: _jsx("div", Object.assign({ className: "flex-1 space-y-1" }, { children: sections.map(({ html }, idx) => (_jsx(Section, Object.assign({ idx: idx }, { children: html }), idx))) })) })) })) })));
    function Section({ idx, children }) {
        return (_jsx("div", { children: _jsx("span", Object.assign({ className: classes("cursor-pointer ", idx !== selected && "hover:bg-accent-100", idx === selected && "bg-accent-200"), onClick: () => {
                    setSelected(idx);
                    if (queries[idx]) {
                        coraStateUpdater(Object.assign(Object.assign({}, queries[idx]), { corpus_ids }));
                    }
                } }, { children: children })) }));
    }
}
const sections = [
    {
        html: (_jsxs("span", { children: [_jsx("b", { children: "Rheumatoid arthritis (RA)" }), " is an autoimmune inflammatory disorder, characterised by joint pain, synovitis and hyperalgesia."] })),
    },
    {
        html: (_jsxs("span", { children: ["Patients with ", _jsx("b", { children: "RA" }), " tend to show an increased generation of", " ", _jsx("b", { children: "Th1/Th17" }), " lymphocytes (", _jsx("b", { children: "CD4+" }), ")."] })),
    },
    {
        html: (_jsxs("span", { children: ["Pathogenic ", _jsx("b", { children: "CD4+ T cells" }), " express excessive amounts of cytokines including", _jsx("b", { children: "IL-17, IFN\u03B3, IL-6, and TNF\u03B1" }), " to simultaneously activate two transcription factors, ", _jsx("b", { children: "NF-\u03BAB" }), " and ", _jsx("b", { children: "STAT3" }), "."] })),
    },
    {
        html: (_jsxs("span", { children: ["Inflammatory cytokines, especially ", _jsx("b", { children: "TNF-\u03B1" }), ", and two interleukins,", " ", _jsx("b", { children: "IL-1\u03B2" }), "and ", _jsx("b", { children: "IL-6" }), ", are key in driving ", _jsx("b", { children: "inflammation" }), " and", " ", _jsx("b", { children: "joint damage" }), " in RA."] })),
    },
    {
        html: (_jsxs("span", { children: ["Chemical inhibition of ", _jsx("b", { children: "IRAK4" }), " with a potent and selective", " ", _jsx("b", { children: "IRAK4 inhibitor" }), "blocked IRF5 nuclear translocation and IRF5 transcriptional activity at the promoters of inflammatory cytokines, such as ", _jsx("b", { children: "IL\u20101, Il\u20106, TNF" }), ", in human monocytes."] })),
    },
];
const queries = [
    {
        concepts1: [
            {
                name: "disease",
                members: [
                    {
                        id: "disease",
                        type_names: [],
                        name: "disease",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
        ],
        concepts1Override: {},
        refuting_relations: null,
        rangeMetadataOverride: [],
        refuting_concepts: null,
        concepts2Override: {
            cell: [
                {
                    name: "macrophages",
                    members: [
                        {
                            id: "macrophage",
                            type_names: [
                                "cell",
                                "cell type",
                                "immune cell",
                                "inflammatory cell",
                                "innate immune cell",
                            ],
                            name: "macrophages",
                            kb_ids: [],
                        },
                    ],
                    has_children_concepts: true,
                },
            ],
        },
        concepts1Possible: [
            {
                name: "tumor",
                members: [
                    {
                        id: "tumor",
                        type_names: [
                            "cancer",
                            "cause",
                            "condition",
                            "disease",
                            "disorder",
                            "lesion",
                            "malignancy",
                            "neoplasm",
                        ],
                        name: "tumor",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "RA",
                members: [
                    {
                        id: "ra",
                        type_names: [
                            "autoimmune disease",
                            "condition",
                            "disease",
                            "disorder",
                            "inflammatory disease",
                        ],
                        name: "RA",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "SLE",
                members: [
                    {
                        id: "sle",
                        type_names: [
                            "autoimmune disease",
                            "autoimmune disorder",
                            "condition",
                            "disease",
                            "disorder",
                        ],
                        name: "SLE",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "lesions",
                members: [
                    {
                        id: "lesion",
                        type_names: [
                            "abnormality",
                            "cause",
                            "condition",
                            "consequence",
                            "diffuse",
                            "disease",
                            "finding",
                            "pathology",
                            "sign",
                            "symptom",
                        ],
                        name: "lesions",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "deficiency",
                members: [
                    {
                        id: "deficiency",
                        type_names: [
                            "cause",
                            "condition",
                            "disease",
                            "disorder",
                            "factor",
                            "health problem",
                            "problem",
                            "result",
                            "risk factor",
                        ],
                        name: "deficiency",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "liver",
                members: [
                    {
                        id: "liver",
                        type_names: ["cancer", "cell", "disease", "internal organ"],
                        name: "liver",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "arthritis",
                members: [
                    {
                        id: "arthritis",
                        type_names: [
                            "autoimmune disease",
                            "chronic inflammatory disease",
                            "condition",
                            "disease",
                            "disorder",
                            "inflammatory disease",
                        ],
                        name: "arthritis",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "therapy",
                members: [
                    {
                        id: "therapy",
                        type_names: [
                            "agent",
                            "alternative",
                            "application",
                            "approach",
                            "disease",
                            "drug",
                            "effective treatment",
                            "factor",
                            "first line treatment",
                        ],
                        name: "therapy",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "OA",
                members: [
                    {
                        id: "oa",
                        type_names: [
                            "arthritis",
                            "condition",
                            "degenerative disease",
                            "degenerative joint disease",
                            "disease",
                            "disorder",
                            "joint disease",
                        ],
                        name: "OA",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "inflammation",
                members: [
                    {
                        id: "inflammation",
                        type_names: [
                            "biological process",
                            "cause",
                            "cellular process",
                            "condition",
                            "disease",
                            "effect",
                        ],
                        name: "inflammation",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "infection",
                members: [
                    {
                        id: "infection",
                        type_names: ["cause", "complication", "condition", "disease"],
                        name: "infection",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "COVID-19",
                members: [
                    {
                        id: "covid19",
                        type_names: [
                            "disease",
                            "infection",
                            "infectious disease",
                            "pandemic",
                        ],
                        name: "COVID-19",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "control",
                members: [
                    {
                        id: "control",
                        type_names: [
                            "aspect",
                            "challenge",
                            "disease",
                            "factor",
                            "function",
                            "group",
                        ],
                        name: "control",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "DSS",
                members: [
                    {
                        id: "dss",
                        type_names: [
                            "agent",
                            "complication",
                            "disease",
                            "method",
                            "model",
                            "stimulus",
                        ],
                        name: "DSS",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "psoriasis",
                members: [
                    {
                        id: "psoriasis",
                        type_names: [
                            "autoimmune disease",
                            "condition",
                            "disease",
                            "disorder",
                            "inflammatory disease",
                            "skin disease",
                        ],
                        name: "psoriasis",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "lpr",
                members: [
                    {
                        id: "lpr",
                        type_names: [
                            "complex disorder",
                            "condition",
                            "contribute factor",
                            "disease",
                            "electrochemical technique",
                            "functional analog historical ahr",
                            "parameter",
                            "relevant factor",
                            "technique",
                        ],
                        name: "lpr",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "synovitis",
                members: [
                    {
                        id: "synovitis",
                        type_names: [
                            "change",
                            "condition",
                            "disease",
                            "disorder",
                            "inflammation",
                            "lesion",
                            "manifestation",
                            "pathology",
                            "symptom",
                        ],
                        name: "synovitis",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "SLE patients",
                members: [
                    {
                        id: "sle patient",
                        type_names: [
                            "autoimmune disease",
                            "control",
                            "disease",
                            "patient",
                            "autoimmune disease patient",
                            "condition patient",
                            "disease patient",
                            "disorder patient",
                            "sle case",
                        ],
                        name: "SLE patients",
                        kb_ids: [],
                    },
                    {
                        id: "patient sle",
                        type_names: [
                            "aging autoimmune individual",
                            "cancer",
                            "candidate",
                            "characteristic",
                            "disease",
                            "epidemiological characteristic",
                            "global epidemiological characteristic",
                            "group",
                            "immunosuppressed individual",
                            "individual",
                        ],
                        name: "patients with SLE",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
            {
                name: "conditions",
                members: [
                    {
                        id: "condition",
                        type_names: ["cause", "disease", "disorder", "factor"],
                        name: "conditions",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
        ],
        concepts2Possible: null,
        required_arguments: [],
        argClauses: {},
        evidenceFilter: "",
        keywordMetadata: [],
        rangeMetadata: [],
        booleanMetadata: [],
        extraConcepts: [],
        extraConceptsOverride: {},
        extraConceptsPossible: null,
        relations: [
            {
                name: "affect",
                ids: [
                    "affect",
                    "indicate",
                    "show",
                    "reveal",
                    "involve",
                    "impact",
                    "change",
                ],
                score: null,
            },
        ],
        concepts2: [
            {
                name: "cell",
                members: [
                    {
                        id: "cell",
                        type_names: [],
                        name: "cell",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
        ],
        crcDirection: "C1C2",
    },
    {
        concepts1: [
            {
                name: "disease",
                members: [
                    {
                        id: "disease",
                        type_names: [],
                        name: "disease",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
        ],
        concepts1Override: {
            disease: [
                {
                    name: "RA",
                    members: [
                        {
                            id: "ra",
                            type_names: [
                                "autoimmune disease",
                                "condition",
                                "disease",
                                "disorder",
                                "inflammatory disease",
                            ],
                            name: "RA",
                            kb_ids: [],
                        },
                    ],
                    has_children_concepts: true,
                },
            ],
        },
        refuting_relations: null,
        rangeMetadataOverride: [],
        refuting_concepts: null,
        concepts2Override: {
            cell: [
                {
                    name: "Th17",
                    members: [
                        {
                            id: "th17",
                            type_names: [
                                "cd4 t cell",
                                "cell",
                                "cell subset",
                                "immune cell",
                                "subset",
                                "t cell",
                                "t cell subset",
                            ],
                            name: "Th17",
                            kb_ids: [],
                        },
                        {
                            id: "th17 cell",
                            type_names: [
                                "cd4 t cell",
                                "cell",
                                "immune cell",
                                "subset",
                                "t cell",
                                "cell cell",
                                "subset cell",
                                "t cell cell",
                                "th17 cd4 t cell",
                            ],
                            name: "Th17 cells",
                            kb_ids: [],
                        },
                    ],
                    has_children_concepts: true,
                },
                {
                    name: "CD4",
                    members: [
                        {
                            id: "cd4",
                            type_names: ["cell", "immune cell", "marker", "t cell"],
                            name: "CD4",
                            kb_ids: [],
                        },
                    ],
                    has_children_concepts: true,
                },
            ],
        },
        concepts1Possible: null,
        concepts2Possible: null,
        required_arguments: [],
        argClauses: {},
        evidenceFilter: "",
        keywordMetadata: [],
        rangeMetadata: [],
        booleanMetadata: [],
        extraConcepts: [],
        extraConceptsOverride: {},
        extraConceptsPossible: null,
        relations: [
            {
                name: "affect",
                ids: [
                    "affect",
                    "indicate",
                    "show",
                    "reveal",
                    "involve",
                    "impact",
                    "change",
                ],
                score: null,
            },
        ],
        concepts2: [
            {
                name: "cell",
                members: [
                    {
                        id: "cell",
                        type_names: [],
                        name: "cell",
                        kb_ids: [],
                    },
                ],
                score: 0,
                has_children_concepts: null,
            },
        ],
        crcDirection: "C1C2",
    },
];
export const CONCEPT_SELECTION_HACK = {
    "kinase inhibitor": [
        {
            name: "IRAK4 inhibitor",
            members: [
                {
                    id: "irak4 inhibitor",
                    type_names: [
                        "gene inhibitor",
                        "inhibitor",
                        "kinase inhibitor",
                        "molecule inhibitor",
                        "pathway inhibitor",
                        "signal molecule inhibitor",
                    ],
                    name: "IRAK4 inhibitor",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
    ],
    cell: [
        {
            name: "Th17",
            members: [
                {
                    id: "th17",
                    type_names: [
                        "cd4 t cell",
                        "cell",
                        "cell subset",
                        "immune cell",
                        "subset",
                        "t cell",
                        "t cell subset",
                    ],
                    name: "Th17",
                    kb_ids: [],
                },
                {
                    id: "th17 cell",
                    type_names: [
                        "cd4 t cell",
                        "cell",
                        "immune cell",
                        "subset",
                        "t cell",
                        "cell cell",
                        "subset cell",
                        "t cell cell",
                        "th17 cd4 t cell",
                    ],
                    name: "Th17 cells",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
        {
            name: "CD4",
            members: [
                {
                    id: "cd4",
                    type_names: ["cell", "immune cell", "marker", "t cell"],
                    name: "CD4",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
    ],
    pathway: [
        {
            name: "NF-κB",
            members: [
                {
                    id: "nf kb",
                    type_names: [
                        "pathway",
                        "regulator",
                        "signal pathway",
                        "transcription factor",
                        "kb",
                    ],
                    name: "NF-κB",
                    kb_ids: [],
                },
                {
                    id: "nfkb",
                    type_names: [
                        "gene",
                        "pathway",
                        "signal pathway",
                        "transcription factor",
                    ],
                    name: "NFκB",
                    kb_ids: [],
                },
                {
                    id: "nf kb pathway",
                    type_names: [
                        "cascade",
                        "pathway",
                        "signal cascade",
                        "signal pathway",
                        "nf kb cascade",
                        "nf kb component",
                        "pathway pathway",
                        "regulator pathway",
                    ],
                    name: "NF-κB pathway",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
        {
            name: "STAT3",
            members: [
                {
                    id: "stat3",
                    type_names: [
                        "gene",
                        "molecule",
                        "pathway",
                        "protein",
                        "signal pathway",
                        "target",
                        "transcription factor",
                    ],
                    name: "STAT3",
                    kb_ids: [],
                },
                {
                    id: "stat3 pathway",
                    type_names: [
                        "mechanism",
                        "multiple signal pathway",
                        "pathway",
                        "signal pathway",
                    ],
                    name: "STAT3 pathway",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
    ],
    cytokine: [
        {
            name: "TNF-α",
            members: [
                {
                    id: "tnf a",
                    type_names: [
                        "cytokine",
                        "inflammatory cytokine",
                        "inflammatory mediator",
                        "mediator",
                        "proinflammatory cytokine",
                        "gene a",
                    ],
                    name: "TNF-α",
                    kb_ids: [],
                },
                {
                    id: "tnfa",
                    type_names: [
                        "cytokine",
                        "inflammatory cytokine",
                        "mediator",
                        "proinflammatory cytokine",
                    ],
                    name: "TNFα",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
        {
            name: "IL-1β",
            members: [
                {
                    id: "il1b",
                    type_names: [
                        "cytokine",
                        "inflammatory cytokine",
                        "inflammatory mediator",
                        "mediator",
                        "proinflammatory cytokine",
                    ],
                    name: "IL-1β",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
        {
            name: "IL-6",
            members: [
                {
                    id: "il6",
                    type_names: [
                        "cytokine",
                        "inflammatory cytokine",
                        "inflammatory mediator",
                    ],
                    name: "IL-6",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
    ],
    disease: [
        {
            name: "RA",
            members: [
                {
                    id: "ra",
                    type_names: [
                        "autoimmune disease",
                        "condition",
                        "disease",
                        "disorder",
                        "inflammatory disease",
                    ],
                    name: "RA",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
    ],
    symptom: [
        {
            name: "synovitis",
            members: [
                {
                    id: "synovitis",
                    type_names: [
                        "change",
                        "condition",
                        "disease",
                        "disorder",
                        "inflammation",
                        "lesion",
                        "manifestation",
                        "pathology",
                        "symptom",
                    ],
                    name: "synovitis",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
        {
            name: "joint damage",
            members: [
                {
                    id: "joint damage",
                    type_names: [
                        "damage",
                        "symptom",
                        "joint effect",
                        "joint event",
                        "joint mechanism",
                        "organ damage",
                        "site damage",
                        "structure damage",
                        "system damage",
                        "tissue damage",
                    ],
                    name: "joint damage",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
        {
            name: "hyperalgesia",
            members: [
                {
                    id: "hyperalgesia",
                    type_names: [
                        "effect",
                        "neuropathic pain",
                        "pain",
                        "sensation",
                        "side effect",
                        "symptom",
                    ],
                    name: "hyperalgesia",
                    kb_ids: [],
                },
                {
                    id: "hyperaemia",
                    type_names: [
                        "active engorgement vascular structure",
                        "clinical feature",
                        "crps like symptom",
                        "dysfunction",
                        "finding",
                        "first response",
                        "macroscopic finding",
                        "outcome",
                        "pathological change",
                        "prognostic sign",
                    ],
                    name: "hyperaemia",
                    kb_ids: [],
                },
                {
                    id: "hypercalcemia",
                    type_names: [
                        "complication",
                        "damage",
                        "effect",
                        "event",
                        "paraneoplastic syndrome",
                        "side effect",
                        "skeletal relate event",
                        "symptom",
                    ],
                    name: "hypercalcemia",
                    kb_ids: [],
                },
            ],
            has_children_concepts: true,
        },
    ],
};
