import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useGlobal, useOnRefEffect, useUniqId } from "../../../utils/hooks";
import { Empty } from "../../../shared/components/Empty";
import { popModal, pushModal } from "../../../shared/components/Modal";
import { bgBrand700 } from "../../components/lf";
import "./OldGraph.less";
import { createModel, filterGraph, filterOutEmptyNodesWithNoEdges, getGraphComponents, } from "./graphUtils";
import { uuidv4 } from "../../../utils/util";
import { Button, Checkbox, Tabs } from "antd";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { showPopupMenu } from "../../../shared/actions/PopupMenu";
import { List, Set } from "immutable";
import { AND } from "../../../utils/filters";
import { createGraph } from "./graphDef";
import { useCoraState } from "../../components/CoraStateProvider";
function usePopup(diagram, eventName, entriesProvider, dependencies) {
    React.useEffect(() => {
        if (!diagram)
            return;
        const listener = () => {
            const entries = entriesProvider(diagram);
            if (entries.length) {
                const { clientX, clientY } = diagram.lastInput.event;
                showPopupMenu({
                    top: clientY,
                    left: clientX,
                    entries,
                });
            }
        };
        diagram.addDiagramListener(eventName, listener);
        return () => {
            diagram.removeDiagramListener(eventName, listener);
        };
    }, [diagram, ...dependencies]);
}
const ConfigFlags = {
    SHOW_GROUPS: `Show Groups`,
    HIDE_IS_A_RELATIONS: `Hide "is a" Relations`,
    HIDE_REMOVED_NODES: "Hide Removed Nodes",
    HIDE_EMPTY_NODES: "Hide Empty Nodes",
};
export function OldGraph({ onSelect, emptyMessage, ignoreKey, className = "", limit = Number.POSITIVE_INFINITY, }) {
    const [coraState, coraStateUpdater] = useCoraState();
    const [selected, setSelected] = React.useState([]);
    const [graph, setGraph] = React.useState(null);
    const [filterText] = useGlobal("filterText", "");
    const graphId = useUniqId(uuidv4);
    const { corpus_ids } = coraState;
    const effect = React.useCallback((div) => {
        if (!div) {
            setGraph(null);
            return;
        }
        const graph = createGraph(graphId);
        const listener = (e) => {
            const { subject } = e;
            const selected = subject.toArray();
            setSelected(selected === null || selected === void 0 ? void 0 : selected.map((s) => s.data));
        };
        graph.diagram.addDiagramListener("ChangedSelection", listener);
        const resizeObserver = new ResizeObserver((entries) => {
            var _a;
            const rect = (_a = entries[0]) === null || _a === void 0 ? void 0 : _a.contentRect;
            // console.log("rect", rect)
            // const aspectRatio = rect.width / rect.height
            // graph.requestUpdate(true)
            graph.fit(rect);
        });
        resizeObserver.observe(div);
        // graph.layout = getLayout()
        setGraph(graph);
        return () => {
            resizeObserver.disconnect();
            graph.destroy();
            setGraph(null);
            // d.getDiagram()?.removeDiagramListener("ChangedSelection", listener)
        };
    }, []);
    const ref = useOnRefEffect(effect);
    const { queryHistory, graphConfigFlags, hiddenGraphNodes } = useCurrentSession();
    const hiddenNodeKeys = React.useMemo(() => hiddenGraphNodes.get(corpus_ids[0]) || Set(), [hiddenGraphNodes]);
    usePopup(graph === null || graph === void 0 ? void 0 : graph.diagram, "ObjectContextClicked", diagram => {
        const selected = diagram.selection.toArray().map((a) => a.data)[0];
        return (selected === null || selected === void 0 ? void 0 : selected.concept)
            ? [
                {
                    caption: hiddenNodeKeys.has(selected.key)
                        ? "Restore Node"
                        : "Remove Node",
                    action() {
                        updateCurrentSession({
                            hiddenGraphNodes: hiddenGraphNodes.set(corpus_ids[0], hiddenNodeKeys.has(selected.key)
                                ? hiddenNodeKeys.remove(selected.key)
                                : hiddenNodeKeys.add(selected.key)),
                        });
                    },
                },
            ]
            : [];
    }, [hiddenGraphNodes, hiddenNodeKeys]);
    usePopup(graph === null || graph === void 0 ? void 0 : graph.diagram, "BackgroundContextClicked", () => [
        {
            caption: "Layout Graphical",
            action() {
                graph === null || graph === void 0 ? void 0 : graph.fit();
            },
        },
    ], []);
    const { model, filteredHistory } = React.useMemo(() => {
        const filteredHistory = queryHistory.filter(crc => crc.corpus_ids[0] === corpus_ids[0]);
        return {
            model: createModel(List(), hiddenNodeKeys),
            filteredHistory,
        };
    }, [history, corpus_ids, hiddenNodeKeys]);
    const hideIsARelations = graphConfigFlags.has(ConfigFlags.HIDE_IS_A_RELATIONS);
    const hideRemovedNodes = graphConfigFlags.has(ConfigFlags.HIDE_REMOVED_NODES);
    const hideEmptyNodes = graphConfigFlags.has(ConfigFlags.HIDE_EMPTY_NODES);
    const showGroups = graphConfigFlags.has(ConfigFlags.SHOW_GROUPS);
    const filtered = React.useMemo(() => {
        const filtered = filterGraph(model, AND(hideRemovedNodes && (n => !hiddenNodeKeys.has(n.key)), hideEmptyNodes && (n => !n.isEmpty)), AND(hideIsARelations && (r => !!r.relation)));
        const { nodes, edges } = filterOutEmptyNodesWithNoEdges(filtered);
        return {
            nodes,
            edges,
        };
    }, [
        model,
        hiddenNodeKeys,
        hideIsARelations,
        hideRemovedNodes,
        hideEmptyNodes,
    ]);
    React.useEffect(() => {
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(selected);
    }, [selected]);
    const canSetQuery = selected.length && (selected[0].concept || selected[0].relation);
    const [activeTab, setActiveTab] = React.useState("");
    const tabs = React.useMemo(() => (showGroups ? getGraphComponents(filtered) : { "": filtered }), [filtered, showGroups]);
    const tabKeys = Object.keys(tabs);
    const validActiveTab = tabs[activeTab] !== undefined ? activeTab : tabKeys[0];
    const activeModel = tabs[validActiveTab];
    React.useEffect(() => {
        activeModel && (graph === null || graph === void 0 ? void 0 : graph.setModel(activeModel));
    }, [graph, activeModel]);
    return (_jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-stretch h-full" }, { children: [filtered.nodes.length && tabKeys.length > 1 ? (_jsx(Tabs, { activeKey: validActiveTab, onChange: setActiveTab, items: tabKeys.map(key => ({
                    label: key,
                    key,
                })) })) : null, filtered.nodes.length ? (_jsx("div", Object.assign({ className: "flex-1 relative", ref: ref }, { children: _jsx("div", { id: graphId, style: { width: "100%", height: "100%" } }) }))) : (_jsx(Empty, { empty: !filteredHistory.size ? "No Queries" : "No Visible Data" })), _jsxs("div", Object.assign({ className: "p-2 flex space-x-2 flex-row items-center" }, { children: [_jsx(Button, Object.assign({ disabled: !canSetQuery, onClick: () => {
                            if (selected[0].concept) {
                                const { coraState, concept } = selected[0];
                                coraStateUpdater({
                                    concepts1: [concept],
                                    relations: [],
                                    concepts2: [],
                                    corpus_ids: coraState.corpus_ids,
                                });
                                popModal(true);
                            }
                            else if (selected[0].relation) {
                                const { coraState } = selected[0];
                                coraStateUpdater(coraState);
                                popModal(true);
                            }
                        } }, { children: "Show Evidence for Selection" })), Object.entries(ConfigFlags).map(([key, value]) => (_jsx(Checkbox, Object.assign({ checked: graphConfigFlags.has(value), onClick: () => updateCurrentSession({
                            graphConfigFlags: graphConfigFlags.has(value)
                                ? graphConfigFlags.remove(value)
                                : graphConfigFlags.add(value),
                        }) }, { children: value }), key)))] }))] })));
}
export function showOlfGraph() {
    pushModal({
        title: "Query History Graph",
        titleColorClass: bgBrand700,
        content: _jsx(OldGraph, {}),
        easyClose: () => false,
        className: "min-w-[80%]",
    });
}
