import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../utils/classes";
import { EMPTY_FILTERS } from "../../utils/coraState";
import { useEffectIfDifferent } from "../../../utils/hooks";
import { CONCEPT_1_CAPTION, CONCEPT_2_CAPTION, } from "../../utils/coraUtil";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { MetadataFacets } from "./metadata/MetadataFacets";
import { WatchlistFacets } from "./WatchlistFacets";
import { Close } from "../Icons";
import { useEmptyFilter } from "../../utils/emptyFIlters";
import { Button, Tabs } from "antd";
import { FilterFilled } from "@ant-design/icons";
import "./CRCFacets.less";
import { FacetsForQualifiers } from "./FacetsForQualifiers";
import { FacetsForCRCConcepts } from "./FacetsForCRCConcepts";
import { getSize } from "../../../utils/util";
import { useCoraState } from "../CoraStateProvider";
import colors from "../../../assets/colors";
function useClearFacetsSelectionWhenClusteringChanges(coraStateUpdater) {
    const { conceptClustering, specificConcepts, id, name } = useCurrentSession();
    useEffectIfDifferent(() => {
        coraStateUpdater({
            concepts1: [],
            concepts2: [],
            relations: [],
            concepts1Override: {},
            concepts2Override: {},
        });
    }, [], [conceptClustering, specificConcepts]);
}
function useExpandCollapseC1C2Facets({ concepts1, concepts2, relations, }) {
    const c1 = concepts1.length;
    const c2 = concepts2.length;
    const r = relations.length;
    React.useEffect(() => {
        updateCurrentSession(session => ({
            expandedFacetGroups: [
                ...session.expandedFacetGroups.filter(name => name !== CONCEPT_1_CAPTION && name !== CONCEPT_2_CAPTION),
                ...(c1 || (r && c2) ? [CONCEPT_1_CAPTION] : []),
                ...(c2 || (r && c1) ? [CONCEPT_2_CAPTION] : []),
            ],
        }));
    }, [c1, c2, r]);
}
var FilterTabs;
(function (FilterTabs) {
    FilterTabs["Concepts"] = "Concepts";
    FilterTabs["Qualifiers"] = "Qualifiers";
    FilterTabs["Document"] = "Document";
    FilterTabs["Watchlist"] = "Watchlist";
})(FilterTabs || (FilterTabs = {}));
export function CRCFacets({ argsInfo }) {
    const [coraState, coraStateUpdater] = useCoraState();
    const [feedback, setFeedback] = React.useState({});
    useClearFacetsSelectionWhenClusteringChanges(coraStateUpdater);
    const emptyFilter = useEmptyFilter();
    useExpandCollapseC1C2Facets(coraState);
    const { keywordMetadata, rangeMetadata, booleanMetadata, concepts1Override, concepts2Override, extraConcepts, extraConceptsOverride, contextConceptsOverride, required_arguments, argClauses, } = coraState;
    return (_jsxs("div", Object.assign({ className: classes("CRCFacets flex-1 flex flex-col items-stretch space-y-2 mt-1") }, { children: [_jsxs("div", Object.assign({ className: "font-bold flex items-center justify-between mr-6" }, { children: [_jsx(Filtered, Object.assign({ filtered: !emptyFilter }, { children: "Filters" })), _jsxs("div", Object.assign({ className: "flex space-x-1 items-center" }, { children: [_jsx(Button, Object.assign({ size: "small", disabled: emptyFilter, onClick: () => coraStateUpdater(EMPTY_FILTERS) }, { children: "Clear All" })), _jsx(Close, { className: " cursor-pointer", onClick: () => updateCurrentSession({ showFilters: false }) })] }))] })), _jsx(Tabs, { className: "FacetTabs", defaultActiveKey: FilterTabs.Concepts, items: [
                    {
                        key: FilterTabs.Concepts,
                        label: button(FilterTabs.Concepts, getSize(concepts1Override) +
                            getSize(concepts2Override) +
                            getSize(contextConceptsOverride) >
                            0),
                        children: (_jsx(FacetsForCRCConcepts, { coraState: coraState, coraStateUpdater: coraStateUpdater })),
                    },
                    {
                        key: FilterTabs.Qualifiers,
                        label: button(FilterTabs.Qualifiers, getSize(argClauses) + required_arguments.length > 0),
                        children: (_jsx(FacetsForQualifiers, { coraState: coraState, coraStateUpdater: coraStateUpdater, argsInfo: argsInfo, feedback: feedback, setFeedback: setFeedback })),
                    },
                    {
                        key: FilterTabs.Document,
                        label: button(FilterTabs.Document, keywordMetadata.length +
                            rangeMetadata.length +
                            booleanMetadata.length >
                            0),
                        children: (_jsx(MetadataFacets, { corpusFilterParams: coraState, updater: coraStateUpdater })),
                    },
                    {
                        key: FilterTabs.Watchlist,
                        label: button(FilterTabs.Watchlist, getSize(extraConceptsOverride) + extraConcepts.length > 0),
                        children: (_jsx(WatchlistFacets, { coraState: coraState, coraStateUpdater: coraStateUpdater, feedback: feedback, setFeedback: setFeedback })),
                    },
                ] })] })));
    function button(caption, filtered) {
        return (_jsx("span", Object.assign({ className: classes("flex-1 flex justify-center text-tiny select-none", filtered ? "filtered-tab" : "unfiltered-tab") }, { children: _jsx(Filtered, Object.assign({ filtered: filtered }, { children: caption })) })));
    }
}
function Filtered({ filtered, children, }) {
    return (_jsxs("div", Object.assign({ className: "flex items-center space-x-1 select-none" }, { children: [_jsx("span", { children: children }), filtered && (_jsx("div", Object.assign({ style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    width: "16px",
                    height: "16px",
                    backgroundColor: colors.accent[500],
                } }, { children: _jsx(FilterFilled, { className: "text-xxs text-white", style: { marginRight: "0px" } }) })))] })));
}
