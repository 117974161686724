import { jsx as _jsx } from "react/jsx-runtime";
import { Down, Right } from "../Icons";
import { TinyButton, } from "../../../shared/components/buttons/Buttons";
import React from "react";
export function CollapseButton({ className, collapsed, onToggle }) {
    return (_jsx(FacetButton, Object.assign({ className: className, onMouseDown: e => {
            e.preventDefault();
            onToggle();
        } }, { children: collapsed ? _jsx(Right, {}) : _jsx(Down, {}) })));
}
export function FacetButton(props) {
    return _jsx(TinyButton, Object.assign({}, props, { size: 12, bgClassName: "" }));
}
