import React from "react";
import { AND, TRUE } from "../../../utils/filters";
export function useEvidenceAddOns(params, ...addOnHooks) {
    // make sure addons don't change during component's lifetime
    addOnHooks = React.useMemo(() => addOnHooks, []);
    const addOns = addOnHooks
        .map((hook, idx) => hook(Object.assign(Object.assign({}, params), { index: idx })))
        .filter(Boolean);
    const { widget, widgetSize } = addOns
        .map(addOn => {
        var _a;
        const widget = (_a = addOn.widget) === null || _a === void 0 ? void 0 : _a.call(addOn);
        return widget && { widget, widgetSize: addOn.widgetSize };
    })
        .filter(Boolean)[0] || { widget: undefined, widgetSize: undefined };
    const left = addOns.map(addOn => { var _a; return (_a = addOn.left) === null || _a === void 0 ? void 0 : _a.call(addOn); }).filter(Boolean)[0];
    const top = addOns.map(addOn => { var _a; return (_a = addOn.top) === null || _a === void 0 ? void 0 : _a.call(addOn); }).filter(Boolean)[0];
    const selectors = addOns.map((addOn, idx) => addOn.selector(idx));
    const filters = addOns.map(addOn => addOn.evidenceFilter || TRUE);
    const filter = React.useCallback(AND(...filters), [
        params.evidenceAndTotals.value,
        filters,
    ]);
    return { widget, widgetSize, top, left, selectors, filter };
}
