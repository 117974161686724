import { isEqual as eq } from "lodash";
import { getValue } from "./util";
// An atom is used to hold a value.
// Invoked with no arguments, it returns the current value.
// Invoked with a value, sets the Atom value and returns
// the previous value.
export function Atom(value, { isEqual = eq, on, } = {}) {
    const Atom = {
        access(...args) {
            if (!args.length)
                return value;
            const newValue = getValue(value, args[0]);
            if (isEqual(newValue, value))
                return value;
            const previous = value;
            value = newValue;
            on === null || on === void 0 ? void 0 : on(value, previous);
            return previous;
        },
    };
    return Atom.access;
}
