var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FeatureIdentifier, FeedbackType, } from "../../api/types";
import { showNotification } from "../../../shared/components/notifications";
import { Facet } from "./Facet";
import { classes } from "../../../utils/classes";
import { ManageLists, ThumbsDown, ThumbsUp } from "../Icons";
import { sendFeedback } from "../../utils/feedback";
import { brandButtonClass } from "../lf";
import { useFacetsCollapseState } from "./facetsCollapsedState";
import { DefaultMarkDown } from "../../../shared/components/MarkDown";
import { FacetButton } from "./facetButtons";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { conceptMenuEntriesProvider } from "../contextMenuEntries";
import { identity } from "../../../utils/util";
import { useCurrentSource } from "../../utils/sourceManagement";
import { showCustomListEditor } from "../modals/CustomListsManager/CustomListEditor";
import { CorpusCapability } from "../../../utils/capabilities/capabilities";
export function NamedConceptMembersFacet(_a) {
    var { category, checkboxClass, initiallyCollapsed, titlePrefix, tagColor, suffixProvider, toOption = defaultItemToOption, itemsOverride, facetFor, getFacetTitle } = _a, props = __rest(_a, ["category", "checkboxClass", "initiallyCollapsed", "titlePrefix", "tagColor", "suffixProvider", "toOption", "itemsOverride", "facetFor", "getFacetTitle"]);
    return (_jsx(Facet, Object.assign({ name: category }, props, { placeholder: category, toOption: toOption, getCheckBoxEntry: getCheckBoxEntry, getTitle: getFacetTitle, titlePrefix: titlePrefix, checkboxClass: classes("flex-1 flex overflow-hidden", checkboxClass), getSuffix: suffixProvider === null || suffixProvider === void 0 ? void 0 : suffixProvider(), itemsOverride: itemsOverride, emptyText: `No types of ${category} found` })));
}
export function CoraStateNamedConceptMembersFacet(_a) {
    var { coraState } = _a, props = __rest(_a, ["coraState"]);
    const { category, argKey, initiallyCollapsed } = props;
    const [collapsed, setCollapsed] = useFacetsCollapseState(coraState.corpus_ids, argKey, category, initiallyCollapsed);
    return (_jsx(NamedConceptMembersFacet, Object.assign({}, props, { collapsed: collapsed, setCollapsed: setCollapsed })));
}
export const ArgumentClausesFacet = (CoraStateNamedConceptMembersFacet);
export function ConceptFacet(props) {
    const { coraStateUpdater, extraMenuEntries = [], coraState, selected } = props;
    const { structuredQuery: sq, showStructuredQuery } = useCurrentSession();
    const structuredQuery = sq && showStructuredQuery;
    const addCustomList = useAddCustomListMenuEntry(coraState.corpus_ids);
    const menuEntries = conceptMenuEntriesProvider(coraStateUpdater, props.coraState, structuredQuery, [addCustomList(selected), ...extraMenuEntries]);
    return (_jsx(CoraStateNamedConceptMembersFacet, Object.assign({}, props, { itemInCollectionFilter: nameAndKBsInMembersFilter, valueToSelection: identity, toOption: conceptClusterToOption, getPopupMenuEntries: menuEntries })));
}
function conceptClusterToOption(item) {
    return {
        label: item.name,
        value: item.name,
        item,
        hasChildren: item.has_children_concepts,
    };
}
export function defaultFeedbackProvider(category, feedback, setFeedback) {
    return () => (item, items, params) => {
        const key = category + item.name;
        function createButton(type) {
            return (_jsx(FacetButton, Object.assign({ className: classes("cursor-pointer  hover:border-brand-400", (feedback === null || feedback === void 0 ? void 0 : feedback[key]) === type && brandButtonClass, (feedback === null || feedback === void 0 ? void 0 : feedback[key]) !== type && "auto_hide"), onClick: () => __awaiter(this, void 0, void 0, function* () {
                    if ((feedback === null || feedback === void 0 ? void 0 : feedback[key]) === type)
                        return;
                    yield sendFeedback(FeatureIdentifier.ASPECTS, CorpusCapability.SEARCH_CONCEPTS, FeedbackType.NEGATIVE, item, params, items);
                    setFeedback === null || setFeedback === void 0 ? void 0 : setFeedback(f => (Object.assign(Object.assign({}, f), { [key]: type })));
                    showNotification("Feedback sent");
                }) }, { children: type === FeedbackType.NEGATIVE ? (_jsx(ThumbsDown, { className: "text-tiny" })) : (_jsx(ThumbsUp, { className: "text-tiny" })) })));
        }
        return !feedback || !setFeedback ? null : (_jsxs("div", Object.assign({ className: "flex space-x-1" }, { children: [createButton(FeedbackType.POSITIVE), createButton(FeedbackType.NEGATIVE)] })));
    };
}
export function nameAndKBsInMembersFilter(concepts) {
    const names = new Set(concepts.map(c => c.members.map(createNames).flat()).flat());
    return (s) => s.members.some(m => createNames(m).some(n => names.has(n)));
    function createNames(concept) {
        return concept.kb_ids.length
            ? concept.kb_ids.map(id => concept.name + ":" + id)
            : [concept.name];
    }
}
export function useAddCustomListMenuEntry(corpus_ids) {
    const { customConceptListsByCorpusId: listsById } = useCurrentSession();
    const source = useCurrentSource(corpus_ids);
    return (facetSelection) => {
        return {
            caption: "Create Custom List from Selection...",
            disabled: !facetSelection.length || !source,
            icon: _jsx(ManageLists, {}),
            action() {
                showCustomListEditor({
                    corpus_id: corpus_ids[0],
                    list: undefined,
                    customConceptListsByCorpusId: listsById,
                    initialText: facetSelection.map(c => c.name).join("\n"),
                }).then(list => {
                    if (!list)
                        return;
                    const lists = listsById[source.id] || [];
                    const updatedListsById = Object.assign(Object.assign({}, listsById), { [source.id]: lists.concat([list]) });
                    updateCurrentSession({ customConceptListsByCorpusId: updatedListsById });
                    showNotification(`Custom list ${list.name} created with ${list.accepted_concepts.length} concepts`);
                });
            },
        };
    };
}
function defaultItemToOption(item) {
    return { label: item.name, value: item.name, item };
}
function getCheckBoxEntry(o, prefix) {
    return (_jsx("span", Object.assign({ className: "flex flex-1 space-x-1 overflow-hidden self-stretch text-[12px]" }, { children: _jsx(DefaultMarkDown, { prefix: true, text: o.label, separator: prefix, style: { textOverflow: "ellipsis" }, className: "overflow-hidden" }) })));
}
