export function Emitter() {
    let listeners = [];
    return {
        emit(v) {
            listeners.forEach(l => l(v));
        },
        on(listener) {
            listeners = listeners.concat([listener]);
            return () => {
                listeners = listeners.filter(l => l !== listener);
            };
        },
    };
}
