import * as go from "gojs";
import chroma from "chroma-js";
import { getTwColor, selectedBg } from "../lf";
import { Icons } from "./DiagramIcons";
import { createLayout } from "../../../shared/components/gojs/gojs";
import { DiagramModel, LinkType, NodeType, } from "./DiagramModel";
import { nodeHasChildren } from "./diagramUtils";
const $ = go.GraphObject.make;
go.Diagram.licenseKey =
    "73f942e2b56628a800ca0d2b113f69ee1bb37b649ed71af95e5341a4ff186e15729fe97c02d4dfc0d2ff4ea8137bc18cd4956d2e855c036eb737d38e13e394e9b13670b10b0f588da2";
const SELECTED_BORDER_COLOR = getTwColor("purple-500");
const BORDER_COLOR = getTwColor("gray-500");
const LINK_COLOR = getTwColor("gray-400");
const SELECTED_LINK_COLOR = "black";
const NODE_BG_COLOR = "white";
const SUB_GRAPH_BG_COLOR = chroma(NODE_BG_COLOR).alpha(0).css();
const SELECTED_WIDTH = 2;
const NODE_TEXT_COLOR = "#333";
const CANVAS_COLOR = "white";
function addSelectionAdornmentTemplate() {
    return {
        selectionAdornmentTemplate: $(go.Adornment, "Auto", $(go.Shape, "RoundedRectangle", {
            fill: null,
            stroke: SELECTED_BORDER_COLOR,
            strokeWidth: 3,
        }), $(go.Placeholder)),
    };
}
export function configureDiagram(diagram) {
    diagram.model = new DiagramModel();
    diagram.animationManager.isEnabled = false;
    //////////////////////////////////////////////////////////////////////////////
    diagram.linkTemplate = $(go.Link, {
        selectionAdorned: false,
        layerName: "Background",
        reshapable: false,
        routing: go.Link.None,
        // routing: go.Link.AvoidsNodes, // straight links
        // routing: go.Link.Orthogonal, // straight links
        // corner: 5,
        // curve: go.Link.JumpOver,
        // curve: go.Link.Bezier,
    }, $(go.Shape, { strokeWidth: 1, stroke: LINK_COLOR }, new go.Binding("strokeDashArray", "", (e) => e.type === LinkType.QUALIFIER ? [5, 3] : []), new go.Binding("stroke", "", (e) => e.selected ? SELECTED_LINK_COLOR : LINK_COLOR), new go.Binding("strokeWidth", "", (e) => e.selected ? SELECTED_WIDTH : 1)), $(go.Panel, "Auto", $(go.Shape, { fill: "", stroke: null }, new go.Binding("fill", "", (e) => chroma(CANVAS_COLOR).alpha(0.4).css())), $(go.Panel, "Vertical", { defaultAlignment: go.Spot.Center }, $(go.TextBlock, {
        textAlign: "center",
        font: "10px Roboto, sans-serif",
        stroke: "#000",
        margin: new go.Margin(2, 4, 2, 4),
    }, new go.Binding("text", "", (e) => e.text || ""))), new go.Binding("visible", "", (e) => { var _a; return Boolean((_a = e.text) === null || _a === void 0 ? void 0 : _a.length); })), $(go.Shape, { toArrow: "", stroke: null, fill: LINK_COLOR }, new go.Binding("toArrow", "", (e) => e.type === LinkType.LINE || !e.directed ? "" : "Triangle"), new go.Binding("fill", "", (e) => e.selected ? SELECTED_LINK_COLOR : LINK_COLOR)));
    function addOnHover() {
        return [
            new go.Binding("mouseEnter", "", (n, m) => {
                return () => diagram.onMouseEnter(n, m);
            }),
            new go.Binding("mouseLeave", "", (n, m) => {
                return () => diagram.onMouseLeave(n, m);
            }),
        ];
    }
    //////////////////////////////////////////////////////////////////////////////
    diagram.nodeTemplate = $(go.Node, "Auto", addSelectionAdornmentTemplate(), ...addOnHover(), new go.Binding("visible", "", (n) => !n.hidden), new go.Binding("opacity", "", (n) => (n.disabled ? 0.3 : 1)), new go.Binding("selectionAdorned", "", n => !n.disabled), new go.Binding("cursor", "", n => !n.disabled ? "pointer" : "not-allowed"), $(go.Shape, "RoundedRectangle", {
        fill: NODE_BG_COLOR,
        portId: "",
        cursor: "pointer",
        parameter1: 8,
        strokeWidth: 1,
        stroke: BORDER_COLOR,
    }, new go.Binding("fill", "fill", fill => (fill === null || fill === void 0 ? void 0 : fill.length) ? fill : NODE_BG_COLOR), new go.Binding("stroke", "stroke", stroke => stroke || BORDER_COLOR)), $(go.Panel, "Horizontal", { defaultAlignment: go.Spot.Center }, $(go.TextBlock, {
        font: "bold 12px Roboto, sans-serif",
        stroke: NODE_TEXT_COLOR,
        // margin: 6,
        isMultiline: false,
    }, new go.Binding("text"), new go.Binding("stroke", "textColor", textColor => (textColor === null || textColor === void 0 ? void 0 : textColor.length) ? textColor : NODE_TEXT_COLOR)), $(go.Panel, "Auto", {
        position: new go.Point(100, 0),
        margin: new go.Margin(0, 0, 0, 8),
        visible: false,
    }, $(go.Shape, {
        margin: 3,
        // fill: "white",
        strokeWidth: 0,
        width: 8,
        height: 8,
        geometry: getGeo(Icons.CLOSE),
    }), new go.Binding("visible", "", (n) => !!n.canRemove), new go.Binding("click", "", n => {
        return () => diagram.onRemove(n);
    }))));
    function getGeo(path) {
        return go.Geometry.parse(path, true);
    }
    function typeNodeLayout() {
        return $(go.LayeredDigraphLayout, {
            direction: 0,
            columnSpacing: 2,
        });
    }
    //////////////////////////////////////////////////////////////////////////////
    diagram.groupTemplate = $(go.Group, "Auto", {
        layout: typeNodeLayout(),
        cursor: "pointer",
    }, addSelectionAdornmentTemplate(), ...addOnHover(), new go.Binding("layout", "", n => n.type === NodeType.TYPE ? typeNodeLayout() : createLayout(true)), new go.Binding("cursor", "", n => !n.disabled ? "pointer" : "not-allowed"), new go.Binding("selectionAdorned", "", n => !n.disabled), new go.Binding("opacity", "", (n) => (n.disabled ? 0.3 : 1)), $(go.Shape, "RoundedRectangle", {
        strokeWidth: 1,
        parameter1: 8,
        fill: NODE_BG_COLOR,
        stroke: BORDER_COLOR,
    }, new go.Binding("fill", "", n => n.fill
        ? n.fill
        : n.type == NodeType.SUB_GRAPH
            ? SUB_GRAPH_BG_COLOR
            : NODE_BG_COLOR), new go.Binding("stroke", "stroke", stroke => stroke || BORDER_COLOR)), $(go.Panel, "Vertical", // position header above the subgraph
    { defaultAlignment: go.Spot.Left, margin: 3 }, new go.Binding("margin", "", n => nodeHasChildren(n.data) ? 8 : new go.Margin(0, 8, 0, 8)), $(go.Panel, "Horizontal", { defaultAlignment: go.Spot.Top, padding: new go.Margin(0, 0, 0, 0) }, new go.Binding("padding", "", n => new go.Margin(0, 0, n.isSubGraphExpanded && nodeHasChildren(n.data) ? 8 : 0, 0)).ofObject(), $("SubGraphExpanderButton", { visible: true, alignment: go.Spot.Center }, new go.Binding("visible", "", nodeHasChildren)), $(go.Panel, "Horizontal", { defaultAlignment: go.Spot.Top, padding: new go.Margin(0, 0, 0, 8) }, new go.Binding("padding", "", (n) => new go.Margin(0, 0, 0, nodeHasChildren(n) ? 8 : 0)), $(go.TextBlock, {
        font: "bold 13px Roboto, sans-serif",
        stroke: NODE_TEXT_COLOR,
        alignment: go.Spot.Center,
    }, new go.Binding("text"), new go.Binding("stroke", "textColor", textColor => (textColor === null || textColor === void 0 ? void 0 : textColor.length) ? textColor : NODE_TEXT_COLOR)), $(go.Panel, "Auto", {
        position: new go.Point(100, 0),
        margin: new go.Margin(0, 0, 0, 8),
        visible: false,
    }, $(go.Shape, "RoundedRectangle", {
        fill: selectedBg,
        stroke: null,
        // p1: 10,
    }), $(go.TextBlock, {
        font: "bold 13px Roboto, sans-serif",
        stroke: "white",
        alignment: go.Spot.Center,
        margin: new go.Margin(0, 2, 0, 2),
    }, new go.Binding("text", "", (n) => n.topConcepts ? n.topConcepts.length + "" : "?")), new go.Binding("visible", "", (n) => n.type === NodeType.TYPE && n.topConcepts !== null)))), $(go.Placeholder, // represents area for all member parts
    { padding: new go.Margin(0, 0), background: NODE_BG_COLOR }, new go.Binding("background", "", n => n.fill
        ? n.fill
        : n.type == NodeType.SUB_GRAPH
            ? SUB_GRAPH_BG_COLOR
            : NODE_BG_COLOR))));
}
