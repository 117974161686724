var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { API } from "../../../api/api";
import { useQueryState } from "../../../utils/queryState/queryState";
import { getConjunctionBindingsRequest } from "../../../utils/conjunctionBindingsRequest";
import { useCurrentSession } from "../../../utils/sessionManagement";
import { useInterruptableFetch } from "../../../utils/interruptableFetch";
import { setPossibleValues, usePossibleValues, } from "../../../utils/possibleValues";
export function useSolutionsActions() {
    // {}: ActionsProviderParams
    const [fetch, loading] = useInterruptableFetch();
    const [state] = useQueryState();
    const possible = usePossibleValues();
    const { conjunctionBindingsSearchWidth, expandedFacetCount } = useCurrentSession();
    return React.useCallback(() => {
        const request = getConjunctionBindingsRequest(state, conjunctionBindingsSearchWidth, expandedFacetCount);
        return {
            globalRoot: [
                {
                    caption: "Solutions",
                    subEntries: [
                        {
                            caption: "Explore Solutions",
                            desc: "Find concepts that satisfy the model.",
                            disabled: !request,
                            action() {
                                return __awaiter(this, void 0, void 0, function* () {
                                    fetch((signal) => __awaiter(this, void 0, void 0, function* () {
                                        const possible = yield API.conjunctionBindings(request, {
                                            signal,
                                            ignoreErrorHandler: true,
                                        });
                                        setPossibleValues(possible);
                                    }), _jsxs("div", { children: ["Finding Solutions", _jsx("div", { children: "This could take some time..." }), _jsx("div", { children: "We are working so you don't have to." })] }));
                                });
                            },
                        },
                        {
                            caption: "Clear",
                            disabled: !possible,
                            desc: "Clear Found Solutions.",
                            action() {
                                setPossibleValues(null);
                            },
                        },
                    ],
                },
            ],
        };
    }, [state, possible]);
}
