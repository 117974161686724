var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { coloredTextInfoClass, columnWidthClass3 } from "../lf";
import { classes } from "../../../utils/classes";
import React from "react";
import { popModal } from "../../../shared/components/Modal";
import { Set } from "immutable";
import { Copy } from "../Icons";
import { InfoBox } from "../../../shared/components/InfoBox";
import { showNotification } from "../../../shared/components/notifications";
import { CancelOkButtons } from "./CancelOkButtons";
import { FilteredCardList, infoRenderer, } from "../../../shared/components/CardsList";
import { Checkbox } from "antd";
export function SelectItemsModal(_a) {
    var { contextText, buttonCaption, notificationMessage, onSelected, buttonColorClass, infoExtractor } = _a, props = __rest(_a, ["contextText", "buttonCaption", "notificationMessage", "onSelected", "buttonColorClass", "infoExtractor"]);
    const { items, brandingColorClass } = props;
    const [selection, setSelection] = React.useState(Set());
    const selectedItems = items.filter(i => selection.has(i));
    return (_jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-stretch space-y-4" }, { children: [contextText && (_jsx(InfoBox, Object.assign({ className: classes(columnWidthClass3, coloredTextInfoClass), extra: _jsx(Copy, { className: "ml-4" }) }, { children: contextText }))), _jsx("div", Object.assign({ className: "flex-1 flex items-stretch space-x-[144px]" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch" }, { children: [_jsx(FilteredCardList, Object.assign({ className: columnWidthClass3 }, props, { itemRender: infoRenderer((item, idx, items, filterText) => {
                                const info = infoExtractor(item, idx, _jsx(_Fragment, { children: _jsx(Checkbox, { checked: selection.has(item), onChange: () => setSelection(selection[!selection.has(item) ? "add" : "remove"](item)) }) }), items, filterText, selection.has(item));
                                return Object.assign(Object.assign({}, info), { brandingColorClass, bgColorClass: selection.has(item) ? "bg-accent-50" : "" });
                            }) })), _jsx(CancelOkButtons, { buttonCaption: buttonCaption, count: selectedItems.length, buttonColorClass: buttonColorClass, disabled: !selection.size, action: () => {
                                onSelected(selectedItems);
                                popModal(true);
                                notificationMessage && showNotification(notificationMessage);
                            } })] })) }))] })));
}
