var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFacetsCollapseState } from "../facetsCollapsedState";
import { Facet } from "../Facet";
import { default_count } from "../../../utils/semanticSearchUtil";
import { API } from "../../../api/api";
import React from "react";
import { useMetadataParamsGetter, } from "../../../utils/metatada";
import { useCurrentSession } from "../../../utils/sessionManagement";
export function FacetsForKeyword({ corpusFilterParams, name, fieldId, selection = [], updateSelection, className, }) {
    const { expandedFacetCount } = useCurrentSession();
    const { paramsGetter, change } = useMetadataParamsGetter(corpusFilterParams, fieldId);
    const [collapsed, setCollapsed] = useFacetsCollapseState(corpusFilterParams.corpus_ids, "metadata", fieldId, true);
    return (_jsx(Facet, { name: name, itemInCollectionFilter: items => {
            const names = new Set(items.map(i => i.name));
            return s => names.has(s.name);
        }, className: className, onlyLoadWhenExpanded: true, collapsed: collapsed, setCollapsed: setCollapsed, placeholder: name, facetLoader: ({ prefix, showMore }) => __awaiter(this, void 0, void 0, function* () {
            const params = !paramsGetter
                ? undefined
                : paramsGetter(showMore ? expandedFacetCount : default_count, prefix);
            return !params ? [] : (yield API.searchMetadata(params)).values;
        }), loaderParamsGetter: a => a, selected: selection, setSelected: value => updateSelection(name, fieldId, value.length ? value : null), valueToSelection: item => item, toOption: item => ({ label: item.name, value: item.name, item }), getCheckBoxEntry: item => (_jsx("div", Object.assign({ className: "flex flex-1 space-x-1 overflow-hidden self-stretch text-[12px]" }, { children: _jsx("span", Object.assign({ className: "overflow-hidden ", style: { textOverflow: "ellipsis" } }, { children: item.value })) }))), getTitle: c => (_jsxs("span", { children: [name, c] })), itemsOverride: null, max_count: expandedFacetCount }, change));
}
