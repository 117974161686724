import { getSize } from "../../../utils/util";
import { CONCEPT_1_COLOR, CONCEPT_2_COLOR, NEUTRAL_COLOR, RELATION_COLOR, } from "../lf";
export function getPartsAndColors(parts, renderParts, conceptColorGetter, styleProvider) {
    const { c1, c2, r, text } = parts;
    const entries = text
        ? [{ parts: [text], color: NEUTRAL_COLOR }]
        : [
            {
                parts: (c1 === null || c1 === void 0 ? void 0 : c1.length) === 0 || (!c1 && ((c2 === null || c2 === void 0 ? void 0 : c2.length) || getSize(r)))
                    ? ["?"]
                    : c1 || [],
                color: (conceptColorGetter === null || conceptColorGetter === void 0 ? void 0 : conceptColorGetter(parts, true)) || CONCEPT_1_COLOR,
            },
            { parts: r || [], color: RELATION_COLOR },
            {
                parts: (c2 === null || c2 === void 0 ? void 0 : c2.length) === 0 || (!c2 && getSize(r)) ? ["?"] : c2 || [],
                color: (conceptColorGetter === null || conceptColorGetter === void 0 ? void 0 : conceptColorGetter(parts, false)) || CONCEPT_2_COLOR,
            },
        ];
    return entries
        .filter(p => p.parts.length)
        .map(({ parts, color }, idx) => renderParts(parts, color, "CRC", idx, styleProvider));
}
export const CRC_ONLY_PARTS = ["c1", "r", "c2", "dir", "text"];
