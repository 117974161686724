import { Beacon, useBeacon } from "../../utils/Beacon";
import { API } from "../api/api";
import { omit } from "lodash";
import React from "react";
import { getValue } from "../../utils/util";
import { getContextCorpusIds, getContextUrl } from "./findings";
const LOADING = { loading: true, value: null };
const evidenceBeacon = Beacon({ initial: {} });
export function suggestEvidence(finding) {
    var _a, _b, _c;
    const { id, context, payload } = finding;
    const url = getContextUrl(payload.context) || "";
    if (evidenceBeacon.value()[id])
        return;
    evidenceBeacon.value(evidence => (Object.assign(Object.assign({}, evidence), { [id]: LOADING })));
    API.suggest_evidence({
        evidence: ((_a = payload.evidence[0]) === null || _a === void 0 ? void 0 : _a.text) || payload.selectedText,
        finding: payload.selectedText,
        url: url,
        source_document_title: ((_c = (_b = payload.context) === null || _b === void 0 ? void 0 : _b.instances[0]) === null || _c === void 0 ? void 0 : _c.title) || "",
        count: 5,
        corpus_ids: getContextCorpusIds(context),
    })
        .then(value => {
        evidenceBeacon.value(evidence => (Object.assign(Object.assign({}, evidence), { [id]: { value, loading: false } })));
    })
        .catch(error => {
        evidenceBeacon.value(evidence => (Object.assign(Object.assign({}, evidence), { [id]: { value: [], loading: false } })));
        console.error(error);
    });
}
export function useSuggestedEvidence() {
    return useBeacon(evidenceBeacon);
}
export function evictSuggestedEvidenceFor(id) {
    evidenceBeacon.value(evidence => omit(evidence, id));
}
export function useSuggestedEvidenceFor(id) {
    const [suggested, setSuggested] = useSuggestedEvidence();
    const setter = React.useCallback((vOrFn) => setSuggested(s => (Object.assign(Object.assign({}, s), { [id]: { value: getValue(s[id].value, vOrFn), loading: false } }))), []);
    return [suggested[id], setter];
}
