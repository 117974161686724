import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { bgEvidence500 } from "../lf";
import { sortMetadata, upsertCoraStateMetadata } from "../../utils/metatada";
import { useCurrentSource } from "../../utils/sourceManagement";
import { useCurrentSession } from "../../utils/sessionManagement";
import { showSelectionMenu } from "../contextMenuEntries";
import { useEvidenceListPagination } from "./EvidenceListPagination";
import { getUrlOpener } from "./urlOpener";
import { EvidenceCard } from "./cards/EvidenceCard";
import { PickerList } from "../../../shared/components/PickerList";
import { scrollToIndex } from "../../../utils/scroll";
import { useQueryState } from "../../utils/queryState/queryState";
export function EvidenceSearchResults({ argsInfo, coraState, coraStateUpdater, results: evidence, disabled, evidenceSearch, evidenceState, evidenceStateUpdater, annotationProvider, renderEvidenceActions, className, }) {
    const { expandedEvidence, findings, structuredQuery: sq, showStructuredQuery, } = useCurrentSession();
    const structuredQuery = sq && showStructuredQuery;
    const source = useCurrentSource(coraState);
    const { evidenceAndTotals, noSelection, corpus_filter, pageSize } = evidenceSearch;
    const { loading } = evidenceAndTotals;
    const { listRender, paginationFooter, virtuosoRef } = useEvidenceListPagination(evidenceAndTotals, evidenceStateUpdater, pageSize);
    function getDocumentIndexForEvidence(evidenceId) {
        return evidence.findIndex(e => e.id === evidenceId);
    }
    React.useEffect(() => {
        if (evidenceState.highlight.size > 0 && virtuosoRef.current) {
            const indexes = evidenceState.highlight
                .map(getDocumentIndexForEvidence)
                .sort();
            scrollToIndex(virtuosoRef.current, indexes.first(), "smooth");
        }
    }, [evidenceState, virtuosoRef]);
    const selectEvidence = React.useCallback((item) => evidenceStateUpdater.update({
        selection: evidenceState.selection[!evidenceState.selection.has(item.id) ? "add" : "remove"](item.id),
    }), [evidenceStateUpdater]);
    const [queryState] = useQueryState();
    const itemRender = React.useCallback((item, idx) => (_jsx(EvidenceCard, { className: className, evidence: [item], onSelect: selectEvidence, selected: evidenceStateUpdater.itemSelected, highlighted: evidenceStateUpdater.itemHighlighted, actionsTooltip: "Give Feedback", showSurrounding: expandedEvidence, onMetadataClick: (e) => {
            upsertCoraStateMetadata(coraState, e, coraStateUpdater);
        }, urlOpenerFn: getUrlOpener(coraState, queryState, coraStateUpdater, findings, structuredQuery, source), onEvidenceTextSelected: ({ evidence, rect, text }) => showSelectionMenu(rect, {
            text,
            coraState,
            coraStateUpdater,
            findings,
            evidence,
            instance: evidence.instances[0],
            context: evidence.text,
            structuredQuery,
            expandedEvidence,
            queryState,
        }), showHighlights: true, metadataSorter: sortMetadata(source), annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions })), [
        evidenceStateUpdater,
        selectEvidence,
        expandedEvidence,
        structuredQuery,
        annotationProvider,
        renderEvidenceActions,
        evidenceStateUpdater,
        queryState,
    ]);
    return (_jsx(PickerList, { containerStyleClass: "pt-0", items: evidence, disabled: disabled, className: "flex-1", narrow: false, itemRender: itemRender, itemsListRenderer: listRender, loading: loading && !evidenceAndTotals.value, isSelected: (e) => evidenceStateUpdater.itemSelected(e.id), onSelect: selectEvidence, bgClassName: "bg-transparent", brandingColorClass: bgEvidence500, setTextFilter: evidenceFilter => coraStateUpdater({ evidenceFilter }), noItemsMessage: noSelection
            ? "No Query"
            : coraState.refuting
                ? "No Refuting Evidence Found"
                : "No Evidence Found", footer: _jsx("div", Object.assign({ className: "flex flex-col border-t py-1" }, { children: paginationFooter })) }));
}
