import Lazy from "lazy.js";
export function textFilter(filterText) {
    function delimited() {
        const trimmed = filterText.trim();
        const char = trimmed.charAt(0);
        const endsWithChar = trimmed.charAt(trimmed.length - 1) === char;
        return [
            endsWithChar && trimmed.length >= 2 ? trimmed.slice(1, -2) : undefined,
            char,
        ];
    }
    const [text, char] = delimited();
    if (text && (char === '"' || char === "'")) {
        return (val) => val === text;
    }
    if (text && text.length >= 1 && char === "/") {
        try {
            const regExp = new RegExp(text);
            return (val) => {
                return Boolean(regExp.exec(val));
            };
        }
        catch (_a) {
            return (val) => false;
        }
    }
    return caseInsensitiveFilter(filterText);
}
export function caseInsensitiveFilter(filterText) {
    return (val) => { var _a, _b; return (_b = (_a = val === null || val === void 0 ? void 0 : val.toLowerCase) === null || _a === void 0 ? void 0 : _a.call(val)) === null || _b === void 0 ? void 0 : _b.includes(filterText.toLowerCase()); };
}
function flatten(filters) {
    return Lazy(filters)
        .filter(Boolean)
        .map(filter => {
        if (typeof filter === "function")
            return [filter];
        if (Array.isArray(filter))
            return flatten(filter);
        return flatten(Object.values(filter));
    })
        .flatten()
        .toArray();
}
export function NOT(filter) {
    return (v) => !filter(v);
}
export function TRUE(v) {
    return true;
}
export function FALSE(v) {
    return false;
}
export function AND(...filters) {
    const fs = flatten(filters);
    if (!fs.length)
        return TRUE;
    if (fs.length === 1)
        return fs[0];
    return (v) => {
        for (const filter of fs) {
            if (!filter(v))
                return false;
        }
        return true;
    };
}
export function OR(...filters) {
    const fs = flatten(filters);
    if (!fs.length)
        return TRUE;
    if (fs.length === 1)
        return fs[0];
    return (v) => {
        for (const filter of fs) {
            if (filter(v))
                return true;
        }
        return false;
    };
}
export function ALL(filterItems) {
    return (items) => Lazy(filterItems).every(item => items.indexOf(item) >= 0);
}
export function EXACTLY(filterItems) {
    const all = ALL(filterItems);
    return (items) => {
        return items.length === filterItems.length && all(items);
    };
}
export function SOME(filterItems) {
    return (items) => Lazy(filterItems).some(tag => items.indexOf(tag) >= 0);
}
export function allLettersInText(text, filter) {
    text = text.toLowerCase();
    filter = filter.toLowerCase();
    let matchIdx = 0;
    for (let i = 0; i < text.length; i++) {
        const c = text.charAt(i);
        matchIdx = filter.indexOf(c, matchIdx);
        if (matchIdx < 0)
            return false;
    }
    return true;
}
export function filterItems(items, filter) {
    return !filter || filter === TRUE
        ? items
        : filter === FALSE
            ? []
            : items.filter(filter);
}
export function startsWithFilter(text, filter) {
    filter = filter === null || filter === void 0 ? void 0 : filter.trim().toLowerCase();
    return !(filter === null || filter === void 0 ? void 0 : filter.length) || text.toLowerCase().trim().startsWith(filter);
}
export function includesFilter(text, filter) {
    filter = filter === null || filter === void 0 ? void 0 : filter.trim().toLowerCase();
    return !(filter === null || filter === void 0 ? void 0 : filter.length) || text.toLowerCase().trim().includes(filter);
}
export function wordMatches(filterText, includes = true) {
    filterText = filterText === null || filterText === void 0 ? void 0 : filterText.trim().toLowerCase();
    if (!(filterText === null || filterText === void 0 ? void 0 : filterText.length))
        return TRUE;
    return text => {
        text = text.toLowerCase();
        return (!(filterText === null || filterText === void 0 ? void 0 : filterText.length) ||
            !!text
                .split(/\s+/g)
                .find(part => includes ? part.includes(filterText) : part.startsWith(filterText)));
    };
}
