import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Popconfirm } from "antd";
import { popModal } from "../../../shared/components/Modal";
import { classes } from "../../../utils/classes";
import React from "react";
import { columnWidthClass2 } from "../lf";
import { BrandButton } from "../../../shared/components/buttons/Buttons";
export function CancelOkButtons({ disabled, buttonColorClass, action, buttonCaption, count, className, confirmMessage = "Lose Changes?", }) {
    function cancelButton(action) {
        return (_jsx(BrandButton, Object.assign({ onClick: !action
                ? undefined
                : () => {
                    popModal(false);
                } }, { children: "Cancel" })));
    }
    return (_jsxs("div", Object.assign({ className: classes("flex space-x-4 px-4 self-end", columnWidthClass2, className) }, { children: [!confirmMessage || disabled ? (cancelButton(true)) : (_jsx(Popconfirm, Object.assign({ title: "", icon: null, onConfirm: () => popModal(false), okText: confirmMessage, showCancel: false }, { children: cancelButton(false) }))), _jsx(Button, Object.assign({ type: "primary", disabled: disabled, className: classes("flex-1", buttonColorClass), onClick: action }, { children: `${buttonCaption}${count !== undefined ? ` (${count})` : ""}` }))] })));
}
