"use strict";
const colors = require("tailwindcss/colors");
module.exports = Object.assign(Object.assign({}, colors), { brand: colors.gray, neutral: {
        100: "#FBFBFB",
        200: "#F3F3F3",
        250: "#E4E4E7",
        300: "#E5E5E5",
        400: "#CCCCCC",
        500: "#B2B2B2",
        550: "#AAAAAA",
        600: "#999999",
        700: "#808080",
        800: "#666666",
        900: "#4D4D4D",
        1000: "#333333",
    }, notification_blue: {
        100: "#E6EBFD",
        200: "#CCD6FB",
        300: "#99ADF7",
        400: "#6684F3",
        500: "#335BEF",
        600: "#0032EB",
        700: "#0018EB",
        800: "#0013C0",
        900: "#000F95",
    }, alert_red: {
        100: "#FFEFED",
        500: "#E11900",
    }, alert_green: {
        100: "#E6F2ED",
        200: "#ADDEC9",
        300: "#66D19E",
        400: "#06C167",
        500: "#05944F",
        600: "#03703C",
        700: "#03582F",
        800: "#10462D",
        900: "#0e3c27",
    }, alert_yellow: {
        100: "#FFFAF0",
        200: "#FFF2D9",
        300: "#FFE3AC",
        400: "#FFCF70",
        500: "#FFC043",
        600: "#BC8B2C",
        700: "#997328",
        800: "#674D1B",
        900: "#4f3d15",
    }, core_yellow: {
        500: "#F8DF03",
    }, decorative_yellow: {
        100: "#FFFDD8",
        200: "#FFFBB5",
        300: "#FFF669",
        400: "#FFF001",
        500: "#F8DF03",
        600: "#ECC600",
        700: "#D8A800",
        800: "#AF8900",
        900: "#8E6F00",
    }, accent: {
        25: "#FAF3FD",
        50: "#F4ECF8",
        100: "#E9D9F0",
        200: "#dca3ec",
        300: "#b253d7",
        400: "#9f49c1",
        500: "#9342B3",
        600: "#76358F",
        700: "#632b7c",
        800: "#3B1A48",
        900: "#1D0D24",
        1000: "#0f0712",
    } });
