import { SortOrder, } from "../api/types";
import { keysFor } from "../../utils/util";
export const KEYWORD_METADATA = {
    keywordMetadata: [],
};
export const RANGE_METADATA_OVERRIDE = {
    rangeMetadataOverride: [],
};
export const RANGE_METADATA = Object.assign(Object.assign({}, RANGE_METADATA_OVERRIDE), { rangeMetadata: [] });
export const BOOLEAN_METADATA = {
    booleanMetadata: [],
};
export const METADATA = Object.assign(Object.assign(Object.assign({}, KEYWORD_METADATA), RANGE_METADATA), BOOLEAN_METADATA);
const DEFAULT_ORDER = SortOrder.DESC;
export const REFUTING_QUERY_PARAMS = {
    refuting_relations: null,
    refuting_concepts: null,
};
export const DEFAULT_APERTURE = 0.5;
export const EMPTY_DM_PARAMS = {
    concepts: {},
    clauses: {},
    relations: {},
    constraints: [],
    // inference_rules: [],
};
export const EMPTY_COMMON_STATE_PARAMS = Object.assign(Object.assign(Object.assign({}, METADATA), REFUTING_QUERY_PARAMS), { corpus_ids: [], evidenceFilter: "", refuting: false, sortFieldId: "", sortOrder: DEFAULT_ORDER, aperture: DEFAULT_APERTURE, dmParams: EMPTY_DM_PARAMS, activeConstraintId: "" });
export const COMMON_STATE_PARAMS_KEYS = keysFor(EMPTY_COMMON_STATE_PARAMS);
