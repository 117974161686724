import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { bgEvidence500 } from "../lf";
import { sortMetadata, upsertCoraStateMetadata } from "../../utils/metatada";
import { useCurrentSource } from "../../utils/sourceManagement";
import { useCurrentSession } from "../../utils/sessionManagement";
import { showSelectionMenu } from "../contextMenuEntries";
import { useEvidenceListPagination } from "./EvidenceListPagination";
import { getUrlOpener } from "./urlOpener";
import { PickerList } from "../../../shared/components/PickerList";
import { DocumentCard } from "./cards/DocumentCard";
import { scrollToIndex } from "../../../utils/scroll";
import { useQueryState } from "../../utils/queryState/queryState";
export function DocumentSearchResults({ argsInfo, coraState, coraStateUpdater, results: documents, evidenceSearch, disabled, evidenceState, evidenceStateUpdater, annotationProvider, renderEvidenceActions, className, }) {
    const { expandedEvidence, findings, structuredQuery: sq, showStructuredQuery, } = useCurrentSession();
    const [queryState] = useQueryState();
    const structuredQuery = sq && showStructuredQuery;
    const source = useCurrentSource(coraState);
    const { evidenceAndTotals, noSelection, corpus_filter, pageSize } = evidenceSearch;
    const { loading } = evidenceAndTotals;
    const { listRender, paginationFooter, virtuosoRef } = useEvidenceListPagination(evidenceAndTotals, evidenceStateUpdater, pageSize);
    function getDocumentIndexForEvidence(evidenceId) {
        return documents.findIndex(d => [d.primary_evidence, ...d.additional_evidence]
            .map(e => e.id)
            .includes(evidenceId));
    }
    React.useEffect(() => {
        if (evidenceState.highlight.size > 0 && virtuosoRef.current) {
            const indexes = evidenceState.highlight
                .map(getDocumentIndexForEvidence)
                .sort();
            scrollToIndex(virtuosoRef.current, indexes.first(), "smooth");
        }
    }, [evidenceState, virtuosoRef]);
    const [collapsedDocuments, setCollapsedDocuments] = React.useState({});
    const setCollapsedDoc = React.useCallback((docId, collapsed) => {
        setCollapsedDocuments(Object.assign(Object.assign({}, collapsedDocuments), { [docId]: collapsed }));
    }, [collapsedDocuments, setCollapsedDocuments]);
    const itemRender = React.useCallback((item, idx) => (_jsx(DocumentCard, { className: className, document: item, evidenceState: evidenceState, evidenceStateUpdater: evidenceStateUpdater, actionsTooltip: "Give Feedback", showSurrounding: expandedEvidence, onMetadataClick: (e) => {
            upsertCoraStateMetadata(coraState, e, coraStateUpdater);
        }, urlOpenerFn: getUrlOpener(coraState, queryState, coraStateUpdater, findings, structuredQuery, source), onEvidenceTextSelected: ({ evidence, rect, text }) => showSelectionMenu(rect, {
            text,
            coraState,
            coraStateUpdater,
            findings,
            evidence,
            instance: evidence.instances[0],
            context: evidence.text,
            structuredQuery,
            queryState,
        }), showHighlights: true, metadataSorter: sortMetadata(source), collapsed: Boolean(collapsedDocuments[item.document_id]), setCollapsed: collapsed => setCollapsedDoc(item.document_id, collapsed), annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions })), [
        queryState,
        collapsedDocuments,
        expandedEvidence,
        structuredQuery,
        annotationProvider,
        renderEvidenceActions,
        evidenceStateUpdater,
    ]);
    return (_jsx(PickerList, { containerStyleClass: "pt-0", items: documents, disabled: disabled, className: "flex", narrow: false, itemRender: itemRender, itemsListRenderer: listRender, loading: loading && !evidenceAndTotals.value, bgClassName: "h-full flex bg-transparent", brandingColorClass: bgEvidence500, setTextFilter: evidenceFilter => coraStateUpdater({ evidenceFilter }), noItemsMessage: noSelection
            ? "No Query"
            : coraState.refuting
                ? "No Refuting Evidence Found"
                : "No Evidence Found", footer: _jsx("div", Object.assign({ className: "flex flex-col border-t py-1" }, { children: paginationFooter })) }));
}
