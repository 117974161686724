import { jsx as _jsx } from "react/jsx-runtime";
import { ElementType } from "../model";
import { getEntityPicker } from "./EntityPicker";
import React from "react";
import { getAncestor } from "../../../../utils/dom";
import { terminateEvent } from "../../../../utils/events";
import { Down } from "../../Icons";
import { DropDown } from "../../../../shared/components/DropDown";
import { classes } from "../../../../utils/classes";
import { Beacon } from "../../../../utils/Beacon";
import { uuidv4 } from "../../../../utils/util";
const CLASS_NAME = "DropDownEntityPicker";
const hideDropDown = Beacon({ initial: null });
export function DropDownEntityPicker({ props, updateContent, }) {
    const id = React.useMemo(uuidv4, []);
    const { type, content, selection, expanded } = props;
    const isConcept = type === ElementType.CONCEPT;
    const selected = isConcept ? selection : content;
    const down = (_jsx(Down, { className: classes(CLASS_NAME, "node-chevron", !expanded && "cursor-pointer"), style: expanded ? { visibility: "hidden" } : undefined }));
    return expanded ? null : (_jsx(DropDown, Object.assign({ containerClassName: "flex items-center", showArrow: true, onVisibleChange: (visible, hide) => {
            const current = hideDropDown.value();
            hideDropDown.value(null);
            if (current && current.id !== id) {
                //if there is another dropdown visible, hide it
                current === null || current === void 0 ? void 0 : current.hide();
            }
            if (visible)
                hideDropDown.value({ hide, id });
        }, getContent: () => {
            return (_jsx("div", Object.assign({ className: "p-4" }, { children: getEntityPicker(props, true, selected, sel => {
                    updateContent(sel);
                }) })));
        } }, { children: down })));
}
export function dropDownEntityPickerMouseHandling(e) {
    if (e.button === 0 && getAncestor(e.target, CLASS_NAME)) {
        terminateEvent(e);
        return true;
    }
    return false;
}
