var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API } from "../../../api/api";
import { dmParamsToDiagram } from "../adapters/dmParamsToDiagram";
import { domainModelToDMParams } from "../../../utils/queryState/DMParams";
import { layoutGraph } from "../layoutNodes";
import { confirmation } from "../../../../utils/dialogs";
import { useDomainModelDescriptors } from "../../domainModel/domainModelDescriptors";
import { useQueryState } from "../../../utils/queryState/queryState";
import React from "react";
import { getViewportForNodes } from "../diagramUtils";
export function usePresetActions({ store, stage, flow, dUpdater, }) {
    const [{ corpus_ids }] = useQueryState();
    const descriptors = useDomainModelDescriptors(corpus_ids);
    return React.useCallback(() => ({
        global: [
            {
                caption: "Presets",
                subEntries: () => (descriptors.value || []).map(d => ({
                    caption: d.name,
                    desc: d.description,
                    action() {
                        return __awaiter(this, void 0, void 0, function* () {
                            const model = yield API.getDomainModel({
                                id: d.id,
                                corpus_ids,
                            });
                            const nodesAndEdges = dmParamsToDiagram(domainModelToDMParams(model));
                            const { width, height } = store.getState();
                            const layoutNodes = yield layoutGraph(nodesAndEdges, width > height ? "H" : "V");
                            const update = {
                                nodes: layoutNodes,
                                edges: nodesAndEdges.edges,
                            };
                            if (!(yield confirmation({ title: `Replace current?` }))) {
                                stage(update);
                                return;
                            }
                            const viewport = getViewportForNodes(update.nodes, width, height);
                            dUpdater.commit(Object.assign(Object.assign({}, update), { viewport }));
                            // setTimeout(() => {
                            //   flow.fitView()
                            // }, 200)
                        });
                    },
                })),
            },
        ],
    }), [stage, corpus_ids, descriptors]);
}
