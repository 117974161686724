var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Beacon, useBeacon } from "../../utils/Beacon";
import { useEffectIfDifferent, useMemoIfEqual } from "../../utils/hooks";
import { corpusFilterToQueryParams } from "../../cora/utils/semanticSearchUtil";
import { groupBy, uniq } from "lodash";
import { API } from "../../cora/api/api";
import { Genius } from "./Genius";
import { invalidCorpusIds, useHasBackgroundKnowledge, } from "../../cora/utils/sourceManagement";
import { getCurrentSession, registerSessionEventListener, updateCurrentSession, useCurrentSession, } from "../../cora/utils/sessionManagement";
import { Tooltip } from "./Tooltip";
import { getEvidenceCorpusFilter } from "../../cora/utils/evidence";
import { EXTRA_CONCEPTS_CAPTION } from "../../cora/utils/coraUtil";
import { useQuestionHistory } from "../../cora/components/queryAnalysis/useQuestionHistory";
import { EMPTY_DM_PARAMS } from "../../cora/utils/commonStateParams";
import { useCoraState } from "../../cora/components/CoraStateProvider";
import { getContextCorpusFilter } from "../../cora/utils/findings";
const geniusBeacon = Beacon({
    initial: {
        questionGroups: {},
        questionCount: 0,
    },
});
registerSessionEventListener(({ type }) => {
    if (type === "BEFORE_NEW_SESSION") {
        geniusBeacon.value({
            questionGroups: {},
            questionCount: 0,
        });
    }
});
export function setSuggestedQuestions(suggestedQuestions) {
    geniusBeacon.value({
        questionGroups: groupBy(suggestedQuestions, "trigger_concept"),
        questionCount: suggestedQuestions.length,
    });
}
export function suggestQuestions({ context, payload }, history) {
    return __awaiter(this, void 0, void 0, function* () {
        const corpus_filter = yield getContextCorpusFilter(context);
        if (!corpus_filter) {
            setSuggestedQuestions([]);
        }
        else {
            const suggestedQuestions = yield API.suggestedQuestionsEvidence({
                corpus_filter,
                evidence: payload.evidence,
                history,
            });
            setSuggestedQuestions(suggestedQuestions);
        }
    });
}
export function suggestQuestionsForConcepts({ corpus_filter, history, concept_clusters, }) {
    return __awaiter(this, void 0, void 0, function* () {
        if (concept_clusters.length <= 0) {
            return;
        }
        else {
            const suggestedQuestions = yield API.suggestQuestionsForConcepts({
                corpus_ids: corpus_filter.corpus_ids,
                history,
                concept_clusters,
            });
            setSuggestedQuestions(suggestedQuestions);
        }
    });
}
export function useSuggestQuestionsFromConcepts(coraState) {
    const { suggestedQuestionsHistory: history } = getCurrentSession();
    const corpus_filter = getEvidenceCorpusFilter(coraState);
    const disabled = invalidCorpusIds(coraState.corpus_ids);
    const backgroundKnowledge = useHasBackgroundKnowledge(coraState);
    return function suggest(concept_clusters) {
        const clusters = concept_clusters.map(c => ({
            members: c.members,
            name: c.name,
        }));
        if (!disabled && backgroundKnowledge) {
            suggestQuestionsForConcepts({
                history,
                corpus_filter,
                concept_clusters: clusters,
            });
        }
    };
}
export function getSuggestedQuestionCaption({ question, explanation, }) {
    return (_jsxs("div", Object.assign({ className: "" }, { children: [_jsx(Tooltip, Object.assign({ content: question }, { children: _jsx("div", Object.assign({ className: "font-bold overflow-ellipsis overflow-hidden" }, { children: question })) })), _jsx(Tooltip, Object.assign({ content: explanation }, { children: _jsxs("div", Object.assign({ className: "font-normal" }, { children: [_jsxs("span", Object.assign({ className: "italic font-normal overflow-ellipsis overflow-hidden" }, { children: ["Reason:", " "] })), explanation] })) }))] })));
}
export function getSuggestedQuestionConceptCaption(name) {
    return (_jsxs("div", Object.assign({ className: "flex m2-2 mr-1" }, { children: ["Suggested Questions for", _jsx("span", Object.assign({ className: "font-bold px-1 overflow-ellipsis overflow-hidden" }, { children: name })), ">"] })));
}
export function getActionForSuggestedQuestion(suggestedQuestion, entryQuestions, coraStateUpdater, expandedFacetGroups, addQuestionToHistory) {
    const { corpus_filter } = suggestedQuestion;
    return () => {
        const queryFilter = corpusFilterToQueryParams(corpus_filter);
        const context = queryFilter.extraConcepts;
        coraStateUpdater(Object.assign(Object.assign({}, queryFilter), { text: suggestedQuestion.question, dmParams: EMPTY_DM_PARAMS, activeConstraintId: "" }));
        addQuestionToHistory(suggestedQuestion.question);
        setSuggestedQuestions(entryQuestions.filter(s => s !== suggestedQuestion));
        updateCurrentSession(s => (Object.assign({ suggestedQuestionsHistory: s.suggestedQuestionsHistory.concat(corpus_filter) }, ((context === null || context === void 0 ? void 0 : context.length) && {
            expandedFacetGroups: uniq(expandedFacetGroups.concat(EXTRA_CONCEPTS_CAPTION())),
        }))));
    };
}
export function questionEntries(entryQuestions, coraStateUpdater, expandedFacetGroups, addQuestionToHistory) {
    return entryQuestions.map(sq => {
        return {
            caption: getSuggestedQuestionCaption(sq),
            action: getActionForSuggestedQuestion(sq, entryQuestions, coraStateUpdater, expandedFacetGroups, addQuestionToHistory),
        };
    });
}
export function useSuggestedQuestions(coraState) {
    const backgroundKnowledge = useHasBackgroundKnowledge(coraState);
    const [suggestedQuestionsBeacon] = useBeacon(geniusBeacon);
    useEffectIfDifferent(() => {
        setSuggestedQuestions([]);
    }, [], [coraState.corpus_ids]);
    const { expandedFacetGroups } = useCurrentSession();
    return {
        backgroundKnowledge,
        suggestedQuestionsBeacon,
        expandedFacetGroups,
    };
}
export function SuggestedQuestions() {
    const [coraState, coraStateUpdater] = useCoraState();
    const { backgroundKnowledge, suggestedQuestionsBeacon, expandedFacetGroups } = useSuggestedQuestions(coraState);
    const { add: addQuestionToHistory } = useQuestionHistory();
    const menu = React.useCallback(() => Object.keys(suggestedQuestionsBeacon.questionGroups).map(name => {
        const entries = questionEntries(suggestedQuestionsBeacon.questionGroups[name], coraStateUpdater, expandedFacetGroups, addQuestionToHistory);
        return {
            caption: (_jsx(Tooltip, Object.assign({ content: name }, { children: getSuggestedQuestionConceptCaption(name) }))),
            subEntries: entries,
        };
    }), [useMemoIfEqual(suggestedQuestionsBeacon), expandedFacetGroups]);
    return (_jsx("div", { children: backgroundKnowledge && (_jsx(Genius, { placement: "rightBottom", offset: [0, 0], entries: menu, name: "", entriesCount: suggestedQuestionsBeacon.questionCount, iconClass: "text-md" })) }));
}
