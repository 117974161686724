import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { TabButton } from "../../shared/components/Tabs";
import { TABS_BG } from "./lf";
import { Down } from "./Icons";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import { Tour } from "../../shared/components/assist/Tour";
import { VideoPopup } from "../../shared/components/assist/VideoPopup";
import tourLandmarks from "../../shared/components/assist/tourLandmarks.json";
import { useFlags } from "launchdarkly-react-client-sdk";
export function HelpMenu() {
    const { onboardingTour } = useFlags();
    return onboardingTour ? (_jsx(DropDownMenu, Object.assign({ title: "Tour", hasClose: true, entries: [
            {
                caption: "Get Started Tour",
                action() {
                    Tour.start(tourLandmarks.landmarks);
                },
            },
            {
                caption: "Learn by Watching",
                action() {
                    VideoPopup.show("https://vimeo.com/video/787685506?h=e2bdd73c3a");
                },
            },
            // {
            //   caption: "Give Feedback",
            //   disabled: true,
            // },
        ] }, { children: _jsxs(TabButton, Object.assign({ className: TABS_BG }, { children: [_jsx("div", Object.assign({ className: "" }, { children: "Tour" })), _jsx(Down, { className: "text-xs" })] })) }))) : null;
}
