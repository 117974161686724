import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button, Tabs } from "antd";
import { getEmptyParamsQuestion, isEmptyParams, } from "../utils/queryState/DMParams";
import { QueryStateEventSource, useQueryState, } from "../utils/queryState/queryState";
import { QueryTextRenderer } from "../redesign/findings/QueryTextRenderer";
import { EMPTY_DM_PARAMS } from "../utils/commonStateParams";
import "./ActiveConstraintBar.less";
import { Tooltip } from "../../shared/components/Tooltip";
import { constraintToQueryParts } from "./queryRenderer/constraintToQueryParts";
import { pick } from "lodash";
import { CRC_ONLY_PARTS } from "./queryRenderer/queryRendererUtils";
import { removeStateConstraint } from "../utils/queryState/mutations";
import "./ConstraintsBar.less";
export function ConstraintsBar() {
    var _a;
    const [state, stateUpdater] = useQueryState();
    const { dmParams, activeConstraintId } = state;
    const emptyQuestion = getEmptyParamsQuestion(dmParams);
    const { constraints } = dmParams;
    const tabs = emptyQuestion || !constraints.length
        ? []
        : constraints.map(c => ({
            key: c.id,
            label: (_jsx(QueryTextRenderer, { parts: pick(constraintToQueryParts(dmParams, c.id), CRC_ONLY_PARTS), maxTags: 1 })),
            closable: true,
        }));
    return (_jsx("div", Object.assign({ className: "ConstraintsBar flex flex-row items-center space-x-2" }, { children: isEmptyParams(dmParams) || emptyQuestion ? (_jsx("div", Object.assign({ className: "flex-1 flex flex-col", style: { height: 38 } }, { children: _jsx("div", Object.assign({ className: "flex items-center border-b border-gray-100 px-2", style: { height: 38 } }, { children: emptyQuestion ? `No answers for: ${emptyQuestion}` : "No query" })) }))) : (_jsx(Tabs, { tabBarGutter: 0, type: "editable-card", size: "small", style: { width: 400 }, className: " flex-1", activeKey: ((_a = tabs[0]) === null || _a === void 0 ? void 0 : _a.key) === "" ? "" : activeConstraintId, onChange: key => {
                if (tabs.length <= 1)
                    return;
                stateUpdater({
                    activeConstraintId: key,
                }, QueryStateEventSource.CONSTRAINTS_BAR);
            }, onEdit: (key, action) => {
                action === "remove" &&
                    stateUpdater(removeStateConstraint(state, key), QueryStateEventSource.CONSTRAINTS_BAR);
            }, items: tabs, tabBarExtraContent: {
                right: (_jsx(Tooltip, Object.assign({ content: "Clear All Queries" }, { children: _jsx(Button, Object.assign({ size: "small", type: "text", disabled: !constraints.length, onClick: () => {
                            stateUpdater({
                                dmParams: EMPTY_DM_PARAMS,
                                activeConstraintId: "",
                            });
                        }, className: "mr-2" }, { children: "Clear" })) }))),
            } })) })));
}
