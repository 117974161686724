import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { SessionPicker } from "./SessionPicker";
import { SourcePicker } from "./SourcePicker";
import { classes } from "../../utils/classes";
import { TabButton } from "../../shared/components/Tabs";
import { useInfoStream } from "../../shared/components/InfoStream";
import { Logo } from "./Icons";
import { HEADER_COLOR } from "./lf";
import { showDF } from "../PopDF";
import { UserMenu } from "./UserMenu";
import { HelpMenu } from "./HelpMenu";
import { updateCurrentSession, useCurrentSession, useIsSessionSaved, } from "../utils/sessionManagement";
import { landmarkIdTag } from "../../shared/components/assist/Tour";
import { formatVersionInfo, Version } from "../../shared/version/Version";
import { copyToClipboard } from "../../utils/clipboard";
import { showNotification } from "../../shared/components/notifications";
import useWindowDimensions from "../utils/useWindowDimensions";
import { Tooltip } from "../../shared/components/Tooltip";
import { Switch } from "antd";
export function Header({ children, controls, }) {
    const info = useInfoStream("NOT_MODAL");
    const saved = useIsSessionSaved();
    const { showStructuredQuery, searchAperture, rank_with_vector_search, rankWithVectorSearchPossible, } = useCurrentSession();
    const { width } = useWindowDimensions();
    const roomForAperture = searchAperture ? 200 : 0;
    const foldPoint = (showStructuredQuery ? 2000 : 1200) + roomForAperture;
    const vectorSearch = rank_with_vector_search && rankWithVectorSearchPossible;
    return (_jsxs("div", Object.assign({ className: classes("flex-1 flex flex-col items-stretch bg-black text-white w-full bg-black p-4"), style: { backgroundColor: HEADER_COLOR } }, { children: [_jsxs("div", Object.assign({ className: classes("min-h-[34px] flex text-sm font-bold weight-medium text-gray-500 w-full space-x-2", width < foldPoint ? "justify-between" : undefined) }, { children: [_jsxs("div", Object.assign({ className: "flex space-x-2 items-center" }, { children: [_jsx("div", Object.assign({ className: "mr-2 relative cursor-pointer", style: { width: 56 } }, { children: _jsx(Version, Object.assign({ onClick: info => {
                                        copyToClipboard(formatVersionInfo(info));
                                        showNotification("Version Information copied to Clipboard");
                                    } }, { children: _jsx(Logo, Object.assign({}, landmarkIdTag("tour-welcome"), { className: "text-5xl absolute mt-0.5 h-8 cursor-pointer", style: { top: -28, cursor: "default" }, onContextMenu: e => {
                                            if (e.shiftKey) {
                                                e.preventDefault();
                                                showDF();
                                            }
                                        } })) })) })), controls] })), width >= foldPoint ? children : undefined, _jsxs("div", Object.assign({ className: "flex items-center space-x-2 whitespace-nowrap" }, { children: [_jsxs(TabButton, Object.assign({ className: "italic" }, { children: ["(", (info === null || info === void 0 ? void 0 : info.message) || (saved ? "Saved" : "Not Saved"), ")"] })), rankWithVectorSearchPossible && (_jsx(Tooltip, Object.assign({ content: "Rank with vector search" }, { children: _jsx(Switch, { size: "small", checked: vectorSearch, onChange: b => updateCurrentSession({ rank_with_vector_search: b }) }) }))), _jsx(SourcePicker, {}), _jsx(SessionPicker, {}), _jsx("div", Object.assign({}, landmarkIdTag("tour-tour"), { children: _jsx(HelpMenu, {}) })), _jsx(UserMenu, {})] }))] })), width < foldPoint ? (_jsx("div", Object.assign({ className: "flex-1 mt-1" }, { children: children }))) : undefined] })));
}
