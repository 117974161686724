var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { dmParamsToQueryParams } from "./dmParamsToQueryParams";
export function queryStateToCoraState(_a, possible) {
    var { dmParams, activeConstraintId, extra, extraOverrides, overrides } = _a, rest = __rest(_a, ["dmParams", "activeConstraintId", "extra", "extraOverrides", "overrides"]);
    if (possible === void 0) { possible = {}; }
    const queryParams = dmParamsToQueryParams(dmParams, activeConstraintId, overrides, possible, extra, extraOverrides);
    return Object.assign(Object.assign(Object.assign({}, queryParams), { queries: [], dmParams,
        activeConstraintId }), rest);
}
