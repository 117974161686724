import { Emitter } from "./Emitter";
import React from "react";
import { merge } from "./collections";
import { getValue } from "./util";
import { delta } from "./objects";
const DEBUG = true;
export function Beacon(config = {}) {
    const { isEqual = (a, b) => a === b, name, validate = a => a, initial } = config;
    function debug(next, prev) {
        DEBUG && name && console.log(`BEACON[${name}] = `, delta(next, prev));
    }
    const emitter = Emitter();
    let value = typeof initial === "function" ? initial() : initial;
    let hasValue = value !== undefined;
    hasValue && debug(value, undefined);
    return {
        name,
        hasValue() {
            return hasValue;
        },
        value(vOrS) {
            if (arguments.length) {
                const v = vOrS === undefined ? undefined : validate(getValue(value, vOrS));
                if (!isEqual(v, value)) {
                    hasValue = true;
                    debug(v, value);
                    value = v;
                    emitter.emit(value);
                }
            }
            return value;
        },
        on(listener) {
            const sub = emitter.on(listener);
            if (value !== undefined) {
                listener(value);
            }
            return sub;
        },
    };
}
export function ParamsBeacon(beacon, updater = (value, updateOrFn) => merge(value, updateOrFn)) {
    return Object.assign(Object.assign({}, beacon), { value(update) {
            return update
                ? beacon.value(value => updater(value, update))
                : beacon.value();
        } });
}
export function useBeacon(beacon) {
    const [value, setValue] = React.useState(beacon.value());
    // DEBUG && beacon.name && console.log(`BEACON_STATE[${beacon.name}]`, value)
    React.useEffect(() => {
        if (beacon.hasValue())
            setValue(beacon.value());
        return beacon.on(setValue);
    }, [beacon]);
    return [value, beacon.value];
}
