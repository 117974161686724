import { Beacon, useBeacon } from "../../utils/Beacon";
export const possibleValues = Beacon({
    initial: null,
});
export function usePossibleValues() {
    return useBeacon(possibleValues)[0];
}
export function setPossibleValues(values) {
    possibleValues.value(values);
}
export function getPossibleValues() {
    return possibleValues.value();
}
