import React from "react";
import { Beacon } from "../../../utils/Beacon";
//TODO: may be a hack but putting it in session or state isn't performant
const nlQuestionText = Beacon({
    initial: "",
});
export function updateNLQuestionText(text) {
    nlQuestionText.value(text);
}
export function useNLQuestion() {
    const [text, setText] = React.useState(nlQuestionText.value());
    React.useEffect(() => {
        if (nlQuestionText.hasValue())
            setText(nlQuestionText.value());
        return nlQuestionText.on(setText);
    }, [nlQuestionText]);
    return {
        questionText: text,
        setQuestionText: (text) => nlQuestionText.value(text),
    };
}
