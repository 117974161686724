var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ActionButton, ButtonWrapper, } from "../../shared/components/buttons/Buttons";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, } from "@ant-design/icons/lib";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import { Select } from "antd";
import { Card } from "../../shared/components/Card";
import React from "react";
import { useSuggestedEvidenceFor } from "../utils/suggestedEvidence";
import { showAttachSuggestedEvidence } from "./modals/AttachSuggestedEvidence";
import { showFindingEditor } from "./modals/FindingEditorModal";
import { addEvidenceToFinding, restoreFindingContext, updateFindingPayload, upsertFinding, } from "../utils/findings";
import { showNotification } from "../../shared/components/notifications";
import { EvidenceSummaryBanner, SuggestedEvidenceBanner, } from "./evidence/evidenceBanners";
import { Link, Robot } from "./Icons";
import { FocusContainer } from "./FocusContainer";
import { DefaultMarkDown } from "../../shared/components/MarkDown";
import { updateCurrentSession } from "../utils/sessionManagement";
import { sendImplicitEvidenceFeedback } from "../utils/feedback";
import { getEvidenceCorpusFilter } from "../utils/evidence";
import { useSelectionChange } from "../utils/useSelectionChange";
export function FindingCard({ item, allTags, highlightText, coraState, coraStateUpdater, suggestQuestions, disabled, className, onTextSelected, }) {
    const evidence = item.payload.evidence || [];
    const [suggested] = useSuggestedEvidenceFor(item.id);
    const corpus_filter = getEvidenceCorpusFilter(coraState);
    // const containerId = useUniqId(uuidv4)
    useSelectionChange(!item.payload.selectedText.length, item.id, item.payload.selectedText, onTextSelected);
    const addEvidenceToFindingInSession = (finding, evidence) => updateCurrentSession(({ findings }) => ({
        findings: findings.map(f => f.id !== finding.id ? f : addEvidenceToFinding(f, evidence)),
    }));
    function showAddEvidence() {
        showAttachSuggestedEvidence({
            coraState,
            finding: item,
            addEvidenceToFinding: addEvidenceToFindingInSession,
            onSelect(selected) {
                return __awaiter(this, void 0, void 0, function* () {
                    addEvidenceToFindingInSession(item, selected);
                    yield sendImplicitEvidenceFeedback(selected, corpus_filter);
                    showNotification("Evidence Attached");
                });
            },
        });
    }
    return (_jsx(Card, { id: item.id, className: className, title: _jsxs("div", Object.assign({ className: "flex flex-col font-bold" }, { children: [_jsx("span", { children: item.title || item.context.contextTitle }), _jsx(DefaultMarkDown, { text: item.payload.selectedText, separator: highlightText, className: "text-base font-normal" })] })), tags: allTags && (_jsx(FocusContainer, Object.assign({ className: "border-white focus-within:border-brand-500" }, { children: _jsx(Select, Object.assign({ mode: "tags", size: "small", bordered: false, allowClear: true, style: { width: "100%" }, placeholder: "No Tags\u2026", value: item.payload.tags, onChange: tags => updateCurrentSession(({ findings }) => ({
                    findings: upsertFinding(findings, updateFindingPayload(item, { tags })),
                })) }, { children: allTags })) }))), banners: _jsx(ButtonWrapper, Object.assign({ className: "flex-1", onClick: () => showFindingEditor(item, false, suggestQuestions, disabled, coraState) }, { children: _jsx(EvidenceSummaryBanner, { evidence: evidence }) })), extra: _jsx(SuggestedEvidenceBanner, { evidence: evidence, suggested: suggested, onClick: showAddEvidence }), actions: _jsx(_Fragment, { children: _jsx(DropDownMenu, Object.assign({ entries: () => [
                    {
                        caption: "Edit",
                        icon: _jsx(EditOutlined, {}),
                        action() {
                            showFindingEditor(item, false, suggestQuestions, disabled, coraState);
                        },
                    },
                    {
                        caption: "Remove",
                        icon: _jsx(DeleteOutlined, {}),
                        action() {
                            updateCurrentSession(({ findings }) => ({
                                findings: findings.filter(f => f.id !== item.id),
                            }));
                        },
                    },
                    {
                        caption: "Suggest Evidence",
                        disabled,
                        icon: _jsx(Robot, {}),
                        action: showAddEvidence,
                    },
                    {
                        caption: "Go to Query",
                        disabled,
                        icon: _jsx(Link, {}),
                        action() {
                            restoreFindingContext(item.context);
                        },
                    },
                ] }, { children: _jsx(ActionButton, { children: _jsx(EllipsisOutlined, {}) }) })) }) }));
}
