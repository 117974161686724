var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormBuilder from "antd-form-builder";
import React, { useCallback, useState } from "react";
import { Button, Form, Modal, Spin } from "antd";
import { CSV_MIME_TYPE, saveTextFile } from "../../../utils/filePicker";
import { API } from "../../api/api";
import { DivButton } from "../../../shared/components/buttons/Buttons";
import { useCurrentSource } from "../../utils/sourceManagement";
import { pushError } from "../../../utils/errorStream";
import { useCoraState } from "../CoraStateProvider";
function getTrendsData(params, setCurrentlyDownloading) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log("Download Config:", params);
        setCurrentlyDownloading(true);
        const response = yield API.exportTrends(params.downloadRequest);
        setCurrentlyDownloading(false);
        if (response instanceof Error) {
            pushError({ error: { status: 401, message: response.message } });
        }
        else {
            saveTextFile(params.name, CSV_MIME_TYPE, response);
        }
    });
}
export function useDownloadTrends(validGroupByOptions) {
    var _a;
    const coraState = useCoraState()[0];
    const groupBy = ((_a = useCurrentSource(coraState)) === null || _a === void 0 ? void 0 : _a.supported_trends_fields.range_group_by) || [];
    const groupByOptions = [
        ...validGroupByOptions.map(x => ({ value: x.id, label: x.name })),
        { value: "none", label: "None" },
    ];
    const META = React.useMemo(() => [
        {
            key: "filename",
            label: "File Name",
            widget: "input",
        },
        {
            key: "range_group_by_field",
            label: "Group By",
            widget: "select",
            options: groupByOptions,
        },
    ], [groupByOptions]);
    function DownloadTrendsDialog(requestParams) {
        const [form] = Form.useForm();
        const [visible, setVisible] = useState(false);
        const [currentlyDownloading, setCurrentlyDownloading] = useState(false);
        const showModal = useCallback(() => {
            setVisible(true);
        }, [setVisible]);
        const hideModal = useCallback(() => setVisible(false), [setVisible]);
        const handleSubmit = useCallback(() => {
            const { filename, range_group_by_field } = form.getFieldsValue();
            if (!filename)
                return;
            if (requestParams) {
                const request = Object.assign(Object.assign({}, requestParams), { link_to_application: window.location.href });
                if (range_group_by_field !== "none") {
                    request.range_group_by_field = range_group_by_field;
                }
                else {
                    delete request["range_group_by_field"];
                }
                getTrendsData({
                    name: filename,
                    downloadRequest: request,
                }, setCurrentlyDownloading);
            }
            hideModal();
        }, [hideModal, requestParams]);
        return (_jsxs("div", { children: [_jsxs(DivButton, Object.assign({ className: "border px-2", onClick: showModal, disabled: currentlyDownloading }, { children: [_jsx("span", { children: "Export Trends" }), currentlyDownloading && (_jsx(Spin, { size: "small", className: "py-auto ml-2" }))] })), _jsx(Modal, Object.assign({ width: 700, title: "Export Trends Data", closable: false, open: visible, destroyOnClose: true, onCancel: hideModal, okText: "Ok", footer: [
                        _jsx(Button, Object.assign({ type: "primary", onClick: hideModal }, { children: "Cancel" }), "cancel"),
                        _jsx(Button, Object.assign({ type: "primary", onClick: handleSubmit }, { children: "Ok" }), "submit"),
                    ] }, { children: _jsx(Form, Object.assign({ form: form, onFinish: handleSubmit }, { children: _jsx(FormBuilder, { meta: META, form: form }) })) }))] }));
    }
    return DownloadTrendsDialog;
}
