var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { omit, pick } from "lodash";
import { COMMON_STATE_PARAMS_KEYS } from "../commonStateParams";
import { isEmptyQuery } from "../semanticSearchUtil";
import { getActualActiveConstraintId, getConstraintReferredIds, isEmptyParams, updateConstraint, } from "./DMParams";
import { EMPTY_QUERY_STATE } from "./queryState";
import { queryParamsToDMParams } from "./queryParamsToDMParams";
export function coraStateToQueryState(coraState, queryState) {
    const { overrides, nodesInfo, viewport } = queryState || EMPTY_QUERY_STATE;
    const dmParams = (queryState === null || queryState === void 0 ? void 0 : queryState.dmParams) || coraState.dmParams;
    const _a = pick(coraState, COMMON_STATE_PARAMS_KEYS), { corpus_ids } = _a, common = __rest(_a, ["corpus_ids"]);
    const emptyCRC = isEmptyQuery(coraState);
    const emptyParams = isEmptyParams(dmParams);
    if (emptyCRC && emptyParams) {
        // everything empty
        return Object.assign(Object.assign({}, EMPTY_QUERY_STATE), { corpus_ids });
    }
    const activeId = getActualActiveConstraintId(dmParams, coraState.activeConstraintId);
    const [fromCRC, overridesFromCRC, replacements] = queryParamsToDMParams(coraState, activeId, dmParams);
    const [updated, idsToRemove] = updateConstraint(dmParams, activeId, fromCRC);
    // update dmParams from CRC
    const updatedOverrides = Object.assign(Object.assign({}, omit(overrides, getConstraintReferredIds(fromCRC.constraints[0]).toArray())), overridesFromCRC);
    return Object.assign(Object.assign({ corpus_ids }, common), { dmParams: updated, activeConstraintId: getActualActiveConstraintId(updated, activeId), extra: coraState.extraConcepts, extraOverrides: coraState.extraConceptsOverride, overrides: updatedOverrides, nodesInfo: omit(nodesInfo, idsToRemove), viewport });
}
