import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Virtuoso } from "react-virtuoso";
import { classes } from "../../../utils/classes";
import { Set } from "immutable";
import { scrollToIndex } from "../../../utils/scroll";
import { Button, Spin } from "antd";
import { landmarkIdTag } from "../../../shared/components/assist/Tour";
import { Tooltip } from "../../../shared/components/Tooltip";
import { OverallFeedback } from "./OverallFeedback";
export function useEvidenceListPagination(evidenceAndTotals, evidenceStateUpdater, pageSize) {
    var _a, _b, _c, _d, _e;
    const { loading, currentPage } = evidenceAndTotals;
    const virtuosoRef = React.useRef(null);
    React.useEffect(() => {
        if (currentPage === 0 && loading) {
            evidenceStateUpdater.update({
                selection: Set(),
                feedback: {},
            });
            // scrollToIndex(virtuosoRef.current, 0)
        }
        if (!loading && currentPage > 0) {
            // scrollToIndex(virtuosoRef.current, currentPage * pageSize - 1)
        }
    }, [loading, currentPage, pageSize]);
    function listRender(itemRender, items) {
        return (_jsx("div", Object.assign({ className: "h-full flex-1" }, { children: _jsx(Virtuoso, { className: classes("h-full", "flex items-stretch relative overflow-y-auto overflow-x-hidden", "flex-col"), totalCount: items.length, itemContent: idx => _jsx(_Fragment, { children: itemRender(items[idx], idx, items) }), followOutput: false, ref: virtuosoRef }) })));
    }
    const paginationFooter = (_jsxs("div", Object.assign({ className: "mt-1 flex flex-col items-stretch" }, { children: [_jsx(OverallFeedback, {}), _jsxs("div", Object.assign({ className: "mt-2 flex", style: { paddingBottom: 1 } }, { children: [_jsx("div", { className: "flex border-b border-neutral-500 flex-1 h-1/2" }), _jsx("div", Object.assign({ className: "flex" }, { children: !evidenceAndTotals.lastPage && (_jsx(Button, Object.assign({ className: classes("bg-neutral-300 text-tiny rounded-lg bg-transparent px-2.5 py-1.5 border-neutral-500"), onClick: evidenceAndTotals.nextPage, disabled: evidenceAndTotals.lastPage ||
                                loading ||
                                !((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.evidence.length) }, landmarkIdTag("showMoreEvidence"), { children: _jsxs("div", Object.assign({ className: "flex justify-center items-center space-x-1" }, { children: ["Show More", loading && currentPage > 0 && (_jsx(Spin, { size: "small", className: "ml-1" }))] })) }))) })), _jsx("div", { className: "flex border-b border-neutral-500 flex-1 h-1/2" }), _jsx("div", Object.assign({ className: "flex justify-end items-center" }, { children: _jsx(Tooltip, Object.assign({ content: "Scroll to Top", placement: "right" }, { children: _jsxs(Button, Object.assign({ onClick: () => scrollToIndex(virtuosoRef.current, 0, "smooth"), className: classes("flex items-center justify-center self-end px-2.5 py-1.5", "bg-neutral-300 text-neutral-800 text-tiny", "rounded-lg border-neutral-500"), disabled: loading || !((_b = evidenceAndTotals.value) === null || _b === void 0 ? void 0 : _b.evidence.length) }, { children: [((_c = evidenceAndTotals.value) === null || _c === void 0 ? void 0 : _c.evidence) && (_jsxs("div", Object.assign({ className: "border-r border-neutral-500 pr-2 mr-2 font-normal" }, { children: [(_d = evidenceAndTotals.value) === null || _d === void 0 ? void 0 : _d.total_count.toLocaleString(), ((_e = evidenceAndTotals.value) === null || _e === void 0 ? void 0 : _e.total_count) === 10000 ? "+" : "", " evidence results"] }))), _jsx("div", Object.assign({ className: "" }, { children: "Back to Top" }))] })) })) }))] }))] })));
    return { listRender, paginationFooter, virtuosoRef };
}
