var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { SelectionModal, showSelectionModal } from "../modals/SelectionModal";
import { useParams } from "../../../utils/hooks";
import { useCurrentSession } from "../../utils/sessionManagement";
import { useConceptExpansions, useRelationsExpansions, } from "../../utils/coraUtil";
import { ItemsPicker } from "../picker/ItemsPicker";
import { conceptColorGetter, queryToPars } from "../ExtraQueriesPicker";
import { QueryRenderer } from "../QueryRenderer";
import { useSuggestRelatedQueries } from "../../utils/querySuggestions";
import { useHasCapability } from "../../utils/sourceManagement";
import { itemsOrOverrides } from "../../utils/semanticSearchUtil";
import { ConfidenceBar } from "../ConfidenceBar";
import { CorpusCapability } from "../../../utils/capabilities/capabilities";
const EMPTY_STATE = {
    concepts1: null,
    concepts2: null,
    extraConcepts: null,
    relations: null,
    queries: null,
};
function clusterRenderer(i) {
    var _a;
    return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex overflow-hidden" }, { children: i.name })), _jsx(ConfidenceBar, { confidence: 1 - Math.max(0, Math.min(1, (_a = i.score) !== null && _a !== void 0 ? _a : 0)) })] })));
}
export function SearchAperture({ coraState, onValue }) {
    const hasSuggestedQueries = useHasCapability(coraState, CorpusCapability.SUGGEST_QUERIES_RELATED);
    const { aperture } = coraState;
    const hasExtraConcepts = coraState.extraConcepts.length > 0 ||
        Object.keys(coraState.extraConceptsOverride).length > 0;
    const [{ concepts1, concepts2, extraConcepts, relations, queries }, update] = useParams(EMPTY_STATE);
    function useSelectForAperture(remoteItems, selectedInCrc, key) {
        React.useEffect(() => {
            if (!remoteItems)
                return;
            const fromState = selectedInCrc.map(i => (Object.assign(Object.assign({}, i), { score: 0 })));
            const selected = new Set([
                ...fromState,
                ...remoteItems.filter(i => i.score !== undefined && i.score <= aperture),
            ]);
            update({
                [key]: {
                    items: [...fromState, ...remoteItems],
                    selected,
                    initial: selected,
                },
            });
        }, [remoteItems]);
    }
    const { conceptExpansion } = useCurrentSession();
    const { remote: suggestedConcepts1 } = useConceptExpansions(itemsOrOverrides(coraState.concepts1, coraState.concepts1Override), coraState.corpus_ids, conceptExpansion, aperture, true);
    const { remote: suggestedConcepts2 } = useConceptExpansions(itemsOrOverrides(coraState.concepts2, coraState.concepts2Override), coraState.corpus_ids, conceptExpansion, aperture, true);
    const { remote: suggestedExtraConcepts } = useConceptExpansions(itemsOrOverrides(coraState.extraConcepts, coraState.extraConceptsOverride), coraState.corpus_ids, conceptExpansion, aperture, true);
    const { remote: suggestedRelations } = useRelationsExpansions(coraState.relations, coraState.corpus_ids, coraState.concepts1, coraState.concepts2, aperture, true);
    const { remote: suggestedQueries } = useSuggestRelatedQueries(coraState);
    useSelectForAperture(suggestedConcepts1 === null || suggestedConcepts1 === void 0 ? void 0 : suggestedConcepts1.value, itemsOrOverrides(coraState.concepts1, coraState.concepts1Override), "concepts1");
    useSelectForAperture(suggestedConcepts2 === null || suggestedConcepts2 === void 0 ? void 0 : suggestedConcepts2.value, itemsOrOverrides(coraState.concepts2, coraState.concepts2Override), "concepts2");
    useSelectForAperture(suggestedExtraConcepts === null || suggestedExtraConcepts === void 0 ? void 0 : suggestedExtraConcepts.value, itemsOrOverrides(coraState.extraConcepts, coraState.extraConceptsOverride), "extraConcepts");
    useSelectForAperture(suggestedRelations === null || suggestedRelations === void 0 ? void 0 : suggestedRelations.value, coraState.relations, "relations");
    useSelectForAperture(suggestedQueries === null || suggestedQueries === void 0 ? void 0 : suggestedQueries.value, coraState.queries, "queries");
    const loading = Boolean((suggestedConcepts1 === null || suggestedConcepts1 === void 0 ? void 0 : suggestedConcepts1.loading) ||
        (suggestedConcepts2 === null || suggestedConcepts2 === void 0 ? void 0 : suggestedConcepts2.loading) ||
        (suggestedRelations === null || suggestedRelations === void 0 ? void 0 : suggestedRelations.loading) ||
        (suggestedQueries === null || suggestedQueries === void 0 ? void 0 : suggestedQueries.loading));
    const concepts1Changed = concepts1 && !same(concepts1.selected, concepts1.initial);
    const concepts2Changed = concepts2 && !same(concepts2.selected, concepts2.initial);
    const extraConceptsChanged = extraConcepts && !same(extraConcepts.selected, extraConcepts.initial);
    const relationsChanged = relations && !same(relations.selected, relations.initial);
    const queriesChanged = queries && !same(queries.selected, queries.initial);
    const okDisabled = loading ||
        !(concepts1Changed ||
            relationsChanged ||
            concepts2Changed ||
            extraConceptsChanged ||
            queriesChanged);
    return (_jsx(SelectionModal, Object.assign({ title: "Search Aperture", visible: true, closable: true, width: `${hasExtraConcepts ? 1320 : 1175}px`, destroyOnClose: true, onCancel: () => onValue(null), onOk: () => {
            onValue({
                concepts1: !concepts1
                    ? []
                    : concepts1.items.filter(i => concepts1.selected.has(i)),
                concepts2: !concepts2
                    ? []
                    : concepts2.items.filter(i => concepts2.selected.has(i)),
                extraConcepts: !extraConcepts
                    ? []
                    : extraConcepts.items.filter(i => extraConcepts.selected.has(i)),
                relations: !relations
                    ? []
                    : relations.items.filter(i => relations.selected.has(i)),
                queries: !queries
                    ? []
                    : queries.items.filter(i => queries.selected.has(i)),
                aperture: 0,
            });
        }, okButtonProps: { disabled: loading || okDisabled } }, { children: _jsxs("div", Object.assign({ className: "flex-1 flex flex-row space-x-4 items-stretch relative p-4" }, { children: [_jsx(ItemsPicker, { entries: (concepts1 === null || concepts1 === void 0 ? void 0 : concepts1.items) || null, getId: i => i.name, loading: suggestedConcepts1 === null || suggestedConcepts1 === void 0 ? void 0 : suggestedConcepts1.loading, title: "Primary Concept", selected: Array.from((concepts1 === null || concepts1 === void 0 ? void 0 : concepts1.selected) || []), updateSelection: selected => update({ concepts1: Object.assign(Object.assign({}, concepts1), { selected: new Set(selected) }) }), renderItem: i => clusterRenderer(i), emptyMessage: "No Suggestions", checkboxClassName: "PrimaryConcept" }), _jsx(ItemsPicker, { title: "Relations", entries: (relations === null || relations === void 0 ? void 0 : relations.items) || null, getId: i => i.name, loading: suggestedRelations === null || suggestedRelations === void 0 ? void 0 : suggestedRelations.loading, selected: Array.from((relations === null || relations === void 0 ? void 0 : relations.selected) || []), updateSelection: selected => update({ relations: Object.assign(Object.assign({}, relations), { selected: new Set(selected) }) }), renderItem: i => clusterRenderer(i), emptyMessage: "No Suggestions", checkboxClassName: "Relation" }), _jsx(ItemsPicker, { title: "Linked Concept", entries: (concepts2 === null || concepts2 === void 0 ? void 0 : concepts2.items) || null, getId: i => i.name, loading: suggestedConcepts2 === null || suggestedConcepts2 === void 0 ? void 0 : suggestedConcepts2.loading, selected: Array.from((concepts2 === null || concepts2 === void 0 ? void 0 : concepts2.selected) || []), updateSelection: selected => update({ concepts2: Object.assign(Object.assign({}, concepts2), { selected: new Set(selected) }) }), renderItem: i => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex overflow-hidden" }, { children: i.name })), _jsx(ConfidenceBar, { confidence: 1 - Math.max(0, Math.min(1, (_a = i.score) !== null && _a !== void 0 ? _a : 0)) })] })));
                    }, emptyMessage: "No Suggestions", checkboxClassName: "LinkedConcept" }), hasExtraConcepts && (_jsx(ItemsPicker, { title: "Context Concepts", entries: (extraConcepts === null || extraConcepts === void 0 ? void 0 : extraConcepts.items) || null, getId: i => i.name, loading: suggestedExtraConcepts === null || suggestedExtraConcepts === void 0 ? void 0 : suggestedExtraConcepts.loading, selected: Array.from((extraConcepts === null || extraConcepts === void 0 ? void 0 : extraConcepts.selected) || []), updateSelection: selected => update({
                        extraConcepts: Object.assign(Object.assign({}, extraConcepts), { selected: new Set(selected) }),
                    }), renderItem: i => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex overflow-hidden" }, { children: i.name })), _jsx(ConfidenceBar, { confidence: 1 - Math.max(0, Math.min(1, (_a = i.score) !== null && _a !== void 0 ? _a : 0)) })] })));
                    }, emptyMessage: "No Suggestions", checkboxClassName: "" })), hasSuggestedQueries && (_jsx(ItemsPicker, { title: "Similar Queries", entries: (queries === null || queries === void 0 ? void 0 : queries.items) || null, getId: i => i.name, loading: suggestedQueries === null || suggestedQueries === void 0 ? void 0 : suggestedQueries.loading, selected: Array.from((queries === null || queries === void 0 ? void 0 : queries.selected) || []), updateSelection: selected => update({ queries: Object.assign(Object.assign({}, queries), { selected: new Set(selected) }) }), renderItem: i => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx(QueryRenderer, { className: "flex-1 overflow-hidden", parts: queryToPars(i), conceptColorGetter: conceptColorGetter, arrowSize: 7 }), _jsx(ConfidenceBar, { confidence: 1 - Math.max(0, Math.min(1, (_a = i.score) !== null && _a !== void 0 ? _a : 0)) })] })));
                    }, emptyMessage: "No Suggestions", style: { flex: 1.75 }, checkboxClassName: "DefaultGray" }))] })) })));
    function same(items, original) {
        return (items.size === original.size &&
            Array.from(items).every(i => original.has(i)));
    }
}
export function showSearchApertureModal(coraState, coraStateUpdater) {
    return __awaiter(this, void 0, void 0, function* () {
        return showSelectionModal(coraStateUpdater, onValue => (_jsx(SearchAperture, { coraState: coraState, onValue: onValue })));
    });
}
