export const FULL_WIDTH = "w-full";
export const FULL_HEIGHT = "h-full";
export const FULL_SIZE = `${FULL_WIDTH} ${FULL_HEIGHT}`;
export const FULL_SCREEN_WIDTH = "w-screen min-w-screen";
export const FULL_SCREEN_HEIGHT = "h-screen min-h-screen";
export const FULL_SCREEN_SIZE = `${FULL_SCREEN_WIDTH} ${FULL_SCREEN_HEIGHT}`;
export const FLEX_ROW = "flex flex-row";
export const FLEX_COL = "flex flex-col";
export const HEADER_HEIGHT = "h-[48px] min-h-[48px]";
export const SUB_HEADER_HEIGHT = "h-[46px] min-h-[46px]";
export const TERTIARY_HEADER_HEIGHT = "h-[42px] min-h-[42px]";
export const RAIL_WIDTH_NARROW = "w-[48px] min-w-[48px]";
export const HEADER = `${HEADER_HEIGHT}`;
export const SUB_HEADER = `${SUB_HEADER_HEIGHT}`;
export const TERTIARY_HEADER = `${TERTIARY_HEADER_HEIGHT}`;
export const FOOTER = `${HEADER_HEIGHT}`;
