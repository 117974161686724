import { jsx as _jsx } from "react/jsx-runtime";
import { ValidatedInput } from "../../../shared/components/InputValidation";
import { classes } from "../../../utils/classes";
import { Search } from "../Icons";
import React from "react";
import "./EvidenceFilter.less";
import { useDebouncedEffect } from "../../../utils/debouncer";
import { CloseCircleOutlined } from "@ant-design/icons";
export function EvidenceFilter({ coraState, coraStateUpdater, disabled, onFocusChange, }) {
    const [filter, setFilter] = React.useState(coraState.evidenceFilter);
    const [disableResync, setDisableResync] = React.useState(false);
    // resync the filter if the coraState changes externally while this component is mounted
    React.useEffect(() => {
        if (!disableResync && filter !== coraState.evidenceFilter)
            setFilter(coraState.evidenceFilter);
    }, [coraState]);
    // give the user time to type before resyncing
    // without this, sometimes the resync will remove the last few characters if their is a pause in typing
    useDebouncedEffect(() => setDisableResync(false), false, {
        value: filter,
        delay: 1000,
    });
    useDebouncedEffect(evidenceFilter => coraStateUpdater({ evidenceFilter }), false, { value: filter, delay: 500 });
    const onChange = React.useCallback((s) => {
        setFilter(s);
        setDisableResync(true);
    }, [setFilter, setDisableResync]);
    return (_jsx("div", Object.assign({ className: "EvidenceFilter flex w-full" }, { children: _jsx(ValidatedInput, { className: classes("flex-1 border-none p-0 min-h-[34px] w-full text-md"), bordered: true, prefix: _jsx(Search, { className: "ml-2 neutral-gray-800 text-base" }), value: filter, placeholder: "Keyword Filter", onChange: onChange, disabled: disabled, onFocus: () => onFocusChange && onFocusChange(true), onBlur: () => onFocusChange && onFocusChange(false), autoFocus: true, clearIcon: _jsx("div", Object.assign({ className: "text-md mr-2 flex items-center text-gray-500" }, { children: _jsx(CloseCircleOutlined, {}) })) }) })));
}
