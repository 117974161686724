var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Popover } from "antd";
import { FeatureIdentifier, FeedbackType, } from "../api/types";
import { API } from "../api/api";
import { limitLength } from "../../utils/util";
import { ActionButton } from "../../shared/components/buttons/Buttons";
import { classes } from "../../utils/classes";
import { brandButtonClass } from "../components/lf";
import { DotDotDot, ThumbsDown, ThumbsUp } from "../components/Icons";
export function getNegativeFeedbackContexts({ text, highlight_spans }) {
    function entry(highlight_span, type) {
        const { span } = highlight_span;
        return {
            caption: (_jsxs("span", { children: ["Incorrect ", type, ":", " ", _jsx("b", { children: span
                            ? `"${limitLength(text.slice(span.start, span.end), 40)}" `
                            : "" })] })),
            context: { highlight_span },
        };
    }
    const highlightsFeedback = (highlight_spans || [])
        .map(s => s.match_type === "RELATION"
        ? entry(s, "Relation")
        : s.match_type === "ARGUMENT" &&
            s.match_name !== "subject" &&
            s.match_name !== "object"
            ? entry(s, s.match_name)
            : undefined)
        .filter(Boolean);
    return [{ caption: "Irrelevant Evidence" }, ...highlightsFeedback];
}
function createFeedback(feature_identifier, endpoint, feedbackType, feedback_target, generating_request, context, comments) {
    return Object.assign(Object.assign({ feature_identifier,
        endpoint, generating_request: JSON.stringify(generating_request), feedback_target: JSON.stringify(feedback_target), type: feedbackType }, (context && { context: JSON.stringify(context) })), (comments && { comments }));
}
export function sendFeedback(feature_identifier, endpoint, feedbackType, feedback_target, generating_request, context, comments) {
    return __awaiter(this, void 0, void 0, function* () {
        yield API.send_feedback({
            feedback: [
                createFeedback(feature_identifier, endpoint, feedbackType, feedback_target, generating_request, context, comments),
            ],
        });
    });
}
export function sendImplicitEvidenceFeedback(evidences, corpus_filter) {
    return __awaiter(this, void 0, void 0, function* () {
        yield API.send_feedback({
            feedback: evidences.map(evidence => createFeedback(FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/suggest/findings", FeedbackType.IMPLICIT_POSITIVE, evidence, corpus_filter)),
        });
    });
}
export function createFeedbackButton(item, type, currentFeedback, setCurrentFeedback) {
    return (_jsx(ActionButton, Object.assign({ className: classes("cursor-pointer", currentFeedback === type && brandButtonClass, currentFeedback !== type && "auto_hide"), onMouseDownCapture: (e) => __awaiter(this, void 0, void 0, function* () {
            e.preventDefault();
            e.stopPropagation();
            if (currentFeedback === type)
                return;
            setCurrentFeedback();
        }), onClick: e => {
            e.preventDefault();
            e.stopPropagation();
        } }, { children: type === FeedbackType.POSITIVE ? _jsx(ThumbsUp, {}) : _jsx(ThumbsDown, {}) }), type));
}
export function FeedbackWidget({ item, feedback, sendFeedbackFn, getNegativeContexts, iconClass, bgClass, expanded, }) {
    function content(item, sendFeedbackFn, getNegativeContexts, sizeClass, className, iconClass) {
        return (_jsxs("div", Object.assign({ className: "flex space-x-2" }, { children: [_jsx("div", { children: feedbackButtonEntry(item, FeedbackType.NEGATIVE, feedback, sendFeedbackFn, getNegativeContexts === null || getNegativeContexts === void 0 ? void 0 : getNegativeContexts(item), sizeClass, className, iconClass) }), _jsx("div", { children: feedbackButtonEntry(item, FeedbackType.POSITIVE, feedback, sendFeedbackFn, [], sizeClass, className, iconClass) })] })));
    }
    return expanded ? (_jsx("div", { children: content(item, sendFeedbackFn, getNegativeContexts, "w-6 h-6", undefined, "text-sm") })) : (_jsx(Popover, Object.assign({ overlayClassName: "feedback-popover bg-gray-100 rounded-sm shadow-md", placement: "left", content: content(item, sendFeedbackFn, getNegativeContexts, "w-6 h-6", undefined, "text-sm"), trigger: "hover", align: { offset: [15, 0] } }, { children: _jsx("div", Object.assign({ className: classes("flex justify-center items-center p-0", "cursor-pointer", "w-6 h-6", "rounded-lg", bgClass
                ? `${bgClass} hover:${bgClass}`
                : getFeedbackStyles(feedback[item.id])) }, { children: getFeedbackIcon(feedback[item.id], iconClass) })) })));
}
function getFeedbackIcon(feedbackType, iconClass) {
    switch (feedbackType) {
        case FeedbackType.NEGATIVE:
            return _jsx(ThumbsDown, { className: classes("cursor-pointer", iconClass) });
        case FeedbackType.POSITIVE:
            return _jsx(ThumbsUp, { className: classes("cursor-pointer", iconClass) });
        default:
            return _jsx(DotDotDot, { className: classes("cursor-pointer", iconClass) });
    }
}
function getFeedbackStyles(feedbackType) {
    switch (feedbackType) {
        case FeedbackType.NEGATIVE:
            return "bg-alert_red-100 text-alert_red-500";
        case FeedbackType.POSITIVE:
            return "bg-alert_green-100 text-alert_green-500";
        default:
            return "bg-neutral-100";
    }
}
function getFeedbackHoverStyles(feedbackType) {
    switch (feedbackType) {
        case FeedbackType.NEGATIVE:
            return "hover:bg-alert_red-100 hover:text-alert_red-500";
        case FeedbackType.POSITIVE:
            return "hover:bg-alert_green-100 hover:text-alert_green-500";
        default:
            return "hover:bg-neutral-100";
    }
}
function feedbackButtonEntry(item, type, feedback, sendFeedbackFn, contexts, sizeClass = "w-6 h-6", className, iconClass) {
    const hasMenu = contexts && contexts.length > 1;
    const action = hasMenu
        ? undefined
        : (e) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            e.preventDefault();
            e.stopPropagation();
            const current = feedback[item.id];
            if (current === type)
                return;
            yield sendFeedbackFn(item, type, (_a = contexts === null || contexts === void 0 ? void 0 : contexts[0]) === null || _a === void 0 ? void 0 : _a.context);
        });
    const button = (_jsx(ActionButton, { className: classes("cursor-pointer border-0 p-2", className, feedback[item.id] === type && getFeedbackStyles(feedback[item.id]), getFeedbackHoverStyles(type)), sizeClass: sizeClass, icon: getFeedbackIcon(type, iconClass), onClick: action }, `${item.id}-${feedback[item.id]}`));
    return !hasMenu ? (button) : (_jsx(Popover, Object.assign({ className: "p-4", content: (contexts || []).map(({ caption, context }, idx) => (_jsx("div", Object.assign({ className: "cursor-pointer hover:bg-neutral-300 p-1", onClick: e => {
                e.preventDefault();
                e.stopPropagation();
                sendFeedbackFn(item, type, context);
            } }, { children: _jsx("span", { children: caption }) }), idx))) }, { children: button })));
}
