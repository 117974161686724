import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useHasCapability } from "../../utils/sourceManagement";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { Document } from "../Icons";
import { Set } from "immutable";
import { useEffectIfDifferent } from "../../../utils/hooks";
import { TRUE } from "../../../utils/filters";
import { SelectionButton } from "../../../shared/components/buttons/SelectionButton";
import { ToggleableMenuItem } from "../../../shared/actions/ToggleableMenuItem";
import { useRemoteQuery } from "../../../utils/query/remoteQuery";
import { API } from "../../api/api";
import { CorpusCapability } from "../../../utils/capabilities/capabilities";
export function useSummariesData(evidence, corpus_filter) {
    const { value, loading } = useRemoteQuery({
        loader: API.suggest_summaries,
        params: (evidence === null || evidence === void 0 ? void 0 : evidence.length)
            ? {
                corpus_filter,
                evidence_ids: evidence.map(e => e.id),
            }
            : undefined,
    });
    return { summaries: value || [], loading };
}
export function useSummariesFilter({ evidenceAndTotals, corpus_filter, }) {
    var _a;
    const { showSummary, selectedSummaries } = useCurrentSession();
    const { summaries, loading } = useSummariesData((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.evidence, corpus_filter);
    const filter = React.useMemo(() => {
        const ids = Set(selectedSummaries.map(s => s.evidence_ids).flat());
        return !showSummary || !ids.size ? TRUE : evidence => ids.has(evidence.id);
    }, [showSummary, selectedSummaries]);
    return { summaries, loading, filter };
}
export function useSummariesAction({ coraState, evidenceRequestParams, evidenceAndTotals, }) {
    const { corpus_ids } = coraState;
    const supportsTrends = useHasCapability(corpus_ids, CorpusCapability.TRENDS);
    const { showSummary } = useCurrentSession();
    useEffectIfDifferent(() => updateCurrentSession({ selectedSummaries: [] }), [], [evidenceRequestParams]);
    useEffectIfDifferent(() => !supportsTrends &&
        updateCurrentSession({ showSummary: false, selectedSummaries: [] }), [], [supportsTrends]);
    const disabled = !supportsTrends;
    return {
        name: "summaries",
        spaceRequired: 200,
        priority: 1,
        expandedDisplay: (_jsx(SelectionButton, { caption: "Show Summaries", disabled: disabled, icon: _jsx(Document, {}), className: "whitespace-nowrap", tooltipPlacement: "top", tooltip: "View Summaries", selected: showSummary, onMouseDown: () => {
                updateCurrentSession({
                    showSummary: !showSummary,
                });
            } })),
        collapsedDisplay: {
            sectionTitle: "Summaries",
            entries: [
                {
                    caption: _jsx(ToggleableMenuItem, { label: "Show", selected: showSummary }),
                    disabled,
                    action: () => {
                        updateCurrentSession({
                            showSummary: !showSummary,
                        });
                    },
                },
            ],
        },
    };
}
