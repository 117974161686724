import chroma from "chroma-js";
import * as go from "gojs";
import "./OldGraph.less";
import { keyBy } from "lodash";
const $ = go.GraphObject.make;
go.Diagram.licenseKey =
    "73f942e2b56628a800ca0d2b113f69ee1bb37b649ed71af95e5341a4ff186e15729fe97c02d4dfc0d2ff4ea8137bc18cd4956d2e855c036eb737d38e13e394e9b13670b10b0f588da2";
const maxScale = 1.5;
const minScale = 0.5;
const LINES_COLOR = "gray";
const ARROW_COLOR = "#b5b6b8";
export function createGraph(elementName) {
    function getLayout(horizontal = true) {
        return $(go.LayeredDigraphLayout, Object.assign({ iterations: 10, setsPortSpots: false }, (horizontal
            ? {
                layerSpacing: 80,
                direction: 0,
            }
            : {
                direction: 270,
                layerSpacing: 32,
            })));
    }
    const diagram = $(go.Diagram, elementName, {
        allowDelete: false,
        allowCopy: false,
        layout: getLayout(true),
        maxScale,
        minScale,
        "undoManager.isEnabled": true,
        // BackgroundDoubleClicked: () => updateLayout(layoutDirectionHorizontal, true),
        initialContentAlignment: go.Spot.Center,
        maxSelectionCount: 1,
    });
    diagram.animationManager.isEnabled = false;
    const edgeColor = chroma("gray").alpha(0.4).css();
    diagram.linkTemplate = $(go.Link, 
    // {toShortLength: 3, relinkableFrom: true, relinkableTo: true},
    {
        selectionAdorned: true,
        layerName: "Foreground",
        reshapable: true,
        // routing: go.Link.AvoidsNodes, // set no crossings
        corner: 5,
        curve: go.Link.JumpOver,
    }, $(go.Shape, { strokeWidth: 2 }, new go.Binding("stroke", "", (e) => !e.relation ? chroma("gray").alpha(0.4).css() : edgeColor)
    // new go.Binding("strokeDashArray", "", (e: Edge) =>
    //   !e.relation ? [5, 3] : []
    // )
    ), $(go.Panel, "Auto", $(go.Shape, { fill: "", stroke: null }, new go.Binding("fill", "", (e) => chroma("lightgray").alpha(0.4).css())), $(go.Panel, "Vertical", { defaultAlignment: go.Spot.Center }, $(go.TextBlock, // the label text
    {
        textAlign: "center",
        font: "12px Roboto, sans-serif",
        stroke: "#000",
        margin: new go.Margin(2, 4, 2, 4),
    }, new go.Binding("text", "", (e) => e.label || ""))), new go.Binding("visible", "", (e) => { var _a; return Boolean((_a = e.label) === null || _a === void 0 ? void 0 : _a.length); })), $(go.Shape, { toArrow: "", stroke: null, fill: ARROW_COLOR }, new go.Binding("toArrow", "", (e) => (e.directed ? "Triangle" : ""))), new go.Binding("selectionAdorned", "", (e) => Boolean(e.relation)));
    diagram.nodeTemplate = $(go.Node, "Auto", {
        contextMenu: {
            show(...a) {
                console.log("SHOW", a);
            },
            hide: null,
            mainElement: null,
            valueFunction: null,
        },
    }, new go.Binding("opacity", "", n => (n.isTranslucent ? 0.3 : 1)), { locationSpot: go.Spot.Center }, $(go.Shape, "RoundedRectangle", {
        fill: "white",
        portId: "",
        cursor: "pointer",
    }), $(go.TextBlock, {
        font: "bold 14px sans-serif",
        stroke: "#333",
        margin: 6,
        isMultiline: false,
    }, new go.Binding("text", "label")) // the label shows the node data's text
    );
    function update(onNodeClick) { }
    function fit(rect) {
        diagram.layout = getLayout();
    }
    // function fit(rect?: any) {
    //   const d = diagram as any
    //   d.documentBounds.height = d.div.clientHeight - 100
    //   d.documentBounds.width = d.div.clientWidth - 100
    //   // const r = (rect ? {...rect, x: 0, y:0} : {...diagram.documentBounds}) as any;
    //   // console.log("R", r)
    //   // diagram.zoomToRect(r);
    //   d.zoomToFit()
    //   d.scale = Math.min(maxScale, d.scale)
    //   d.contentAlignment = go.Spot.Center
    //   d.requestUpdate()
    // }
    let nodes = {};
    let edges = {};
    return {
        fit,
        update,
        diagram,
        destroy() {
            diagram.div = null;
        },
        setModel(model) {
            nodes = keyBy(model.nodes, "key");
            edges = keyBy(model.edges, "key");
            diagram.model = new go.GraphLinksModel(model.nodes, model.edges);
            fit();
        },
        getNodes() {
            return nodes;
        },
        getEdges() {
            return nodes;
        },
    };
}
