import { arrDelta } from "./collections";
import { isEqual } from "lodash";
export function filterByKey(obj, keyFilter) {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => keyFilter(key)));
}
export function filterByValue(obj, valueFilter) {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => valueFilter(value)));
}
export function mapEntries(obj, entryFilter) {
    return Object.fromEntries(Object.entries(obj).map(entryFilter));
}
export function normalize(v, sortArrays = false) {
    if (Array.isArray(v)) {
        const norm = v.map(i => normalize(i, sortArrays));
        return (sortArrays ? norm.sort() : norm);
    }
    else if (typeof v === "object" && v !== null) {
        return Object.fromEntries(Object.entries(v)
            .map(([k, v]) => [k, normalize(v, sortArrays)])
            .sort((a, b) => a[0].localeCompare(b[0])));
    }
    else {
        return v;
    }
}
export function delta(newValue, oldValue, path = "", result = {}) {
    const different = !isEqual(newValue, oldValue);
    if (different) {
        if (typeof newValue === "object" &&
            typeof oldValue === "object" &&
            newValue !== null &&
            oldValue !== null) {
            const { both, added, removed } = arrDelta(Object.keys(newValue), Object.keys(oldValue));
            removed.forEach(key => push("removed", oldValue, key));
            added.forEach(key => push("added", newValue, key));
            both.forEach(key => delta(newValue[key], oldValue[key], `${path}/${key}`, result));
        }
        else {
            getOrCreate("changed")[path] = { old: oldValue, new: newValue };
        }
    }
    return result;
    function push(entryKey, source, key) {
        getOrCreate(entryKey)[`${path}/${key}`] = source[key];
    }
    function getOrCreate(key) {
        return (result[key] = result[key] || {});
    }
}
export function replaceKeys(dic, replacements) {
    return mapEntries(dic, ([k, v]) => [replacements[k] || k, v]);
}
