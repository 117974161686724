var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Beacon, ParamsBeacon, useBeacon } from "../../utils/Beacon";
import { ClusteringMode, } from "../api/types";
import { API } from "../api/api";
import { createDebouncer, useDebouncedEffect } from "../../utils/debouncer";
import { useEffectIfDifferent, useInitializer } from "../../utils/hooks";
import { isEqual, mapValues } from "lodash";
import { uuidv4 } from "../../utils/util";
import { useQueryParams } from "../../utils/routing";
import { infoTask } from "../../shared/components/InfoStream";
import { error } from "../../utils/dialogs";
import { Emitter } from "../../utils/Emitter";
import { List, Map, Set } from "immutable";
import { getCoraServerUrl, getCurrentUserInfo } from "../../utils/authInfo";
import { cleanupSession, migrateSession } from "./versionManagement";
import { METADATA } from "./commonStateParams";
import { loadQueryState, QueryId } from "./queryState/queryId";
import { isHistoryEntry } from "./history";
import { EMPTY_QUERY_STATE, getEmptyQueryStateForCorpus, } from "./queryState/queryState";
import { pushError } from "../../utils/errorStream";
import { IGNORED_SESSION_IDS } from "../../utils/globalRegistry";
const REMOTE_SAVE_INTERVAL_MS = 5000;
const LOCAL_SAVE_INTERVAL_MS = 3000;
export const UNNAMED_SESSION_ID = "EMPTY_SESSION-000";
function getCurrentSessionKey(noUser = false, noOrg = false) {
    return ("CURRENT_SESSION_KEY" +
        "-" +
        getCoraServerUrl() +
        (noUser ? "" : getCurrentUserInfo().userId) +
        (noOrg ? "" : "-" + getCurrentUserInfo().orgId));
}
export var Panels;
(function (Panels) {
    Panels["Evidence"] = "Evidence";
    Panels["Findings"] = "Findings";
    Panels["ModelBuilder"] = "ModelBuilder";
})(Panels || (Panels = {}));
export const EMPTY_SESSION = {
    id: UNNAMED_SESSION_ID,
    name: "Untitled Session",
    timestamp: 0,
    findings: [],
    showMyFindings: false,
    showFilters: true,
    conceptClustering: ClusteringMode.AGGRESSIVE,
    specificConcepts: false,
    suggestedQuestionsHistory: [],
    conceptExpansion: ClusteringMode.AGGRESSIVE,
    relationExpansion: ClusteringMode.NONE,
    expandedEvidence: false,
    showTrends: false,
    showSummary: false,
    groupEvidenceByDocument: true,
    selectedSummaries: [],
    selectedGranularity: undefined,
    selectedTrendRange: undefined,
    selectedTrendDomain: undefined,
    useFindingsNlg: false,
    facetsCollapsedState: {},
    queryHistory: List(),
    expandedFacetGroups: [],
    stickyFacets: [],
    customConceptListsByCorpusId: {},
    extraConceptsByCorpusId: {},
    hiddenFacetsByCorpusIdAndGroup: {},
    conjunctionBindingsSearchWidth: 50,
    hierarchicalFacets: false,
    graphConfigFlags: Set(),
    hiddenGraphNodes: Map(),
    metadataSearchParams: METADATA,
    queryGraph: true,
    showQueryGraph: false,
    showModelGraph: false,
    structuredQuery: true,
    showStructuredQuery: false,
    searchAperture: false,
    selectedDMConceptsByCorpusAndModelId: {},
    findingCollapsedSections: {},
    // new Session state
    visiblePanels: [],
    sessionKey: "",
    currentFacetsTab: "",
    modelBuilderAvailable: true,
    modelBuilderMode: true,
    questionsByCorpusId: {},
    expandedFacetCount: 20,
    treatMultipleConceptsAsAnd: false,
    lastQueryIdByCorpusId: {},
    rank_with_vector_search: false,
    rankWithVectorSearchPossible: false,
    autoExpandEvidence: false,
};
const sessionEvents = Emitter();
export const registerSessionEventListener = sessionEvents.on;
export function newSession() {
    return EMPTY_SESSION;
}
let currentSessionBeacon;
function getCurrentSessionBeacon() {
    if (!currentSessionBeacon)
        throw "currentSessionBeacon not initialized";
    return currentSessionBeacon;
}
function initializeCurrentSessionBeacon() {
    currentSessionBeacon = ParamsBeacon(Beacon({
        initial: getCurrentSessionFromLocalStorage() || EMPTY_SESSION,
    }));
    currentSessionBeacon.on(v => {
        // console.warn(">>>", v.name, v.id, v.diagramStateByCorpusId)
    });
}
const directoryBeacon = Beacon({
    initial: [],
});
const savedStatus = Beacon({ initial: true });
function setSavedStatus(status) {
    savedStatus.value(status);
}
export function useIsSessionSaved() {
    return useBeacon(savedStatus)[0];
}
export function updateCurrentSession(update) {
    const current = getCurrentSessionBeacon().value();
    const newValue = getCurrentSessionBeacon().value(update);
    setSavedStatus(isEqual(newValue, current));
}
export function getCurrentSession() {
    return getCurrentSessionBeacon().value();
}
export function useCurrentSession() {
    return useBeacon(getCurrentSessionBeacon())[0];
}
export function useLoadSessionsDirectory() {
    return useInitializer(() => __awaiter(this, void 0, void 0, function* () {
        const directory = yield API.getSessionsDirectory();
        directoryBeacon.value(directory.filter(d => !IGNORED_SESSION_IDS().includes(d.id)));
    }));
}
export function initSession(session = {}) {
    return cleanupSession(migrateSession(Object.assign(Object.assign({}, EMPTY_SESSION), session)));
}
export function getCurrentSessionFromLocalStorage() {
    try {
        const legacySessionKeys = [
            getCurrentSessionKey(false, true),
            getCurrentSessionKey(true, true),
        ];
        const currentSessionKey = getCurrentSessionKey();
        const legacySessions = legacySessionKeys.map(key => [key, window.localStorage.getItem(key)]);
        const legacySession = legacySessions.find(([_, json]) => json !== null);
        const newSessionJson = window.localStorage.getItem(currentSessionKey);
        if (legacySession) {
            // cleanup legacy sessions
            if (!newSessionJson) {
                console.log("Migrating legacy session (from,to)", legacySession[0], currentSessionKey);
                window.localStorage.setItem(currentSessionKey, legacySession[1]);
            }
            legacySessions.forEach(([key, json]) => {
                if (json) {
                    console.log("Removing legacy session", key);
                    window.localStorage.removeItem(key);
                }
            });
        }
        const json = newSessionJson || (legacySession === null || legacySession === void 0 ? void 0 : legacySession[1]);
        return json
            ? initSession(deserializeSession(JSON.parse(json)))
            : null;
    }
    catch (e) {
        console.warn("Error loading session from local storage");
        console.error(e);
        return null;
    }
}
export function deleteSession(id) {
    return __awaiter(this, void 0, void 0, function* () {
        sessionEvents.emit({ type: "BEFORE_DELETE", id });
        yield API.deleteSession(id);
        directoryBeacon.value(info => info.filter(s => s.id !== id));
    });
}
const remoteDebouncer = createDebouncer();
const localDebouncer = createDebouncer();
export function useSessionSaver() {
    const session = useCurrentSession();
    const processedSession = React.useRef(null);
    React.useEffect(() => {
        processedSession.current = null;
    }, [session]);
    useDebouncedEffect(saveLocalSession, true, { value: session, delay: LOCAL_SAVE_INTERVAL_MS }, localDebouncer);
    useDebouncedEffect((session) => __awaiter(this, void 0, void 0, function* () {
        yield saveRemoteSession(session);
        setSavedStatus(true);
    }), true, { value: session, delay: REMOTE_SAVE_INTERVAL_MS }, remoteDebouncer);
}
export function saveLocalSession(session) {
    console.log("Saving Local Session…", session.name, session.id);
    window.localStorage.setItem(getCurrentSessionKey(), JSON.stringify(serializeSession(session)));
}
export function saveRemoteSession(session) {
    return __awaiter(this, void 0, void 0, function* () {
        yield infoTask({ message: `Saving Session…` }, () => __awaiter(this, void 0, void 0, function* () {
            console.log("Saving Remote Session…", session.name, session.id);
            yield API.upsertSession(serializeSession(session));
            directoryBeacon.value(dir => {
                const entry = { id: session.id, name: session.name };
                let update = false;
                const updated = dir.map(s => {
                    const isUpdate = s.id === session.id;
                    update = update || isUpdate;
                    return isUpdate ? entry : s;
                });
                return update ? updated : updated.concat([entry]);
            });
        }));
    });
}
function serializeSession(session) {
    const { queryHistory, hiddenGraphNodes, graphConfigFlags } = session, rest = __rest(session, ["queryHistory", "hiddenGraphNodes", "graphConfigFlags"]);
    return Object.assign(Object.assign({}, rest), { queryHistory: queryHistory.toArray(), hiddenGraphNodes: Object.fromEntries(hiddenGraphNodes.toArray().map(([k, v]) => [k, v.toArray()])), graphConfigFlags: graphConfigFlags.toArray() });
}
function deserializeSession(session) {
    const { queryHistory, hiddenGraphNodes, graphConfigFlags } = session, rest = __rest(session, ["queryHistory", "hiddenGraphNodes", "graphConfigFlags"]);
    return Object.assign(Object.assign({}, rest), { queryHistory: List(queryHistory), hiddenGraphNodes: Map(mapValues(hiddenGraphNodes, v => Set(v))), graphConfigFlags: Set(graphConfigFlags) });
}
function getRemoteSession(id) {
    return __awaiter(this, void 0, void 0, function* () {
        const serializedSession = yield API.loadSession(id);
        return initSession(deserializeSession(serializedSession));
    });
}
export function switchSession(id, name, queryStateUpdater) {
    return __awaiter(this, void 0, void 0, function* () {
        const currentSession = getCurrentSession();
        yield infoTask({ message: `Loading ${name}…`, modal: true }, () => __awaiter(this, void 0, void 0, function* () {
            yield remoteDebouncer.exec({ flush: true });
            const remoteSession = yield getRemoteSession(id);
            const session = currentSession.id === id &&
                remoteSession.timestamp < currentSession.timestamp
                ? currentSession
                : remoteSession;
            sessionEvents.emit({ type: "BEFORE_NEW_SESSION", id });
            setCurrentSession(session, queryStateUpdater);
        }));
    });
}
export function flushCurrentSession() {
    return __awaiter(this, void 0, void 0, function* () {
        yield localDebouncer.exec({ flush: true });
        yield remoteDebouncer.exec({ flush: true });
    });
}
function setCurrentSession(session, queryStateUpdater) {
    return __awaiter(this, void 0, void 0, function* () {
        updateCurrentSession(session);
        const id = yield getLatestQueryId(session);
        queryStateUpdater === null || queryStateUpdater === void 0 ? void 0 : queryStateUpdater(!id ? EMPTY_QUERY_STATE : yield loadQueryState(id));
        QueryId.set(id);
    });
}
export function setNewSession(queryStateUpdater) {
    return __awaiter(this, void 0, void 0, function* () {
        sessionEvents.emit({ type: "BEFORE_NEW_SESSION", id: UNNAMED_SESSION_ID });
        yield infoTask({ message: `Creating new Session…`, modal: true }, () => __awaiter(this, void 0, void 0, function* () {
            const newSession = initSession({
                sessionKey: uuidv4(),
                timestamp: Date.now(),
            });
            const currentSession = getCurrentSessionBeacon().value();
            yield deleteSession(UNNAMED_SESSION_ID);
            yield localDebouncer.exec({ flush: false });
            yield remoteDebouncer.exec({ flush: !isUnnamedSession(currentSession.id) });
            yield setCurrentSession(newSession, queryStateUpdater);
        }));
    });
}
export function saveSessionAs(session, name, queryStateUpdater) {
    return __awaiter(this, void 0, void 0, function* () {
        const id = uuidv4();
        yield saveRemoteSession(initSession(Object.assign(Object.assign({}, session), { name, id: id })));
        yield switchSession(id, name, queryStateUpdater);
        return id;
    });
}
export function isUnnamedSession(id) {
    return id === UNNAMED_SESSION_ID;
}
export function useSessionsDirectory(includeUnnamed = false) {
    const directory = useBeacon(directoryBeacon)[0];
    return !includeUnnamed
        ? directory.filter(s => !isUnnamedSession(s.id))
        : directory;
}
export function getLatestCorpusIds(session) {
    var _a;
    const { queryHistory } = session;
    return (_a = queryHistory.get(queryHistory.size - 1)) === null || _a === void 0 ? void 0 : _a.corpus_ids;
}
export function getLatestQueryId(session) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!session)
            return "";
        const { queryHistory } = session;
        const entry = queryHistory.get(queryHistory.size - 1);
        return !entry
            ? ""
            : isHistoryEntry(entry)
                ? entry.queryId
                : yield QueryId.getQueryIdForCoraState(entry);
    });
}
export function SessionsInitializer({ afterInitializing, }) {
    const [queryState, setQueryState] = React.useState(null);
    const directory = useSessionsDirectory(true);
    const [params, setQueryParams] = useQueryParams();
    const { sessionId: sessionIdInUrl = UNNAMED_SESSION_ID } = params, urlParams = __rest(params, ["sessionId"]);
    const initalized = React.useRef(false);
    useEffectIfDifferent(() => {
        function initialize() {
            return __awaiter(this, void 0, void 0, function* () {
                if (queryState !== null || initalized.current)
                    return;
                initalized.current = true;
                initializeCurrentSessionBeacon();
                if (sessionIdInUrl === "!") {
                    yield setNewSession();
                    setQueryParams(mapValues(params, () => undefined));
                    QueryId.set("");
                    setQueryState(EMPTY_QUERY_STATE);
                    return;
                }
                const localSession = getCurrentSessionFromLocalStorage();
                if (!isUnnamedSession(sessionIdInUrl)) {
                    // the url has a session id
                    const session = yield getStoredSession(sessionIdInUrl);
                    if (!session) {
                        const localSessionIsNamed = localSession && !isUnnamedSession(localSession.id);
                        yield error({
                            title: (_jsxs("div", { children: ["Unknown Session ID", _jsx("br", {}), "ID (", sessionIdInUrl, ")"] })),
                            content: !localSession ? ("Starting a new Session…") : !localSessionIsNamed ? ("Restoring Local Session…") : (_jsxs("div", { children: ["It seems you were previously working on a session named \"", localSession.name, "\".", _jsx("br", {}), "Restoring it..."] })),
                            type: "error",
                            width: 600,
                        });
                        yield setSession(localSession);
                        return;
                    }
                }
                const session = yield getStoredSession();
                yield setSession(session);
                function setSession(session) {
                    return __awaiter(this, void 0, void 0, function* () {
                        const corpusIds = session ? getLatestCorpusIds(session) : undefined;
                        const queryIdFromUrl = yield QueryId.getQueryIdOrLegacyFromUrl(urlParams, corpusIds);
                        const latestQueryId = yield getLatestQueryId(session);
                        const queryId = queryIdFromUrl || latestQueryId;
                        QueryId.set(queryId);
                        if (session) {
                            updateCurrentSession(session);
                        }
                        else {
                            yield setNewSession();
                        }
                        try {
                            const queryState = !queryId
                                ? getEmptyQueryStateForCorpus(corpusIds)
                                : yield loadQueryState(queryId, true);
                            setQueryState(queryState);
                        }
                        catch (e) {
                            pushError({
                                error: "Error restoring URL queryId",
                                fatal: false,
                                showMessage: true,
                            });
                            console.error("Error loading query state", e);
                            setQueryState(getEmptyQueryStateForCorpus(corpusIds));
                        }
                    });
                }
                function getStoredSession(id) {
                    return __awaiter(this, void 0, void 0, function* () {
                        if (!id)
                            id = localSession === null || localSession === void 0 ? void 0 : localSession.id;
                        if (!id)
                            return undefined;
                        const entry = directory.find(e => e.id === id);
                        const remoteSession = entry && (yield getRemoteSession(id));
                        return (localSession === null || localSession === void 0 ? void 0 : localSession.id) !== id
                            ? remoteSession
                            : !remoteSession
                                ? localSession
                                : localSession.timestamp < remoteSession.timestamp
                                    ? remoteSession
                                    : localSession;
                    });
                }
            });
        }
        initialize();
    }, [directory, urlParams], [sessionIdInUrl], true);
    return queryState !== null ? afterInitializing(queryState) : null;
}
