import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { take } from "lodash";
import Tippy from "@tippyjs/react";
import { NodeType } from "./DiagramModel";
import * as go from "gojs";
const MAX = 10;
export function NodeTooltip({ node, rect }) {
    const concepts = take(node.topConcepts, MAX);
    return (_jsx(Tippy, Object.assign({ content: _jsxs("div", Object.assign({ className: "text-tiny" }, { children: [!concepts.length ? (_jsx("div", { children: "Nothing was found." })) : (concepts.map((c, idx) => _jsx("div", { children: c.name }, idx))), node.topConcepts && node.topConcepts.length > MAX && _jsx("div", { children: "..." })] })), visible: true, placement: "bottom" }, { children: _jsx("div", { style: {
                top: rect.y,
                left: rect.x,
                width: rect.width,
                height: rect.height,
                position: "absolute",
                visibility: "hidden",
                zIndex: 50,
            } }) })));
}
export function useNodeTooltip(diagram, selectedConceptsByKey, refresh) {
    const [hoverNode, setHoverNode] = React.useState(null);
    React.useEffect(() => diagram.addEventHandlers({
        onMouseEnter(node, obj) {
            const b = obj.getDocumentBounds();
            const p1 = diagram.transformDocToView(new go.Point(b.x, b.y));
            const p2 = diagram.transformDocToView(new go.Point(b.x + b.width, b.y + b.height));
            const rect = {
                x: p1.x,
                y: p1.y,
                width: p2.x - p1.x,
                height: p2.y - p1.y,
            };
            setHoverNode((node === null || node === void 0 ? void 0 : node.type) === NodeType.TYPE && node.topConcepts
                ? { rect, node }
                : null);
        },
        onMouseLeave(e, obj) {
            setHoverNode(null);
        },
    }), [refresh, selectedConceptsByKey]);
    return (hoverNode && _jsx(NodeTooltip, { node: hoverNode.node, rect: hoverNode.rect }));
}
