var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useEffectIfDifferent } from "./hooks";
export function createDebouncer() {
    let currentTask = null;
    let timoutId = null;
    return {
        exec({ task, flush, delay = 0 }) {
            return __awaiter(this, void 0, void 0, function* () {
                if (timoutId !== null) {
                    clearTimeout(timoutId);
                    timoutId = null;
                }
                if (flush && currentTask) {
                    yield currentTask();
                }
                if (task) {
                    if (delay <= 0) {
                        yield task();
                    }
                    else {
                        currentTask = () => __awaiter(this, void 0, void 0, function* () {
                            try {
                                yield task();
                            }
                            finally {
                                currentTask = null;
                                timoutId = null;
                            }
                        });
                        timoutId = setTimeout(currentTask, delay);
                    }
                }
            });
        },
        isIdle() {
            return currentTask === null;
        },
    };
}
export function useDebouncedEffect(processor, flushOnUnmount, params, d) {
    const debouncer = React.useMemo(() => d || createDebouncer(), []);
    React.useEffect(() => {
        if (flushOnUnmount) {
            return () => {
                debouncer.exec({ flush: true });
            };
        }
    }, []);
    useEffectIfDifferent(() => {
        const { flush, delay, value } = params;
        debouncer.exec(Object.assign({ flush,
            delay }, (value !== undefined && { task: () => processor(value) })));
    }, [], [params], false);
}
