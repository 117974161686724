var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { omit } from "lodash";
import { FeatureIdentifier, } from "../../api/types";
import { useEffectIfDifferent } from "../../../utils/hooks";
import { useCurrentSource } from "../../utils/sourceManagement";
import { API } from "../../api/api";
import { toConceptOrCustom, validateTextFilter, } from "../../utils/semanticSearchUtil";
import { useCurrentSession } from "../../utils/sessionManagement";
import { identity } from "../../../utils/util";
import { ClusterPickerField } from "./ClustersPickerField";
import { BrandButton } from "../../../shared/components/buttons/Buttons";
import { ManageLists } from "../Icons";
import { showCustomListsManager } from "../modals/CustomListsManager/CustomListsManager";
import { useConceptExpansions, useRelationsExpansions, } from "../../utils/coraUtil";
import { CorpusCapability } from "../../../utils/capabilities/capabilities";
const clusterCount = 50;
function createParams(prefix, clustering_mode, corpus_filter, argument_name) {
    prefix = validateTextFilter(prefix);
    const hasPrefix = prefix.length > 0;
    return (corpus_filter && Object.assign(Object.assign({ corpus_filter: hasPrefix
            ? { corpus_ids: corpus_filter.corpus_ids }
            : omit(corpus_filter, "context"), count: clusterCount, clustering_mode }, (hasPrefix && { prefix })), { argument_name }));
}
export function ConceptsPicker(_a) {
    var { corpus_filter, clustering_mode, argument_name, selected, corpus_ids, noCustomLists, noSimilar = true, className, horizontal, noBorder, entityType } = _a, props = __rest(_a, ["corpus_filter", "clustering_mode", "argument_name", "selected", "corpus_ids", "noCustomLists", "noSimilar", "className", "horizontal", "noBorder", "entityType"]);
    const [selection, setSelection] = React.useState([]);
    useEffectIfDifferent(() => {
        setSelection(selected);
    }, [], [selected]);
    React.useEffect(() => setSelection(selected), [selected]);
    const source = useCurrentSource(corpus_ids);
    const { conceptExpansion } = useCurrentSession();
    const { remote, params } = useConceptExpansions(selection, corpus_ids, conceptExpansion, null, !noSimilar);
    function customListEditorButton(selected, hide) {
        return (_jsx(BrandButton, Object.assign({ disabled: !source, className: "flex items-center text-black px-4", roundedClass: "rounded-lg", paddingClass: "px-4 py-4", size: "small", icon: _jsx(ManageLists, {}), onClick: () => {
                hide(false);
                showCustomListsManager(corpus_ids);
            } }, { children: "List Manager" })));
    }
    function useExtraConceptsPickerTabs(selected) {
        React.useEffect(() => {
            setSelection(selected);
        }, [selected]);
        const { customConceptListsByCorpusId } = useCurrentSession();
        const customLists = customConceptListsByCorpusId[corpus_ids[0]] || [];
        const customTab = {
            "Custom Lists": {
                remote: {
                    value: customLists.filter(c => c.members.length),
                    loading: false,
                },
                alwaysEnabled: true,
                emptyMessage: (hide) => "No Custom Lists",
            },
        };
        return Object.assign({}, customTab);
    }
    return (_jsx(ClusterPickerField, Object.assign({}, props, { className: className, clusterType: "Concept", selected: selected, synonyms: () => [], updateSynonyms: (item, synonyms) => (Object.assign(Object.assign({}, item), { surface_forms: synonyms })), loader: API.searchConcepts, mapper: toConceptOrCustom, getParams: prefix => createParams(prefix, clustering_mode, corpus_filter, argument_name), extraClusterTabsHook: useExtraConceptsPickerTabs, extraControls: customListEditorButton, horizontal: horizontal, noBorder: noBorder, remote: remote, entityName: "Concepts", entityType: entityType, identifier: FeatureIdentifier.SUGGEST_CONCEPTS, capability: CorpusCapability.SEARCH_CONCEPTS, confidence: c => { var _a; return (_a = c.score) !== null && _a !== void 0 ? _a : 0; }, nameRenderer: c => c.isCustom ? (_jsxs("span", { children: [_jsx(ManageLists, { className: "relative mr-1", style: { top: 2 } }), _jsx("span", { children: c.name })] })) : (c.name) })));
}
export function RelationsPicker(_a) {
    var { corpus_filter, clustering_mode, argument_name, selected, corpus_ids, concepts1, concepts2, noSimilar = true } = _a, props = __rest(_a, ["corpus_filter", "clustering_mode", "argument_name", "selected", "corpus_ids", "concepts1", "concepts2", "noSimilar"]);
    const [selection, setSelection] = React.useState([]);
    useEffectIfDifferent(() => {
        setSelection(selected);
    }, [], [selected]);
    React.useEffect(() => setSelection(selected), [selected]);
    const { remote, params } = useRelationsExpansions(selection, corpus_ids, concepts1, concepts2, null, !noSimilar);
    function useExtraClusterTabs(selected) {
        React.useEffect(() => {
            setSelection(selected);
        }, [selected]);
        return Object.assign({}, (remote &&
            !noSimilar && {
            "Similar Relations": {
                remote,
            },
        }));
    }
    return (_jsx(ClusterPickerField, Object.assign({}, props, { synonyms: i => i.ids, updateSynonyms: (item, synonyms) => (Object.assign(Object.assign({}, item), { ids: synonyms })), loader: API.relations, mapper: identity, getParams: prefix => createParams(prefix, clustering_mode, corpus_filter, undefined), title: "Relation", clusterType: "Relation", placeholder: "select relation(s)\u2026", selected: selected, extraClusterTabsHook: useExtraClusterTabs, remote: remote, entityName: "Relations", entityType: "Relation", identifier: FeatureIdentifier.SUGGEST_RELATIONS, capability: CorpusCapability.SEARCH_RELATIONS })));
}
