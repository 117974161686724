var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { ClusteringMode, } from "../../api/types";
import { getCorpusFilter, isEmptyQuery, mergeNamedMembers, validateTextFilter, } from "../../utils/semanticSearchUtil";
import { API } from "../../api/api";
import { applyUpdate } from "../../../utils/collections";
import { invalidCorpusIds, } from "../../utils/sourceManagement";
import { ArgumentClausesFacet, defaultFeedbackProvider, } from "./specializedFacets";
import { omit } from "lodash";
import { useRemoteQuery } from "../../../utils/query/remoteQuery";
import { FacetsTabContent } from "./FacetsTabContent";
import { classes } from "../../../utils/classes";
import { useCurrentSession } from "../../utils/sessionManagement";
import { getSize } from "../../../utils/util";
export function FacetsForQualifiers(_a) {
    var { coraState, coraStateUpdater, argsInfo, feedback, setFeedback, classname } = _a, props = __rest(_a, ["coraState", "coraStateUpdater", "argsInfo", "feedback", "setFeedback", "classname"]);
    const { required_arguments, argClauses } = coraState;
    const { expandedFacetCount, rank_with_vector_search, rankWithVectorSearchPossible, } = useCurrentSession();
    function createFacet(argKey, loaderParamsGetter) {
        function setReq(req) {
            const other = omit(argClauses, argKey);
            coraStateUpdater({
                required_arguments: applyUpdate(required_arguments, req ? "ADD" : "REMOVE", argKey),
                argClauses: Object.assign({}, other),
            });
        }
        const selectedItems = coraState.argClauses[argKey] || [];
        const reqSelected = required_arguments.indexOf(argKey) >= 0 || getSize(selectedItems) > 0;
        const disabled = !reqSelected && !availableArgs.has(argKey);
        const tagColor = reqSelected || selectedItems.length
            ? argsInfo.argColors[argKey]
            : undefined;
        return (_jsx(ArgumentClausesFacet, Object.assign({ facetLoader: API.searchArgumentClauses, valueToSelection: v => v, itemInCollectionFilter: items => {
                const names = new Set(items.map(i => i.name));
                return s => names.has(s.name);
            }, onlyLoadWhenExpanded: true, initiallyCollapsed: true, loaderParamsGetter: loaderParamsGetter, category: argKey, coraState: coraState, coraStateUpdater: coraStateUpdater, argKey: argKey, setSelected: selection => {
                const other = omit(argClauses, argKey);
                coraStateUpdater({
                    required_arguments: required_arguments.filter(a => a !== argKey),
                    argClauses: Object.assign(Object.assign({}, other), (selection.length && { [argKey]: selection })),
                });
            }, selected: selectedItems, tagColor: tagColor, noExpansion: !argsInfo.qualifierClauses.has(argKey) || !availableArgs.has(argKey), setReq: availableArgs.has(argKey) ? setReq : undefined, reqSelected: reqSelected, disabled: disabled, getFacetTitle: (count, disabled) => (_jsx("div", Object.assign({ className: "relative w-full" }, { children: _jsx("div", Object.assign({ className: classes("font-semibold", disabled && "cursor-not-allowed"), style: {
                        opacity: disabled ? 0.5 : 1,
                    } }, { children: argKey })) }))) }, props, { suffixProvider: defaultFeedbackProvider(argKey, feedback, setFeedback), itemsOverride: null, max_count: expandedFacetCount }), argKey));
    }
    const { args, paramsGetter } = useQualifiers(coraState, rank_with_vector_search && rankWithVectorSearchPossible);
    const availableArgs = new Set((args.value || []).map(a => a.id));
    const hasQuery = !isEmptyQuery(coraState);
    return (_jsx(FacetsTabContent, { description: !hasQuery && (_jsx("div", { children: "Run a Research Query to display available qualifiers that influence the meaning of your query, which you can select to filter your results." })), facets: () => (_jsx(_Fragment, { children: argsInfo.allArgumentNames.map(argument_name => {
                return createFacet(argument_name, ({ prefix, maxCount }) => {
                    prefix = validateTextFilter(prefix);
                    return paramsGetter(argument_name, maxCount, prefix);
                });
            }) })) }));
}
export function useQualifiers(coraState, rank_with_vector_search) {
    const { concepts1, concepts2Override, concepts2, concepts1Override, relations, crcDirection, keywordMetadata, rangeMetadata, booleanMetadata, queries, required_arguments, argClauses, extraConcepts, extraConceptsOverride, contextConcepts, contextConceptsOverride, aperture, corpus_ids, } = coraState;
    const corpusFilterParams = {
        corpus_ids,
        concepts1,
        concepts2Override,
        concepts2,
        concepts1Override,
        crcDirection,
        relations,
        keywordMetadata,
        rangeMetadata,
        booleanMetadata,
        queries,
        extraConcepts,
        extraConceptsOverride,
        contextConcepts,
        contextConceptsOverride,
        aperture,
    };
    const corpus_filter = getCorpusFilter(corpusFilterParams);
    const merged = mergeNamedMembers(concepts1, concepts1Override);
    const mergedContext = mergeNamedMembers(contextConcepts, contextConceptsOverride);
    const disabled = invalidCorpusIds(coraState.corpus_ids) ||
        (!merged.length && !mergedContext.length && !relations.length);
    const args = useRemoteQuery({
        loader: API.searchArguments,
        params: disabled
            ? undefined
            : {
                corpus_filter,
            },
    });
    function paramsGetter(argument_name, count, prefix) {
        const corpus_filter = getCorpusFilter(Object.assign(Object.assign({}, corpusFilterParams), { required_arguments: required_arguments.filter(arg => arg !== argument_name), argClauses: omit(argClauses, argument_name) }));
        return Object.assign(Object.assign({ corpus_filter,
            count, clustering_mode: ClusteringMode.AGGRESSIVE, argument_name }, (prefix.length && { prefix })), { rank_with_vector_search: rank_with_vector_search });
    }
    return { args, paramsGetter, disabled };
}
