var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEqual, omit } from "lodash";
import React from "react";
import { useMemoIfEqual, useParams } from "../hooks";
import { useQueryLoader } from "./queryLoader";
import { sequentialMessageCoordinator } from "./messageCoordinator";
import { Atom } from "../atom";
import { plainRequestExecutor } from "../requestExecutors";
function noCursor(v) {
    return v && omit(v, "cursor");
}
export function usePaginatedRemoteQuery({ loader, params, pageAppender, }) {
    const initialState = React.useMemo(() => ({
        remote: {
            loading: false,
            value: null,
            lastPage: false,
        },
        cursor: null,
        nextCursor: null,
        currentPage: 0,
    }), []);
    const [{ remote, cursor, nextCursor, currentPage }, update] = useParams(initialState);
    const loadQuery = useQueryLoader(loader, plainRequestExecutor, sequentialMessageCoordinator);
    const calcParamsWithCursor = React.useMemo(() => {
        const atom = Atom({});
        return (params, cursor) => {
            const { params: previousParams, paramsWithCursor: previousParamsWithCursor, } = atom();
            atom({
                params,
                paramsWithCursor: params &&
                    Object.assign(Object.assign({}, params), { cursor: !previousParams || !isEqual(params, previousParams)
                            ? null
                            : cursor }),
            });
            return {
                paramsWithCursor: atom().paramsWithCursor,
                previousParamsWithCursor,
            };
        };
    }, []);
    React.useEffect(() => {
        const { paramsWithCursor, previousParamsWithCursor } = calcParamsWithCursor(params, cursor);
        loadQuery(({ remote: { loading, value: responseWithCursor, error }, params: paramsWithCursor, previousParams: previousParamsWithCursor, }) => {
            update(state => {
                const { remote, currentPage } = state;
                const params = noCursor(paramsWithCursor);
                const paramsChanged = !isEqual(params, noCursor(previousParamsWithCursor));
                if (!params) {
                    return initialState;
                }
                else if (loading || error || !responseWithCursor) {
                    const newCurrentPage = paramsChanged ? 0 : currentPage + 1;
                    return {
                        remote: {
                            lastPage: false,
                            loading: !error,
                            value: paramsChanged ? null : remote.value,
                            error,
                        },
                        currentPage: newCurrentPage,
                    };
                }
                const { cursor: nextCursor } = responseWithCursor, response = __rest(responseWithCursor, ["cursor"]);
                const { value, lastPage } = pageAppender(remote.value, response);
                return {
                    remote: {
                        loading: false,
                        value,
                        lastPage: lastPage && !!nextCursor,
                    },
                    nextCursor: nextCursor !== null && nextCursor !== void 0 ? nextCursor : null,
                };
            });
        }, paramsWithCursor, previousParamsWithCursor);
    }, [useMemoIfEqual([params, cursor])]);
    const { lastPage, loading } = remote;
    const nextPage = React.useCallback(() => {
        if (!loading && !lastPage && nextCursor) {
            update({ cursor: nextCursor, nextCursor: null });
        }
    }, [nextCursor, lastPage, loading]);
    return Object.assign(Object.assign({}, remote), { nextPage,
        currentPage });
}
