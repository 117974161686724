import { jsx as _jsx } from "react/jsx-runtime";
import { EvidenceType } from "../../api/types";
import { partition } from "lodash";
import { Banner } from "../../../shared/components/Banner";
import { classes } from "../../../utils/classes";
import React from "react";
import { getAvailableEvidence } from "../../utils/findings";
import { ButtonWrapper } from "../../../shared/components/buttons/Buttons";
import { Robot } from "../Icons";
export function EvidenceSummaryBanner({ evidence }) {
    const [supporting, refuting] = partition(evidence.filter(e => e.evidence_type === EvidenceType.SUPPORTING ||
        e.evidence_type === EvidenceType.REFUTING), i => i.evidence_type === EvidenceType.SUPPORTING);
    return (_jsx(Banner, { children: classes(supporting.length && `Supporting (${supporting.length})`, refuting.length && `Refuting (${refuting.length})`, !evidence.length
            ? "No Evidence"
            : supporting.length + refuting.length === 0
                ? `Evidence Count: ${evidence.length}`
                : "") }));
}
export function SuggestedEvidenceBanner({ evidence, suggested, onClick, }) {
    const available = React.useMemo(() => getAvailableEvidence(suggested === null || suggested === void 0 ? void 0 : suggested.value, evidence), [suggested === null || suggested === void 0 ? void 0 : suggested.value, evidence]);
    return !(suggested === null || suggested === void 0 ? void 0 : suggested.loading) && !available.length ? null : (_jsx(ButtonWrapper, Object.assign({ className: "flex-1", onClick: onClick }, { children: _jsx(Banner, Object.assign({ prefix: _jsx(Robot, { className: "text-gray-400", style: { fontSize: 20 } }) }, { children: classes((suggested === null || suggested === void 0 ? void 0 : suggested.loading) && "Looking for more evidence…", !(suggested === null || suggested === void 0 ? void 0 : suggested.loading) && `Suggested evidence (${available.length})…`) })) })));
}
