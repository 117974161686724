var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Readability } from "@mozilla/readability";
import DOMPurify from "dompurify";
import Url from "url";
import { WEB_SOURCE_ID } from "../cora/utils/globalInfo";
export function readable(html, url, source) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log("SOURCE", source);
        const processor = source === WEB_SOURCE_ID ? squeeze : noSqueeze;
        return processor(html, url);
    });
}
export function createDocumentFromHtml(html) {
    html = DOMPurify.sanitize(html);
    const parser = new DOMParser();
    const dom = parser.parseFromString(html, "text/html");
    return dom;
}
function cleanUrls(item, url) {
    item.querySelectorAll("a").forEach(e => {
        const href = e.getAttribute("href");
        if (href && !href.startsWith("#")) {
            e.setAttribute("href", Url.resolve(url, href));
        }
        if (href && !href.startsWith("#")) {
            e.setAttribute("target", "_blank");
            e.classList.add("ExternalLink");
        }
    });
    item.querySelectorAll("img").forEach(e => {
        const src = e.getAttribute("src");
        if (src) {
            e.setAttribute("src", Url.resolve(url, src));
        }
    });
    return item;
}
function squeeze(html, url) {
    return __awaiter(this, void 0, void 0, function* () {
        let doc = createDocumentFromHtml(html);
        doc = cleanUrls(doc, url);
        const squeezed = new Readability(doc).parse();
        return {
            title: squeezed.title,
            html: squeezed.content,
            text: squeezed.textContent,
        };
    });
}
export function noSqueeze(html, url) {
    return __awaiter(this, void 0, void 0, function* () {
        const doc = createDocumentFromHtml(html);
        let element = doc.getElementById("maincontent");
        if (!element)
            element = doc.getElementsByTagName("main")[0];
        if (!element)
            element = doc.getElementsByClassName("article")[0];
        if (!element)
            return squeeze(html, url);
        element = cleanUrls(element, url);
        return {
            title: doc.title,
            html: element.innerHTML,
            text: element.textContent,
        };
    });
}
