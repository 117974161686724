var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bgFinding100, findingBorderClass, headerInputClass } from "./lf";
import { FindingCard } from "./FindingCard";
import { Close, Down, Export, Findings, Import, Plus, Right } from "./Icons";
import { showFindingEditor } from "./modals/FindingEditorModal";
import { getContextCorpusIds, getContextQueryParts, getContextText, JsonFileToFindings, newFinding, restoreFindingContext, } from "../utils/findings";
import { ActionButton, TinyButton } from "../../shared/components/buttons/Buttons";
import { FilteredCardList } from "../../shared/components/CardsList";
import React from "react";
import { useSelectedContext } from "../utils/selectedContext";
import { useAllTagComponents, useAllTags } from "../utils/allTags";
import { groupBy, mapValues } from "lodash";
import { toggle, upsertArray } from "../../utils/collections";
import { invalidCorpusIds, useHasBackgroundKnowledge, } from "../utils/sourceManagement";
import { updateCurrentSession, useCurrentSession, } from "../utils/sessionManagement";
import { EllipsisOutlined } from "@ant-design/icons/lib";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import { FILE_TYPES, inputFileName, pickFiles, saveTextFile, } from "../../utils/filePicker";
import { message } from "antd";
import { showNotification } from "../../shared/components/notifications";
import { setLoading } from "../../shared/components/Loading";
import { classes } from "../../utils/classes";
import { QueryRenderer } from "./QueryRenderer";
import { useGlobalInfo } from "../utils/globalInfo";
import { getArgsInfo } from "../utils/coraUtil";
import { showSelectionMenu } from "./contextMenuEntries";
import { useCoraState } from "./CoraStateProvider";
import { useQueryState } from "../utils/queryState/queryState";
function useArgInfoById(findings, globalInfo) {
    return findings.reduce((acc, f) => {
        const corpus_id = getContextCorpusIds(f.context)[0];
        if (corpus_id && !acc[corpus_id]) {
            acc[corpus_id] = getArgsInfo(corpus_id, globalInfo);
        }
        return acc;
    }, {});
}
export function MyFindings() {
    const [coraState, coraStateUpdater] = useCoraState();
    const disabled = invalidCorpusIds(coraState.corpus_ids);
    const [allCollapsed, setAllCollapsed] = React.useState([]);
    const { findings, structuredQuery: sq, showStructuredQuery, } = useCurrentSession();
    const structuredQuery = sq && showStructuredQuery;
    const globalInfo = useGlobalInfo();
    const allTags = useAllTagComponents(useAllTags());
    const [selectedContext] = useSelectedContext();
    const [showTags, setShowTags] = React.useState(false);
    const backgroundKnowledge = useHasBackgroundKnowledge(coraState);
    const argInfoById = React.useMemo(() => useArgInfoById(findings, globalInfo), [findings, globalInfo]);
    const [queryState] = useQueryState();
    const itemRender = React.useCallback((item, idx, items, filterText) => {
        const queryParts = getContextQueryParts(item.context);
        const jsonParams = JSON.stringify(queryParts);
        const collapsed = allCollapsed.indexOf(jsonParams) >= 0;
        const newGroup = idx === 0 ||
            jsonParams !==
                JSON.stringify(getContextQueryParts(items[idx - 1].context));
        const colorProvider = argInfoById[getContextCorpusIds(item.context)[0]].argColors;
        return (_jsxs("div", { children: [!newGroup ? undefined : (_jsxs("div", Object.assign({ className: classes("rounded px-2 py-1 text-xs flex justify-start items-center cursor-pointer space-x-1"), onClick: () => {
                        restoreFindingContext(item.context);
                    } }, { children: [_jsx("div", Object.assign({ className: "flex-1 font-semibold italic text-base overflow-hidden" }, { children: getContextText(item.context) || (_jsx(QueryRenderer, { parts: queryParts, maxTags: 1, colorProvider: colorProvider })) })), _jsx(TinyButton, Object.assign({ size: 12, onMouseDown: e => {
                                e.preventDefault();
                                setAllCollapsed(c => toggle(c, jsonParams));
                            } }, { children: collapsed ? _jsx(Right, {}) : _jsx(Down, {}) }))] }))), !collapsed && (_jsx(FindingCard, { className: "mt-1", disabled: disabled, item: item, allTags: showTags ? allTags : undefined, highlightText: filterText, coraStateUpdater: coraStateUpdater, suggestQuestions: backgroundKnowledge, coraState: coraState, onTextSelected: ({ rect, text }) => showSelectionMenu(rect, {
                        text,
                        coraState,
                        queryState,
                        coraStateUpdater,
                        context: text,
                        structuredQuery,
                    }) }))] }));
    }, [
        allCollapsed,
        coraStateUpdater,
        setAllCollapsed,
        disabled,
        backgroundKnowledge,
        allTags,
        argInfoById,
        queryState,
    ]);
    return (_jsx(FilteredCardList, { style: { maxWidth: "32rem", minWidth: "24rem", flexGrow: 1, flexShrink: 1 }, bgClassName: "bg-gray-100", items: findings, noItemsMessage: "No Findings", filterBorderClass: findingBorderClass, brandingColorClass: bgFinding100, title: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(Findings, { className: "mr-1 pt-1 text-gray-500 text-base" }), _jsx("span", { children: "My Findings" })] })), itemRender: itemRender, itemTexts: item => [item.payload.selectedText], itemTags: item => item.payload.tags, showTags: showTags, setShowTags: setShowTags, filterClass: headerInputClass, headerActions: _jsxs("div", Object.assign({ className: "flex space-x-1" }, { children: [_jsx(DropDownMenu, Object.assign({ entries: () => [
                        {
                            caption: "Create New Finding",
                            icon: _jsx(Plus, {}),
                            action() {
                                var _a;
                                return __awaiter(this, void 0, void 0, function* () {
                                    const finding = yield newFinding({
                                        selectedText: ((_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString()) || "",
                                        context: selectedContext,
                                        queryState,
                                    });
                                    showFindingEditor(finding, true, backgroundKnowledge, disabled, coraState);
                                });
                            },
                        },
                        {
                            caption: "Export Findings…",
                            disabled: !findings.length,
                            icon: _jsx(Export, {}),
                            action() {
                                return __awaiter(this, void 0, void 0, function* () {
                                    const name = yield inputFileName("Export My Findings");
                                    name &&
                                        saveTextFile(name, FILE_TYPES, JSON.stringify(findings, undefined, 2));
                                });
                            },
                        },
                        {
                            caption: "Import Findings…",
                            icon: _jsx(Import, {}),
                            action() {
                                return __awaiter(this, void 0, void 0, function* () {
                                    const [file] = (yield pickFiles(FILE_TYPES, false)) || [];
                                    if (!file)
                                        return;
                                    try {
                                        setLoading(`Importing ${file.name}…`);
                                        const toMerge = yield JsonFileToFindings(file);
                                        if (typeof toMerge === "string") {
                                            message.error(toMerge, 10);
                                            return;
                                        }
                                        const { replaced, added, result } = upsertArray(findings, toMerge, f => f.id);
                                        showNotification({
                                            message: `Findings\nReplaced: ${replaced.length}\nFindings Added: ${added.length}`,
                                            duration: 5,
                                        });
                                        updateCurrentSession({
                                            findings: result,
                                        });
                                    }
                                    finally {
                                        setLoading(false);
                                    }
                                });
                            },
                        },
                    ] }, { children: _jsx(ActionButton, { children: _jsx(EllipsisOutlined, {}) }) })), _jsx(ActionButton, Object.assign({ className: "ml-1", onClick: () => {
                        updateCurrentSession({ showMyFindings: false });
                    } }, { children: _jsx(Close, {}) }))] })), itemsSorter: findingSorter }));
}
function findingSorter(items) {
    const groups = mapValues(groupBy(items, i => i.context.contextTitle), items => items.sort(timeComparator));
    return Object.values(groups)
        .sort((a, b) => timeComparator(a[0], b[0]))
        .flat();
}
function timeComparator(a, b) {
    return ((b.updateTimestamp || b.payload.foundTime) -
        (a.updateTimestamp || a.payload.foundTime));
}
