import dagre from "dagre";
import { groupBy, keyBy, memoize } from "lodash";
import { extract } from "../../../utils/collections";
const { alg, Graph } = dagre.graphlib;
export function GraphAnalysis(nodes, edges) {
    const graph = memoize(() => {
        const graph = new Graph({ directed: true });
        nodes.forEach(node => {
            graph.setNode(node.id, node);
        });
        edges.forEach(edge => graph.node(edge.source) &&
            graph.node(edge.target) &&
            graph.setEdge(edge.source, edge.target, edge));
        return graph;
    });
    const nodesById = memoize(() => keyBy(nodes, n => n.id));
    const components = memoize(() => alg.components(graph()));
    const edgesByNodeId = memoize(() => GraphAnalysis.edgesByNodeId(edges));
    function neighbors(id) {
        return (edgesByNodeId()[id] || []).map(e => nodesById()[e.source === id ? e.target : e.source]);
    }
    function validate() {
        const invalidEdges = edges.filter(e => !nodesById()[e.source] || !nodesById()[e.target]);
        if (invalidEdges.length > 0) {
            const ids = new Set(invalidEdges.map(e => e.id));
            edges = edges.filter(e => !ids.has(e.id));
        }
    }
    const nodeComponent = memoize(() => Object.fromEntries(components()
        .map((comp, idx) => comp.map(id => [id, idx]))
        .flat()));
    validate();
    return {
        edges,
        nodes,
        graph,
        nodesById,
        components,
        componentIdx(id) {
            return nodeComponent()[id];
        },
        edgesByNodeId,
        edgesForNode(id) {
            return edgesByNodeId()[id] || [];
        },
        neighbors,
        node(id) {
            return nodesById()[id];
        },
        getEdges(nodeId) {
            return edgesByNodeId()[nodeId] || [];
        },
        neighborIds(id) {
            return new Set(extract(neighbors(id), "id"));
        },
        nodeAndNeighborIds(id) {
            return [id, ...extract(neighbors(id), "id")];
        },
    };
}
GraphAnalysis.edgesByNodeId = (edges) => {
    const s = groupBy(edges, e => e.source);
    const t = groupBy(edges, e => e.target);
    return Object.fromEntries(Object.entries(s)
        .map(([k, es]) => [k, !t[k] ? es : [...es, ...t[k]]])
        .concat(Object.keys(t)
        .filter(k => !s[k])
        .map(k => [k, t[k]])));
};
GraphAnalysis.from = (flow) => GraphAnalysis(flow.getNodes(), flow.getEdges());
