var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { classes } from "../../../utils/classes";
import { TitledContainer, } from "../../../shared/components/TitledContainer";
import React from "react";
export function FacetContainer(_a) {
    var { containerStyleClass, titlePrefix, title } = _a, props = __rest(_a, ["containerStyleClass", "titlePrefix", "title"]);
    return (_jsx(TitledContainer, Object.assign({ noScroll: true, containerStyleClass: classes("rounded-lg", containerStyleClass), bgClassName: "bg-gray-100", headerBottomMargin: false, headerLeft: _jsxs("div", Object.assign({ className: "flex space-x-1 w-full" }, { children: [titlePrefix, title] })), className: "mr-2" }, props)));
}
