import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { MeasuredContainer } from "./MeasuredContainer";
import { DropDownMenu } from "../../../shared/components/DropDownMenu";
import Tippy from "@tippyjs/react";
import { SelectionButton } from "../../../shared/components/buttons/SelectionButton";
import { EllipsisOutlined } from "@ant-design/icons";
export function ActionBar({ actions, className, minimumExpandedWidth, enforcedPaddingWidth = 0, menuTitle, forceCollapse, }) {
    const [availableSpace, setAvailableSpace] = React.useState(0);
    const showPriority = React.useMemo(() => {
        if (forceCollapse)
            return 0;
        if (availableSpace === 0 ||
            (minimumExpandedWidth && availableSpace < minimumExpandedWidth))
            return 0;
        const spacePerPriority = actions.reduce((acc, action) => {
            if (!acc[action.priority])
                acc[action.priority] = 0;
            acc[action.priority] += action.spaceRequired;
            return acc;
        }, {});
        return Object.entries(spacePerPriority).reduce((acc, [priority, space]) => {
            const spaceRequiredForPriorityToBeIncluded = Object.entries(spacePerPriority)
                .filter(([p]) => Number(p) <= Number(priority))
                .reduce((acc, [, space]) => acc + space + enforcedPaddingWidth, 0);
            if (spaceRequiredForPriorityToBeIncluded <= availableSpace)
                acc = Number(priority);
            return acc;
        }, 0);
    }, [actions, availableSpace, forceCollapse]);
    const collapsedActionCount = React.useMemo(() => actions.filter(a => a.priority > showPriority).length, [actions, showPriority]);
    return (_jsx(MeasuredContainer, Object.assign({ className: className, onSizeChange: ({ w }) => setAvailableSpace(w) }, { children: _jsxs("div", Object.assign({ className: "w-full flex justify-between" }, { children: [_jsx("div", Object.assign({ className: "flex space-x-2" }, { children: actions
                        .filter(a => a.priority <= showPriority)
                        .map((action, i) => action.tooltip ? (_jsx(Tippy, Object.assign({ content: action.tooltip }, { children: _jsx("div", { children: action.expandedDisplay }) }), action.name)) : (_jsx("div", { children: action.expandedDisplay }, action.name))) })), collapsedActionCount > 0 && (_jsx(DropDownMenu, Object.assign({ dontHideOnSelect: true, title: menuTitle, hasClose: true, entries: actions
                        .filter(a => a.priority > showPriority)
                        .map(action => action.collapsedDisplay)
                        .reduce((acc, section, idx, arr) => {
                        const currMenu = [
                            ...acc,
                            {
                                caption: (_jsx("div", Object.assign({ className: "font-bold" }, { children: section.sectionTitle }))),
                            },
                            ...section.entries,
                        ];
                        if (idx < arr.length - 1)
                            currMenu.push("SEPARATOR");
                        return currMenu;
                    }, []) }, { children: _jsx(SelectionButton, { style: { height: "36px" }, icon: _jsx(EllipsisOutlined, {}) }) })))] })) })));
}
