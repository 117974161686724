import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getLinePath } from "./diagramUtils";
import { Circle } from "./Circle";
export function ConnectionLine({ fromX, fromY, toX, toY, }) {
    return (_jsxs("g", { children: [_jsx("path", { fill: "none", stroke: "#222", strokeWidth: 1.5, className: "animated", d: getLinePath(fromX, fromY, toX, toY) }), _jsx(Circle, { cx: fromX, cy: fromY }), _jsx(Circle, { cx: toX, cy: toY })] }));
}
ConnectionLine.style = {
    strokeWidth: 3,
    stroke: "black",
};
