import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../utils/classes";
export function MeasuredContainer({ children, className, onSizeChange, }) {
    const containerRef = React.useRef(null);
    React.useEffect(() => {
        if (!containerRef.current)
            return;
        const resizeObserver = new ResizeObserver(() => {
            if (!containerRef.current)
                return;
            onSizeChange === null || onSizeChange === void 0 ? void 0 : onSizeChange({
                w: containerRef.current.offsetWidth,
                h: containerRef.current.offsetHeight,
            });
        });
        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect();
    }, [containerRef.current]);
    return (_jsx("div", Object.assign({ className: classes("MeasuredContainer", className), ref: containerRef }, { children: children })));
}
