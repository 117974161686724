import { CRCDirection, } from "../api/types";
import { filterByKey } from "../../utils/objects";
import { getCorpusFilter, getSpecificConceptsParam, } from "./semanticSearchUtil";
import { useCurrentSession } from "./sessionManagement";
export function getCorpusFilterProvider({ corpus_ids, coraState, conceptsKey, conceptsOverridesKey, otherConceptsKeys, otherConceptsOverrideKeys, }) {
    const { relations, required_arguments, argClauses, crcDirection, keywordMetadata, rangeMetadata, booleanMetadata, aperture, queries, } = coraState;
    const conceptsOrCustom = coraState[conceptsKey];
    const overrides = coraState[conceptsOverridesKey];
    const otherConceptsAndOverrides = Object.fromEntries([...otherConceptsKeys, ...otherConceptsOverrideKeys].map(key => [
        key,
        coraState[key],
    ]));
    return (conceptExcludeKeys, overrideExcludeKeys, forcedConceptsOrCustom) => {
        const conceptsParams = {
            [conceptsKey]: forcedConceptsOrCustom
                ? [forcedConceptsOrCustom]
                : !conceptExcludeKeys || !conceptExcludeKeys.length
                    ? conceptsOrCustom
                    : conceptsOrCustom.filter(c => conceptExcludeKeys.indexOf(c.name) < 0),
        };
        const overridesParams = !overrideExcludeKeys
            ? {}
            : {
                [conceptsOverridesKey]: !overrideExcludeKeys.length
                    ? overrides
                    : filterByKey(overrides, key => overrideExcludeKeys.indexOf(key) < 0),
            };
        return getCorpusFilter(Object.assign(Object.assign(Object.assign(Object.assign({ corpus_ids,
            required_arguments,
            argClauses,
            crcDirection,
            keywordMetadata,
            rangeMetadata,
            booleanMetadata,
            relations }, otherConceptsAndOverrides), conceptsParams), overridesParams), { queries,
            aperture }));
    };
}
export function useSubconceptParamsGetter(corpusFilterProvider, argument_name) {
    const { conceptClustering, specificConcepts } = useCurrentSession();
    const specificConceptsParam = getSpecificConceptsParam(specificConcepts);
    function subconceptParamsGetter(count, conceptExcludeKeys, overrideExcludeKeys, forcedConceptsOrCustom) {
        const filter = corpusFilterProvider(conceptExcludeKeys, overrideExcludeKeys, forcedConceptsOrCustom);
        return Object.assign(Object.assign({ corpus_filter: filter, count, clustering_mode: conceptClustering }, specificConceptsParam), (argument_name && { argument_name }));
    }
    return subconceptParamsGetter;
}
export function useFacetParamsGetterForTopResults(corpusFilterProvider, coraState, conceptsKey, otherConceptsKeys, otherConceptsOverrideKeys, argument_name, disabled) {
    const { conceptClustering, specificConcepts } = useCurrentSession();
    const specificConceptsParam = getSpecificConceptsParam(specificConcepts);
    const corpus_filter = corpusFilterProvider(null, null, undefined);
    const hasNoSelectionFacet = getTopResultsField(coraState) === conceptsKey;
    return disabled || !hasNoSelectionFacet || !corpus_filter
        ? undefined
        : (count) => (Object.assign(Object.assign({ corpus_filter,
            count, clustering_mode: conceptClustering }, specificConceptsParam), (argument_name && { argument_name })));
}
export function getTopResultsField({ concepts1, concepts2, relations, crcDirection, }) {
    return crcDirection === CRCDirection.BOTH || !relations.length
        ? undefined
        : !concepts1.length && concepts2.length
            ? "concepts1"
            : !concepts2.length && concepts1.length
                ? "concepts2"
                : undefined;
}
