var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Menu } from "../../shared/actions/Menu";
import { Glossary } from "../components/Icons";
import { registerConceptMenuExtension, registerCrcMenuExtension, } from "./dropdownMenuExtensions";
import { MenuEntry } from "../../shared/actions/MenuEntry";
import { Spin } from "antd";
import { useRemoteQuery } from "../../utils/query/remoteQuery";
import { getConceptFromText, getKbIdsAndNamesFromConcept, } from "../utils/coraUtil";
import { useFlags } from "launchdarkly-react-client-sdk";
import { API } from "../api/api";
import { getCurrentSource } from "../utils/sourceManagement";
import { showGlossary } from "../components/modals/Glossary";
const KBIndex = {
    ProMap: ["UniProt", "UniProt gene names", "Gene Ontology"],
};
const Openers = {
    "UniProt gene names": {
        siteName: "ProMap",
        open(kb_id) {
            window.open(`https://app.pro-map.org/structure?concept1=uniprot:gene:${kb_id}`, "PROMAP");
        },
        name(name, kb_id) {
            return `${name} (gene:${kb_id})`;
        },
    },
    UniProt: {
        siteName: "ProMap",
        open(kb_id) {
            window.open(`https://app.pro-map.org/structure?concept1=uniprot:${kb_id}`, "PROMAP");
        },
        name(name, kb_id) {
            return `${name} (${kb_id})`;
        },
    },
    "Gene Ontology": {
        siteName: "ProMap",
        open(kb_id) {
            window.open(`https://app.pro-map.org/structure?concept1=uniprot:GO:${kb_id}`, "PROMAP");
        },
        name(name, kb_id) {
            return `${name} (GO:${kb_id})`;
        },
    },
};
export function addGlossaryConceptSearch(entries, concepts, coraState, coraStateUpdater) {
    const extra = {
        caption: "Find in glossary",
        action() {
            const selected = getCurrentSource(coraState);
            if (!selected)
                return;
            if (!concepts.length)
                return;
            const concept_cluster = concepts[0];
            showGlossary({
                corpus_id: selected.id,
                concept_cluster,
                coraStateUpdater,
                context: "",
            });
        },
        icon: _jsx(Glossary, {}),
    };
    return [...entries, extra];
}
export function addGlossaryEvidenceSearch(entries, span_in_evidence, coraState, coraStateUpdater) {
    const base = {
        caption: "Find in glossary",
        icon: _jsx(Glossary, {}),
    };
    const extra = span_in_evidence.span.start === -1
        ? Object.assign(Object.assign({}, base), { disabled: true }) : Object.assign(Object.assign({}, base), { action() {
            const selected = getCurrentSource(coraState);
            if (!selected)
                return;
            showGlossary({
                corpus_id: selected.id,
                span_in_evidence,
                coraStateUpdater,
                context: "",
            });
        } });
    return [...entries, extra];
}
export function addProMapConceptSearch(entries, concepts, coraState) {
    const extra = [
        createMenuEntriesForConcepts(concepts.map(c => c.members).flat(), coraState.corpus_ids),
    ];
    return !extra.length ? entries : [...entries, ...extra];
}
export function addProMapCRCSearch(entries, coraState, field) {
    const extra = [
        createMenuEntriesForConcepts(field === "relations" ? [] : coraState[field].map(c => c.members).flat(), coraState.corpus_ids),
    ];
    return !extra.length ? entries : [...entries, Menu.SEPARATOR, ...extra];
}
export function useRegisterKBMenuExtensions() {
    const { disableFindInProMap } = useFlags();
    React.useEffect(() => {
        const unregister1 = (!disableFindInProMap &&
            registerConceptMenuExtension(addProMapConceptSearch)) ||
            (() => { });
        const unregister2 = registerConceptMenuExtension(addGlossaryConceptSearch);
        const unregister3 = (!disableFindInProMap && registerCrcMenuExtension(addProMapCRCSearch)) ||
            (() => { });
        return () => {
            unregister1();
            unregister2();
            unregister3();
        };
    }, [disableFindInProMap]);
}
function KBMenuRows(_a) {
    var { getKbIds } = _a, rowProps = __rest(_a, ["getKbIds"]);
    const { disableFindInProMap } = useFlags();
    const { value, loading } = useRemoteQuery({
        loader: getKbIds,
        params: disableFindInProMap,
    });
    const menuEntries = React.useMemo(() => disableFindInProMap ? [] : createCreateKBMenuEntries(value ? value : []), [value, disableFindInProMap]);
    return (_jsx(_Fragment, { children: menuEntries.map((entry, idx) => (_jsx(MenuEntry, { rowProps: rowProps, entry: Object.assign(Object.assign({}, entry), { disabled: entry.disabled || !value, icon: loading ? _jsx(Spin, { size: "small" }) : _jsx(Glossary, {}) }) }, idx))) }));
}
export function createMenuEntriesForText(text, corpus_ids) {
    const getKbIds = (disable) => {
        if (disable)
            return Promise.resolve([]);
        return getKbIdsFromTextHack({ text, corpus_ids });
    };
    return {
        icon: _jsx(Spin, { size: "small" }),
        renderer: (rowProps, key) => (_jsx(KBMenuRows, Object.assign({}, rowProps, { getKbIds: getKbIds }), key)),
    };
}
export function createMenuEntriesForConcepts(concepts, corpus_ids) {
    const getKbIds = (disable) => {
        if (disable)
            return Promise.resolve([]);
        return getKbIdsAndNamesFromConcept({ concepts, corpus_ids });
    };
    return {
        icon: _jsx(Spin, { size: "small" }),
        renderer: (rowProps, key) => (_jsx(KBMenuRows, Object.assign({}, rowProps, { getKbIds: getKbIds }), key)),
    };
}
function createCreateKBMenuEntries(concepts, alwaysPresent = true) {
    return Object.keys(KBIndex).reduce((acc, key) => {
        const entry = createKBMenuEntryFor(key, concepts, alwaysPresent);
        if (entry) {
            acc.push(entry);
        }
        return acc;
    }, []);
}
function createKBMenuEntryFor(kbType, concepts, alwaysPresent = true) {
    const openerKeys = KBIndex[kbType];
    const names = new Set();
    const subEntries = [];
    concepts.forEach(concept => concept.kb_ids_and_names.forEach(kb_id_and_name => {
        if (!kb_id_and_name)
            return;
        const { kb_id, kb_name } = kb_id_and_name;
        if (!openerKeys.includes(kb_name))
            return;
        const opener = Openers[kb_name];
        const caption = opener.name(concept.name, kb_id);
        if (names.has(caption))
            return;
        names.add(caption);
        subEntries.push({
            caption,
            action() {
                opener.open(kb_id);
            },
        });
    }));
    return !alwaysPresent && !subEntries.length
        ? null
        : {
            caption: `Find in ${kbType}…`,
            icon: _jsx(Glossary, {}),
            subEntries,
            disabled: !subEntries.length,
        };
}
export function getKbIdsFromTextHack({ text, corpus_ids, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const concept = yield getConceptFromText({ text, corpus_ids });
        if (!concept)
            return [];
        const identifiedConcepts = yield getKbIdsAndNamesFromConcept({
            concepts: concept.members,
            corpus_ids,
        });
        return identifiedConcepts;
    });
}
export function getConceptsFromKBIds(kb_ids, corpus_ids) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (corpus_ids.length && kb_ids.length) {
                const { concepts_found_by_kb_id, concepts_found_by_name } = yield API.lookupConcepts({ corpus_ids, kb_ids }, { ignoreErrorHandler: true });
                return concepts_found_by_kb_id;
            }
        }
        catch (error) {
            console.error(error);
        }
        return kb_ids.map(() => null);
    });
}
//http://localhost:3000/?concept1=uniprot:P15494
function getNoSpaces(text) {
    return text.toLowerCase().replaceAll(/\s+/g, "");
}
