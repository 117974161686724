import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { resolveValue } from "../../utils/util";
import { Menu } from "./Menu";
import { keyBy, mapValues } from "lodash";
import { getShortcutDisplayText, useShortcuts } from "../../utils/keyboard";
import React from "react";
import { Checkbox, Radio } from "antd";
import { beep } from "../../utils/beep";
function getActionsWithShortcut(mActions) {
    const actions = mActions.filter(Boolean);
    const params = actions.filter(a => a.action && a.shortcut);
    const subParams = actions
        .map(a => a.subEntries || [])
        .flat()
        .filter(a => a !== Menu.SEPARATOR);
    return !subParams.length
        ? params
        : [...params, ...getActionsWithShortcut(subParams)];
}
export function useBindActions(actions, disabled = false, element) {
    const [actionsWithShortcut, actionsByShortcut] = React.useMemo(() => {
        const withShortcut = getActionsWithShortcut(actions);
        return [
            withShortcut,
            mapValues(keyBy(withShortcut, "shortcut"), a => resolveValue(a.disabled) ? () => beep() : a.action),
        ];
    }, [actions]);
    const triggerAction = useShortcuts(actionsByShortcut, disabled, element);
    return [triggerAction, actionsWithShortcut];
}
export function getActionTooltip({ disabled, shortcut, desc }) {
    const disabledMsg = disabled && disabled !== true ? disabled : null;
    if (disabledMsg)
        return disabledMsg;
    const shortComp = shortcut ? _jsx(ShortcutForTooltip, { shortcut: shortcut }) : null;
    return !desc ? (shortComp) : (_jsxs("span", { children: [desc, " ", shortComp] }));
}
export function ShortcutForTooltip({ shortcut }) {
    const shortTxt = getShortcutDisplayText(shortcut);
    return shortTxt ? (_jsx("span", Object.assign({ className: "bg-gray-500  px-1 py-0.5 rounded  text-white" }, { children: shortTxt }))) : null;
}
export function getActionIcon(params) {
    const icon = resolveValue(params.icon);
    if (icon)
        return icon;
    return "selected" in params ? (params.selectionGroup ? (_jsx(Radio, { className: "mr-0", checked: params.selected })) : (_jsx(Checkbox, { checked: params.selected }))) : null;
}
export function getActionDisabled(d) {
    // an action is disabled if it is itself disabled, or if all its sub-entries are disabled
    return !d || d === Menu.SEPARATOR
        ? false
        : resolveValue(d.disabled)
            ? true
            : !d.subEntries
                ? false
                : (resolveValue(d.subEntries) || []).every(sub => getActionDisabled(sub));
}
