import { CRCDirection } from "../../api/types";
import { getConstraint } from "../../utils/queryState/DMParams";
export function constraintToQueryParts(params, id) {
    const constraint = getConstraint(params, id);
    if (!constraint)
        return {};
    const { concepts, relations, clauses } = params;
    const { source, target, relation, qualifiers, context, text, is_directed } = constraint;
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (source ? { c1: getNames(concepts, source) } : {})), (relation ? { r: getNames(relations, relation) } : {})), (target ? { c2: getNames(concepts, target) } : {})), (context.length
        ? { context: context.map(id => getNames(concepts, id)).flat() }
        : {})), { dir: is_directed ? CRCDirection.C1C2 : CRCDirection.BOTH }), (text && { text }));
    function getNames(items, id) {
        var _a;
        return (_a = items[id]) === null || _a === void 0 ? void 0 : _a.map(i => i.name);
    }
}
