var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Tippy from "@tippyjs/react";
import { classes } from "../../../../utils/classes";
import { ActionButton } from "../../../../shared/components/buttons/Buttons";
import { DocumentHeader, EvidenceFooter } from "../../InfoRow";
import { EllipsisOutlined } from "@ant-design/icons/lib";
import { DropDownMenu } from "../../../../shared/components/DropDownMenu";
import { useUniqId } from "../../../../utils/hooks";
import { uuidv4 } from "../../../../utils/util";
import { Down, Right } from "../../Icons";
import { EvidenceRow } from "../EvidenceRow";
import { BorderlessButton } from "../../../redesign/generic/BorderlessButton";
export function DocumentCard(_a) {
    var { evidenceState, evidenceStateUpdater, document, className, style, urlOpenerFn, filterText, actions, extraItemActions, renderAsHtml, onRemove, showSurrounding, onMetadataClick, onEvidenceTextSelected, showHighlights, actionsTooltip, metadataSorter, collapsed = false, setCollapsed = () => { }, annotationProvider, renderEvidenceActions } = _a, props = __rest(_a, ["evidenceState", "evidenceStateUpdater", "document", "className", "style", "urlOpenerFn", "filterText", "actions", "extraItemActions", "renderAsHtml", "onRemove", "showSurrounding", "onMetadataClick", "onEvidenceTextSelected", "showHighlights", "actionsTooltip", "metadataSorter", "collapsed", "setCollapsed", "annotationProvider", "renderEvidenceActions"]);
    const primaryEvidence = document.primary_evidence;
    const containerId = useUniqId(uuidv4);
    const getAllEvidence = React.useCallback((document) => [
        document.primary_evidence,
        ...document.additional_evidence,
    ], [document]);
    const { selection } = evidenceState;
    const selectEvidence = React.useCallback((item) => evidenceStateUpdater.update({
        selection: selection[!selection.has(item.id) ? "add" : "remove"](item.id),
    }), [selection, evidenceStateUpdater]);
    const selectionCount = React.useMemo(() => getAllEvidence(document).filter(e => evidenceStateUpdater.itemSelected(e.id)).length, [document, evidenceStateUpdater]);
    const groupCollapse = (_jsx("div", Object.assign({ className: "px-4 py-2 w-[24px] min-w-[24px]" }, { children: _jsx(Tippy, Object.assign({ content: collapsed ? "Expand" : "Collapse" }, { children: _jsx(BorderlessButton, Object.assign({ className: classes("text-base text-black", "cursor-pointer"), style: { verticalAlign: "text-top", paddingTop: 3 }, onClick: () => setCollapsed(!collapsed) }, { children: collapsed ? (_jsx(Right, { style: { verticalAlign: "text-top" } })) : (_jsx(Down, { style: { verticalAlign: "text-top" } })) })) })) })));
    return (_jsx("div", Object.assign({ className: classes("relative flex flex-col items-stretch py-3 font-sans border-neutral-300 bg-neutral-100 rounded-xl border-b border-t border-r", className), style: style }, props, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch" }, { children: [_jsx("div", Object.assign({ className: classes("sticky top-0 bg-neutral-100"), style: { zIndex: 10 } }, { children: _jsxs("div", Object.assign({ className: "flex" }, { children: [groupCollapse, _jsx("div", Object.assign({ style: { width: "0", flex: "1 1 auto" } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx(DocumentHeader, { metadataSorter: metadataSorter, document: document, selectCount: selectionCount, urlOpener: urlOpenerFn }), _jsx(EvidenceFooter, { className: "px-1", evidence: primaryEvidence, onMetadataClick: onMetadataClick, metadataSorter: metadataSorter, showHighlights: showHighlights, urlOpener: urlOpenerFn, suffix: _jsxs(_Fragment, { children: [actions && (_jsx(Tippy, Object.assign({ content: actionsTooltip }, { children: _jsx("span", { children: _jsx(DropDownMenu, Object.assign({ entries: () => actions(primaryEvidence) }, { children: _jsx(ActionButton, { children: _jsx(EllipsisOutlined, {}) }) })) }) }))), extraItemActions] }) })] })) }))] })) })), !collapsed && (_jsx("div", Object.assign({ className: classes("flex flex-col items-stretch", "mt-2") }, { children: _jsx("div", Object.assign({ id: containerId }, { children: _jsx("div", Object.assign({ className: "" }, { children: [
                                document.primary_evidence,
                                ...document.additional_evidence,
                            ].map((evidence, index) => {
                                return (_jsx(EvidenceRow, { className: "px-2", evidence: evidence, documentId: document.document_id, filterText: filterText, showSurrounding: showSurrounding, renderAsHtml: renderAsHtml, isHighlighted: evidenceStateUpdater.itemHighlighted, isSelected: evidenceStateUpdater.itemSelected, selectEvidence: selectEvidence, removeEvidence: onRemove, openEvidence: urlOpenerFn, onEvidenceTextSelected: onEvidenceTextSelected, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions }, index));
                            }) })) })) }))), collapsed && selectionCount ? (_jsx("div", { className: "w-full border-t border-gray-100" })) : null] })) })));
}
