var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import { DropDownMenu } from "./DropDownMenu";
import { Badge, Spin } from "antd";
import { BRAND_COLOR_VALS } from "../../cora/components/lf";
import colors from "../../assets/colors";
export const BADGE_COLOR = BRAND_COLOR_VALS[500];
export function GeniusButton({ entriesCount, iconClass, onClick, offset, content, disabled, }) {
    return (_jsx("div", Object.assign({ onMouseDownCapture: onClick }, { children: _jsx(Badge, { count: entriesCount, style: { backgroundColor: BADGE_COLOR }, offset: offset || [0, -6] }) })));
}
export function BrandBadge({ remote, offset, emptyValue, showZero, }) {
    var _a;
    const val = (_a = remote.value) === null || _a === void 0 ? void 0 : _a.length;
    const bgColor = val && val > 0 ? colors.accent[500] : colors.neutral[300];
    const textColor = val && val > 0 ? "#FFFFFF" : colors.neutral[600];
    return (_jsx(Badge, { count: remote.loading ? _jsx(Spin, { size: "small" }) : val || emptyValue || 0, showZero: showZero, offset: offset || [4, 0], style: remote.loading
            ? undefined
            : {
                backgroundColor: bgColor,
                color: textColor,
                borderRadius: 4,
                fontSize: 10,
            } }));
}
export function Genius(_a) {
    var { entriesCount, name, className, placement = "rightBottom", iconClass, disabled, offset } = _a, props = __rest(_a, ["entriesCount", "name", "className", "placement", "iconClass", "disabled", "offset"]);
    return (_jsxs(DropDownMenu, Object.assign({}, props, { placement: placement, className: "flex items-center my-1", disabled: entriesCount < 1, containerClassName: "flex space-x-1 items-center" }, { children: [_jsx("span", Object.assign({ className: classes("mr-1 font-normal text-white select-none") }, { children: "Suggested Questions" })), _jsx(GeniusButton, { entriesCount: entriesCount, iconClass: iconClass, disabled: entriesCount < 1, offset: offset || [0, 12] }), entriesCount > 0 && name && (_jsxs("span", { children: [_jsx("span", { children: "for " }), _jsx("span", Object.assign({ className: "font-bold" }, { children: name }))] }))] })));
}
