import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useUpdateCurrentContextTitle } from "../../utils/findings";
import { invalidCorpusIds, useHasBackgroundKnowledge, } from "../../utils/sourceManagement";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { useEvidence } from "../../utils/evidence";
import { landmarkIdTag } from "../../../shared/components/assist/Tour";
import { useExportEvidenceAddOn } from "./exportAddOn";
import { useEvidenceAddOns } from "./useEvidenceAddOns";
import { useTrendsAddOn } from "../trends/trendsAddOn";
import { useEvidenceState } from "./evidenceState";
import { TitledContainer } from "../../../shared/components/TitledContainer";
import { Allotment } from "allotment";
import { classes } from "../../../utils/classes";
import { useLocalStorageState } from "../../../utils/hooks";
import { Bar } from "../../redesign/generic/Bar";
import { Fill } from "../../redesign/generic/Fill";
import { SwappableQuery } from "../../redesign/generic/SwappableQuery";
import { EvidenceFilter } from "./EvidenceFilter";
import { ActionBar } from "../../redesign/generic/ActionBar";
import { useGroupByDocumentAction } from "../../redesign/evidence/useGroupByDocumentAction";
import { useRelationsTypeAction } from "../../redesign/evidence/useRelationsTypeAction";
import { useSummariesAction, useSummariesFilter, } from "../summaries/summariesAddOn";
import { useDMParamsDiagramAddOn } from "../domainModel/DMParamsDiagramAddOn";
import { useEvidenceSort } from "../../redesign/evidence/useEvidenceSort";
import { useDocumentItems } from "../../redesign/evidence/useDocumentItems";
import { useEvidenceItems } from "../../redesign/evidence/useEvidenceItems";
import { AND } from "../../../utils/filters";
import { Button, Checkbox, Divider } from "antd";
import { FindingsDropdown } from "./FindingsDropdown";
import { FindingFilled } from "../Icons";
import { Summaries } from "../summaries/Summaries";
import { LabeledSection } from "./LabeledSection";
import { EvidenceResults } from "./EvidenceResults";
import { useCoraState } from "../CoraStateProvider";
export function EvidencePanel({ argsInfo, extraAddOns = [], className }) {
    var _a, _b;
    const [coraState, coraStateUpdater] = useCoraState();
    const [divider, setDivider] = useLocalStorageState("EVIDENCE_PICKER_DIVIDER_LOCATION", [1, 3]);
    const disabled = invalidCorpusIds(coraState.corpus_ids);
    const { evidenceAndTotals, corpus_filter, evidenceRequestParams, noSelection } = useEvidence(coraState, disabled);
    const [state, stateUpdater] = useEvidenceState();
    const totalEvidenceCount = ((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.total_count) || 0;
    const { widget, widgetSize, top, filter: actionsFilters, selectors: evidenceHeaderSelectors, } = useEvidenceAddOns({
        coraState,
        coraStateUpdater,
        evidenceAndTotals,
        evidenceState: state,
        evidenceStateUpdater: stateUpdater,
        corpus_filter,
        evidenceRequestParams,
        argsInfo,
    }, useExportEvidenceAddOn, useDMParamsDiagramAddOn, useTrendsAddOn, ...extraAddOns);
    const hasBackgroundKnowledge = useHasBackgroundKnowledge(coraState);
    useUpdateCurrentContextTitle(coraState);
    const { showSummary, selectedSummaries, groupEvidenceByDocument } = useCurrentSession();
    const evidenceSearch = useEvidence(coraState, disabled);
    const { sortField, sortOrder } = useEvidenceSort(coraState, coraStateUpdater);
    const summariesAction = useSummariesAction({
        coraState,
        evidenceRequestParams,
        evidenceAndTotals,
    });
    const { summaries, loading: loadingSummaries, filter: summariesFilter, } = useSummariesFilter({
        evidenceAndTotals,
        corpus_filter,
    });
    const filter = AND(summariesFilter, actionsFilters);
    const relationsTypeAction = useRelationsTypeAction(coraState, coraStateUpdater, noSelection, totalEvidenceCount);
    const groupByDocumentAction = useGroupByDocumentAction();
    //COR-5435: hiding sort actions for now
    //const documentSortAction = useDocumentSortAction(coraState, coraStateUpdater)
    //const evidenceSortAction = useEvidenceSortAction(coraState, coraStateUpdater)
    const { items: documents } = useDocumentItems(evidenceSearch, filter, sortField, sortOrder);
    const { items: evidence } = useEvidenceItems(evidenceSearch, filter, sortField, sortOrder);
    const summariesList = showSummary && (_jsx(Summaries, { evidence: evidence, summaries: summaries, loading: loadingSummaries, loadingEvidence: evidenceAndTotals.loading, corpus_filter: corpus_filter, selected: selectedSummaries, setSelected: selectedSummaries => {
            stateUpdater.clearSelected();
            updateCurrentSession({ selectedSummaries });
        }, evidenceStateUpdater: stateUpdater }));
    const controlBar = (_jsx(ActionBar, { className: "w-full flex mr-2", actions: [
            relationsTypeAction,
            groupByDocumentAction,
            // groupEvidenceByDocument ? documentSortAction : evidenceSortAction,
        ], minimumExpandedWidth: 300, enforcedPaddingWidth: 10, menuTitle: "Options" }));
    const someSelected = stateUpdater.someSelected(evidence.map(e => e.id) || []);
    const allSelected = stateUpdater.everySelected(evidence.map(e => e.id) || []);
    const disabledSelection = disabled || state.selection.size === 0;
    const selectedEvidence = React.useMemo(() => {
        return state.selection.toArray().reduce((acc, id) => {
            const selected = evidence.find(e => e.id === id);
            if (!selected) {
                return acc;
            }
            return [...acc, selected];
        }, []);
    }, [evidence, state]);
    const actionBar = (_jsxs(Bar, Object.assign({ className: "pb-2" }, { children: [_jsx(Checkbox, { className: "ml-2 px-2", disabled: evidenceAndTotals.loading ||
                    ((_b = evidenceAndTotals.value) === null || _b === void 0 ? void 0 : _b.total_count) === 0, checked: allSelected, indeterminate: someSelected && !allSelected, onChange: () => someSelected
                    ? stateUpdater.clearSelected()
                    : stateUpdater.updateEvidence(evidence.map(e => e.id) || [], "add") }), !disabledSelection && (_jsx(FindingsDropdown, { evidence: selectedEvidence, disabledDropdown: disabledSelection, renderDropdownAnchor: (setMenuOpen, hasExistingFinding) => {
                    return (_jsx(Button, Object.assign({ style: { height: "36px" }, className: "rounded-xl font-medium text-tiny mr-2", onClick: setMenuOpen }, { children: _jsxs("div", Object.assign({ className: "flex flex-1 justify-center items-center" }, { children: [_jsx(FindingFilled, { className: classes("text-base my-auto mr-1") }), `Add/Remove Findings (${state.selection.size})`] })) }), disabledSelection ? "disabled" : "enabled"));
                } })), _jsx(SwappableQuery, Object.assign({ hasActiveQuery: Boolean(coraState.evidenceFilter), openWhenActive: true, className: "mr-2", queryBox: _jsx(EvidenceFilter, { coraState: coraState, coraStateUpdater: coraStateUpdater, disabled: disabled }), suffix: React.cloneElement(controlBar, {
                    forceCollapse: true,
                    className: "mr-2",
                }), onClose: coraStateUpdater && (() => coraStateUpdater({ evidenceFilter: "" })) }, { children: controlBar })), summariesAction.expandedDisplay] })));
    const summariesDescription = React.useMemo(() => {
        if (!summariesList ||
            loadingSummaries ||
            evidenceAndTotals.loading ||
            summaries.length === 0) {
            return [];
        }
        const description = [
            `showing ${summaries.length} ${summaries.length === 1 ? "summary" : "summaries"}`,
        ];
        if (summaries.length > 1) {
            description.push(`sorted by number of results considered`);
        }
        return description;
    }, [summaries, summariesList, evidenceAndTotals, loadingSummaries]);
    const summariesColumn = summariesList && (_jsx(LabeledSection, Object.assign({ label: summariesDescription.join(", "), style: { flexGrow: "1", maxWidth: "30rem", minWidth: "24rem" } }, { children: summariesList })));
    const evidenceResults = (_jsx("div", Object.assign({ className: "w-full flex flex-col flex-1" }, landmarkIdTag("tour-evidence"), { children: _jsxs("div", Object.assign({ className: "flex flex-row flex-1" }, { children: [_jsx(EvidenceResults, { coraState: coraState, coraStateUpdater: coraStateUpdater, corpus_filter: corpus_filter, argsInfo: argsInfo, documents: documents, evidence: evidence, results: groupEvidenceByDocument
                        ? documents.map(d => ({ type: "document", value: d }))
                        : evidence.map(e => ({ type: "evidence", value: e })), disabled: disabled, evidenceSearch: evidenceSearch, evidenceState: state, evidenceStateUpdater: stateUpdater, totalEvidenceCount: totalEvidenceCount, filteredBySummary: summariesList && selectedSummaries.length > 0 }), summariesColumn] })) })));
    return (_jsx(Fill, Object.assign({ flexRow: true, className: "justify-center" }, { children: _jsxs(Fill, Object.assign({ className: classes("flex flex-col flex-1 pt-1 ml-4"), style: {
                maxWidth: !summariesColumn ? "50rem" : "76rem",
            } }, { children: [_jsx("div", Object.assign({ className: "w-full flex justify-center" }, { children: _jsx("div", Object.assign({ className: "w-full flex flex-col flex-grow" }, { children: _jsx(EvidenceHeader, { headerSelectors: evidenceHeaderSelectors }) })) })), _jsx(Fill, Object.assign({ flexRow: true }, { children: _jsx(TitledContainer, Object.assign({ className: classes("flex flex-col flex-1 h-full relative", className) }, landmarkIdTag("tour-evidence"), { noScroll: true, bgClassName: "bg-transparent", containerStyleClass: "pt-0" }, { children: _jsxs("div", Object.assign({ className: "flex-1 flex flex-col relative" }, { children: [top, widget && widgetSize ? (_jsxs(Allotment, Object.assign({ vertical: true, className: "h-full", defaultSizes: divider, onChange: setDivider }, { children: [_jsx(Allotment.Pane, Object.assign({ className: "", minSize: widgetSize.min }, { children: _jsx("div", Object.assign({ className: "h-full relative flex flex-col items-stretch" }, { children: widget })) })), _jsx(Allotment.Pane, Object.assign({ minSize: 260 }, { children: _jsxs("div", Object.assign({ className: "h-full relative flex flex-col items-stretch pt-2" }, { children: [actionBar, evidenceResults] })) }))] }))) : (_jsxs(_Fragment, { children: [widget, widget && _jsx(Divider, { className: "my-1" }), actionBar, evidenceResults] }))] })) })) }))] })) })));
}
function EvidenceHeader({ headerSelectors, }) {
    return (_jsx(Bar, Object.assign({ className: "pb-2" }, { children: _jsxs("div", Object.assign({ className: classes("w-full flex justify-between items-start items-center") }, { children: [_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx("div", Object.assign({ className: "font-bold mr-2 select-none" }, { children: "Evidence" })) })), _jsx("div", Object.assign({ className: "flex items-center space-x-2" }, { children: headerSelectors }))] })) })));
}
