import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Findings, Graph, HistoryFull, ManageLists, SettingsGear } from "./Icons";
import { classes } from "../../utils/classes";
import { FilterFilled, QuestionOutlined } from "@ant-design/icons";
import { showCustomListsManager } from "./modals/CustomListsManager/CustomListsManager";
import { useCurrentSource } from "../utils/sourceManagement";
import { updateCurrentSession, useCurrentSession, } from "../utils/sessionManagement";
import { useEmptyFilterFromCoraState } from "../utils/emptyFIlters";
import { useShowDocument360 } from "./Document360";
import { useConfigDialog } from "./Config";
import { Rail } from "../redesign/generic/Rail";
import { ListToggle } from "../redesign/generic/ListToggle";
import { ToggleButton } from "../redesign/generic/ToggleButton";
import { showCombinedHistory } from "./modals/history/CombinedHistory";
import { getCurrentUserInfo } from "../../utils/authInfo";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import { ModalType, useModalState } from "./modals/useModalState";
import { useCoraState } from "./CoraStateProvider";
export function Toolbar() {
    const [coraState, coraStateUpdater] = useCoraState();
    const currentSource = useCurrentSource(coraState);
    const { showFilters, showMyFindings, modelBuilderAvailable, modelBuilderMode } = useCurrentSession();
    const modelBuilderActive = modelBuilderAvailable && modelBuilderMode;
    useEmptyFilterFromCoraState(coraState);
    const showDocument360 = useShowDocument360();
    const wideButtonClass = "flex items-center justify-start pl-2";
    const isNarrow = true;
    const { showModal, modalContainer } = useConfigDialog({ coraStateUpdater });
    const [modalState] = useModalState();
    const topControls = (_jsxs("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: [!modelBuilderAvailable ? (_jsxs(ToggleButton, Object.assign({ onClick: () => {
                    updateCurrentSession({
                        showFilters: !showFilters,
                        modelBuilderMode: false,
                    });
                }, selected: !modelBuilderActive && showFilters, tooltip: "Show Filters", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined, selectedClass: "bg-brand-300" }, { children: [_jsx(FilterFilled, {}), !isNarrow && _jsx(Label, { children: "Filters" })] }))) : (_jsx(ListToggle, { buttonWidth: !isNarrow ? 80 : undefined, buttonClassName: classes(!isNarrow ? wideButtonClass : "flex items-center justify-center", "rounded-md"), style: { padding: "1px" }, activeToggle: modelBuilderActive ? "graph" : showFilters ? "facets" : undefined, toggleEntries: [
                    {
                        key: "facets",
                        icon: (_jsxs(_Fragment, { children: [_jsx(FilterFilled, {}), !isNarrow && _jsx(Label, { children: "Filters" })] })),
                        tooltip: "Show Filters",
                        onClick: () => {
                            updateCurrentSession({
                                showFilters: !showFilters,
                                modelBuilderMode: showFilters,
                            });
                        },
                    },
                    {
                        key: "graph",
                        icon: (_jsxs(_Fragment, { children: [_jsx(Graph, {}), !isNarrow && _jsx(Label, { children: "Graph" })] })),
                        tooltip: "Show Graph",
                        onClick: () => {
                            updateCurrentSession({
                                showFilters: !showFilters,
                                modelBuilderMode: !modelBuilderMode,
                            });
                        },
                    },
                ] })), _jsxs("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: [_jsxs(ToggleButton, Object.assign({ onClick: () => updateCurrentSession({ showMyFindings: !showMyFindings }), tooltip: "Show My Findings", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined, selected: showMyFindings, selectedClass: "bg-brand-300" }, { children: [_jsx(Findings, {}), !isNarrow && _jsx(Label, { children: "History" })] })), _jsxs(ToggleButton, Object.assign({ onClick: () => showCombinedHistory(), tooltip: "Query History", width: !isNarrow ? 80 : undefined, selected: modalState[ModalType.HISTORY], selectedClass: "bg-brand-300", className: !isNarrow ? wideButtonClass : undefined }, { children: [_jsx(HistoryFull, {}), !isNarrow && _jsx(Label, { children: "History" })] })), _jsxs(ToggleButton, Object.assign({ onClick: () => showCustomListsManager(coraState.corpus_ids), tooltip: "List Manager", disabled: !currentSource, selected: modalState[ModalType.LIST_MANAGER], selectedClass: "bg-brand-300", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined }, { children: [_jsx(ManageLists, {}), !isNarrow && _jsx(Label, { children: "Lists" })] }))] })), _jsxs(ToggleButton, Object.assign({ onClick: e => showModal(e.altKey), tooltip: "System Configuration", disabled: !currentSource, selected: modalState[ModalType.CONFIG], selectedClass: "bg-brand-300", width: !isNarrow ? 80 : undefined, style: { color: "transparent" }, className: !isNarrow ? wideButtonClass : undefined }, { children: [_jsx(SettingsGear, {}), !isNarrow && _jsx(Label, { children: "Config" })] }))] })));
    const bottomControls = (_jsxs("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: [_jsx(DropDownMenu, Object.assign({ placement: "right", entries: [
                    {
                        caption: "Help Center",
                        action() {
                            setTimeout(showDocument360, 100);
                        },
                    },
                    {
                        caption: "Submit Feedback",
                        action() {
                            window.open(`https://elementalcognition.zendesk.com/hc/en-us/requests/new?tf_17381088022935=cora_app&tf_anonymous_requester_email=${getCurrentUserInfo().userId}`, "_blank");
                        },
                    },
                ] }, { children: _jsxs(ToggleButton, Object.assign({ tooltip: "Help", width: !isNarrow ? 80 : undefined }, { children: [_jsx(QuestionOutlined, {}), !isNarrow && _jsx(Label, { children: "Help" })] })) })), modalContainer] })));
    return (_jsx(Rail, { style: !isNarrow
            ? {
                width: "96px",
                minWidth: "96px",
                flex: "0 0 0",
            }
            : undefined, narrow: isNarrow, topControls: topControls, bottomControls: bottomControls }));
}
/*
text-accent-100 text-2xl bg-gray-200


      style={
        showFilters
          ? {borderRightWidth: 1, borderColor: getTwColor("gray-300")}
          : undefined
      }

*/
function Label({ children }) {
    return _jsx("span", Object.assign({ className: "ml-1" }, { children: children }));
}
