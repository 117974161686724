import { groupBy } from "lodash";
import { FixedArgs } from "../../utils/coraUtil";
import { NodeType, } from "./DiagramModel";
import { setNodeProperties } from "../../../shared/components/gojs/gojs";
export function categorizeSelection(nodes) {
    const { conceptNodes, statementNodes, typeNodes } = partitionNodes(nodes);
    return {
        conceptNode: conceptNodes[0],
        statementNode: statementNodes[0],
        typeNode: typeNodes[0],
    };
    function partitionNodes(nodes) {
        const { conceptNodes, statementNodes, typeNodes } = groupBy(nodes, i => i.type === NodeType.CONCEPT
            ? "conceptNodes"
            : i.type === NodeType.STATEMENT
                ? "statementNodes"
                : i.type === NodeType.TYPE
                    ? "typeNodes"
                    : "");
        return {
            conceptNodes: (conceptNodes || []),
            statementNodes: (statementNodes || []),
            typeNodes: (typeNodes || []),
        };
    }
}
export function getTypeNode(node, model) {
    if ((node === null || node === void 0 ? void 0 : node.type) === NodeType.TYPE)
        return node;
    if ((node === null || node === void 0 ? void 0 : node.type) === NodeType.CONCEPT) {
        const groupNode = model.findNodeDataForKey(node.group);
        if ((groupNode === null || groupNode === void 0 ? void 0 : groupNode.type) === NodeType.TYPE)
            return groupNode;
    }
    return undefined;
}
export function getTypeNodes(node, model, defaultRole = FixedArgs.CONCEPT_1) {
    const typeNodes = [];
    function add(node, role) {
        const typeNode = getTypeNode(node, model);
        if (typeNode) {
            typeNodes.push({ node: typeNode, role });
        }
    }
    if ((node === null || node === void 0 ? void 0 : node.type) === NodeType.CONCEPT || (node === null || node === void 0 ? void 0 : node.type) === NodeType.TYPE) {
        add(node, defaultRole);
    }
    if ((node === null || node === void 0 ? void 0 : node.type) === NodeType.STATEMENT) {
        node.from && add(node.from, FixedArgs.CONCEPT_1);
        node.to && add(node.to, FixedArgs.CONCEPT_2);
        node.qualifiers.forEach(n => add(n.toNode, n.payload.argName));
        node.context.forEach(n => add(n.toNode, FixedArgs.CONTEXT_CONCEPT));
    }
    return typeNodes;
}
export function getNodeConcepts(n, useOverride = true) {
    return !n
        ? []
        : n.type === NodeType.CONCEPT
            ? [n.concept]
            : n.type === NodeType.TYPE
                ? n.overrideConcepts.length && useOverride
                    ? n.overrideConcepts
                    : n.concepts
                : [];
}
export function getConceptNodeKey(concept, groupKey) {
    return groupKey ? groupKey + ":" + concept.name : concept.name;
}
export function getItemsName(items) {
    return items.length ? `${items[0].name}${items.length > 1 ? "…" : ""}` : "?";
}
export function nodeHasChildren(n) {
    return (n.type === NodeType.SUB_GRAPH ||
        (n.type === NodeType.TYPE && !!n.overrideConcepts.length));
}
export function clearSelectedAndTop(model) {
    setNodeProperties(model, { overrideConcepts: [], topConcepts: null }, node => node.type === NodeType.TYPE);
    model.nodeDataArray
        .filter(n => n.type === NodeType.CONCEPT && n.group)
        .forEach(node => model.removeNodeData(node));
}
function isLoneConstraint(node) {
    return (node.type === NodeType.STATEMENT &&
        node.from &&
        node.qualifiers.length === 0 &&
        node.context.length === 0 &&
        !node.to);
}
export function allLoneConstraints(model) {
    const statementNodes = model.getStatementNodes();
    return statementNodes.length > 0 && statementNodes.every(isLoneConstraint);
}
export function getActiveConstraintId(model, newSelection, currentStatementNodeId) {
    const { typeNode: selectedTypeNode, statementNode: selectedStatementNode, conceptNode: selectedConceptNode, } = categorizeSelection(newSelection.filter(n => !n.disabled));
    const statementNodes = model.getStatementNodes();
    if (statementNodes.length === 1 ||
        (statementNodes.length && statementNodes.every(isLoneConstraint)))
        return statementNodes[0].key;
    const currentStatementNodes = findCurrentStatementNodes();
    const activeStatementNode = (currentStatementNodeId &&
        currentStatementNodes.find(s => s.key === currentStatementNodeId)) ||
        currentStatementNodes[0] ||
        null;
    return (activeStatementNode === null || activeStatementNode === void 0 ? void 0 : activeStatementNode.key) || null;
    function findCurrentStatementNodes() {
        if (selectedStatementNode) {
            return [selectedStatementNode];
        }
        const selectedKey = (selectedConceptNode === null || selectedConceptNode === void 0 ? void 0 : selectedConceptNode.group)
            ? model.findNodeDataForKey(selectedConceptNode === null || selectedConceptNode === void 0 ? void 0 : selectedConceptNode.group).key
            : selectedTypeNode === null || selectedTypeNode === void 0 ? void 0 : selectedTypeNode.key;
        return !selectedKey
            ? []
            : statementNodes
                .map(statement => {
                var _a, _b, _c;
                return ({
                    statement,
                    priority: ((_a = statement.from) === null || _a === void 0 ? void 0 : _a.key) === selectedKey
                        ? 1
                        : ((_b = statement.to) === null || _b === void 0 ? void 0 : _b.key) === selectedKey
                            ? 2
                            : ((_c = statement.context) === null || _c === void 0 ? void 0 : _c.find(n => n.to === selectedKey))
                                ? 3
                                : statement.qualifiers.find(l => l.to === selectedKey)
                                    ? 4
                                    : 0,
                });
            })
                .filter(s => s.priority > 0)
                .sort((a, b) => a.priority - b.priority)
                .map(s => s.statement);
    }
}
