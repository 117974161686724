import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { CONTEXT_CONCEPT_COLOR, CONTEXT_CONCEPT_COLOR_VALS } from "../lf";
import { FacetsForConcepts } from "./FacetsForConcepts";
import { getCorpusFilter } from "../../utils/semanticSearchUtil";
import { ConceptsPicker } from "../picker/PickerFields";
import { pick, uniq } from "lodash";
import { EXTRA_CONCEPTS_CAPTION } from "../../utils/coraUtil";
import { invalidCorpusIds } from "../../utils/sourceManagement";
import { FacetsTabContent } from "./FacetsTabContent";
import { referenceCreator, ReferenceType, useCurrentExtraConcepts, } from "../../utils/references";
import { getSelectionModelFactory } from "./ConceptFacetSelectionModel";
import { BrandButton, } from "../../../shared/components/buttons/Buttons";
const CAPTION = EXTRA_CONCEPTS_CAPTION();
export function WatchlistFacets({ coraState, coraStateUpdater, feedback, setFeedback, }) {
    const { corpus_ids, extraConcepts, extraConceptsOverride } = coraState;
    const corpusFilter = getCorpusFilter({
        corpus_ids,
    });
    const { conceptClustering, extraConceptsByCorpusId, expandedFacetGroups } = useCurrentSession();
    const currentExtraConcepts = useCurrentExtraConcepts(coraState);
    React.useEffect(() => {
        const currentNames = currentExtraConcepts.map(c => c.name);
        const currentNamesSet = new Set(currentNames);
        coraStateUpdater({
            extraConcepts: extraConcepts.filter(c => currentNamesSet.has(c.name)),
            extraConceptsOverride: pick(extraConceptsOverride, currentNames),
        });
    }, [currentExtraConcepts, extraConcepts, extraConceptsOverride]);
    const disabled = invalidCorpusIds(coraState.corpus_ids);
    return (_jsx(FacetsTabContent, { header: conceptsPicker(), description: !currentExtraConcepts.length &&
            `Run a Research Query to add tangential concepts that might be mentioned in the surrounding document for each result. If any are found, you can select them to filter your results.`, facets: () => (_jsx(FacetsForConcepts, { coraState: coraState, coraStateUpdater: coraStateUpdater, relevantConcepts: currentExtraConcepts, conceptsKey: "extraConcepts", conceptsOverridesKey: "extraConceptsOverride", possibleConceptsKey: "extraConceptsPossible", otherConceptsKeys: ["concepts1", "concepts2", "contextConcepts"], otherConceptsOverrideKeys: [
                "concepts1Override",
                "concepts2Override",
                "contextConceptsOverride",
            ], title: CAPTION, tagColor: CONTEXT_CONCEPT_COLOR, checkboxClass: "", feedback: feedback, setFeedback: setFeedback, selectionModelFactory: getSelectionModelFactory(true), hideNoSubconcepts: false, nameProvider: referenceCreator(0, ReferenceType.WATCHLIST) })) }));
    function conceptsPicker() {
        const dropDownTrigger = (_jsx("div", Object.assign({ className: "flex justify-center items-center" }, { children: _jsx(BrandButton, Object.assign({ size: "small" }, { children: "Manage Watchlist..." })) })));
        return disabled ? (dropDownTrigger) : (_jsx(ConceptsPicker, { corpus_ids: coraState.corpus_ids, disabled: !corpus_ids.length, clustering_mode: conceptClustering, corpus_filter: corpusFilter, title: CAPTION, selected: currentExtraConcepts, setSelected: selected => {
                const selectedNames = new Set(selected.map(c => c.name));
                updateCurrentSession(Object.assign({ extraConceptsByCorpusId: Object.assign(Object.assign({}, extraConceptsByCorpusId), { [corpus_ids[0]]: selected }) }, (selected.length > 0 && {
                    expandedFacetGroups: uniq(expandedFacetGroups.concat(EXTRA_CONCEPTS_CAPTION())),
                })));
                coraStateUpdater({
                    extraConcepts: coraState.extraConcepts.filter(c => selectedNames.has(c.name)),
                    extraConceptsOverride: Object.fromEntries(Object.entries(coraState.extraConceptsOverride).filter(([key]) => selectedNames.has(key))),
                });
            }, placeholder: "select concept(s)\u2026", tagColorVals: CONTEXT_CONCEPT_COLOR_VALS, noSimilar: true, customDropDownTrigger: dropDownTrigger, placement: "right", inputTrigger: false, dropdownContainerClassName: " " }));
    }
}
