import { isEqual } from "lodash";
import React from "react";
export function queryLoader(loader, requestExecutor, coordinator) {
    return function loadQuery(onValue, params, previousParams) {
        const id = coordinator.newFrame();
        if (params === undefined) {
            coordinator.newMessage(onValue, id, {
                remote: { value: null, loading: false },
                params,
                previousParams,
            }, true);
        }
        else if (!isEqual(previousParams, params)) {
            coordinator.newMessage(onValue, id, {
                remote: { loading: true, value: null },
                params,
                previousParams,
            }, false);
            requestExecutor({
                exec() {
                    return loader(params);
                },
                onResponse(value) {
                    coordinator.newMessage(onValue, id, {
                        remote: { loading: false, value },
                        params,
                        previousParams,
                    }, true);
                },
                onError(error) {
                    console.error(error);
                    coordinator.newMessage(onValue, id, {
                        remote: { loading: false, value: null, error },
                        params,
                        previousParams,
                    }, true);
                },
            });
        }
    };
}
export function useQueryLoader(loader, requestExecutorProvider, messageCoordinatorSupplier) {
    const coordinator = React.useMemo(messageCoordinatorSupplier, []);
    const executor = React.useCallback(requestExecutorProvider(), []);
    React.useEffect(() => coordinator.destroy, []);
    return React.useMemo(() => queryLoader(loader, executor, coordinator), []);
}
