var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bgBrand700, brandButtonClass } from "../lf";
import { EvidenceType, FeatureIdentifier, FeedbackType, } from "../../api/types";
import React from "react";
import { showNotification } from "../../../shared/components/notifications";
import { pushModal } from "../../../shared/components/Modal";
import { ActionButton } from "../../../shared/components/buttons/Buttons";
import { ThumbsDown } from "../Icons";
import { suggestEvidence, useSuggestedEvidenceFor, } from "../../utils/suggestedEvidence";
import { useCurrentSource } from "../../utils/sourceManagement";
import { SelectItemsModal } from "./SelectItemsModal";
import { getAvailableEvidence } from "../../utils/findings";
import { EvidenceFooter, EvidenceHeader } from "../InfoRow";
import { sendFeedback } from "../../utils/feedback";
import { getEvidenceCorpusFilter } from "../../utils/evidence";
import { sortMetadata } from "../../utils/metatada";
import { uuidv4 } from "../../../utils/util";
import { showPopupMenu } from "../../../shared/actions/PopupMenu";
import { DefaultMarkDown } from "../../../shared/components/MarkDown";
function AttachSuggestedEvidence({ coraState, finding, onSelect, addEvidenceToFinding, }) {
    const source = useCurrentSource(coraState);
    const [suggested, setSuggested] = useSuggestedEvidenceFor(finding.id);
    const items = getAvailableEvidence(suggested === null || suggested === void 0 ? void 0 : suggested.value, finding.payload.evidence);
    const corpus_filter = getEvidenceCorpusFilter(coraState);
    React.useEffect(() => {
        suggestEvidence(finding);
    }, [finding, suggested]);
    return (_jsx(SelectItemsModal, { items: items, noFilter: true, brandingColorClass: bgBrand700, buttonColorClass: brandButtonClass, title: "Suggested Evidence", buttonCaption: "Attach Evidence", noItemsMessage: "No Suggestions", loading: suggested === null || suggested === void 0 ? void 0 : suggested.loading, contextText: finding.payload.selectedText, onSelected: onSelect, infoExtractor: (item, idx, actions, items, filterText, isSelected) => ({
            title: (_jsxs("div", Object.assign({ className: "divide-y" }, { children: [_jsx(EvidenceHeader, { evidence: item, highlight: item.text, metadataSorter: sortMetadata(source), onSelectionChangeInDocView: ({ rect, text, docText, setHighlights, }) => {
                            showPopupMenu({
                                top: rect.top,
                                left: rect.left + rect.width / 2,
                                entries: [
                                    {
                                        caption: "Attach Evidence to Finding",
                                        disabled: false,
                                        action() {
                                            var _a;
                                            addEvidenceToFinding(finding, [
                                                {
                                                    text,
                                                    id: uuidv4(),
                                                    instances: item.instances,
                                                    evidence_type: EvidenceType.SUPPORTING,
                                                    argument_spans: [],
                                                    highlight_spans: [],
                                                    is_precise: true,
                                                },
                                            ]);
                                            (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
                                            showNotification("Evidence attached to finding.");
                                            setHighlights(highlights => highlights.concat(text));
                                        },
                                    },
                                ],
                            });
                        }, suffix: actions }), _jsx("div", Object.assign({ className: "text-base pt-2" }, { children: _jsx(DefaultMarkDown, { text: item.text }) }))] }))),
            banners: (_jsx(EvidenceFooter, { evidence: item, className: "w-full", selected: isSelected, metadataSorter: sortMetadata(source), suffix: _jsx(ActionButton, Object.assign({ onClick: () => __awaiter(this, void 0, void 0, function* () {
                        yield sendFeedback(FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/suggest/findings", FeedbackType.NEGATIVE, item, corpus_filter);
                        showNotification("Feedback sent");
                        setSuggested(items => (items === null || items === void 0 ? void 0 : items.filter(i => i !== item)) || []);
                    }) }, { children: _jsx(ThumbsDown, {}) })) })),
        }) }));
}
export function showAttachSuggestedEvidence(props) {
    pushModal({
        title: "Additional Evidence",
        titleColorClass: "bg-gray-500",
        content: _jsx(AttachSuggestedEvidence, Object.assign({}, props)),
    });
}
