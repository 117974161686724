import React from "react";
import { showPopupMenu } from "../../actions/PopupMenu";
import { TRUE } from "../../../utils/filters";
import Lazy from "lazy.js";
import * as go from "gojs";
go.Diagram.licenseKey =
    "73f942e2b56628a800ca0d2b113f69ee1bb37b649ed71af95e5341a4ff186e15729fe97c02d4dfc0d2ff4ea8137bc18cd4956d2e855c036eb737d38e13e394e9b13670b10b0f588da2";
const $ = go.GraphObject.make;
const DEFAULT_DIAGRAM_CONFIGURATION = {
    allowDelete: false,
    allowCopy: false,
    layout: createLayout(true),
    maxScale: 3,
    minScale: 0.2,
    "undoManager.isEnabled": true,
    initialContentAlignment: go.Spot.Center,
    contentAlignment: go.Spot.Center,
    initialViewportSpot: go.Spot.Center,
    autoScale: go.Diagram.Uniform,
    // viewportSpot: go.Spot.Center,
    // maxSelectionCount: 1,
    padding: new go.Margin(16),
    "draggingTool.isEnabled": false, // disable node movement
};
export function createDiagram() {
    return $(go.Diagram, DEFAULT_DIAGRAM_CONFIGURATION);
}
export function useDiagram(div, configure) {
    const diagramEventHandlers = React.useRef([]);
    const diagram = React.useMemo(() => {
        const d = $(go.Diagram, DEFAULT_DIAGRAM_CONFIGURATION);
        const diagram = d;
        diagram.onRemove = n => diagramEventHandlers.current.forEach(h => { var _a; return (_a = h.onRemove) === null || _a === void 0 ? void 0 : _a.call(h, n); });
        diagram.onMouseEnter = (e, obj) => diagramEventHandlers.current.forEach(h => { var _a; return (_a = h.onMouseEnter) === null || _a === void 0 ? void 0 : _a.call(h, e, obj); });
        diagram.onMouseLeave = (e, obj) => diagramEventHandlers.current.forEach(h => { var _a; return (_a = h.onMouseLeave) === null || _a === void 0 ? void 0 : _a.call(h, e, obj); });
        diagram.addEventHandlers = handlers => {
            diagramEventHandlers.current.push(handlers);
            return () => diagramEventHandlers.current.splice(diagramEventHandlers.current.findIndex(h => h === handlers), 1);
        };
        configure === null || configure === void 0 ? void 0 : configure(diagram);
        return diagram;
    }, [div]);
    React.useEffect(() => {
        if (div) {
            diagram.div = div;
            diagram.requestUpdate();
            return () => {
                diagram.div = null;
            };
        }
    }, [div, diagram]);
    return diagram;
}
export function diagramTransaction(diagram, exec) {
    try {
        diagram.startTransaction();
        exec();
    }
    finally {
        diagram.commitTransaction();
    }
}
function packing(packMedian, packStraighten, packExpand) {
    return (packMedian ? 4 : 0) | (packStraighten ? 2 : 0) | (packExpand ? 1 : 0);
}
export function createLayout(horizontal = true) {
    return $(go.LayeredDigraphLayout, Object.assign(Object.assign({ iterations: 10, setsPortSpots: false, 
        // cycleRemoveOption: go.LayeredDigraphLayout.CycleDepthFirst,
        // cycleRemoveOption: go.LayeredDigraphLayout.CycleGreedy,
        // layeringOption: go.LayeredDigraphLayout.LayerOptimalLinkLength,
        layeringOption: go.LayeredDigraphLayout.LayerLongestPathSource }, (horizontal
        ? {
            layerSpacing: 30,
            direction: 0,
        }
        : {
            direction: 270,
            layerSpacing: 32,
        })), { packOption: packing(false, false, true) }));
}
export function useGraphPopupMenu(diagram, eventName, entriesProvider, dependencies) {
    React.useEffect(() => {
        if (!diagram)
            return;
        const listener = () => {
            const entries = entriesProvider(diagram);
            if (entries.length) {
                const { clientX, clientY } = diagram.lastInput.event;
                showPopupMenu({
                    top: clientY,
                    left: clientX,
                    entries,
                });
            }
        };
        diagram.addDiagramListener(eventName, listener);
        return () => {
            diagram.removeDiagramListener(eventName, listener);
        };
    }, [diagram, ...dependencies]);
}
export function setNodeProperties(model, properties, selector = TRUE) {
    setElementProperties(model, properties, "findNodeDataForKey", selector);
}
export function setLinkProperties(model, properties, selector = TRUE) {
    setElementProperties(model, properties, "findLinkDataForKey", selector);
}
function setElementProperties(model, properties, fn, selector = TRUE) {
    const items = typeof selector === "string"
        ? Lazy([selector])
        : Array.isArray(selector)
            ? Lazy(selector).map(i => (typeof i === "string" ? i : i.key))
            : typeof selector === "function"
                ? Lazy(model.nodeDataArray.filter(selector)).map(n => n.key)
                : Lazy([selector.key]);
    items.each(key => {
        const data = model[fn](key);
        const props = typeof properties === "function" ? properties(data) : properties;
        Object.entries(props).forEach(([key, value]) => model.setDataProperty(data, key, value));
    });
}
export function useDiagramChangeListener(diagram, key, listener) {
    React.useEffect(() => {
        diagram.addDiagramListener(key, listener);
        return () => {
            diagram.removeDiagramListener(key, listener);
        };
    }, [diagram, key, listener]);
}
export function getPartBounds(diagram, key) {
    const part = diagram.findPartForKey(key);
    const nw = part && diagram.transformDocToView(part.position);
    const se = part &&
        diagram.transformDocToView(new go.Point(part.position.x + part.measuredBounds.width, part.position.y + part.measuredBounds.height));
    return nw && se
        ? {
            left: nw.x,
            top: nw.y,
            width: se.x - nw.x,
            height: se.y - nw.y,
        }
        : undefined;
}
export function getNodeBounds(diagram, nodeKey) {
    const part = diagram.findPartForKey(nodeKey);
    const bounds = part.getDocumentBounds();
    const p1 = diagram.transformDocToView(new go.Point(bounds.x, bounds.y));
    const p2 = diagram.transformDocToView(new go.Point(bounds.x + bounds.width, bounds.y + bounds.height));
    return { left: p1.x, top: p1.y, width: p2.x - p1.x, height: p2.y - p1.y };
}
