var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDebouncedValue, useMemoIfEqual } from "../../../utils/hooks";
import { default_count } from "../../utils/semanticSearchUtil";
import React from "react";
import { Set } from "immutable";
import { FocusContainer } from "../FocusContainer";
import { classes } from "../../../utils/classes";
import { Button, Checkbox, Spin } from "antd";
import { ValidatedInput } from "../../../shared/components/InputValidation";
import { CollapseButton } from "./facetButtons";
import { DropDown } from "../../../shared/components/DropDown";
import { FacetTree } from "./FacetTree";
import { useRemoteQuery } from "../../../utils/query/remoteQuery";
import { NOT } from "../../../utils/filters";
import { FacetContainer } from "./FacetContainer";
import { uuidv4 } from "../../../utils/util";
import "./Facet.less";
import { Tooltip } from "../../../shared/components/Tooltip";
export function Facet(_a) {
    var { placeholder, selected, collapsed, setCollapsed, loaderParamsGetterExtraArg, disabled = false, toOption, getTitle, titlePrefix, onlyLoadWhenExpanded, noExpansion, loading, name, noShowMore, max_count, className = "p-2 ", isDropDown, hierarchical, minFilterLength, id, itemInCollectionFilter, itemsOverride, emptyText = "No Values", setReq, reqSelected } = _a, optionProps = __rest(_a, ["placeholder", "selected", "collapsed", "setCollapsed", "loaderParamsGetterExtraArg", "disabled", "toOption", "getTitle", "titlePrefix", "onlyLoadWhenExpanded", "noExpansion", "loading", "name", "noShowMore", "max_count", "className", "isDropDown", "hierarchical", "minFilterLength", "id", "itemInCollectionFilter", "itemsOverride", "emptyText", "setReq", "reqSelected"]);
    const uuid = React.useMemo(() => uuidv4(), []);
    id = id || uuid;
    const { facetLoader, loaderParamsGetter, valueToSelection } = optionProps;
    const [dropdownVisible, onDropdownVisible] = React.useState(false);
    const isCollapsed = isDropDown ? !dropdownVisible : collapsed;
    const [prefix, setPrefix] = React.useState("");
    const debouncedPrefix = useDebouncedValue(prefix.trim()).trim();
    const [showMore, setShowMore] = React.useState(false);
    const count = noShowMore || showMore ? max_count : default_count;
    function getName(s) {
        return toOption(s).label;
    }
    const [load, setLoad] = React.useState(onlyLoadWhenExpanded && isCollapsed ? false : true);
    const usingitemsOverride = itemsOverride && !debouncedPrefix.trim().length;
    const params = useMemoIfEqual(disabled || noExpansion || usingitemsOverride || !load
        ? undefined
        : loaderParamsGetter({
            prefix: debouncedPrefix.trim(),
            maxCount: count,
        }));
    React.useEffect(() => {
        if (onlyLoadWhenExpanded && !isCollapsed) {
            setLoad(true);
        }
    }, [isCollapsed]);
    const remote = useRemoteQuery({
        loader: facetLoader,
        params,
    });
    const values = itemsOverride || remote.value || [];
    const thereAreNoMore = showMore && values.length < count;
    const thereAreLess = values.length > default_count;
    const showMoreButton = !itemsOverride &&
        !noShowMore &&
        (values.length >= count || showMore) &&
        !(thereAreNoMore && !thereAreLess);
    const options = React.useMemo(() => {
        const filter = itemInCollectionFilter(selected);
        const items = isCollapsed
            ? selected
            : selected.concat(values.map(valueToSelection).filter(NOT(filter)));
        return items.map(toOption);
    }, [values, selected, isCollapsed]);
    const selectedNames = React.useMemo(() => Set(selected.map(getName)), [selected]);
    const actualLoading = loading || (!usingitemsOverride && remote.loading);
    const isHierarchical = hierarchical && !collapsed;
    const facetTree = (_jsx(FacetTree, Object.assign({ id: id }, optionProps, { hierarchical: isHierarchical, selected: selected, options: options, params: params, values: values, prefix: prefix, toOption: toOption, selectedNames: selectedNames, max_count: count, itemInCollectionFilter: itemInCollectionFilter }), prefix +
        showMore +
        (isHierarchical &&
            optionProps.subEntriesLoader &&
            optionProps.subEntriesLoaderParamsGetter
            ? "Hierarchical"
            : "Flat")));
    const req = setReq && (_jsx(Tooltip, Object.assign({ content: `This term is required in the evidence.` }, { children: _jsxs("div", Object.assign({ className: "flex space-x-1 items-center cursor-pointer" }, { children: [_jsx("label", Object.assign({ className: "text-tiny cursor-pointer", htmlFor: uuid }, { children: "Req" })), _jsx(Checkbox, { id: uuid, defaultChecked: reqSelected, checked: reqSelected, onChange: e => setReq(e.target.checked) })] })) })));
    const empty = disabled || !options.length;
    const noMatched = prefix.trim().length > 0 && !(values === null || values === void 0 ? void 0 : values.length);
    const emptyMessage = actualLoading
        ? undefined
        : noMatched
            ? `No Matches`
            : undefined;
    const entries = facetTree;
    const showFilterInput = prefix ||
        ((!emptyMessage || noMatched) &&
            !noExpansion &&
            Boolean(values.length) &&
            load);
    const filterInput = showFilterInput && (_jsx(FocusContainer, Object.assign({ className: classes("focus-within:border-brand-400 focus-within:bg-white", prefix.trim().length > 0 && "border-brand-400") }, { children: _jsx(ValidatedInput, { size: "small", bordered: false, placeholder: "filter\u2026", allowClear: true, value: prefix, onChange: prefix => {
                if (isCollapsed) {
                    setCollapsed && setCollapsed(false);
                }
                setPrefix(prefix);
            }, disabled: disabled, minFilterLength: minFilterLength }) })));
    const content = (_jsxs(_Fragment, { children: [filterInput, isCollapsed || noExpansion || loading || !values.length ? (entries) : (_jsx("div", Object.assign({ className: "flex flex-col mt-2" }, { children: emptyMessage ? (setReq ? (_jsx("div", Object.assign({ className: "flex flex-col items-stretch divide-y" }, { children: _jsx("div", Object.assign({ className: "px-2 italic text-gray-400" }, { children: emptyMessage })) }))) : (_jsx("div", Object.assign({ className: "px-2 italic text-gray-400" }, { children: emptyMessage })))) : (_jsxs(_Fragment, { children: [entries, showMoreButton && (_jsx(Button, Object.assign({ className: "text-left self-start italic text-tiny", type: "link", size: "small", onClick: () => setShowMore(s => !s), disabled: disabled }, { children: showMore ? "less…" : "more…" })))] })) })))] }));
    const countStr = ` (${values.length >= count ? "+" + count : values.length})`;
    const expansionButton = noExpansion ? null : actualLoading ? (_jsx(Spin, { size: "small" })) : (setCollapsed && (_jsx(CollapseButton, { className: params && !values.length && !loading ? "invisible" : "", collapsed: isCollapsed, onToggle: () => {
            if (!isDropDown) {
                setCollapsed(!isCollapsed);
                if (isCollapsed) {
                    setTimeout(() => {
                        var _a;
                        (_a = document
                            .getElementById(id)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "smooth" });
                    }, 100);
                }
            }
        } })));
    if (isDropDown) {
        const titleTail = (_jsx("div", Object.assign({ className: classes("flex flex-1 space-x-1 items-center justify-between w-full", !noExpansion && (!disabled ? "cursor-pointer" : "cursor-not-allowed")) }, { children: getTitle(selected.length ? `(${selected.length})` : "", disabled) })));
        return (_jsxs("div", Object.assign({ id: id, className: "flex-1 flex justify-between items-center w-full px-2 py-1 bg-gray-100 rounded-lg font-bold flex-1 max-w-[216px] space-x-1" }, { children: [expansionButton, titlePrefix, noExpansion || disabled ? (titleTail) : (_jsx(DropDown, Object.assign({ hasClose: true, title: name + countStr, onVisibleChange: onDropdownVisible, getContent: () => _jsx("div", Object.assign({ className: classes("p-2") }, { children: content })), containerClassName: "flex-1", placement: "bottom" }, { children: titleTail })))] })));
    }
    return (_jsx(FacetContainer, Object.assign({ id: id, containerStyleClass: classes("rounded-lg", className), title: getTitle((onlyLoadWhenExpanded && isCollapsed) || noExpansion ? "" : countStr, disabled), titlePrefix: _jsxs(_Fragment, { children: [titlePrefix, expansionButton] }), headerRight: req }, { children: content })));
}
