var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CRCDirection } from "../api/types";
import { DEFAULT_CRC_DIRECTION, EMPTY_CORA_STATE } from "./coraState";
import { createRelation } from "./semanticSearchUtil";
import { getAllCorpora } from "./sourceManagement";
import { isEqual } from "lodash";
import { getStateFromQueryParams } from "./codedParamsState";
import { message } from "antd";
import { getConceptsFromKBIds } from "../extensions/KBLinks";
const EMPTY_USER_FRIENDLY_PARAMS = {
    concept1: "",
    relation: "",
    concept2: "",
    corpus_id: "",
    directed: "false",
};
export function getCoraStateFromUserFriendlyParams(params, corpus_ids) {
    return __awaiter(this, void 0, void 0, function* () {
        const { concept1, relation, concept2, corpus_id, directed } = Object.assign(Object.assign({}, EMPTY_USER_FRIENDLY_PARAMS), params);
        const [validatedCorpusIds] = validateCorpusIds(toParts(corpus_id), corpus_ids);
        const [concepts1, unknown1, concepts2, unknown2] = yield getConcepts(validatedCorpusIds);
        if (unknown1.length + unknown2.length > 0) {
            message.error(`Unknown concepts: ${[...unknown1, ...unknown2].join(", ")}`);
            console.warn("Unknown concepts", [...unknown1, ...unknown2]);
        }
        const coraState = Object.assign(Object.assign({}, EMPTY_CORA_STATE), { concepts1,
            concepts2, relations: toParts(relation).map(createRelation), crcDirection: directed === "true"
                ? CRCDirection.C1C2
                : directed === "false"
                    ? CRCDirection.BOTH
                    : DEFAULT_CRC_DIRECTION, corpus_ids: validatedCorpusIds });
        return {
            coraState,
            corpus_ids: validatedCorpusIds,
            hasUrlParams: !isEqual(coraState, EMPTY_CORA_STATE),
        };
        function getConcepts(corpus_ids) {
            return __awaiter(this, void 0, void 0, function* () {
                const concept1Names = toParts(concept1);
                const concept2Names = toParts(concept2);
                const conceptNames = [...concept1Names, ...concept2Names];
                const concepts = yield getConceptsFromKBIds(conceptNames, corpus_ids);
                return [
                    ...getValidConcepts(concept1Names, concepts.slice(0, concept1Names.length)),
                    ...getValidConcepts(concept2Names, concepts.slice(concept1Names.length)),
                ];
                function getValidConcepts(names, concepts) {
                    return concepts.reduce((acc, concept, idx) => {
                        if (concept) {
                            acc[0].push(concept);
                        }
                        else {
                            acc[1].push(names[idx]);
                        }
                        return acc;
                    }, [[], []]);
                }
            });
        }
    });
}
function validateCorpusIds(corpusIdsFromParams, corpus_ids) {
    const corpora = getAllCorpora();
    const validated = (corpusIdsFromParams.length ? corpusIdsFromParams : corpus_ids).filter(id => corpora[id]);
    if (corpusIdsFromParams.length &&
        validated.length !== corpusIdsFromParams.length) {
        message.error(`Invalid corpus_id: ${corpusIdsFromParams.join(", ")}`);
        console.warn("Invalid corpus_id", corpusIdsFromParams);
    }
    return [validated, corpusIdsFromParams];
}
export function getCoraStateFromUrl(params, corpus_ids = []) {
    return __awaiter(this, void 0, void 0, function* () {
        const fromFriendly = yield getCoraStateFromUserFriendlyParams(params, corpus_ids);
        if (!isEqual(fromFriendly, EMPTY_CORA_STATE))
            return fromFriendly;
        const coraState = yield getStateFromQueryParams(params, EMPTY_CORA_STATE);
        return {
            coraState,
            corpus_ids: validateCorpusIds(coraState.corpus_ids, corpus_ids)[0],
            hasUrlParams: false,
        };
    });
}
function toParts(field) {
    return field.split(",").filter(t => t.trim() !== "");
}
