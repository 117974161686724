import React from "react";
import { useParams } from "../../../utils/hooks";
import Immutable, { Set } from "immutable";
const INITIAL_EVIDENCE_STATE = {
    selection: Set(),
    highlight: Set(),
    feedback: {},
};
export function useEvidenceState() {
    const [{ selection, highlight, feedback }, updateParams] = useParams(INITIAL_EVIDENCE_STATE);
    const itemHighlighted = React.useCallback((evidenceId) => highlight.toArray().some(s => s === evidenceId), [highlight]);
    const updateHighlight = React.useCallback((evidenceIds, action) => {
        updateParams({
            highlight: evidenceIds.reduce((acc, eId) => {
                if (action === "add" && !acc.toArray().some(sId => sId === eId))
                    return acc.add(eId);
                if (action === "remove" && acc.toArray().some(sId => sId === eId))
                    return acc.filter(sId => sId !== eId);
                return acc;
            }, highlight),
        });
    }, [highlight, updateParams]);
    const clearHighlight = React.useCallback(() => updateParams({ highlight: Set() }), [updateParams]);
    const itemSelected = React.useCallback((evidenceId) => selection.toArray().some(s => s === evidenceId), [selection]);
    const someSelected = React.useCallback((evidenceIds) => evidenceIds.some(eId => selection.toArray().some(sId => sId === eId)) ||
        false, [selection]);
    const everySelected = React.useCallback((evidenceIds) => (evidenceIds.length > 0 &&
        evidenceIds.every(eId => selection
            .toArray()
            .map(sId => sId)
            .includes(eId))) ||
        false, [selection]);
    const updateEvidence = React.useCallback((evidenceIds, action) => {
        updateParams({
            selection: action === "replace"
                ? Immutable.Set(evidenceIds)
                : evidenceIds.reduce((acc, eId) => {
                    if (action === "add" && !acc.toArray().some(sId => sId === eId))
                        return acc.add(eId);
                    if (action === "remove" &&
                        acc.toArray().some(sId => sId === eId))
                        return acc.filter(sId => sId !== eId);
                    return acc;
                }, selection),
        });
    }, [selection, updateParams]);
    return [
        { selection, highlight, feedback },
        {
            update: updateParams,
            itemHighlighted,
            updateHighlight,
            clearHighlight,
            itemSelected,
            someSelected,
            everySelected,
            updateEvidence,
            clearSelected: () => updateParams({ selection: Set() }),
        },
    ];
}
