import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { Graph } from "../Icons";
import { SelectionButton } from "../../../shared/components/buttons/SelectionButton";
import { DMParamsDiagram } from "./DMParamsDiagram";
import { useEffectIfDifferent } from "../../../utils/hooks";
export function useDMParamsDiagramAddOn({ coraState, coraStateUpdater, argsInfo, index, }) {
    const { showModelGraph, showTrends, queryGraph, modelBuilderAvailable } = useCurrentSession();
    React.useEffect(() => {
        showTrends && updateCurrentSession({ showModelGraph: false });
    }, [showTrends]);
    const show = showModelGraph && queryGraph && !modelBuilderAvailable;
    useEffectIfDifferent(() => {
        if (!show) {
            coraStateUpdater({
                concepts1Possible: null,
                concepts2Possible: null,
                contextConceptsPossible: null,
            });
        }
    }, [], [show]);
    return {
        name: "modelGraph",
        index,
        selector(key) {
            return (queryGraph &&
                !modelBuilderAvailable && (_jsx(SelectionButton, { icon: _jsx(Graph, {}), tooltip: "View Graph", tooltipPlacement: "top", selected: showModelGraph, onMouseDown: () => updateCurrentSession({
                    showModelGraph: !showModelGraph,
                    showTrends: !showModelGraph ? false : showTrends,
                }) }, key)));
        },
        widgetSize: { min: 100, max: 2000 },
        widget() {
            return show ? _jsx(DMParamsDiagram, { argsInfo: argsInfo }) : null;
        },
    };
}
