import { CRCDirection, } from "../../api/types";
import { getConceptIds, mergeNamedMembers, mergeRelationClusters, TOP_RESULTS_KEY, } from "../../utils/semanticSearchUtil";
import { textComparator } from "../../../utils/comparators";
import { keyBy, groupBy } from "lodash";
import Lazy from "lazy.js";
import { AND } from "../../../utils/filters";
import { alg, Graph } from "graphlib";
function createNode(coraState, c, isType, isTranslucent) {
    return {
        key: c.name,
        label: c.name,
        concept: c,
        isType,
        coraState,
        isTranslucent,
    };
}
function getEdgeKey(corpus_ids, c1, c2, r = "") {
    return corpus_ids.concat([c1, c2].sort(textComparator)).concat([r]).join("|");
}
function createConceptIndex() {
    const items = [];
    const index = {};
    function add(item) {
        var _a;
        if (!item.members || !item.members.length)
            return;
        const idx = (_a = Lazy(getConceptIds(item))
            .map(id => { var _a; return (_a = index[id]) !== null && _a !== void 0 ? _a : -1; })
            .find(idx => idx >= 0)) !== null && _a !== void 0 ? _a : items.length;
        if (idx === items.length) {
            items.push(item);
        }
        else {
            items[idx] = {
                name: items[idx].name,
                members: items[idx].members.concat(item.members),
            };
        }
        getConceptIds(items[idx]).forEach(id => (index[id] = idx));
    }
    return {
        get(item) {
            // console.log("ConceptFilterItem", item)
            if (!item.members || !item.members.length)
                return undefined;
            return items[index[item.members[0].id]];
        },
        add(items) {
            items.forEach(add);
        },
    };
}
export function filterGraph(model, nodeFilter, edgeFilter) {
    const nFilter = AND(nodeFilter);
    const nodes = Object.values(model.nodes).filter(nFilter);
    const nodesNyKey = keyBy(nodes, "key");
    function validEdge({ from, to }) {
        return Boolean(nodesNyKey[from] && nodesNyKey[to]);
    }
    const edges = Object.values(model.edges).filter(AND(validEdge, edgeFilter));
    return { nodes, edges };
}
export function filterOutEmptyNodesWithNoEdges(model) {
    const { nodes, edges } = model;
    const emptyNodeKeys = new Set(Object.values(nodes)
        .filter(n => n.isEmpty)
        .map(n => n.key));
    const emptyNodesWithEdges = new Set();
    Object.values(edges).forEach(e => {
        if (emptyNodeKeys.has(e.from))
            emptyNodesWithEdges.add(e.from);
        if (emptyNodeKeys.has(e.to))
            emptyNodesWithEdges.add(e.to);
    });
    return filterGraph(model, ({ key }) => {
        return !emptyNodeKeys.has(key) || emptyNodesWithEdges.has(key);
    });
}
export function createModel(history, translucentNodeKeys) {
    const conceptIndex = createConceptIndex();
    history.forEach(c => {
        conceptIndex.add(mergeNamedMembers(c.concepts1));
        conceptIndex.add(mergeNamedMembers(c.concepts2));
        Object.values(c.concepts1Override).forEach(c => conceptIndex.add(c));
        Object.values(c.concepts2Override).forEach(c => conceptIndex.add(c));
    });
    const typeConcepts = new Set();
    const { nodes, edges } = history.reduce((model, coraState) => {
        const { nodes, edges } = model;
        function getOrCreateEmpty(isC1, relation, other) {
            var _a;
            if (!relations.length)
                return [];
            const r = !(relation === null || relation === void 0 ? void 0 : relation.length)
                ? undefined
                : mergeRelationClusters(relation)[0];
            const key = (isC1 ? "C1:" : "C2:") +
                r.name +
                ":" +
                (((_a = mergeNamedMembers(other)[0]) === null || _a === void 0 ? void 0 : _a.name) || "");
            nodes[key] = {
                key,
                coraState,
                label: "?",
                isType: false,
                isEmpty: true,
                concept: null,
                isTranslucent: translucentNodeKeys.has(key),
            };
            return [nodes[key]];
        }
        function getOrCreate(c, isType) {
            const concept = conceptIndex.get(c);
            if (!concept)
                return undefined;
            nodes[concept.name] = createNode(coraState, concept, isType, translucentNodeKeys.has(concept.name));
            if (isType) {
                typeConcepts.add(concept.name);
            }
            return nodes[concept.name];
        }
        function createEdge(n1, n2, directed, label, relation) {
            if (!n1 || !n2)
                return undefined;
            const r = !(relation === null || relation === void 0 ? void 0 : relation.length)
                ? undefined
                : mergeRelationClusters(relation)[0];
            const key = getEdgeKey(corpus_ids, n1.key, n2.key, r === null || r === void 0 ? void 0 : r.name);
            edges[key] = {
                coraState,
                key,
                from: n1.key,
                to: n2.key,
                directed,
                label: label || (r === null || r === void 0 ? void 0 : r.name),
                relation: r,
            };
            return edges[key];
        }
        function getConcepts(concepts, override) {
            if (!Object.keys(override).length) {
                return mergeNamedMembers(concepts);
            }
            const acc = [];
            function createNodesForOverride(overridesForConcept, typeNode) {
                overridesForConcept.forEach(o => {
                    const n = getOrCreate(o, false);
                    createEdge(n, typeNode, true, "is a");
                    acc.push(o);
                });
            }
            concepts.forEach(c => {
                const overridesForConcept = override[c.name];
                if (overridesForConcept) {
                    const typeNode = getOrCreate(c, true);
                    overridesForConcept.forEach(o => {
                        const n = getOrCreate(o, false);
                        createEdge(n, typeNode, true, "is a");
                        acc.push(o);
                    });
                }
                const byKey = keyBy(concepts, "name");
                Object.entries(override).forEach(([key, overridesForConcept]) => {
                    const typeConcept = byKey[key];
                    const typeNode = typeConcept
                        ? getOrCreate(typeConcept, true)
                        : undefined;
                    createNodesForOverride(overridesForConcept, typeNode);
                });
                return acc;
            });
            createNodesForOverride(override[TOP_RESULTS_KEY] || []);
            return acc;
        }
        const { concepts1, concepts2, concepts1Override, concepts2Override, relations, corpus_ids, crcDirection, } = coraState;
        const n1 = !mergeNamedMembers(concepts1, concepts1Override).length
            ? getOrCreateEmpty(true, relations, mergeNamedMembers(concepts2, concepts2Override))
            : getConcepts(concepts1, concepts1Override).map(c => getOrCreate(c, false));
        const n2 = !mergeNamedMembers(concepts2, concepts2Override).length
            ? getOrCreateEmpty(false, relations, mergeNamedMembers(concepts1, concepts1Override))
            : getConcepts(concepts2, concepts2Override).map(c => getOrCreate(c, false));
        n1.forEach(a => n2.forEach(b => {
            createEdge(crcDirection === CRCDirection.C2C1 ? b : a, crcDirection === CRCDirection.C2C1 ? a : b, crcDirection === CRCDirection.C1C2 ||
                crcDirection === CRCDirection.C2C1, undefined, relations);
        }));
        return {
            nodes,
            edges,
        };
    }, { edges: {}, nodes: {} });
    const model = {
        nodes: Object.values(nodes).map(n => typeConcepts.has(n.key) ? Object.assign(Object.assign({}, n), { isType: true }) : n),
        edges: Object.values(edges),
    };
    return filterGraph(model);
}
function modelToGraph({ nodes, edges }) {
    const graph = new Graph({ multigraph: true, directed: true });
    nodes.forEach(n => graph.setNode(n.key, n));
    edges.forEach(e => {
        graph.setEdge(e.from, e.to, e, e.key);
        if (!e.directed) {
            graph.setEdge(e.to, e.from, e, e.key);
        }
    });
    return graph;
}
function toComponents(model) {
    const { nodes, edges } = model;
    const nodesByKey = keyBy(nodes, "key");
    const graph = modelToGraph({ nodes, edges });
    const components = alg.components(graph);
    const compIndex = Object.fromEntries(components.reduce((acc, keys, idx) => {
        keys.forEach(key => acc.push([key, idx]));
        return acc;
    }, []));
    const edgesByComponent = groupBy(edges, e => compIndex[e.from]);
    return [
        components.map((nodeKeys, idx) => ({
            nodes: nodeKeys.map(n => nodesByKey[n]),
            edges: edgesByComponent[idx] || [],
        })),
        nodesByKey,
    ];
}
function getEdgeLabel(edge) {
    var _a;
    return edge.label || ((_a = edge.relation) === null || _a === void 0 ? void 0 : _a.name);
}
export function getGraphComponents(model) {
    const [components, nodesByKey] = toComponents(model);
    function getNodeLabel(edge, from) {
        return nodesByKey[edge[from ? "from" : "to"]].label;
    }
    function getNane(edge, from) {
        const label = getNodeLabel(edge, from);
        const middle = getEdgeLabel(edge);
        const prefix = from ? "" : middle + " - ";
        const suffix = from ? " - " + middle : "";
        return prefix + label + suffix;
    }
    return components.reduce((acc, model, idx) => {
        if (model.edges.length) {
            const g = modelToGraph(model);
            const sources = g
                .sources()
                .map(key => nodesByKey[key])
                .filter(n => !n.isEmpty);
            const sinks = g
                .sinks()
                .map(key => nodesByKey[key])
                .filter(n => !n.isEmpty);
            const relationsSeparator = model.edges.length > 1 ? " ... " : " - ";
            if (sources.length && sinks.length) {
                const name = sources[0].label + relationsSeparator + sinks[0].label;
                acc[name] = model;
            }
            else {
                const sortedEdges = model.edges.sort((a, b) => a.coraState.time - b.coraState.time);
                const minEdge = sortedEdges[0];
                const maxEdge = sortedEdges[sortedEdges.length - 1];
                const name = minEdge === maxEdge
                    ? getNodeLabel(minEdge, true) +
                        " - " +
                        getEdgeLabel(minEdge) +
                        " - " +
                        getNodeLabel(minEdge, false)
                    : getNane(minEdge, true) +
                        relationsSeparator +
                        getNane(maxEdge, false);
                acc[name] = model;
            }
        }
        return acc;
    }, {});
}
