var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Url from "url";
import { API } from "../api/api";
import { setLoading } from "../../shared/components/Loading";
import { showDocViewer } from "../components/modals/DocViewerModal";
export function getCaption(source) {
    if (source.title)
        return source.title;
    if (source.url)
        return Url.parse(source.url).hostname || undefined;
    return undefined;
}
export function getDocumentUrl(docId) {
    return `/api/v1/corpora/document?id=${docId}`;
}
export function getUrl(ev) {
    return isWebLink(ev.corpus_id)
        ? ev.url || getDocumentUrl(ev.document_id)
        : getDocumentUrl(ev.document_id);
}
export function getId(ev) {
    return isWebLink(ev.corpus_id) ? ev.url || ev.document_id : ev.document_id;
}
export function isWebLink(corpus_id) {
    return corpus_id.toLowerCase().startsWith("web");
}
export function openUrl(url, corpus_id, document_id, highlight, title, onSelectionChange, metadata, metadataSorter, externalUrl, disableScrollToHighlight) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            setLoading(true);
            if (isWebLink(corpus_id)) {
                window.open(url, "_blank");
            }
            else {
                const docContent = yield API.corporaDocument({
                    corpus_ids: [corpus_id],
                    id: document_id,
                });
                if (docContent) {
                    const hostname = Url.parse(url).hostname;
                    showDocViewer({
                        title: title || (hostname ? `Evidence at ${hostname}` : "Evidence"),
                        url,
                        doc: { value: docContent.text, loading: false },
                        highlight,
                        onSelectionChange,
                        metadata: metadata || docContent.metadata,
                        metadataSorter,
                        externalUrl: externalUrl || docContent.external_url,
                        disableScrollToHighlight,
                    });
                }
                else {
                    window.open(url, "_blank");
                }
            }
        }
        finally {
            setLoading(false);
        }
    });
}
