import { NodeType } from "./DiagramModel";
import { CRCDirection } from "../../api/types";
import { isEqual } from "lodash";
import { useEffectIfDifferent } from "../../../utils/hooks";
import { useCoraState } from "../CoraStateProvider";
export function findStatementNodeFor(model, concepts1, relations, concepts2, crcDirection) {
    return model
        .getStatementNodes()
        .find(n => isEqual(getConceptsAndOverrides(n.from)[0], concepts1) &&
        isEqual(getConceptsAndOverrides(n.to)[0], concepts2) &&
        isEqual(n.relations, relations) &&
        ((crcDirection === CRCDirection.BOTH && !n.directed) ||
            (crcDirection === CRCDirection.C1C2 && n.directed)));
}
export function getConceptsAndOverrides(n) {
    var _a;
    return (n === null || n === void 0 ? void 0 : n.type) === NodeType.CONCEPT
        ? [[n.concept], {}]
        : (n === null || n === void 0 ? void 0 : n.type) === NodeType.TYPE
            ? [n.concepts, { [((_a = n.concepts[0]) === null || _a === void 0 ? void 0 : _a.name) || ""]: n.overrideConcepts }]
            : [[], {}];
}
export function useUpdateDiagramFromQueryParams(model, selectedConceptsByKey, activeStatementId, onUpdate, dependencies) {
    const { concepts1, relations, concepts2, concepts1Override, concepts2Override, extraConcepts, extraConceptsOverride, contextConcepts, contextConceptsOverride, crcDirection, } = useCoraState()[0];
    useEffectIfDifferent(() => {
        var _a;
        const node = findStatementNodeFor(model, concepts1, relations, concepts2, crcDirection);
        onUpdate(node === null || node === void 0 ? void 0 : node.key, !isEqual((_a = node === null || node === void 0 ? void 0 : node.key) !== null && _a !== void 0 ? _a : null, activeStatementId), getOverrides());
        function getOverrides() {
            if (node) {
                let overrides = selectedConceptsByKey;
                if (node.from) {
                    overrides = Object.assign(Object.assign({}, overrides), { [node.from.key]: concepts1Override });
                }
                if (node.to) {
                    overrides = Object.assign(Object.assign({}, overrides), { [node.to.key]: concepts2Override });
                }
                overrides = node.context.reduce((acc, link) => {
                    const ov = contextConceptsOverride[link.to];
                    if (ov) {
                        const n = model.findNodeDataForKey(link.to);
                        const concepts = n.type === NodeType.CONCEPT ? [n.concept] : n.concepts;
                        acc[link.to] = { [concepts[0].name]: ov };
                    }
                    return acc;
                }, overrides);
                return overrides;
            }
            return undefined;
        }
    }, dependencies, [
        model,
        concepts1,
        relations,
        concepts2,
        concepts1Override,
        concepts2Override,
        contextConcepts,
        contextConceptsOverride,
        extraConcepts,
        extraConceptsOverride,
        selectedConceptsByKey,
    ], true);
}
