// All messages for a frame are executed in the order they were provided
// Messages for new frames are not executed until prior frames have been consumed
export function sequentialMessageCoordinator() {
    const framesQueue = {};
    let latestFrameId = 0;
    let activeFrameId = 0;
    let destroyed = false;
    function execute(onMessage) {
        const frameIds = Object.keys(framesQueue).map(Number).sort();
        for (const frameId of frameIds) {
            if (frameId > activeFrameId)
                break;
            const frames = framesQueue[frameId];
            while (frames.length) {
                const { message, lastMessageInFrame } = frames[0];
                onMessage(message);
                frames.shift();
                if (lastMessageInFrame) {
                    activeFrameId++;
                    delete framesQueue[frameId];
                    break;
                }
            }
        }
    }
    return {
        newFrame() {
            const frameId = latestFrameId++;
            framesQueue[frameId] = [];
            return frameId;
        },
        newMessage(onMessage, frameId, message, lastMessageInFrame) {
            if (destroyed)
                return;
            framesQueue[frameId].push({ message, lastMessageInFrame });
            execute(onMessage);
        },
        destroy() {
            destroyed = true;
        },
    };
}
// All messages for a frame are executed until a new frame schedules messages
export function debouncedMessageCoordinator() {
    let latestFrameId = 0;
    let destroyed = false;
    return {
        newFrame() {
            return ++latestFrameId;
        },
        newMessage(onMessage, frameId, message) {
            if (latestFrameId !== frameId || destroyed)
                return;
            onMessage(message);
        },
        destroy() {
            destroyed = true;
        },
    };
}
