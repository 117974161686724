import { getSelectedNodeIds, isSelected } from "./diagramUtils";
import { updateItems } from "../../../utils/collections";
export var SelectionMode;
(function (SelectionMode) {
    SelectionMode["add"] = "add";
    SelectionMode["set"] = "set";
    SelectionMode["toggle"] = "toggle";
    SelectionMode["remove"] = "remove";
})(SelectionMode || (SelectionMode = {}));
export function getSelectionMode(event) {
    return event.shiftKey
        ? SelectionMode.add
        : event.ctrlKey
            ? SelectionMode.toggle
            : event.altKey
                ? SelectionMode.remove
                : SelectionMode.set;
}
export function NodeSelector(dUpdater, flow) {
    const initial = getSelectedNodeIds(flow.getNodes());
    return {
        commit() {
            const selected = getSelectedNodeIds(flow.getNodes());
            const nodes = updateItems(flow.getNodes(), n => (Object.assign(Object.assign({}, n), { selected: selected.has(n.id) })));
            setTimeout(() => dUpdater.commit({
                nodes,
            }), 100);
        },
        cancel() {
            this.set(Array.from(initial));
        },
        clear() {
            flow.setNodes(updateItems(flow.getNodes(), n => ({ selected: false })));
        },
        set(ids) {
            const set = new Set(ids);
            flow.setNodes(updateItems(flow.getNodes(), n => ({ selected: set.has(n.id) })));
        },
        add(ids) {
            const set = new Set(ids);
            flow.setNodes(updateItems(flow.getNodes(), n => ({
                selected: set.has(n.id) || isSelected(n),
            })));
        },
        remove(ids) {
            const set = new Set(ids);
            flow.setNodes(updateItems(flow.getNodes(), n => ({
                selected: set.has(n.id) ? false : isSelected(n),
            })));
        },
        toggle(ids) {
            const set = new Set(ids);
            flow.setNodes(updateItems(flow.getNodes(), n => ({
                selected: set.has(n.id) ? false : isSelected(n),
            })));
        },
        update(mode, selection) {
            switch (mode) {
                case SelectionMode.add: {
                    this.add(selection);
                    break;
                }
                case SelectionMode.remove: {
                    this.remove(selection);
                    break;
                }
                case SelectionMode.toggle: {
                    this.toggle(selection);
                    break;
                }
                case SelectionMode.set: {
                    this.set(selection);
                    break;
                }
            }
        },
    };
}
