export function IdReplacer() {
    const ids = new Map();
    const replacer = function (id) {
        if (!ids.has(id))
            ids.set(id, `ID-${ids.size}`);
        return ids.get(id);
    };
    replacer.reset = () => ids.clear();
    return replacer;
}
