import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useAllTags } from "../../cora/utils/allTags";
import { tagFilterProvidersByType, TagsFilter } from "./TagsFilter";
import { filterItems } from "../../utils/filters";
import { ActionButton } from "./buttons/Buttons";
import { Search, Tags } from "../../cora/components/Icons";
import { Input } from "antd";
import { classes } from "../../utils/classes";
import { identity } from "../../utils/util";
export function useItemFilters({ items, textFilter, setTextFilter, showTags, setShowTags, itemTags, headerWrapper, filterBorderClass, itemsSorter, extraFilterTop, extraFilterBottom, extraFilterInner, extraFilterRight, disabled, textFilterComponent, }) {
    const [tags, setTags] = React.useState([]);
    const [tagsFilterType, setTagsFilterType] = React.useState("SOME");
    const showingTags = showTags && (itemTags || !textFilter);
    const showingTextFilter = !showTags && textFilter !== undefined;
    const hasTextFilter = textFilter && textFilter.trim().length >= 3;
    const allTags = useAllTags(tags);
    const { itemsToShow, filtering } = React.useMemo(() => {
        const tagsFilter = tagFilterProvidersByType[tagsFilterType](tags);
        const tagsF = tags.length && itemTags && showTags
            ? (item) => tagsFilter(itemTags ? itemTags(item) : [])
            : undefined;
        const filtered = filterItems(items, tagsF);
        return {
            itemsToShow: itemsSorter ? itemsSorter(filtered) : filtered,
            filtering: Boolean(hasTextFilter || tagsF),
        };
    }, [
        items,
        itemsSorter,
        itemTags,
        tags,
        tagsFilterType,
        showTags,
        hasTextFilter,
    ]);
    const toggleFilter = !setTextFilter || !itemTags ? null : (_jsx(ActionButton, Object.assign({ roundedClass: "rounded", onClick: () => setShowTags === null || setShowTags === void 0 ? void 0 : setShowTags(s => !s) }, { children: showingTags ? _jsx(Tags, {}) : _jsx(Search, {}) })));
    const filterComponent = showingTags ? (_jsx(TagsFilter, { className: "flex-1", value: tags, onChange: setTags, placeholder: "Filter Tags\u2026", allTags: allTags, filterType: tagsFilterType, setFilterType: setTagsFilterType })) : ((textFilterComponent === null || textFilterComponent === void 0 ? void 0 : textFilterComponent(textFilter, setTextFilter, disabled)) || (_jsx(Input, { className: classes("flex-1"), allowClear: true, disabled: disabled, bordered: false, placeholder: "Filter\u2026", value: textFilter, onChange: e => setTextFilter === null || setTextFilter === void 0 ? void 0 : setTextFilter(e.target.value), onKeyDown: e => {
            if (e.key === "Escape") {
                setTextFilter === null || setTextFilter === void 0 ? void 0 : setTextFilter("");
            }
        } })));
    return {
        items: itemsToShow,
        filtering,
        headerWrapper: !showingTags && !showingTextFilter
            ? undefined
            : (h) => (headerWrapper || identity)(_jsxs("div", Object.assign({ className: "flex flex-col mb-2" }, { children: [h, extraFilterTop, _jsxs("div", Object.assign({ className: "flex flex-1 items-center space-x-2" }, { children: [(filterComponent || toggleFilter || extraFilterInner) && (_jsxs("div", Object.assign({ className: classes("flex flex-1 focus-within:bg-white", filterBorderClass) }, { children: [filterComponent, toggleFilter, extraFilterInner] }))), extraFilterRight] })), extraFilterBottom] }))),
    };
}
