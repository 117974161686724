import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useCurrentSession, updateCurrentSession, } from "../../utils/sessionManagement";
import { SelectionButton } from "../../../shared/components/buttons/SelectionButton";
import { Document } from "../../components/Icons";
import { ToggleableMenuItem } from "../../../shared/actions/ToggleableMenuItem";
export function useGroupByDocumentAction() {
    const { groupEvidenceByDocument } = useCurrentSession();
    return {
        name: "groupByDocument",
        spaceRequired: 200,
        priority: 3,
        expandedDisplay: (_jsx(SelectionButton, { caption: "Group by Document", className: "whitespace-nowrap", tooltipPlacement: "top", icon: _jsx(Document, {}), selected: groupEvidenceByDocument, onMouseDown: () => updateCurrentSession({
                groupEvidenceByDocument: !groupEvidenceByDocument,
            }) })),
        collapsedDisplay: {
            sectionTitle: "Grouping",
            entries: [
                {
                    caption: (_jsx(ToggleableMenuItem, { label: "By Document", selected: groupEvidenceByDocument })),
                    action: () => {
                        updateCurrentSession({
                            groupEvidenceByDocument: !groupEvidenceByDocument,
                        });
                    },
                },
            ],
        },
    };
}
