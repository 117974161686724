import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { mergeNamedMembers, TOP_RESULTS_KEY } from "./semanticSearchUtil";
import { useCurrentSession } from "./sessionManagement";
import { useMemoIfEqual } from "../../utils/hooks";
import { getTopResultsField } from "./concepts";
import { limitLength } from "../../utils/util";
import { ManageLists } from "../components/Icons";
export var ReferenceType;
(function (ReferenceType) {
    ReferenceType["CONCEPT"] = "C";
    ReferenceType["WATCHLIST"] = "W";
})(ReferenceType || (ReferenceType = {}));
export function useCurrentExtraConcepts(coraState) {
    const { corpus_ids, extraConcepts } = coraState;
    const { extraConceptsByCorpusId } = useCurrentSession();
    const extra = extraConceptsByCorpusId[corpus_ids[0]] || [];
    return useMemoIfEqual(extra.concat(extraConcepts.filter(c => !extra.find(e => e.name === c.name))));
}
export function useAnnotationProvider(coraState) {
    const { concepts1: c1, concepts1Override: c1o, concepts1Possible: c1p, concepts2: c2, concepts2Override: c2o, concepts2Possible: c2p, contextConcepts: ctx, contextConceptsOverride: ctxO, contextConceptsPossible: ctxP, extraConceptsOverride: eO, extraConceptsPossible: eP, argClauses, } = coraState;
    const e = useCurrentExtraConcepts(coraState);
    const topResultsField = getTopResultsField(coraState);
    const topIsC1 = topResultsField === "concepts1";
    const topIsC2 = topResultsField === "concepts2";
    const c1Count = topIsC1 ? 1 : c1.length;
    const c2Count = topIsC2 ? 1 : c2.length;
    const conceptIndex = React.useMemo(() => {
        const c1Items = indexConcepts(c1, c1o, c1p, ReferenceType.CONCEPT, 0);
        const c2Items = indexConcepts(c2, c2o, c2p, ReferenceType.CONCEPT, c1Count);
        return Object.fromEntries([
            ...c1Items,
            ...c2Items,
            ...indexConcepts(ctx, ctxO, ctxP, ReferenceType.CONCEPT, c1Count + c2Count),
            ...indexConcepts(e, eO, eP, ReferenceType.WATCHLIST, 0),
        ]);
        function indexConcepts(concepts, overrides, possible, prefix, offset) {
            if (!concepts.length) {
                const c = mergeNamedMembers(Object.values(overrides).flat())[0];
                return !c ? [] : mapMembers(c, 0);
            }
            const localOverrides = Object.assign({}, overrides);
            if (concepts.length > 1 && possible && possible.length > 0) {
                console.log("multiple concepts with possible concepts");
            }
            else if (possible && possible.length > 0) {
                const c = concepts[0];
                const possibleConcepts = possible || [];
                possibleConcepts.forEach(p => {
                    if (!localOverrides[c.name]) {
                        localOverrides[c.name] = [];
                    }
                    localOverrides[c.name] = [...localOverrides[c.name], p];
                });
            }
            return concepts
                .map((concept, idx) => {
                const c = localOverrides[concept.name]
                    ? mergeNamedMembers(localOverrides[concept.name])[0]
                    : concept;
                return mapMembers(c, idx);
            })
                .flat();
            function mapMembers(c, idx, overrideTooltip) {
                return c.members.map(m => [
                    m.id,
                    {
                        superscript: `${prefix}${idx + offset + 1}`,
                        tooltip: overrideTooltip || c.name,
                        index: idx + offset + 1,
                    },
                ]);
            }
        }
    }, [c1, c1o, c1p, c2, c2o, c2p, ctx, ctxO, ctxP, e, eO, eP]);
    const clausesIndex = React.useMemo(() => Object.fromEntries(Object.entries(argClauses)
        .map(([key, cl]) => cl
        .map(c => [
        c.name,
        ...c.members.map(m => [m.id, ...m.surface_forms]).flat(),
    ].map(s => [s, { superscript: key.slice(0, 2), tooltip: key }]))
        .flat())
        .flat()), [argClauses]);
    return React.useCallback(evidenceSpans => {
        return evidenceSpans.reduce((acc, s) => {
            const m = s.match;
            if (m.match_type === "TEXT_MATCH") {
                acc.push({
                    type: "text_match",
                    value: {
                        key: m.match_id,
                        value: m.match_id,
                        context: "Text Match",
                    },
                });
            }
            else if (m.match_type === "KEYWORD_MATCH") {
                acc.push({
                    type: "keyword_match",
                    value: {
                        key: m.match_id,
                        value: m.match_id,
                        context: "Keyword Match",
                    },
                });
            }
            else if (m.match_type === "CONCEPT" ||
                m.match_type === "CONTEXT_CONCEPT") {
                const i = conceptIndex[m.match_id];
                if (i) {
                    acc.push({
                        type: "concept",
                        value: {
                            key: i.superscript,
                            value: i.tooltip,
                            context: `Concept ${i.index}`,
                            ordering: i.index,
                        },
                    });
                }
                else {
                    console.log("UNKNOWN CONCEPT", m.match_name);
                }
            }
            else if (m.match_type === "ARGUMENT") {
                acc.push({
                    type: "argument",
                    value: {
                        key: m.match_id,
                        value: m.match_id,
                        context: "Qualifier",
                    },
                });
            }
            else if (m.match_type === "ARGUMENT_CLAUSE") {
                const i = clausesIndex[m.match_id];
                if (i) {
                    acc.push({
                        type: "argument",
                        value: {
                            key: i.superscript,
                            value: i.tooltip,
                            context: "Qualifier",
                        },
                    });
                }
                else {
                    const additionalArgs = s.additionalSpans.filter(s => s.type === "argument" &&
                        Object.values(clausesIndex).filter(c => c.tooltip === s.payload.name).length > 0);
                    if (additionalArgs.length) {
                        additionalArgs.forEach(a => acc.push({
                            type: "argument",
                            value: {
                                key: a.payload.name.slice(0, 2),
                                value: a.payload.name,
                                context: "Qualifier",
                            },
                        }));
                    }
                }
            }
            else if (m.match_type === "RELATION") {
                acc.push({
                    type: "relation",
                    value: {
                        key: "R",
                        value: m.match_name,
                        context: "Relation",
                    },
                });
            }
            return acc;
        }, []);
    }, [conceptIndex, clausesIndex]);
}
export function referenceCreator(offset = 0, prefix) {
    return (c, idx) => (_jsxs("span", { children: [_jsx("span", Object.assign({ className: "mr-1" }, { children: `${prefix}${idx + offset + 1}.` })), _jsx("span", Object.assign({ className: "relative mr-1", style: { top: -2 } }, { children: (c === null || c === void 0 ? void 0 : c.isCustom) ? _jsx(ManageLists, {}) : undefined })), _jsx("span", { children: `${limitLength((c === null || c === void 0 ? void 0 : c.name) || TOP_RESULTS_KEY, 50)}` })] }));
}
