import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { NodeToolbar, useStore } from "reactflow";
import { useDiagramActions } from "./actions/actions";
import { useActionBarEntries } from "../../../shared/actions/ActionsBar";
import { useShowingPopup } from "../../../shared/components/GlobalPopUp";
const selectedNodesSelector = (state) => state
    .getNodes()
    .filter(node => node.selected)
    .map(node => node.id);
export default function SelectionToolbar() {
    const nodeId = useStore(selectedNodesSelector);
    const isVisible = !useShowingPopup();
    const actions = useDiagramActions().selection;
    const entries = useActionBarEntries(actions);
    return (_jsx(NodeToolbar, Object.assign({ nodeId: nodeId, isVisible: isVisible, className: "flex border bg-white border-gray-200 shadow-lg rounded divide-x" }, { children: entries })));
}
