import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { useValidatedCorpusIds } from "../../utils/sourceManagement";
import { useEffectIfDifferent } from "../../../utils/hooks";
import { omit } from "lodash";
function getCorpusKey(corpus_ids) {
    return corpus_ids.join(",");
}
function getKey(corpus_ids, type, name) {
    return `${getCorpusKey(corpus_ids)}|${type}|${name}`;
}
function getKeyParts(key) {
    return key.split("|");
}
export function useFacetsCollapseState(corpus_ids, type, name, initiallyCollapsed) {
    const { facetsCollapsedState } = useCurrentSession();
    const key = getKey(corpus_ids, type, name);
    const collapsed = key in facetsCollapsedState ? facetsCollapsedState[key] : initiallyCollapsed;
    function setCollapsed(isCollapsed) {
        if (collapsed === isCollapsed)
            return;
        updateCurrentSession({
            facetsCollapsedState: isCollapsed === initiallyCollapsed
                ? omit(facetsCollapsedState, key)
                : Object.assign(Object.assign({}, facetsCollapsedState), { [key]: isCollapsed }),
        });
    }
    return [collapsed, setCollapsed];
}
export function useUpdateFacetCollapseCleanup(corpus_ids, type, existingNames) {
    const corpusKey = getCorpusKey(useValidatedCorpusIds(corpus_ids));
    const { facetsCollapsedState } = useCurrentSession();
    useEffectIfDifferent(() => {
        const names = new Set(existingNames);
        const entries = Object.entries(facetsCollapsedState);
        const filtered = entries.filter(([k]) => {
            const parts = getKeyParts(k);
            return (type !== parts[1] || (corpusKey === parts[0] && names.has(parts[2])));
        });
        if (filtered.length !== entries.length) {
            updateCurrentSession({
                facetsCollapsedState: Object.fromEntries(filtered),
            });
        }
    }, [facetsCollapsedState], [corpusKey, type, existingNames]);
}
