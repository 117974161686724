import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../utils/classes";
import { Close, Search } from "../../components/Icons";
import { SelectionButton } from "../../../shared/components/buttons/SelectionButton";
export function SwappableQuery({ children, queryBox, queryButtonLocation = "right", closeButtonLocation = "right", hasActiveQuery, className, suffix, onClose, onOpen, openByDefault = false, openWhenActive = false, }) {
    const [open, setOpen] = React.useState(openByDefault);
    // set to open if there is an active query
    React.useEffect(() => {
        if (openWhenActive && hasActiveQuery)
            setOpen(true);
    }, [hasActiveQuery]);
    const queryButton = (_jsx(SelectionButton, { style: { height: "36px" }, selected: hasActiveQuery, icon: _jsx(Search, { className: classes("text-base") }), onClick: () => {
            setOpen(true);
            onOpen && onOpen();
        } }));
    const closeButton = (_jsx(SelectionButton, { style: { height: "36px" }, icon: _jsx(Close, {}), onClick: () => {
            setOpen(false);
            onClose && onClose();
        } }));
    return (_jsxs("div", Object.assign({ className: classes("w-full flex justify-between", className) }, { children: [!open && queryButtonLocation === "left" && queryButton, open && closeButtonLocation === "left" && closeButton, open && (_jsx("div", Object.assign({ className: "w-full bg-neutral-100 rounded-lg flex justify-between mr-2" }, { children: queryBox }))), !open && children, !open && queryButtonLocation === "right" && queryButton, open && suffix, open && closeButtonLocation === "right" && closeButton] })));
}
