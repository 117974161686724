var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { default_count, getSearchConceptsMembers, getSubconceptsForClusterParams, TOP_RESULTS_KEY, validateTextFilter, } from "../../utils/semanticSearchUtil";
import { invalidCorpusIds, useValidatedCorpusIds, } from "../../utils/sourceManagement";
import { useCurrentSession } from "../../utils/sessionManagement";
import { useUpdateFacetCollapseCleanup } from "./facetsCollapsedState";
import { Empty } from "../../../shared/components/Empty";
import { ConceptFacet, defaultFeedbackProvider, } from "./specializedFacets";
import { API } from "../../api/api";
import { getCorpusFilterProvider, useFacetParamsGetterForTopResults, useSubconceptParamsGetter, } from "../../utils/concepts";
import { limitLength } from "../../../utils/util";
import { showPopupMenu } from "../../../shared/actions/PopupMenu";
import { conceptMenuEntriesProvider } from "../contextMenuEntries";
import { classes } from "../../../utils/classes";
export function FacetsForConcepts(_a) {
    var { coraState, coraStateUpdater, conceptsKey, otherConceptsKeys, otherConceptsOverrideKeys, argument_name, title, conceptsOverridesKey, possibleConceptsKey, feedback, setFeedback, hideNoSubconcepts = true, selectionModelFactory, relevantConcepts, selectedConcepts, nameProvider } = _a, props = __rest(_a, ["coraState", "coraStateUpdater", "conceptsKey", "otherConceptsKeys", "otherConceptsOverrideKeys", "argument_name", "title", "conceptsOverridesKey", "possibleConceptsKey", "feedback", "setFeedback", "hideNoSubconcepts", "selectionModelFactory", "relevantConcepts", "selectedConcepts", "nameProvider"]);
    const { hierarchicalFacets, structuredQuery: sq, showStructuredQuery, expandedFacetCount, } = useCurrentSession();
    const structuredQuery = sq && showStructuredQuery;
    const disabled = invalidCorpusIds(coraState.corpus_ids);
    const corpus_ids = useValidatedCorpusIds(coraState.corpus_ids);
    const corpusFilterProvider = getCorpusFilterProvider({
        corpus_ids,
        coraState,
        conceptsKey,
        conceptsOverridesKey,
        otherConceptsKeys,
        otherConceptsOverrideKeys,
    });
    const subconceptParamsGetter = useSubconceptParamsGetter(corpusFilterProvider, argument_name);
    const facetParamsGetterForTopResults = useFacetParamsGetterForTopResults(corpusFilterProvider, coraState, conceptsKey, otherConceptsKeys, otherConceptsOverrideKeys, argument_name, disabled);
    useUpdateFacetCollapseCleanup(corpus_ids, conceptsKey, (facetParamsGetterForTopResults ? [TOP_RESULTS_KEY] : []).concat(relevantConcepts.map(c => c.name)));
    // check that we either have custom List or subconcepts
    const selectedHaveSubconcepts = true;
    const selectedHaveCustom = relevantConcepts.map(c => c.isCustom).some(Boolean);
    const entries = facetParamsGetterForTopResults
        ? createFacet({
            idx: 0,
            category: TOP_RESULTS_KEY,
            key: coraState.crcDirection,
            concept: undefined,
            facetLoader: API.searchConcepts,
            loaderParamsGetter: ({ prefix, maxCount, extraArg }) => {
                prefix = validateTextFilter(prefix);
                return Object.assign(Object.assign(Object.assign({}, facetParamsGetterForTopResults(maxCount)), { argument_name, check_if_concepts_have_children: true }), (prefix.length && { prefix }));
            },
            subEntriesLoader: API.searchConcepts,
            subEntriesLoaderParamsGetter: createSubconceptsParamsGetter(),
            suffixProvider: defaultFeedbackProvider(TOP_RESULTS_KEY, feedback, setFeedback),
        })
        : relevantConcepts.map((cluster, idx) => {
            const noSubConcepts = false;
            const loaderParamsGetter = createSubconceptsParamsGetter(cluster);
            return cluster.isCustom
                ? createFacet({
                    idx,
                    category: cluster.name,
                    key: cluster.name + idx,
                    concept: cluster,
                    facetLoader: getSearchConceptsMembers,
                    loaderParamsGetter: ({ prefix, maxCount, extraArg }) => {
                        prefix = validateTextFilter(prefix, 1);
                        const corpus_filter = corpusFilterProvider([], [], extraArg);
                        return Object.assign({ corpus_filter, customConceptList: cluster, count: maxCount === expandedFacetCount
                                ? 1000 /* SHOW ALL */
                                : default_count, argument_name, check_if_concepts_have_children: hierarchicalFacets }, (prefix.length && { prefix }));
                    },
                    subEntriesLoader: API.searchConcepts,
                    subEntriesLoaderParamsGetter: loaderParamsGetter,
                    minFilterLength: 1,
                })
                : hideNoSubconcepts && noSubConcepts
                    ? null
                    : createFacet({
                        idx,
                        category: cluster.name,
                        key: cluster.name + idx,
                        concept: cluster,
                        facetLoader: API.searchConcepts,
                        loaderParamsGetter,
                        subEntriesLoader: API.searchConcepts,
                        subEntriesLoaderParamsGetter: loaderParamsGetter,
                        suffixProvider: defaultFeedbackProvider(cluster.name, feedback, setFeedback),
                        noExpansion: noSubConcepts,
                        loading: false,
                    });
        });
    const showEmpty = hideNoSubconcepts &&
        !selectedHaveSubconcepts &&
        !facetParamsGetterForTopResults &&
        !selectedHaveCustom;
    return showEmpty ? (_jsx(Empty, { empty: !relevantConcepts.length
            ? `No ${title} Selected`
            : `No Aspects for ${title}`, loading: false })) : (_jsx(_Fragment, { children: entries }));
    function createFacet({ idx, category, key, concept, facetLoader, subEntriesLoader, loaderParamsGetter, subEntriesLoaderParamsGetter, suffixProvider, noExpansion = false, loading, minFilterLength, }) {
        var _a, _b;
        const selectionModel = selectionModelFactory({
            coraState,
            coraStateUpdater,
            category,
            concept,
            conceptsKey,
            conceptsOverridesKey,
        });
        const itemsOverride = coraState[possibleConceptsKey];
        const menuEntries = conceptMenuEntriesProvider(coraStateUpdater, coraState, structuredQuery);
        return (_jsx(ConceptFacet, Object.assign({ facetFor: concept, hierarchical: hierarchicalFacets, facetLoader: facetLoader, subEntriesLoader: subEntriesLoader, onlyLoadWhenExpanded: true, initiallyCollapsed: false, loaderParamsGetter: loaderParamsGetter, loaderParamsGetterExtraArg: concept, subEntriesLoaderParamsGetter: subEntriesLoaderParamsGetter, category: category, disabled: disabled, coraState: coraState, coraStateUpdater: coraStateUpdater, argKey: conceptsKey, setSelected: selectionModel.setSelected, selected: selectionModel.getSelected(), suffixProvider: suffixProvider, getFacetTitle: getFacetTitle, noExpansion: noExpansion, loading: loading, minFilterLength: minFilterLength, itemsOverride: itemsOverride, setReq: concept && selectionModel.setReq, reqSelected: (_b = (_a = selectionModel.isReqSelected) === null || _a === void 0 ? void 0 : _a.call(selectionModel)) !== null && _b !== void 0 ? _b : false, max_count: expandedFacetCount }, props), key));
        function getFacetTitle(count, disabled) {
            return (_jsx("div", Object.assign({ className: "relative w-full", onContextMenu: e => {
                    e.preventDefault();
                    showPopupMenu({
                        top: e.clientY,
                        left: e.clientX,
                        entries: menuEntries(concept, []),
                    });
                } }, { children: _jsx("div", Object.assign({ className: classes("font-semibold", disabled && "cursor-not-allowed") }, { children: nameProvider
                        ? nameProvider(concept, idx)
                        : limitLength(concept === null || concept === void 0 ? void 0 : concept.name, 50) })) })));
        }
    }
    function createSubconceptsParamsGetter(cluster) {
        return ({ prefix, maxCount, extraArg }) => {
            prefix = validateTextFilter(prefix);
            return getSubconceptsForClusterParams(Object.assign({ params: Object.assign(Object.assign({}, subconceptParamsGetter(maxCount, [], cluster ? [cluster.name] : [], extraArg)), { argument_name }), check_if_concepts_have_children: true, cluster: (extraArg || cluster) }, (prefix.length && { prefix })));
        };
    }
}
