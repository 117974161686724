import React from "react";
import { Beacon, useBeacon } from "../../utils/Beacon";
import { isEqual, pick } from "lodash";
import { EMPTY_FILTERS } from "./coraState";
const emptyFiltersBeacon = Beacon({ initial: true });
export function useEmptyFilter() {
    return useBeacon(emptyFiltersBeacon)[0];
}
export function setEmptyFilter(empty) {
    emptyFiltersBeacon.value(empty);
}
export function useEmptyFilterFromCoraState(coraState) {
    React.useEffect(() => {
        setEmptyFilter(isEqual(EMPTY_FILTERS, pick(coraState, Object.keys(EMPTY_FILTERS))));
    }, [coraState]);
}
