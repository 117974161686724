var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
export function Field(_a) {
    var { title, prefix, suffix, content, placeholder, className, titleClass, size = "small", noBorder, noTitle, titleOnBorder, horizontal, contentClassName, contentContainerClassName } = _a, props = __rest(_a, ["title", "prefix", "suffix", "content", "placeholder", "className", "titleClass", "size", "noBorder", "noTitle", "titleOnBorder", "horizontal", "contentClassName", "contentContainerClassName"]);
    const component = typeof content === "string" ? (_jsx("div", Object.assign({ className: classes(!noTitle && "leading-[27px] flex-1 min-h-[27px]", !content.length ? "text-gray-300" : "font-medium") }, { children: content.length ? content : placeholder }))) : (content);
    const titleComponent = noTitle ? null : typeof title === "string" ? (_jsx("div", Object.assign({ className: classes("text-gray-800 px-2", titleOnBorder && "absolute", size === "small"
            ? "text-xs font-medium top-[-8px] left-[8px]"
            : "text-md font-medium top-[-12px] left-[8px]", titleClass, horizontal && "my-auto") }, { children: title }))) : (title);
    return (_jsxs("div", Object.assign({ className: classes(titleOnBorder ? "relative px-2" : "px-1", "CoraField flex py-1 rounded-md", !horizontal
            ? "flex-col items-stretch space-y-1"
            : "flex-row items-center", !noBorder && "border-gray-400 border", className) }, props, { children: [titleComponent, _jsxs("div", Object.assign({ className: classes("flex flex-row items-center space-x-1", contentContainerClassName) }, { children: [_jsxs("div", Object.assign({ className: classes("flex flex-1 items-center space-x-1", contentClassName) }, { children: [prefix, component] })), suffix] }))] })));
}
