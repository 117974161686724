import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useUniqId } from "../../utils/hooks";
import "./DocViewer.less";
import { scrollToElement, uuidv4 } from "../../utils/util";
import { Button, Result } from "antd";
import { TitledContainer } from "../../shared/components/TitledContainer";
import { classes } from "../../utils/classes";
import { isArray } from "lodash";
import { filterMetadata } from "../utils/metatada";
import { useSelectionChange } from "../utils/useSelectionChange";
import { AnnotatedString } from "../../shared/components/AnnotatedString";
export function findTextSpans(text, search) {
    const spans = [];
    let index = text.indexOf(search);
    while (index !== -1) {
        spans.push({ start: index, end: index + search.length });
        index = text.indexOf(search, index + 1);
    }
    return spans;
}
export default function DocViewer({ url, title, highlight, highlightWords, className, onSelectionChange, doc, metadata, disableScrollToHighlight, }) {
    var _a;
    const [highlights, setHighlights] = React.useState(isArray(highlight)
        ? highlight
        : highlight !== undefined
            ? [highlight]
            : []);
    const filteredMetadata = filterMetadata(metadata);
    const containerId = useUniqId(uuidv4);
    const text = url && doc.value;
    const highlightSpans = text && highlights.map(h => findTextSpans(text, h)).flat();
    const containerRef = React.useRef(null);
    useSelectionChange(!text || doc.loading, containerId, ((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.textContent) || undefined, onSelectionChange, valueMapper => setHighlights(valueMapper(highlights)));
    const topHighlightRef = React.useRef(null);
    React.useEffect(() => {
        if (!text || !highlightSpans || highlightSpans.length === 0)
            return;
        const topHighlight = topHighlightRef.current;
        if (!topHighlight)
            return;
        scrollToElement(() => topHighlight, 490);
    }, [text, highlightSpans]);
    return (_jsx(TitledContainer, Object.assign({ headerLeft: title, loading: doc.loading, className: className, empty: !url && "Select a document…" }, { children: !doc.loading && text ? (_jsxs("div", Object.assign({ className: "relative" }, { children: [_jsx("div", Object.assign({ id: containerId, className: classes("Document bg-white p-4 rounded-lg", "whitespace-pre-wrap") }, { children: _jsx(AnnotatedString, { text: text, annotations: (highlightSpans || []).map(s => ({
                            span: s,
                            annotation: "highlight",
                        })), renderAnnotation: ({ text, annotations, annotationIndex }) => {
                            if (annotations.length === 0) {
                                return text.replace(/\n{3,}/g, "\n\n");
                            }
                            return (_jsx("span", Object.assign({ ref: disableScrollToHighlight || annotationIndex !== 0
                                    ? undefined
                                    : topHighlightRef, className: "bg-yellow-200" }, { children: text.replace(/\n{3,}/g, "\n\n") })));
                        } }) })), _jsx("div", Object.assign({ className: "flex flex-col bg-brand-700 p-2 pt-0 sticky bottom-0 w-full text-white text-tiny overflow-auto max-h-36" }, { children: _jsxs("table", Object.assign({ className: "border-separate border-spacing-0 w-full" }, { children: [_jsx("thead", { children: _jsx("tr", Object.assign({ className: "font-bold text-left" }, { children: filteredMetadata === null || filteredMetadata === void 0 ? void 0 : filteredMetadata.map((m, i) => (_jsxs("th", Object.assign({ className: "p-2 pt-4 sticky top-0 bg-brand-700 border-b border-gray-400" }, { children: [m.name, " "] }), i))) })) }), _jsx("tbody", { children: _jsx("tr", { children: filteredMetadata === null || filteredMetadata === void 0 ? void 0 : filteredMetadata.map((m, i) => (_jsx("td", Object.assign({ className: "p-2 scroll-y align-top break-all" }, { children: Array.isArray(m.value) ? m.value.join(", ") : m.value }), i))) }) })] })) }))] }))) : (_jsx(Result, { status: "error", title: "Loading Failed", subTitle: `Error loading ${url}`, extra: [
                _jsx("a", Object.assign({ href: url, target: "_blank", rel: "noreferrer" }, { children: _jsx(Button, Object.assign({ type: "primary" }, { children: "Open in New Tab" })) }), "open"),
            ] })) })));
}
