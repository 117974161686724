var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Beacon, ParamsBeacon, useBeacon } from "../../utils/Beacon";
import { API } from "../api/api";
import { keyBy } from "lodash";
import { useInitializer } from "../../utils/hooks";
const searchURL = "/api/v1/search/concepts";
const globalInfoBeacon = ParamsBeacon(Beacon({
    initial: {
        allArgumentNames: {},
        corporaById: {},
    },
}));
export function useGlobalInfo() {
    return useBeacon(globalInfoBeacon)[0];
}
export function getGlobalInfo() {
    return globalInfoBeacon.value();
}
export function useInitGlobalInfo() {
    return useInitializer(() => __awaiter(this, void 0, void 0, function* () {
        const corpora = (yield API.corpora()).filter(c => c.supported_apis.includes(searchURL));
        const argNames = yield Promise.all(corpora.map(c => API.searchArguments({ corpus_filter: { corpus_ids: [c.id] } })));
        const allArgumentNames = Object.fromEntries(corpora.map((c, idx) => [c.id, argNames[idx]]));
        globalInfoBeacon.value({
            allArgumentNames,
            corporaById: keyBy(corpora, "id"),
        });
    }));
}
export const WEB_SOURCE_ID = "web";
