var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BrandBadge } from "../../../shared/components/Genius";
import { Space, Tabs } from "antd";
import { classes } from "../../../utils/classes";
import { getTagBaseClass, getTagBaseStyle } from "../lf";
import { useDebouncedValue } from "../../../utils/hooks";
import { ClusterCheckList } from "./ClusterCheckList";
import { TagsList } from "../../../shared/components/TagsList";
import { Trash } from "../Icons";
import { useRemoteQuery } from "../../../utils/query/remoteQuery";
import { keyToStr, useOnGlobalKeyDown } from "../../../utils/events";
import { BrandButton } from "../../../shared/components/buttons/Buttons";
import { sendFeedback } from "../../utils/feedback";
import "./ClustersPicker.less";
export function ClusterPicker(_a) {
    var { loader, getParams, selected, combinedSelection, hasUpdates, tagColorVals, tagColorBase, title, extraClusterTabsHook, clusterType, hideDropdown, synonyms, popupMenuEntries, extraControls, activeTabIndex, entityName, entityType, identifier, capability, summary, extraContent, clearSelection, setInitialStatus, filterVal, filterComponent, nameRenderer } = _a, props = __rest(_a, ["loader", "getParams", "selected", "combinedSelection", "hasUpdates", "tagColorVals", "tagColorBase", "title", "extraClusterTabsHook", "clusterType", "hideDropdown", "synonyms", "popupMenuEntries", "extraControls", "activeTabIndex", "entityName", "entityType", "identifier", "capability", "summary", "extraContent", "clearSelection", "setInitialStatus", "filterVal", "filterComponent", "nameRenderer"]);
    const debouncedPrefix = useDebouncedValue(filterVal, 300).trim();
    const remote = useRemoteQuery({
        loader,
        params: props.disabled ? undefined : getParams(debouncedPrefix),
    });
    const extraClusterTabs = extraClusterTabsHook(selected);
    const [activeTab, setActiveTab] = React.useState(activeTabIndex || 0);
    useOnGlobalKeyDown(true, e => {
        if (keyToStr(e) === "Escape") {
            hideDropdown(false);
        }
    });
    function getSelectionIndex(namedArr, item) {
        return namedArr.findIndex(cs => cs.name === item.name);
    }
    function getOriginalItems(selectionArr) {
        return selectionArr.filter(item => item.isOriginal);
    }
    const tagClass = getTagBaseClass(tagColorBase);
    function createCheckList(remote, isMain, title, emptyMessage, filter, entityType) {
        function getHeight() {
            return extraContent
                ? combinedSelection.length > 0
                    ? 215
                    : 265
                : combinedSelection.length > 0
                    ? 280
                    : 355;
        }
        return (_jsx(ClusterCheckList, Object.assign({}, props, { selected: selected, remote: remote, filter: isMain ? filter : undefined, itemFilter: item => {
                const idx = combinedSelection.findIndex(cs => cs.name === item.name);
                return idx > -1 ? !combinedSelection[idx].isOriginal : true;
            }, synonyms: synonyms, emptyMessage: (emptyMessage === null || emptyMessage === void 0 ? void 0 : emptyMessage(hideDropdown)) ||
                (!isMain
                    ? `Select a ${clusterType} to show ${title}`
                    : `Select a ${clusterType}`), sendFeedback: (item, type, context) => {
                sendFeedback(identifier, capability, type, item, getParams(debouncedPrefix));
            }, hideHeaderList: true, style: { height: `${getHeight()}px`, marginBottom: "8px" }, checkboxClassName: entityType, tagColorVals: tagColorVals, renderName: nameRenderer })));
    }
    React.useEffect(() => {
        if (!combinedSelection.length) {
            setActiveTab(0);
        }
    }, [combinedSelection.length]);
    return (_jsxs("div", Object.assign({ className: "ClusterPicker flex flex-col items-stretch rounded-b-lg max-h-[500px] min-w-[516px] h-full" }, { children: [_jsx(Tabs, { className: "", size: "middle", activeKey: activeTab + "", onChange: t => setActiveTab(parseInt(t)), tabBarStyle: {
                    marginBottom: 4,
                    backgroundColor: "#F3F3F3",
                    padding: 8,
                    borderRadius: 4,
                    margin: 8,
                }, tabBarExtraContent: _jsx(Space, Object.assign({ size: "small" }, { children: extraControls === null || extraControls === void 0 ? void 0 : extraControls(combinedSelection, hideDropdown) })), items: [
                    {
                        key: "0",
                        label: (_jsxs("div", Object.assign({ className: "flex align-center" }, { children: [_jsxs("span", { children: [entityName, " "] }), remote && _jsx(BrandBadge, { remote: remote })] }))),
                        children: (_jsxs("div", { children: [filterComponent, createCheckList(remote, true, clusterType, undefined, filterVal, entityType)] })),
                    },
                    ...Object.entries(extraClusterTabs).map(([key, { remote, alwaysEnabled, emptyMessage }], idx) => {
                        const disabled = !alwaysEnabled && !selected.length;
                        return {
                            key: idx + 1 + "",
                            disabled,
                            label: (_jsxs("div", Object.assign({ className: "flex align-center" }, { children: [_jsxs("span", Object.assign({ className: "mr-2" }, { children: [key, " "] })), remote && (_jsx(BrandBadge, { remote: remote, offset: [0, 0], showZero: true }))] }))),
                            children: (_jsx("div", { children: createCheckList(remote, false, key, emptyMessage, filterVal, entityType) })),
                        };
                    }),
                ] }), _jsxs("div", Object.assign({ className: "sticky w-full border-t border-neutral-300" }, { children: [(combinedSelection.length > 0 || extraContent !== undefined) && (_jsxs("div", Object.assign({ className: "px-2" }, { children: [_jsx("div", Object.assign({ className: "mb-6 mt-4" }, { children: extraContent })), combinedSelection.length > 0 && (_jsxs("div", Object.assign({ className: "flex justify-between align-center mb-6 w-full" }, { children: [_jsx("div", Object.assign({ className: "w-[95%]" }, { children: _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(TagsList, { items: combinedSelection, closable: true, className: classes("flex-1 overflow-hidden w-full font-semibold"), tagContent: s => nameRenderer ? nameRenderer(s) : s.name, tagClassName: tagClass, tagStyle: () => tagColorVals && getTagBaseStyle(tagColorVals), onClose: item => {
                                                    const idx = getSelectionIndex(combinedSelection, item);
                                                    if (combinedSelection[idx].isOriginal) {
                                                        const updatedList = getOriginalItems(combinedSelection);
                                                        const selIdx = getSelectionIndex(updatedList, item);
                                                        updatedList[selIdx].removed = true;
                                                        setInitialStatus(updatedList);
                                                    }
                                                    else {
                                                        props.setSelected(selected.filter(i => i.name !== item.name), []);
                                                    }
                                                }, onAdd: item => {
                                                    const updatedList = getOriginalItems(combinedSelection);
                                                    const selIdx = getSelectionIndex(updatedList, item);
                                                    updatedList[selIdx].removed = false;
                                                    setInitialStatus(updatedList);
                                                }, isRemoved: item => item.removed, keepRemoved: true, widthClass: "w-[calc(100%-1em)]" }) })) }), combinedSelection.length), _jsx("div", Object.assign({ className: "rounded bg-neutral-100 cursor-pointer p-1", onClick: clearSelection }, { children: _jsx(Trash, { className: "font-lg flex align-center text-lg" }) }))] })))] }))), _jsxs("div", Object.assign({ className: "flex bg-neutral-100 justify-between px-4 py-5 rounded-b-lg" }, { children: [_jsx("div", Object.assign({ className: "font-semibold" }, { children: summary })), _jsxs("div", Object.assign({ className: "flex justify-end space-x-2" }, { children: [_jsx(BrandButton, Object.assign({ onClick: () => hideDropdown(false) }, { children: "Cancel" })), _jsx(BrandButton, Object.assign({ type: "primary", onClick: () => hideDropdown(true), disabled: remote.loading || !hasUpdates }, { children: "Apply" }))] }))] }))] }))] })));
}
