import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { CRCDirection } from "../../api/types";
import { intercalate } from "../../../utils/collections";
import { FixedArgs } from "../../utils/coraUtil";
import { classes } from "../../../utils/classes";
import { getArrow } from "../../components/DirectionPicker";
import { Tooltip } from "../../../shared/components/Tooltip";
import { getPartsAndColors, } from "../../components/queryRenderer/queryRendererUtils";
const NO_COLOR = "lightgray";
const SPACER_STYLE = { width: 0 };
export function QueryTextRenderer({ parts, suffix, conceptColorGetter, maxTags, partClassName, className, colorProvider, styleProvider, noColor, arrowSize = 16, }) {
    function getContent() {
        if (parts.text)
            return parts.text;
        const entries = getPartsAndColors(parts, renderParts, conceptColorGetter, styleProvider);
        const { dir = CRCDirection.BOTH, extra = [], context = [], argClauses = {}, } = parts;
        const argClausesEntries = Object.entries(argClauses || []);
        return (_jsx(_Fragment, { children: _jsxs("span", Object.assign({ className: "flex flex-row items-center flex-1" }, { children: [intercalate(entries, idx => getArrow(dir, {
                        key: idx + "ARROW",
                        style: {
                            fontSize: arrowSize,
                            // color: arrowColor,
                            marginLeft: 4,
                            marginRight: 4,
                        },
                    })), (argClausesEntries.length > 0 ||
                        context.length > 0 ||
                        extra.length > 0) && (_jsxs(_Fragment, { children: [_jsx("div", { style: SPACER_STYLE }), intercalate([
                                entries.filter(Boolean).length > 0 ? (_jsx("div", Object.assign({ className: "font-bold not-italic mr-1" }, { children: "\u254E Context:" }), ":")) : undefined,
                                renderParts(context, (colorProvider === null || colorProvider === void 0 ? void 0 : colorProvider[FixedArgs.CONTEXT_CONCEPT]) || NO_COLOR, "EXTRA", 0, styleProvider),
                            ], idx => (_jsx("div", { style: SPACER_STYLE }, idx + "SPACE")))] }))] })) }));
    }
    return (_jsxs("div", Object.assign({ className: classes("flex flex-row items-center whitespace-nowrap overflow-auto", className) }, { children: [getContent(), suffix] })));
    function renderParts(parts, color, key, idx, styleProvider) {
        if (!parts.length)
            return null;
        const partRender = (_jsxs("span", Object.assign({ style: styleProvider ? styleProvider(noColor ? NO_COLOR : color) : {}, className: classes(partClassName) }, { children: [parts.slice(0, maxTags).join(", "), maxTags && parts.length > maxTags ? "…" : ""] }), key + idx));
        if (maxTags && parts.length > maxTags) {
            return (_jsx(Tooltip, Object.assign({ content: parts.join(", ") }, { children: partRender }), key + idx));
        }
        return partRender;
    }
}
