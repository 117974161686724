import React from "react";
import { Beacon } from "../../../utils/Beacon";
export var ModalType;
(function (ModalType) {
    ModalType["HISTORY"] = "history";
    ModalType["LIST_MANAGER"] = "listManager";
    ModalType["CONFIG"] = "config";
})(ModalType || (ModalType = {}));
const modals = Object.values(ModalType);
const EMPTY_MODAL_STATE = {
    [ModalType.CONFIG]: false,
    [ModalType.LIST_MANAGER]: false,
    [ModalType.HISTORY]: false,
};
const modalState = Beacon({
    initial: EMPTY_MODAL_STATE,
});
export function updateModalState(newState) {
    modalState.value(typeof newState === "function" ? newState(modalState.value()) : newState);
}
// one at a time
export function openModal(modal) {
    const otherModals = modals.filter(m => m !== modal);
    updateModalState(state => (Object.assign(Object.assign(Object.assign({}, state), { [modal]: true }), Object.fromEntries(otherModals.map(m => [m, false])))));
}
export function closeModal(modal) {
    updateModalState(state => (Object.assign(Object.assign({}, state), { [modal]: false })));
}
export function useModalState() {
    const [state, setState] = React.useState(modalState.value());
    React.useEffect(() => {
        if (modalState.hasValue())
            setState(modalState.value());
        return modalState.on(setState);
    }, [modalState]);
    return [state, updateModalState];
}
