var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Input, Radio } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Menu } from "./Menu";
import { confirmation, promptText } from "../../utils/dialogs";
import { textComparator } from "../../utils/comparators";
import { Empty } from "../components/Empty";
export function useCollectionActions({ selected, name, items, onSelect, onRemove, onCopy, onReplace, onNew, newCaption, saveCaption, sort = true, }) {
    const [filter, setFilter] = React.useState("");
    const [minWidth, setMinWidth] = React.useState(0);
    items = React.useMemo(() => {
        const filtered = !filter
            ? items
            : items.filter(e => name(e).toLowerCase().includes(filter.toLowerCase()));
        return sort
            ? filtered.sort((a, b) => textComparator(name(a), name(b)))
            : filtered;
    }, [items, filter, sort]);
    return React.useMemo(() => [
        {
            renderer: () => (_jsx("div", Object.assign({ ref: ref => {
                    if (ref) {
                        setMinWidth(ref.getBoundingClientRect().width);
                    }
                }, className: "flex flex-col items-stretch", style: minWidth ? { minWidth } : undefined }, { children: _jsx(Input, { autoFocus: true, size: "small", placeholder: "Filter...", value: filter, onChange: e => setFilter(e.target.value), allowClear: true }) }))),
            lastHeader: true,
        },
        ...(items.length ? [Menu.SEPARATOR] : []),
        ...(items.length
            ? items.map(item => ({
                caption: name(item),
                icon: selected && (() => _jsx(Radio, { checked: selected(item) })),
                action() {
                    onSelect(item);
                },
                extra: {
                    icon: _jsx(DeleteOutlined, {}),
                    action() {
                        return __awaiter(this, void 0, void 0, function* () {
                            if (yield confirmation({ title: `Remove ${name(item)}?` })) {
                                onRemove(item);
                            }
                        });
                    },
                },
            }))
            : [{ renderer: () => _jsx(Empty, { empty: "No sessions", size: 64 }) }]),
        ...(items.length ? [Menu.SEPARATOR] : []),
        {
            firstFooter: true,
            caption: `${saveCaption}…`,
            action() {
                return __awaiter(this, void 0, void 0, function* () {
                    const names = new Set(items.map(name));
                    yield promptText({
                        title: saveCaption,
                        placeholder: "Enter name...",
                        exists(name) {
                            return names.has(name);
                        },
                        replace(name) {
                            return __awaiter(this, void 0, void 0, function* () {
                                onReplace(name);
                            });
                        },
                        add(name) {
                            return __awaiter(this, void 0, void 0, function* () {
                                onCopy(name);
                            });
                        },
                    });
                });
            },
        },
        ...(newCaption && onNew
            ? [Menu.SEPARATOR, { caption: newCaption, action: onNew }]
            : []),
    ], [items, selected]);
}
