var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useEffectIfDifferent, useParams } from "../../../utils/hooks";
import "./DMParamsDiagram.less";
import { categorizeSelection, getActiveConstraintId } from "./diagramUtils";
import { API } from "../../api/api";
import { PillButton } from "../../../shared/components/buttons/Buttons";
import "allotment/dist/style.css";
import { configureDiagram } from "./diagramDefinition";
import { useCurrentSession } from "../../utils/sessionManagement";
import { useDiagram, useDiagramChangeListener, useGraphPopupMenu, } from "../../../shared/components/gojs/gojs";
import { DiagramModel, getSelectedNodeKeys } from "./DiagramModel";
import "tippy.js/dist/tippy.css";
import { useNodeTooltip } from "./NodeTooltip";
import { useUpdateDiagramFromQueryParams } from "./diagramController";
import { isEqual, pick, size } from "lodash";
import { selectedBgClass } from "../lf";
import { updateModel } from "./diagramModelUpdater";
import { getActualActiveConstraintId, getEmptyParams, } from "../../utils/queryState/DMParams";
import { DMPicker } from "./DMPicker";
import { getConjunctionBindingsRequest } from "../../utils/conjunctionBindingsRequest";
import { colorizeNodes } from "./colorizeNodes";
import { useInterruptableFetch } from "../../utils/interruptableFetch";
import { getDiffKeys, sameItems } from "../../../utils/collections";
import { Tooltip } from "../../../shared/components/Tooltip";
import { CONCEPT_SELECTION_HACK, ReportPanelHack } from "./ReportPanelHack";
import { useFlags } from "launchdarkly-react-client-sdk";
import { delay } from "../../../utils/util";
import { useQueryState } from "../../utils/queryState/queryState";
import { Empty } from "../../../shared/components/Empty";
import { setPossibleValues, usePossibleValues } from "../../utils/possibleValues";
const EMPTY_DIAGRAM_STATE = {
    refresh: 0,
    // TODO HACK
    showReportHack: false,
    enableReportHack: false,
};
export function DMParamsDiagram({ argsInfo }) {
    const { showGraphReport } = useFlags();
    const [state, stateUpdater] = useQueryState();
    const { corpus_ids, aperture, dmParams, activeConstraintId, overrides } = state;
    const possible = usePossibleValues();
    const [{ refresh, showReportHack, enableReportHack }, updateDiagramState] = useParams(EMPTY_DIAGRAM_STATE);
    const [loading, setLoading] = React.useState(false);
    const divRef = React.useRef(null);
    const diagram = useDiagram(divRef.current, configureDiagram);
    React.useEffect(() => updateDiagramState(EMPTY_DIAGRAM_STATE), [corpus_ids]);
    const { conjunctionBindingsSearchWidth, expandedFacetCount } = useCurrentSession();
    const activeStatementNode = DiagramModel.getModel(diagram).getStatementNode(activeConstraintId);
    useUpdateDiagramModel();
    const onNodeSelectionChanged = React.useCallback((e) => {
        var _a;
        const selected = (_a = e.subject.toArray()) === null || _a === void 0 ? void 0 : _a.map((s) => s.data);
        updateCoraStateFromActive(getActiveConstraintId(DiagramModel.getModel(diagram), selected, activeConstraintId) || "");
    }, [dmParams, stateUpdater, activeConstraintId]);
    useDiagramChangeListener(diagram, "ChangedSelection", onNodeSelectionChanged);
    useUpdateDiagramFromQueryParams(DiagramModel.getModel(diagram), overrides, activeConstraintId, (key, updateNodeKey, newOverrides) => {
        if (key) {
            if (!isEqual(newOverrides, overrides)) {
                const topToPreserve = pick(possible, getDiffKeys(overrides, newOverrides || {}));
                setPossibleValues(!size(topToPreserve) ? null : topToPreserve);
                stateUpdater({
                    overrides: newOverrides || {},
                });
            }
        }
        if (updateNodeKey) {
            if (key) {
                diagram.select(diagram.findPartForKey(key));
            }
            else {
                diagram.clearSelection(false);
            }
        }
    }, [stateUpdater]);
    React.useEffect(() => {
        colorizeNodes(diagram, argsInfo, activeStatementNode);
    }, [argsInfo, activeStatementNode, refresh]);
    useUpdateCoraStateFromActive();
    useGraphPopupMenu(diagram, "ObjectContextClicked", diagram => {
        const { typeNode, conceptNode } = categorizeSelection(diagram.selection.toArray().map(n => n.data));
        const concepts = (typeNode === null || typeNode === void 0 ? void 0 : typeNode.concepts) || (conceptNode && [conceptNode.concept]);
        const sel = typeNode || conceptNode;
        return [];
    }, []);
    // useClearTopConceptsIfStateOrModelChanged()
    const nodeTooltip = useNodeTooltip(diagram, overrides, refresh);
    const findingButtons = useFindingButtons();
    return (_jsxs("div", Object.assign({ className: "h-full relative mb-2 flex flex-col items-stretch bg-white rounded-lg relative" }, { children: [_jsx("div", { ref: divRef, className: "ModelGraph flex-1  relative" }), nodeTooltip, loading ? (_jsx(Empty, { className: "absolute w-full h-full", loading: true })) : dmParams && dmParams.constraints.length > 0 ? null : (_jsx("div", Object.assign({ className: "absolute w-full h-full flex flex-col items-center justify-center text-gray-500 left-0 top-0" }, { children: _jsx("div", { children: "Pick a Concept or Relation" }) }))), _jsxs("div", Object.assign({ className: "p-4 pt-0 space-x-2 flex items-center", style: { bottom: 0, left: 0, zIndex: 10 } }, { children: [_jsx(DMPicker, { onLoading: setLoading }), dmParams && dmParams.constraints.length > 1 && (_jsx(PillButton, Object.assign({ disabled: !Object.values(overrides).flat().length, onClick: () => {
                            updateCoraStateFromActive(null);
                            setTimeout(() => {
                                execAndPreserveSelection(() => {
                                    setPossibleValues(null);
                                    stateUpdater({ overrides: {} });
                                });
                            }, 200);
                        }, className: "bg-gray-300" }, { children: "Reset Graph" }))), dmParams && dmParams.constraints.length > 1 && findingButtons] })), showGraphReport && showReportHack && (_jsx(ReportPanelHack, { onClose: () => updateDiagramState({ showReportHack: false, enableReportHack: false }) }))] })));
    //////////////////////////////////////////////////////////////////////////////
    function useUpdateCoraStateFromActive() {
        useEffectIfDifferent(() => updateCoraStateFromActive(activeConstraintId), [dmParams, overrides, stateUpdater], [activeConstraintId, possible]);
    }
    function updateCoraStateFromActive(activeConstraintId, state = {}) {
        if (dmParams) {
            activeConstraintId = getActualActiveConstraintId(dmParams, activeConstraintId);
            stateUpdater(Object.assign(Object.assign({}, state), { activeConstraintId }));
        }
    }
    function useUpdateDiagramModel() {
        React.useEffect(() => {
            const currentSelection = getSelectedNodeKeys(diagram);
            let newSelection = currentSelection;
            diagram.animationManager.isEnabled = false;
            // if (isEqual(previousDesc, selectedModelDesc)) {
            //   diagramTransaction(diagram, () => {
            //     updateModel(
            //       dmParams || getEmptyParams(),
            //       dmParams ? overrides : {},
            //       dmParams ? possible || {} : {},
            //       DiagramModel.getModel(diagram)
            //     )
            //   })
            // } else {
            diagram.model = updateModel(dmParams || getEmptyParams(), dmParams ? overrides : {}, dmParams ? possible || {} : {});
            // }
            diagram.animationManager.isEnabled = false;
            const nodes = DiagramModel.getModel(diagram).nodeDataArray;
            if (nodes.length === 1) {
                newSelection = nodes.map(n => n.key);
            }
            updateDiagramState(s => ({
                activeConstraintId: getActiveConstraintId(DiagramModel.getModel(diagram), DiagramModel.getModel(diagram).getNodesForKeys(newSelection), null),
                refresh: s.refresh + 1,
            }));
            diagram.requestUpdate();
            if (newSelection.length === 1 &&
                isEqual(currentSelection, newSelection)) {
                diagram.select(diagram.findPartForKey(newSelection[0]));
            }
        }, [
            diagram,
            dmParams,
            overrides,
            possible,
            // previousDesc,
            // selectedModelDesc,
        ]);
    }
    function execAndPreserveSelection(action, keysToSelect) {
        return __awaiter(this, void 0, void 0, function* () {
            const selectedKeys = keysToSelect || getSelectedNodeKeys(diagram);
            yield action();
            selectNodes(selectedKeys, () => updateDiagramState({ refresh: refresh + 1 }));
        });
    }
    function selectNodes(keys, afterSelect) {
        setTimeout(() => {
            diagram.selectCollection(keys
                .map(key => diagram.findNodeForKey(key))
                .filter(Boolean));
            afterSelect && setTimeout(afterSelect, 10);
        }, 0);
    }
    function useFindingButtons() {
        const [fetch, loading] = useInterruptableFetch();
        const conjunctionBindingsParams = dmParams &&
            getConjunctionBindingsRequest(state, conjunctionBindingsSearchWidth, expandedFacetCount);
        const bindingsFor = conjunctionBindingsParams === null || conjunctionBindingsParams === void 0 ? void 0 : conjunctionBindingsParams.query.bindings_for;
        const disabled = Boolean(!(bindingsFor === null || bindingsFor === void 0 ? void 0 : bindingsFor.length) ||
            loading ||
            (possible &&
                bindingsFor &&
                sameItems(Object.keys(possible), bindingsFor)));
        return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ content: "Find concepts that satisfy the model." }, { children: _jsx(PillButton, Object.assign({ disabled: disabled, onClick: () => fetch((signal) => __awaiter(this, void 0, void 0, function* () {
                            diagram.clearSelection();
                            yield delay(() => execAndPreserveSelection(() => __awaiter(this, void 0, void 0, function* () {
                                const possible = yield API.conjunctionBindings(conjunctionBindingsParams, {
                                    signal,
                                    ignoreErrorHandler: true,
                                });
                                if (showGraphReport) {
                                    updateDiagramState({
                                        enableReportHack: true,
                                    });
                                    updateCoraStateFromActive(null, {});
                                    setPossibleValues(CONCEPT_SELECTION_HACK);
                                    return;
                                }
                                updateCoraStateFromActive(null);
                                setPossibleValues(possible);
                            })), 100);
                        }), _jsxs("div", { children: ["Finding Solutions", _jsx("div", { children: "This could take some time..." }), _jsx("div", { children: "We are working so you don't have to." })] })), className: disabled ? "bg-gray-300" : selectedBgClass }, { children: "Explore Solutions" })) })), _jsx(PillButton, Object.assign({ disabled: !possible, onClick: () => {
                        updateCoraStateFromActive(null);
                        setPossibleValues(CONCEPT_SELECTION_HACK);
                        updateDiagramState({ enableReportHack: false });
                    }, className: !possible ? "bg-gray-300" : selectedBgClass }, { children: "Clear Solutions" })), showGraphReport && (_jsx(PillButton, Object.assign({ disabled: !enableReportHack, onClick: () => fetch(() => __awaiter(this, void 0, void 0, function* () {
                        return delay(() => {
                            updateDiagramState({ showReportHack: true });
                            // return true
                        }, 3000);
                    }), _jsx("div", { children: "Generating Report..." })), className: !possible ? "bg-gray-300" : selectedBgClass }, { children: "Show Report" })))] }));
    }
}
