var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { normalize } from "../../utils/objects";
import { EMPTY_CORA_STATE } from "./coraState";
import { EMPTY_SESSION } from "./sessionManagement";
import { isHistoryEntry } from "./history";
import { isLegacyFindingContext } from "./findings";
export function migrateSession(session) {
    session = Object.assign(Object.assign({}, EMPTY_SESSION), session);
    return Object.assign(Object.assign({}, session), { queryHistory: session.queryHistory.map(migrateHistoryEntry), findings: session.findings.map(migrateFinding) });
}
function migrateHistoryEntry(entry) {
    return isHistoryEntry(entry) ? entry : migrateCoraState(entry);
}
export function cleanupSession(session) {
    session = Object.assign(Object.assign({}, EMPTY_SESSION), session);
    return Object.assign(Object.assign({}, session), { queryHistory: session.queryHistory.map(cleanupHistoryEntry), findings: session.findings.map(cleanupFinding) });
}
export function cleanupHistoryEntry(entry) {
    return isHistoryEntry(entry) ? entry : cleanupCoraState(entry);
}
function cleanupFinding(finding) {
    const context = finding.context;
    return !isLegacyFindingContext(context)
        ? finding
        : Object.assign(Object.assign({}, finding), { context: Object.assign(Object.assign({}, context), { coraState: cleanupCoraState(context.coraState) }) });
}
export function cleanupCoraState(coraState) {
    coraState = Object.assign(Object.assign({}, EMPTY_CORA_STATE), coraState);
    return Object.assign(Object.assign({}, coraState), { concepts1: removeDuplicates(coraState.concepts1), concepts2: removeDuplicates(coraState.concepts2), contextConcepts: removeDuplicates(coraState.contextConcepts), extraConcepts: removeDuplicates(coraState.extraConcepts) });
    function removeDuplicates(concepts) {
        return Object.values(Object.fromEntries((concepts || []).map(c => [JSON.stringify(normalize(c, true)), c])));
    }
}
function migrateFinding(finding) {
    const context = finding.context;
    return !isLegacyFindingContext(context)
        ? finding
        : Object.assign(Object.assign({}, finding), { context: Object.assign(Object.assign(Object.assign({}, context), { coraState: migrateCoraState(context.coraState) }), (context.corpus_filter && {
                corpus_filter: migrateCorpusFilter(context.corpus_filter),
            })) });
}
function migrateCorpusFilter(corpus_filter) {
    function migrateMetadataFilter(meta) {
        if (isStrArray(meta.value)) {
            return Object.assign(Object.assign({}, meta), { value: meta.value.map(toMetadataCluster) });
        }
        return meta;
        function isStrArray(value) {
            var _a;
            if (!Array.isArray(value) ||
                !value.length ||
                typeof value[0] !== "string")
                return false;
            const first = (_a = value[0]) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            return first !== "true" && first !== "false";
        }
    }
    return Object.assign(Object.assign({}, corpus_filter), (corpus_filter.metadata && {
        metadata: corpus_filter.metadata.map(migrateMetadataFilter),
    }));
}
export function migrateCoraState(coraState) {
    coraState = Object.assign(Object.assign({}, EMPTY_CORA_STATE), coraState);
    return Object.assign(Object.assign({}, coraState), { keywordMetadata: coraState.keywordMetadata.map(migrateKeywordMetadata) });
}
export function migrateKeywordMetadata(_a) {
    var { value } = _a, rest = __rest(_a, ["value"]);
    return Object.assign(Object.assign({}, rest), { value: typeof value[0] === "string"
            ? value.map(toMetadataCluster)
            : value });
}
function toMetadataCluster(name) {
    return { name, ids: [name] };
}
