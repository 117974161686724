var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../utils/classes";
import { Down } from "../../../cora/components/Icons";
import { Spin } from "antd";
import { DivButton } from "./Buttons";
import { Tooltip } from "../Tooltip";
export function SelectionButton(_a) {
    var { className, selected, icon, caption, beta, loading, isDropdown, tooltip, tooltipPlacement, dropDownCallBack } = _a, props = __rest(_a, ["className", "selected", "icon", "caption", "beta", "loading", "isDropdown", "tooltip", "tooltipPlacement", "dropDownCallBack"]);
    const button = (_jsxs(DivButton, Object.assign({ className: classes("flex flex-nowrap items-center border rounded-xl py-2 px-4 font-medium text-tiny", selected
            ? "border-accent-500 bg-accent-100 text-accent-500"
            : "text-neutral-800 border-neutral-300 bg-white", "hover:border-accent-500 hover:text-accent-500 hover:bg_neutral-100", className) }, props, { children: [icon && _jsx("div", Object.assign({ className: "text-base flex items-center mr-1" }, { children: icon })), caption, beta && (_jsx("div", Object.assign({ className: classes("text-xxs ml-1 px-1 py-0.5 rounded", selected ? "bg-white" : "bg-neutral-300") }, { children: "BETA" }))), isDropdown && (_jsx(Down, { className: "text-base ml-1", onClick: dropDownCallBack })), loading && _jsx(Spin, { size: "small", className: "py-auto ml-1" })] })));
    return tooltip ? (_jsx(Tooltip, Object.assign({ content: tooltip, placement: tooltipPlacement }, { children: button }))) : (button);
}
