import { jsx as _jsx } from "react/jsx-runtime";
import { getTwColor } from "./lf";
import React from "react";
const BAR_WIDTH = 24;
const BAR_HEIGHT = 4;
export function ConfidenceBar({ confidence, width = BAR_WIDTH, classname, }) {
    return (_jsx("div", Object.assign({ className: classname, style: {
            minWidth: width,
            height: BAR_HEIGHT,
            borderRadius: BAR_HEIGHT / 2,
            backgroundColor: getTwColor("gray-200"),
            position: "relative",
        } }, { children: _jsx("div", { style: {
                position: "absolute",
                top: 0,
                left: 0,
                width: width * confidence,
                height: BAR_HEIGHT,
                borderRadius: BAR_HEIGHT / 2,
                backgroundColor: getConfidenceColor(confidence),
            } }) })));
}
export function getConfidenceColor(confidence) {
    return confidence > 0.5 ? "green" : confidence > 0.3 ? "orange" : "red";
}
