var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import React from "react";
import "./SelectionPicker.less";
import { UserOutlined } from "@ant-design/icons";
import { flushCurrentSession } from "../utils/sessionManagement";
import { setLoading } from "../../shared/components/Loading";
import { Avatar } from "antd";
import { getCurrentUserInfo, logout } from "../../utils/authInfo";
export function UserMenu() {
    return (_jsx(DropDownMenu, Object.assign({ title: getCurrentUserInfo().userName, placement: "bottomRight", entries: () => [
            {
                caption: "Logout",
                action() {
                    return __awaiter(this, void 0, void 0, function* () {
                        setLoading(true);
                        yield flushCurrentSession();
                        logout();
                    });
                },
            },
        ] }, { children: _jsx(Avatar, { className: "flex items-center justify-center", icon: _jsx(UserOutlined, {}) }) })));
}
