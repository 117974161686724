import { jsx as _jsx } from "react/jsx-runtime";
import { MiniMap } from "reactflow";
import { getTwColor } from "../lf";
import React from "react";
import { isActive, isSelected } from "./diagramUtils";
const miniMapNodeColor = (n) => isActive(n) && isSelected(n)
    ? getTwColor("accent-500")
    : isActive(n)
        ? "#dbe9ff"
        : isSelected(n)
            ? "#888888"
            : "rgba(0,0,0,0.1)";
export function ViewMap() {
    return _jsx(MiniMap, { nodeColor: miniMapNodeColor, pannable: true, zoomable: true });
}
