import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { columnMinWidthClass1_25 } from "../../lf";
import { CollectionManager } from "../../../../shared/components/CollectionManager";
import { mapperComparator, textComparator } from "../../../../utils/comparators";
import { ValidationIcon } from "../../../../shared/components/InputValidation";
import { XTag } from "../../../../shared/components/XTag";
export const widthClassName = columnMinWidthClass1_25;
export function CustomListEntries({ list, update, controls, empty, title, readonly, }) {
    const currentListName = list === null || list === void 0 ? void 0 : list.name;
    return (_jsx(CollectionManager, { title: title, className: widthClassName, empty: empty, items: list === null || list === void 0 ? void 0 : list.accepted_concepts, itemClassName: "hover:bg-gray-200", itemRender: item => _jsx(XTag, Object.assign({ className: "font-semibold" }, { children: item })), onRemove: readonly
            ? undefined
            : item => {
                update(Object.assign(Object.assign({}, list), { members: list.members.filter(m => m.id !== item), accepted_concepts: list.accepted_concepts.filter(m => m !== item) }));
            }, sorter: mapperComparator(item => item, textComparator), itemText: item => item, controls: controls || (_jsx(ValidationIcon, { error: !(list === null || list === void 0 ? void 0 : list.members.length) && "No Entries" })) }, currentListName));
}
