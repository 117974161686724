// if multiple invocations to the caller with the same params happen, only one call is made
export function OnlyOneAsyncCall(caller) {
    const pending = {};
    return (params) => {
        const key = JSON.stringify(params);
        const promise = pending[key] ||
            new Promise((resolve, reject) => {
                caller(params)
                    .then(result => {
                    delete pending[key];
                    resolve(result);
                })
                    .catch(reason => {
                    delete pending[key];
                    reject(reason);
                });
            });
        pending[key] = promise;
        return promise;
    };
}
