var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { Emitter } from "../../../utils/Emitter";
import { useStage } from "../modelBuilder/Stage";
import { useCurrentSession } from "../../utils/sessionManagement";
import { useInterruptableFetch } from "../../utils/interruptableFetch";
import { API } from "../../api/api";
import { useSuggestQuestionsFromConcepts } from "../../../shared/components/SuggestedQuestions";
import { getSize } from "../../../utils/util";
import { useQueryState } from "../../utils/queryState/queryState";
import { useCoraState } from "../CoraStateProvider";
import { useNLQuestion } from "./useNLQuestion";
import { useQuestionHistory } from "./useQuestionHistory";
import { implyActiveConstraintId } from "../../utils/queryState/constraints";
import { dmParamsToDiagram } from "../modelBuilder/adapters/dmParamsToDiagram";
import { conjunctiveToDMParams } from "../../utils/queryState/DMParams";
const questionEmitter = Emitter();
export function postQuestion(text) {
    questionEmitter.emit(text);
}
export function useOnQuestionPost(callback) {
    React.useEffect(() => questionEmitter.on(callback), [callback]);
}
export function isValidQuestion(text) {
    return text.trim().length > 3;
}
export function useQuestionAnalyzer() {
    const [, stateUpdater] = useQueryState();
    const [coraState] = useCoraState();
    const { corpus_ids } = coraState;
    const stage = useStage();
    const { modelBuilderAvailable, modelBuilderMode } = useCurrentSession();
    const [fetch] = useInterruptableFetch();
    const suggest = useSuggestQuestionsFromConcepts(coraState);
    return React.useCallback((text) => {
        return new Promise((resolve, reject) => {
            if (isValidQuestion(text)) {
                const question = text.trim();
                fetch((signal) => __awaiter(this, void 0, void 0, function* () {
                    const resp = yield API.analyzeQuery({ query: question, corpus_ids }, { signal, ignoreErrorHandler: true } // ignoreErrorHandler is needed because we handle errors with interruptableFetch
                    );
                    const dmParams = conjunctiveToDMParams(resp, question);
                    if (modelBuilderMode && modelBuilderAvailable) {
                        const nodesAndEdges = dmParamsToDiagram(dmParams);
                        stage(nodesAndEdges, {
                            active: true,
                            layout: "H",
                            newKeys: true,
                        });
                    }
                    else {
                        stateUpdater({
                            dmParams,
                            activeConstraintId: implyActiveConstraintId(dmParams),
                        });
                    }
                    getSize(dmParams.concepts) &&
                        suggest(Object.values(dmParams.concepts).flat());
                    resolve(true);
                }), "Analyzing Question…");
            }
            else {
                resolve(false);
            }
        });
    }, [corpus_ids, stateUpdater, modelBuilderAvailable, modelBuilderMode, stage]);
}
export function useQuestionAnalysisHelper() {
    const { setQuestionText } = useNLQuestion();
    const { add: addQuestionToHistory } = useQuestionHistory();
    const analyzeQuestion = useQuestionAnalyzer();
    return function analyze(text) {
        setQuestionText(text);
        analyzeQuestion(text).then(success => {
            if (!success)
                return;
            addQuestionToHistory(text.trim());
        });
    };
}
