export function Bounds(x = 0, y = 0, w = 0, h = 0) {
    return [x, y, w, h];
}
Bounds.x = (b) => b[0];
Bounds.y = (b) => b[1];
Bounds.w = (b) => b[2];
Bounds.h = (b) => b[3];
Bounds.xMax = (b) => Bounds.x(b) + Bounds.w(b);
Bounds.yMax = (b) => Bounds.y(b) + Bounds.h(b);
Bounds.fromDiagonal = (x1, y1, x2, y2) => {
    if (x2 < x1) {
        const t = x1;
        x1 = x2;
        x2 = t;
    }
    if (y2 < y1) {
        const t = y1;
        y1 = y2;
        y2 = t;
    }
    return Bounds(x1, y1, x2 - x1, y2 - y1);
};
function inSegment(offset, len, x) {
    return x >= offset && x <= offset + len;
}
Bounds.contains = ([ax, ay, aw, ah], [bx, by, bw, bh]) => inSegment(ax, aw, bx) &&
    inSegment(ax, aw, bx + bw) &&
    inSegment(ay, ah, by) &&
    inSegment(ay, ah, by + bh);
Bounds.intersects = ([ax, ay, aw, ah], [bx, by, bw, bh]) => ((aw > 0 && ah > 0 && bw > 0) || bh > 0) &&
    ax + aw > bx &&
    ay + ah > by &&
    ax < bx + bw &&
    ay < by + bh;
Bounds.intersection = ([ax, ay, aw, ah], [bx, by, bw, bh]) => {
    const x1 = Math.max(ax, bx);
    const y1 = Math.max(ay, by);
    const x2 = Math.min(ax + aw, bx + Math.max(0, bw));
    const y2 = Math.min(ay + ah, by + Math.max(0, bh));
    return Bounds.fromDiagonal(x1, y1, x2, y2);
};
Bounds.union = ([ax, ay, aw, ah], [bx, by, bw, bh]) => {
    const x1 = Math.min(ax, bx);
    const y1 = Math.min(ay, by);
    const x2 = Math.max(ax + aw, bx + Math.max(0, bw));
    const y2 = Math.max(ay + ah, by + Math.max(0, bh));
    return Bounds.fromDiagonal(x1, y1, x2, y2);
};
Bounds.toDiagonal = ([x1, y1, w, h]) => Diagonal(x1, y1, x1 + w, y1 + h);
export function Diagonal(x1, y1, x2, y2) {
    return [
        Math.min(x1, x2),
        Math.min(y1, y2),
        Math.max(x1, x2),
        Math.max(y1, y2),
    ];
}
Diagonal.x1 = (b) => b[0];
Diagonal.y1 = (b) => b[1];
Diagonal.x2 = (b) => b[2];
Diagonal.y2 = (b) => b[3];
