import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { EvidenceType } from "../../api/types";
import { RelationsTypePicker, useRelationsTypeMenuEntry, } from "../../components/evidence/RelationsTypePicker";
import { evidenceTypeFromRefuting } from "../../utils/evidence";
export function useRelationsTypeAction(coraState, coraStateUpdater, noSelection, evidenceCount) {
    const { refuting } = coraState;
    const evidenceType = evidenceTypeFromRefuting(refuting);
    const setSelectedEvidenceType = (type) => coraStateUpdater({ refuting: type === EvidenceType.REFUTING });
    const entries = useRelationsTypeMenuEntry(evidenceType, setSelectedEvidenceType);
    return {
        name: "relationsType",
        spaceRequired: 200,
        priority: 2,
        expandedDisplay: (_jsx(RelationsTypePicker, { setSelectedEvidenceType: type => coraStateUpdater({ refuting: type === EvidenceType.REFUTING }), selectedEvidenceType: evidenceType, coraState: coraState, coraStateUpdater: coraStateUpdater, noSelection: noSelection, showingCount: evidenceCount })),
        collapsedDisplay: {
            sectionTitle: "Show",
            entries,
        },
    };
}
