var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Tree } from "antd";
import React from "react";
import { Down } from "../Icons";
import { useEffectIfDifferent, useParams } from "../../../utils/hooks";
import { showPopupMenu } from "../../../shared/actions/PopupMenu";
import { uniq } from "lodash";
import "./FacetTree.less";
import { NOT } from "../../../utils/filters";
export function FacetTree({ options, getPopupMenuEntries, values, disabled, checkboxClass, selectedNames, selected, setSelected, getCheckBoxEntry, prefix, getSuffix, params: suffixParams, toOption, hierarchical, loaderParamsGetter, subEntriesLoaderParamsGetter, facetLoader, subEntriesLoader, valueToSelection, max_count, itemInCollectionFilter, facetFor, }) {
    const loader = subEntriesLoader || facetLoader;
    const paramsGetter = subEntriesLoaderParamsGetter || loaderParamsGetter;
    const [{ treeData, index }, update] = useParams({
        treeData: [],
        index: { nodes: {}, parents: {} },
    });
    const selectedKeysSet = React.useMemo(() => new Set(selected.map(getName)), [selected]);
    const selectedKeys = React.useMemo(() => getKeysEndingIn(treeData, selectedKeysSet), [treeData, selectedKeysSet]);
    useEffectIfDifferent(() => {
        const treeData = options.map(option => index.nodes[option.label] ||
            createNode(getName(option.item), option, isLeafNode(option.item)));
        update({
            treeData,
            index: createIndex(treeData),
        });
    }, [index, treeData], [options], true);
    const filteredTreeData = React.useMemo(() => filterHiddenNodes(treeData, selectedKeysSet, false), [treeData, selectedKeysSet]);
    function renderTitle(option) {
        return (_jsxs("div", Object.assign({ className: "flex-1 flex auto_hide_container items-center overflow-hidden", onContextMenu: getPopupMenuEntries &&
                (e => {
                    e.preventDefault();
                    showPopupMenu({
                        top: e.clientY,
                        left: e.clientX,
                        entries: (getPopupMenuEntries === null || getPopupMenuEntries === void 0 ? void 0 : getPopupMenuEntries(option.item, values)) || [],
                    });
                }) }, { children: [_jsx(Checkbox, Object.assign({ disabled: disabled, className: checkboxClass, checked: selectedNames.has(option.value), onChange: e => {
                        if (e.target.checked) {
                            const keysToRemove = new Set(getKeysEndingIn(treeData, new Set([
                                ...getPredecessors(treeData, option.key),
                                ...getSuccessors(option),
                            ].map(node => getName(node.item)))));
                            setSelected(selected
                                .filter(s => !keysToRemove.has(getName(s)))
                                .concat(option.item));
                        }
                        else {
                            setSelected(selected.filter(s => getName(s) !== getName(option.item)));
                        }
                    } }, { children: getCheckBoxEntry(option, prefix) })), getSuffix === null || getSuffix === void 0 ? void 0 : getSuffix(option.item, values, suffixParams)] })));
    }
    return !filteredTreeData.length ? null : (_jsx(Tree, { className: hierarchical ? "FacetTree Hierarchical" : "FacetTree Flat", style: { backgroundColor: "none" }, checkable: false, blockNode: true, selectable: false, switcherIcon: _jsx(Down, {}), treeData: filteredTreeData, loadData: onLoadData, checkedKeys: selectedKeys, titleRender: option => renderTitle(option) }));
    function getName(s) {
        return toOption(s).label;
    }
    function isLeafNode(s) {
        return hierarchical ? !toOption(s).hasChildren : !hierarchical;
    }
    function createIndex(treeData, index = {
        nodes: {},
        parents: {},
    }) {
        for (const node of treeData) {
            index.nodes[node.key] = node;
            if (node.children) {
                node.children.forEach((child) => {
                    const parents = index.parents[child.key] || [];
                    parents.push(node.key);
                    index.parents[child.key] = parents;
                });
                createIndex(node.children, index);
            }
        }
        return index;
    }
    function onLoadData(n) {
        return __awaiter(this, void 0, void 0, function* () {
            const { key, children } = n;
            if (children)
                return;
            const node = index.nodes[key];
            const { item } = node;
            const loaderParams = paramsGetter({
                prefix: "",
                maxCount: max_count,
                extraArg: item,
            });
            const isParent = itemInCollectionFilter((facetFor ? [facetFor] : []).concat((index.parents[node.key] || []).map(key => index.nodes[key].item)));
            const items = loaderParams
                ? (yield loader(loaderParams)).filter(NOT(isParent))
                : [];
            const updated = updateTreeData(treeData, new Set(getKeysEndingIn(treeData, new Set([getName(node.item)]))), key => {
                return items.map(r => {
                    const s = valueToSelection(r);
                    return createNode(`${key}/${getName(s)}`, toOption(s), isLeafNode(s), selectedKeysSet.has(getName(s)));
                });
            });
            update({ treeData: updated, index: createIndex(updated) });
        });
    }
    function createNode(key, option, isLeaf = false, hidden = false) {
        return Object.assign(Object.assign({}, option), { key,
            isLeaf,
            hidden, title: option.label });
    }
}
function updateTreeData(treeData, keys, childrenCreator) {
    return treeData.map(node => {
        if (keys.has(node.key)) {
            const children = childrenCreator(node.key);
            return Object.assign(Object.assign({}, node), { isLeaf: !children.length, children });
        }
        else if (node.children) {
            return Object.assign(Object.assign({}, node), { children: updateTreeData(node.children, keys, childrenCreator) });
        }
        return node;
    });
}
function filterHiddenNodes(treeData, selectedKeysSet, filterLevel) {
    return treeData
        .filter(node => !filterLevel || !selectedKeysSet.has(getKeyPars(node).at(-1)))
        .map(node => (Object.assign(Object.assign({}, node), (node.children && {
        children: filterHiddenNodes(node.children, selectedKeysSet, true),
    }))));
}
function getSuccessors(node, successors = []) {
    var _a;
    if ((_a = node.children) === null || _a === void 0 ? void 0 : _a.length) {
        node.children.forEach((child) => {
            successors.push(child);
            getSuccessors(child, successors);
        });
    }
    return successors;
}
function getPredecessors(treeData, key) {
    function getNodePredecessors(node, ...predecessors) {
        if (node.key === key)
            return predecessors;
        predecessors = [...predecessors, node];
        for (const child of node.children || []) {
            const p = getNodePredecessors(child, ...predecessors);
            if (p)
                return p;
        }
    }
    for (const node of treeData) {
        const path = getNodePredecessors(node);
        if (path)
            return path;
    }
    return [];
}
function getKeysEndingIn(treeData, suffixes) {
    const keys = [];
    function find(nodes = []) {
        nodes.forEach(node => {
            const keyPars = getKeyPars(node);
            if (suffixes.has(keyPars[keyPars.length - 1]))
                keys.push(node.key);
            find(node.children);
        });
    }
    find(treeData);
    return uniq(keys);
}
function getKeyPars(node) {
    return node.key.split("/");
}
