var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function delay(provider, millis) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(provider());
            }, millis);
        });
    });
}
export function hashCode(str) {
    let hash = 0, i, chr;
    if (str.length === 0)
        return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
export function getElementById(elementId) {
    return document.getElementById(elementId);
}
export function focusElementById(id) {
    const diagram = document.getElementById(id);
    diagram === null || diagram === void 0 ? void 0 : diagram.focus();
}
export function scrollToElement(elem, delay = 0) {
    setTimeout(() => {
        var _a;
        (_a = elem === null || elem === void 0 ? void 0 : elem()) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "center",
        });
    }, delay);
}
export function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export function isNully(v) {
    return v === undefined || v === null;
}
export function getValue(original, vOrFn) {
    return typeof vOrFn === "function" ? vOrFn(original) : vOrFn;
}
export function timeout(promise, millis, timeoutMessage = "timeout") {
    return __awaiter(this, void 0, void 0, function* () {
        let timeoutID;
        const result = yield Promise.race([
            promise,
            new Promise((resolve, reject) => {
                timeoutID = setTimeout(() => reject(timeoutMessage), millis);
            }),
        ]);
        timeoutID && clearTimeout(timeoutID);
        return result;
    });
}
export function identity(v) {
    return v;
}
const EMPTY_ARR = [];
const EMPTY_OBJ = {};
export function emptyArr() {
    return EMPTY_ARR;
}
export function emptyObj() {
    return EMPTY_OBJ;
}
export function limitLength(text, len = Number.MAX_SAFE_INTEGER) {
    if (!text)
        return "";
    return text.length <= len ? text : text.slice(0, len) + "…";
}
export function cleanupHtmlTags(html) {
    return html.replaceAll("<em>", "").replaceAll("</em>", "");
}
export function resolveValue(i) {
    return typeof i === "function" ? i() : i;
}
export function keysFor(item) {
    return Object.keys(item);
}
export function selection(defaultValue, ...options) {
    return options.find(o => o) || defaultValue;
}
export function toBoolean(value) {
    return typeof value === "boolean"
        ? value
        : String(value).toLowerCase() === String(true).toLowerCase();
}
export function getSize(obj) {
    return Array.isArray(obj) || typeof obj === "string"
        ? obj.length
        : !obj
            ? 0
            : Object.keys(obj).length;
}
export function actuator(actuators) {
    return (v) => {
        for (const actuator of actuators) {
            if (actuator(v))
                return true;
        }
        return false;
    };
}
