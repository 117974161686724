import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import { useHorizontalScroll } from "../../utils/hooks";
export function Scroller({ children, className = "", flex = 1, style, id, direction = "XY", noAbsolute, }) {
    const ref = useHorizontalScroll();
    return (_jsx("div", Object.assign({ id: id, className: classes("Scroller relative", direction === "Y"
            ? "overflow-y-auto overflow-x-hidden"
            : "overflow-auto", className), style: Object.assign(Object.assign({}, (flex > 0 && { flex })), style), ref: direction === "X" ? ref : null }, { children: _jsx("div", Object.assign({ className: classes(!noAbsolute && "absolute", direction !== "X" ? "w-full" : "h-full w-full") }, { children: children })) })));
}
