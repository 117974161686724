import { CRCDirection, } from "../api/types";
import { createDependencies } from "../../utils/dependencies";
import { pick } from "lodash";
import React from "react";
import { Beacon, ParamsBeacon, useBeacon } from "../../utils/Beacon";
import { keysFor } from "../../utils/util";
import { EMPTY_COMMON_STATE_PARAMS, METADATA, REFUTING_QUERY_PARAMS, } from "./commonStateParams";
export const ENCODED_QUERY_PARAM_KEY = "dataId";
export const CONCEPTS1_OVERRIDE = {
    concepts1Override: {},
};
export const CONCEPTS1_POSSIBLE = {
    concepts1Possible: null,
};
export const CONCEPT1 = Object.assign(Object.assign({ concepts1: [] }, CONCEPTS1_OVERRIDE), CONCEPTS1_POSSIBLE);
export const CONCEPTS2_OVERRIDE = {
    concepts2Override: {},
};
export const CONCEPTS2_POSSIBLE = {
    concepts2Possible: null,
};
export const CONCEPT2 = Object.assign(Object.assign({ concepts2: [] }, CONCEPTS2_OVERRIDE), CONCEPTS2_POSSIBLE);
export const EXTRA_CONCEPTS_OVERRIDE = {
    extraConceptsOverride: {},
};
export const EXTRA_CONCEPTS_POSSIBLE = {
    extraConceptsPossible: null,
};
export const EXTRA_CONCEPTS = Object.assign(Object.assign({ extraConcepts: [] }, EXTRA_CONCEPTS_OVERRIDE), EXTRA_CONCEPTS_POSSIBLE);
export const CONTEXT_CONCEPTS_OVERRIDE = {
    contextConceptsOverride: {},
};
export const CONTEXT_CONCEPTS_POSSIBLE = {
    contextConceptsPossible: null,
};
export const CONTEXT_CONCEPTS = Object.assign(Object.assign({ contextConcepts: [] }, CONTEXT_CONCEPTS_OVERRIDE), CONTEXT_CONCEPTS_POSSIBLE);
export const RELATION = {
    relations: [],
};
export const DEFAULT_CRC_DIRECTION = CRCDirection.BOTH;
export const EMPTY_QUERY_PARAMS = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, CONCEPT1), RELATION), CONCEPT2), EXTRA_CONCEPTS), CONTEXT_CONCEPTS), REFUTING_QUERY_PARAMS), { crcDirection: DEFAULT_CRC_DIRECTION, argClauses: {}, required_arguments: [], text: "" });
export const EMPTY_CORA_STATE = Object.assign(Object.assign(Object.assign({}, EMPTY_COMMON_STATE_PARAMS), EMPTY_QUERY_PARAMS), { queries: [] });
export const EMPTY_CRC = {
    concepts1: [],
    concepts2: [],
    relations: [],
    crcDirection: DEFAULT_CRC_DIRECTION,
    queries: [],
    text: "",
};
export const EMPTY_CRC_AND_CONTEXT = Object.assign(Object.assign({}, EMPTY_CRC), { contextConcepts: [] });
export const EMPTY_FILTERS = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, METADATA), { required_arguments: [], argClauses: {} }), CONCEPTS1_OVERRIDE), CONCEPTS2_OVERRIDE), EXTRA_CONCEPTS), EXTRA_CONCEPTS_OVERRIDE), CONTEXT_CONCEPTS_OVERRIDE);
export const CRC_KEYS = keysFor(EMPTY_CRC);
export function filterCoraStateQueryParams(update) {
    return pick(update, ...Object.keys(EMPTY_CORA_STATE));
}
const extraDependenciesBeacon = ParamsBeacon(Beacon({
    initial: {},
}));
export function registerExtraClearDependencies(key, items, dependencies) {
    extraDependenciesBeacon.value({ [key]: { items, dependencies } });
}
export function unregisterExtraClearDependencies(key) {
    extraDependenciesBeacon.value({ [key]: undefined });
}
const DEFAULT_UPDATE_CONFIG = {
    filter: true,
};
export function useCoraStateUpdater(updater) {
    const [extraDependencies] = useBeacon(extraDependenciesBeacon);
    return React.useCallback(updateOrFn => {
        updater(state => {
            const update = typeof updateOrFn === "function" ? updateOrFn(state) : updateOrFn;
            function calcUpdate() {
                function getDependencies() {
                    const deps = createDependencies()
                        .define("corpus_ids", [
                        ...keysFor(CONCEPT1),
                        ...keysFor(CONCEPT2),
                        ...keysFor(RELATION),
                        ...keysFor(METADATA),
                        ...keysFor(EXTRA_CONCEPTS),
                        ...keysFor(CONTEXT_CONCEPTS),
                        "evidenceFilter",
                        "required_arguments",
                        "argClauses",
                        "crcDirection",
                        "queries",
                        "sortFieldId",
                        "sortOrder",
                        "text",
                        "dmParams",
                        "activeConstraintId",
                    ])
                        .define(CRC_KEYS, [
                        ...keysFor(CONCEPTS1_OVERRIDE),
                        ...keysFor(CONCEPTS2_OVERRIDE),
                        ...keysFor(CONCEPTS1_POSSIBLE),
                        ...keysFor(CONCEPTS2_POSSIBLE),
                        "required_arguments",
                        "argClauses",
                        "evidenceFilter",
                    ])
                        .define("extraConcepts", keysFor(EXTRA_CONCEPTS_OVERRIDE))
                        .define(["contextConcepts"], keysFor(CONTEXT_CONCEPTS_OVERRIDE))
                        .define([
                        ...keysFor(CONCEPTS1_OVERRIDE),
                        ...keysFor(CONCEPTS2_OVERRIDE),
                        "required_arguments",
                        "argClauses",
                        "rangeMetadata",
                        "keywordMetadata",
                        "booleanMetadata",
                    ], [...keysFor(REFUTING_QUERY_PARAMS), "rangeMetadataOverride"])
                        .define([...keysFor(REFUTING_QUERY_PARAMS)], ["rangeMetadataOverride"]);
                    Object.values(extraDependencies).forEach(v => deps.define(v.items, v.dependencies));
                    return deps.dependencies(...Object.keys(update));
                }
                const keysToUpdate = getDependencies();
                return keysToUpdate.reduce((acc, k) => {
                    acc[k] = k in update ? update[k] : EMPTY_CORA_STATE[k];
                    return acc;
                }, {});
            }
            const enhancedUpdate = calcUpdate();
            // console.debug("UPDATE", enhancedUpdate)
            return enhancedUpdate;
        });
    }, [updater, extraDependencies]);
}
export function getMetadataSearchParams(params) {
    return pick(params, keysFor(METADATA));
}
