var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ALL_OVERRIDES, coraStateToQueryParts } from "../components/QueryRenderer";
import { API } from "../api/api";
import { pick } from "lodash";
import { keysFor } from "../../utils/util";
import { METADATA } from "./commonStateParams";
import { QueryId } from "./queryState/queryId";
import { updateNLQuestionText } from "../components/queryAnalysis/useNLQuestion";
import { queryStateToCoraState } from "./queryState/queryStateToCoraState";
export function isHistoryEntry(e) {
    return "queryId" in e;
}
export function getQueryStateHistoryEntry(queryState) {
    return __awaiter(this, void 0, void 0, function* () {
        const coraState = queryStateToCoraState(queryState);
        const queryParts = coraStateToQueryParts(coraState, ALL_OVERRIDES);
        const queryId = yield API.getUrlId({ data: queryState });
        return Object.assign(Object.assign({}, pick(queryState, ["corpus_ids", ...keysFor(METADATA)])), { queryParts, text: coraState.text, time: Date.now(), queryId });
    });
}
export function restoreQuery(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const queryId = isHistoryEntry(entry)
            ? entry.queryId
            : yield QueryId.getQueryIdForCoraState(entry);
        QueryId.set(queryId);
        updateNLQuestionText("");
    });
}
