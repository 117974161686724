import { EMPTY_QUERY_PARAMS } from "../../../utils/coraState";
import { corpusFilterToQueryParams, getCorpusFilter, } from "../../../utils/semanticSearchUtil";
import { dmParamsToDiagram } from "./dmParamsToDiagram";
import { diagramToQueryState } from "./diagramToQueryState";
import { getConstraintsContaining } from "../../../utils/queryState/constraints";
import { dmParamsToQueryParams } from "../../../utils/queryState/dmParamsToQueryParams";
import { queryParamsToDMParams } from "../../../utils/queryState/queryParamsToDMParams";
import { EMPTY_VIEWPORT } from "../../../utils/queryState/queryState";
export function diagramToCorpusFilter(state, node, nodes, edges, possible, excludeIds = [] // TODO
) {
    const q = diagramToQueryState({ nodes, edges, viewport: EMPTY_VIEWPORT }, state);
    const activeConstraintId = getConstraintsContaining(q.dmParams.constraints, node.id)[0];
    const queryParams = dmParamsToQueryParams(q.dmParams, activeConstraintId, q.overrides, possible, q.extra, q.extraOverrides, excludeIds);
    return getCorpusFilter(Object.assign({ corpus_ids: q.corpus_ids, aperture: q.aperture }, queryParams));
}
export function corpusFilterToDiagram(corpus_filter) {
    const [params] = queryParamsToDMParams(Object.assign(Object.assign({}, EMPTY_QUERY_PARAMS), corpusFilterToQueryParams(corpus_filter)));
    return dmParamsToDiagram(params);
}
