import { applyEdgeChanges, applyNodeChanges, useReactFlow, useViewport, } from "reactflow";
import { useEffectIfDifferent } from "../../../utils/hooks";
import React from "react";
import { useDiagramState, useFireDiagramStateCommits } from "./DiagramState";
import { isEqual } from "lodash";
export function useSyncReactFlowAndState() {
    const [state, dUpdater] = useDiagramState();
    const { nodes, edges, viewport: defaultViewport } = state;
    useFireDiagramStateCommits(state, dUpdater);
    useSyncViewport();
    return {
        nodes,
        edges,
        defaultViewport,
        onNodesChange: React.useCallback((c) => dUpdater.update(s => ({
            nodes: applyNodeChanges(c, s.nodes),
        })), []),
        onEdgesChange: React.useCallback((c) => dUpdater.update(s => ({
            edges: applyEdgeChanges(c, s.edges),
        })), []),
    };
    function useSyncViewport() {
        const viewport = useViewport();
        useEffectIfDifferent(() => dUpdater.update(state => {
            return isEqual(state.viewport, viewport) ? state : { viewport };
        }), [], [viewport]);
        const flow = useReactFlow();
        useEffectIfDifferent(() => !isEqual(flow.getViewport(), defaultViewport) &&
            flow.setViewport(defaultViewport), [flow], [defaultViewport]);
    }
}
