import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { popModal, pushModal } from "../../../shared/components/Modal";
import DocViewer from "../DocViewer";
import { Button } from "antd";
import { bgBrand700, columnWidthClass2 } from "../lf";
import { ShareFromSquare } from "../Icons";
import { classes } from "../../../utils/classes";
export function showDocViewer({ highlight, url, doc, title, onSelectionChange, metadata, metadataSorter, externalUrl, disableScrollToHighlight, }) {
    pushModal({
        title,
        easyClose() {
            return true;
        },
        titleColorClass: bgBrand700,
        className: "w-[80%]",
        content: (_jsxs("div", Object.assign({ className: "flex-1 flex flex-col space-y-2 items-stretch" }, { children: [_jsx(DocViewer, { className: "flex-1", doc: doc, metadata: metadata
                        ? metadataSorter
                            ? metadataSorter(metadata)
                            : metadata
                        : [], url: url, highlight: highlight, onSelectionChange: onSelectionChange, disableScrollToHighlight: disableScrollToHighlight }), _jsxs("div", Object.assign({ className: classes("flex items-center", externalUrl ? "justify-between" : "justify-end") }, { children: [externalUrl && (_jsxs("a", Object.assign({ "aria-disabled": true, href: externalUrl, target: "_blank", rel: "noreferrer", className: "flex align-center items-center" }, { children: [_jsx(ShareFromSquare, { className: "text-base mr-1" }), _jsx("span", Object.assign({ className: "" }, { children: "Original Document" }))] }))), _jsx(Button, Object.assign({ className: columnWidthClass2, type: "primary", onClick: () => popModal(true) }, { children: "Close" }))] }))] }))),
    });
}
