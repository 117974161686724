import { getMetadataFilter, mergeNamedMembers, mergeRelationClusters, } from "./semanticSearchUtil";
import { notNully } from "../../utils/collections";
export function getConjunctionBindingsRequest(state, search_width, count) {
    const query = dmParamsToConjunctiveQuery(state.dmParams, state.overrides);
    return (query && Object.assign({ query, corpus_ids: state.corpus_ids, aperture: state.aperture, count, search_width: Math.max(search_width, count) }, getMetadataFilter(state)));
}
function getAllSourcesAndTargets(constraints) {
    return Array.from(new Set([
        ...notNully(constraints.map(c => c.source)),
        ...notNully(constraints.map(c => c.target)),
    ]));
}
function dmParamsToConjunctiveQuery({ concepts: cs, relations: rs, clauses: cls, constraints }, overrides) {
    const allSourcesAndTargets = getAllSourcesAndTargets(constraints);
    const allReferences = new Set([
        ...allSourcesAndTargets,
        ...notNully(constraints.map(c => c.context || []).flat()),
        ...notNully(constraints.map(c => c.relation)),
        ...constraints.map(c => Object.values(c.qualifiers || {})).flat(),
    ]);
    const concepts = Object.fromEntries(Object.entries(cs)
        .filter(([id]) => allReferences.has(id))
        .filter(([id]) => overrides[id])
        .map(([id]) => [
        id,
        mergeNamedMembers(Object.values(overrides[id]).flat())[0],
    ]));
    const types = Object.fromEntries(Object.entries(cs)
        .filter(([id]) => allReferences.has(id))
        .filter(([id]) => { var _a; return !((_a = overrides[id]) === null || _a === void 0 ? void 0 : _a.length); })
        .map(([id, concepts]) => [
        id,
        concepts.map(c => c.members.map(m => m.id)).flat(),
    ]));
    const relations = Object.fromEntries(Object.entries(rs)
        .filter(([id]) => allReferences.has(id))
        .map(([id, r]) => [id, mergeRelationClusters(r)[0]]));
    const clauses = Object.fromEntries(Object.entries(cls)
        .filter(([id]) => allReferences.has(id))
        .map(([id, items]) => {
        return [
            id,
            { name: items[0].name, members: items.map(i => i.members).flat() },
        ];
    }));
    const bindings_for = allSourcesAndTargets.filter(id => { var _a; return !((_a = overrides[id]) === null || _a === void 0 ? void 0 : _a.length); });
    return !bindings_for.length
        ? undefined
        : {
            concepts,
            relations,
            clauses,
            types,
            constraints,
            bindings_for,
        };
}
