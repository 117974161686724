var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getContextUrl, newFinding, upsertFinding } from "../utils/findings";
import { suggestEvidence } from "../utils/suggestedEvidence";
import { showNotification } from "../../shared/components/notifications";
import { Claim, Glossary, Plus, RelatedSearch } from "./Icons";
import { Menu } from "../../shared/actions/Menu";
import { showAttachEvidenceToFindings } from "./modals/AttachEvidenceToFindings";
import { showPopupMenu } from "../../shared/actions/PopupMenu";
import { EMPTY_CRC_AND_CONTEXT, } from "../utils/coraState";
import { EvidenceType, } from "../api/types";
import { updateCurrentSession } from "../utils/sessionManagement";
import { uuidv4 } from "../../utils/util";
import { createRemoteMenuEntry } from "../../shared/actions/RemoteMenuRow";
import { CONCEPT_1_CAPTION, CONCEPT_2_CAPTION, CONTEXT_CAPTION, getConceptFromText, } from "../utils/coraUtil";
import { addGlossaryEvidenceSearch, createMenuEntriesForText, } from "../extensions/KBLinks";
import { isValidQuestion, postQuestion } from "./queryAnalysis/queryAnalysis";
import { conceptMenuExtension } from "../extensions/dropdownMenuExtensions";
import { getCurrentSource } from "../utils/sourceManagement";
function getSpanFromTextSelection(originalText, text, offset = 0) {
    const start = originalText.indexOf(text) + offset;
    const end = start + text.length;
    return { start, end };
}
export function getSelectionMenuEntries({ corpus_id, evidence, document_id, text, coraState, queryState, coraStateUpdater, findings, selectedContext, structuredQuery, }) {
    var _a, _b, _c, _d;
    const findingEntries = createFindingMenuEntries();
    const crcEntries = createCRCEntries();
    const selected = getCurrentSource(coraState);
    const sentence_text = [
        ((_a = evidence === null || evidence === void 0 ? void 0 : evidence.context) === null || _a === void 0 ? void 0 : _a.before) || "",
        (evidence === null || evidence === void 0 ? void 0 : evidence.text) || "",
        ((_b = evidence === null || evidence === void 0 ? void 0 : evidence.context) === null || _b === void 0 ? void 0 : _b.after) || "",
    ]
        .filter(x => x)
        .join(" ");
    const evidenceSpan = selected && text.trim().length && evidence
        ? getSpanFromTextSelection(evidence === null || evidence === void 0 ? void 0 : evidence.text, text, ((_c = evidence === null || evidence === void 0 ? void 0 : evidence.context) === null || _c === void 0 ? void 0 : _c.before) ? ((_d = evidence === null || evidence === void 0 ? void 0 : evidence.context) === null || _d === void 0 ? void 0 : _d.before.length) + 1 : 0 // can't forget the space we'd have to add
        )
        : undefined;
    let entries = [
        ...findingEntries,
        findingEntries.length && crcEntries.length && Menu.SEPARATOR,
        ...crcEntries,
        findingEntries.length + crcEntries.length && Menu.SEPARATOR,
    ];
    if (evidence && evidenceSpan && coraStateUpdater) {
        entries = addGlossaryEvidenceSearch(entries, {
            evidence_id: evidence === null || evidence === void 0 ? void 0 : evidence.id,
            span: evidenceSpan,
        }, coraState, coraStateUpdater);
    }
    entries.push(createMenuEntriesForText(text, queryState.corpus_ids));
    return entries;
    function createCRCEntries() {
        if (!coraStateUpdater)
            return [];
        const options = [
            {
                caption: "Use as next natural language query",
                disabled: !isValidQuestion(text) || text.trim().length > 100,
                icon: _jsx(RelatedSearch, {}),
                action() {
                    postQuestion(text);
                },
            },
        ];
        if (structuredQuery) {
            options.push(...[
                createRemoteMenuEntry({
                    caption: `Use as ${CONCEPT_1_CAPTION}`,
                    icon: _jsx(Glossary, {}),
                    params: text,
                    loader,
                    action(concept) {
                        coraStateUpdater({ concepts1: [concept] });
                    },
                }),
                createRemoteMenuEntry({
                    caption: `Use as ${CONCEPT_2_CAPTION}`,
                    icon: _jsx(Glossary, {}),
                    params: text,
                    loader,
                    action(concept) {
                        coraStateUpdater({ concepts2: [concept] });
                    },
                }),
                createRemoteMenuEntry({
                    caption: `Use as ${CONTEXT_CAPTION}`,
                    icon: _jsx(Glossary, {}),
                    params: text,
                    loader,
                    action(concept) {
                        coraStateUpdater({ contextConcepts: [concept] });
                    },
                }),
            ]);
        }
        return options;
    }
    function createFindingMenuEntries() {
        if (!selectedContext || !findings || !document_id)
            return [];
        const url = getContextUrl(selectedContext);
        const instances = url
            ? [{ url, external_url: url, title: url, id: url, corpus_id, document_id }]
            : [];
        return [
            {
                caption: "Add To My Findings",
                action() {
                    return __awaiter(this, void 0, void 0, function* () {
                        const finding = yield newFinding({
                            selectedText: text,
                            context: selectedContext,
                            queryState,
                            evidence: [
                                {
                                    text,
                                    id: uuidv4(),
                                    instances,
                                    evidence_type: EvidenceType.SUPPORTING,
                                    argument_spans: [],
                                    highlight_spans: [],
                                    is_precise: true,
                                },
                            ],
                        });
                        suggestEvidence(finding);
                        updateCurrentSession(({ findings }) => ({
                            findings: upsertFinding(findings, finding),
                        }));
                        showNotification("Finding Added");
                    });
                },
                icon: _jsx(Plus, {}),
            },
            Menu.SEPARATOR,
            {
                caption: "Add Evidence to Finding",
                disabled: !findings.length,
                action() {
                    showAttachEvidenceToFindings({
                        evidence: [
                            {
                                text,
                                id: uuidv4(),
                                instances,
                                evidence_type: EvidenceType.SUPPORTING,
                                argument_spans: [],
                                highlight_spans: [],
                                is_precise: true,
                            },
                        ],
                    });
                },
                icon: _jsx(Claim, {}),
            },
        ];
    }
    function loader(text) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield getConceptFromText({ text, corpus_ids: coraState.corpus_ids });
        });
    }
}
export function showSelectionMenu(rect, { text, coraState, coraStateUpdater, findings, evidence, instance, context, structuredQuery, expandedEvidence, queryState, }) {
    showPopupMenu({
        top: rect.top,
        left: rect.left + rect.width / 2,
        entries: getSelectionMenuEntries({
            corpus_id: (instance === null || instance === void 0 ? void 0 : instance.corpus_id) || queryState.corpus_ids[0],
            evidence,
            document_id: instance === null || instance === void 0 ? void 0 : instance.document_id,
            text,
            context,
            coraState,
            coraStateUpdater,
            selectedContext: evidence,
            findings,
            structuredQuery,
            expandedEvidence,
            queryState,
        }),
    });
}
export function conceptMenuEntriesProvider(coraStateUpdater, coraState, structuredQuery, extraMenuEntries = []) {
    return (item, items) => {
        const structuredQueryEntries = !structuredQuery
            ? [
                {
                    caption: "Use as next natural language query",
                    action() {
                        postQuestion(item.name);
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
            ]
            : [
                {
                    caption: "Use as next structured query",
                    action() {
                        coraStateUpdater(Object.assign(Object.assign({}, EMPTY_CRC_AND_CONTEXT), { concepts1: [item] }));
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
                {
                    caption: `Use as ${CONCEPT_1_CAPTION}`,
                    action() {
                        coraStateUpdater({ concepts1: [item] });
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
                {
                    caption: `Use as ${CONCEPT_2_CAPTION}`,
                    action() {
                        coraStateUpdater({ concepts2: [item] });
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
                {
                    caption: `Use as ${CONTEXT_CAPTION}`,
                    action() {
                        coraStateUpdater({ contextConcepts: [item] });
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
            ];
        return conceptMenuExtension([
            ...structuredQueryEntries,
            Menu.SEPARATOR,
            ...(extraMenuEntries.length
                ? [...extraMenuEntries, Menu.SEPARATOR]
                : []),
        ], [item], coraState, coraStateUpdater);
    };
}
