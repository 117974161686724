import { CRCDirection, } from "../../api/types";
import { ANY, getConstraint, getEmptyParams } from "./DMParams";
import { genClustersId } from "../semanticSearchUtil";
import { getIdReplacements, replaceRefs } from "./idReferences";
import { mapEntries, replaceKeys } from "../../../utils/objects";
import { getSize, uuidv4 } from "../../../utils/util";
export function queryParamsToDMParams({ concepts1, concepts1Override, concepts2, concepts2Override, contextConcepts, contextConceptsOverride, relations: rel, argClauses, required_arguments, crcDirection, text, }, constraintId, originalParams) {
    constraintId = constraintId || uuidv4();
    const org = originalParams && getConstraint(originalParams, constraintId);
    const overrides = {};
    const [sConcepts, sOverrides, tConcepts, tOverrides] = crcDirection === CRCDirection.C2C1
        ? [concepts2, concepts2Override, concepts1, concepts1Override]
        : [concepts1, concepts1Override, concepts2, concepts2Override];
    const params = getEmptyParams();
    const source = getId(sConcepts, sOverrides, org === null || org === void 0 ? void 0 : org.source);
    const target = getId(tConcepts, tOverrides, org === null || org === void 0 ? void 0 : org.target);
    const relation = getId(rel, {}, org === null || org === void 0 ? void 0 : org.relation);
    if (source ||
        target ||
        relation ||
        getSize(contextConcepts) ||
        getSize(argClauses) ||
        getSize(required_arguments)) {
        if (relation)
            params.relations[relation] = rel;
        if (source) {
            params.concepts[source] = sConcepts;
            if (getSize(sOverrides))
                overrides[source] = sOverrides;
        }
        if (target) {
            params.concepts[target] = tConcepts;
            if (getSize(tOverrides))
                overrides[target] = tOverrides;
        }
        addContext();
        const qualifiers = qualifiersFromArgClauses();
        const constraint = {
            id: constraintId,
            source,
            relation,
            target,
            is_directed: crcDirection !== CRCDirection.BOTH,
            context: contextConcepts.map(c => c.name),
            qualifiers: Object.assign(Object.assign({}, qualifiers), Object.fromEntries(required_arguments.map(argName => [argName, ANY]))),
            text,
        };
        params.constraints.push(constraint);
    }
    const replacements = originalParams
        ? getIdReplacements(params, originalParams, constraintId)
        : {};
    return [
        replaceRefs(params, replacements),
        replaceKeys(overrides, replacements),
        replacements,
    ];
    function addContext() {
        const contextNames = new Set();
        contextConcepts.forEach(c => {
            contextNames.add(c.name);
            return (params.concepts[c.name] = [c]);
        });
        Object.entries(contextConceptsOverride)
            .filter(([k, v]) => v.length && contextNames.has(k))
            .forEach(([k, v]) => (overrides[k] = { [k]: v }));
    }
    function qualifiersFromArgClauses() {
        return mapEntries(argClauses, ([argName, clusters]) => {
            const id = getId(clusters, {}, null);
            params.clauses[id] = clusters;
            return [argName, id];
        });
    }
    function getId(values, overrides, id) {
        return !id && (values.length || getSize(overrides))
            ? values.length
                ? genClustersId(values)
                : uuidv4()
            : id || null;
    }
}
