import { useHasCapability, useValidatedCorpusIds } from "./sourceManagement";
import { getQuery } from "./semanticSearchUtil";
import { useRemoteQuery } from "../../utils/query/remoteQuery";
import { API } from "../api/api";
import { CorpusCapability } from "../../utils/capabilities/capabilities";
export function useSuggestRelatedQueries(coraState) {
    const hasSuggestedQueries = useHasCapability(coraState, CorpusCapability.SUGGEST_QUERIES_RELATED);
    const corpus_ids = useValidatedCorpusIds(coraState.corpus_ids);
    const { concepts1, concepts2, relations, crcDirection, queries } = coraState;
    const query = getQuery({
        concepts1,
        concepts2,
        relations,
        crcDirection,
    });
    const params = !(corpus_ids === null || corpus_ids === void 0 ? void 0 : corpus_ids.length) || !query || !hasSuggestedQueries
        ? undefined
        : {
            count: 10,
            corpus_ids,
            queries: [query].concat(queries),
        };
    const remote = useRemoteQuery({
        loader: API.suggestRelatedQueries,
        params: params,
    });
    return { remote, params };
}
