var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Auth0Provider, useAuth0, withAuthenticationRequired, } from "@auth0/auth0-react";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { BrowserRouter, Redirect, Route, Switch, useLocation, } from "react-router-dom";
import queryString from "query-string";
import { hasAuthToken, setSessionInfo, usingHeliosAuth } from "./utils/authInfo";
import { getConfig } from "./utils/serverConfig";
import App from "./App";
import { Loading } from "./shared/components/Loading";
import FullPageError from "./shared/components/FullPageError";
import { ConfigProvider } from "antd";
import { coraTheme } from "./theme";
const LD_CLIENT_ID = getConfig("LD_CLIENT_SIDE_ID");
const ORG_LANDING_PAGES = JSON.parse(getConfig("ORG_LANDING_PAGES", "[]"));
function AppWrapper() {
    const [isLoading, setIsLoading] = React.useState(true);
    const { getAccessTokenSilently, user, logout } = useAuth0();
    React.useEffect(() => {
        ;
        (() => __awaiter(this, void 0, void 0, function* () {
            try {
                yield getAccessTokenSilently();
                setSessionInfo(getAccessTokenSilently, false, {
                    userId: user.preferred_username,
                    userName: user.name,
                    orgId: user.org_id,
                }, () => {
                    const olpConfig = ORG_LANDING_PAGES.find(olp => olp.org_id == user.org_id);
                    const returnTo = window.location.origin +
                        (olpConfig
                            ? `?redirect=${encodeURIComponent(olpConfig.path)}`
                            : "");
                    logout({ returnTo });
                });
            }
            finally {
                setIsLoading(false);
            }
        }))();
    }, []);
    if (isLoading) {
        return _jsx(Loading, { loading: true });
    }
    return _jsx(App, {});
}
const AuthWrapper = withAuthenticationRequired(AppWrapper, {
    onRedirecting: () => _jsx(Loading, { loading: true }),
});
const OrgLandingPage = ({ olpConfig }) => {
    const { isAuthenticated, user, isLoading, loginWithRedirect } = useAuth0();
    const isLandingPageIsAuthenticated = isAuthenticated && user && user.org_id == olpConfig.org_id;
    React.useEffect(() => {
        if (isLoading || isLandingPageIsAuthenticated) {
            return;
        }
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield loginWithRedirect({
                organization: olpConfig.org_id,
                connection: olpConfig.connection,
            });
        }))();
    }, [isLoading, isLandingPageIsAuthenticated, loginWithRedirect]);
    return isLandingPageIsAuthenticated ? (_jsx(Redirect, { to: "/" })) : (_jsx(Loading, { loading: true }));
};
function DefaultLoginRoute() {
    const loc = useLocation();
    const { loginWithRedirect } = useAuth0();
    React.useEffect(() => {
        const parsed = queryString.parse(loc.search);
        loginWithRedirect({
            invitation: typeof parsed.invitation == "string" ? parsed.invitation : undefined,
            organization: typeof parsed.organization == "string"
                ? parsed.organization
                : undefined,
        }).catch(console.error);
    }, [loc.search]);
    return _jsx(Loading, { loading: true });
}
/**
 * Primarily a wrapper around Auth0Provider, but also intercepts error messages
 * that we may get in return from Auth0 (e.g., an expired org invite)
 */
function CoraAuth0Provider({ children }) {
    // look for auth0 params (and our custom redirect) for special handling
    const params = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        return {
            error: params.get("error"),
            error_description: params.get("error_description"),
            redirect: params.get("redirect"),
        };
    }, [window.location.search]);
    if (params.error && params.error_description) {
        return _jsx(FullPageError, { message: params.error_description });
    }
    if (params.redirect) {
        window.location.href = params.redirect;
        return null;
    }
    return (_jsx(Auth0Provider, Object.assign({ domain: getConfig("AUTH0_DOMAIN"), clientId: getConfig("AUTH0_CLIENT_ID"), redirectUri: window.location.origin, audience: getConfig("AUTH0_AUDIENCE"), useRefreshTokens: true, scope: "offline_access", onRedirectCallback: () => {
            // Auth0 sometimes leaves these in our URL after login
            const params = new URLSearchParams(window.location.search);
            if (params.get("code") || params.get("state")) {
                window.location.href = window.location.pathname;
            }
        } }, { children: children })));
}
function AuthApp() {
    const skipAuth0Provider = hasAuthToken() || usingHeliosAuth();
    const landingPageRoutes = React.useMemo(() => {
        return ORG_LANDING_PAGES.map(olpConfig => (_jsx(Route, Object.assign({ exact: true, strict: false, path: olpConfig.path }, { children: _jsx(OrgLandingPage, { olpConfig: olpConfig }) }), olpConfig.path)));
    }, ORG_LANDING_PAGES);
    const routes = (_jsxs(Switch, { children: [_jsx(Route, Object.assign({ exact: true, strict: false, path: "/login" }, { children: _jsx(DefaultLoginRoute, {}) })), landingPageRoutes, _jsx(Route, Object.assign({ path: "*" }, { children: skipAuth0Provider ? _jsx(App, {}) : _jsx(AuthWrapper, {}) }))] }));
    return (_jsx(BrowserRouter, { children: skipAuth0Provider ? (routes) : (_jsx(ConfigProvider, Object.assign({ theme: coraTheme }, { children: _jsx(CoraAuth0Provider, { children: routes }) }))) }));
}
export default withLDProvider({ clientSideID: LD_CLIENT_ID })(AuthApp);
