import { Beacon } from "./Beacon";
import { uuidv4 } from "./util";
export const activityBeacon = Beacon({
    initial: {},
});
export function addRequest(id, r) {
    activityBeacon.value(v => (Object.assign(Object.assign({}, v), { [id]: Object.assign(Object.assign({}, r), { started: new Date() }) })));
}
export function removeRequest(id) {
    activityBeacon.value(v => Object.fromEntries(Object.entries(v).filter(([k, v]) => k !== id)));
}
export function clearRequests() {
    activityBeacon.value(v => ({}));
}
export function monitoredFetch(url, init, options) {
    const { description, timeout = 30000, onTimeout, autoInterruptOnTimeout = false, announce = false, } = options;
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchPromise = fetch(url, Object.assign(Object.assign({}, init), { signal })).then(response => {
        return response;
    });
    const uuid = uuidv4();
    addRequest(uuid, {
        onInterrupt: () => {
            controller.abort();
            removeRequest(uuid);
        },
        description,
        timeout,
        onTimeout: () => {
            console.log(`Fetch to ${url} took longer than ${timeout}ms`);
            onTimeout && onTimeout();
        },
        autoInterruptOnTimeout,
        announce,
    });
    fetchPromise.finally(() => {
        removeRequest(uuid);
    });
    return fetchPromise;
}
