var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { EvidenceType, MetadataType, } from "../../api/types";
import { useCurrentSession } from "../../utils/sessionManagement";
import { DIFFERENCE_LINE_NAME, TrendsChart } from "./TrendsChart";
import { getCurrentGranularity, nothingSelected, useTrendsCorpusFilter, } from "../../utils/semanticSearchUtil";
import { API } from "../../api/api";
import { upsertOrRemove } from "../../../utils/collections";
import { useDownloadTrends } from "../modals/DownloadTrends";
import { mapValues } from "lodash";
import { useRemoteQuery } from "../../../utils/query/remoteQuery";
import { Empty } from "../../../shared/components/Empty";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getTrendDomain, getTrendRange } from "./common";
function findMatchingPeriod(buckets, label) {
    var _a;
    return (_a = buckets.find((x) => x.name === label)) === null || _a === void 0 ? void 0 : _a.interval;
}
function dateFromPeriod(period) {
    return (period === null || period === void 0 ? void 0 : period.start) ? new Date(period === null || period === void 0 ? void 0 : period.start) : undefined;
}
function getSelectedDateRange(dateRange, selectedTrendRange) {
    const dateFilter = dateRange.filter(metadata => metadata.id === (selectedTrendRange === null || selectedTrendRange === void 0 ? void 0 : selectedTrendRange.id));
    return dateFilter.length > 0 ? dateFilter[0].value : undefined;
}
export function Trends({ trends, coraState, coraStateUpdater, loading, noSelection, trendsGranularities, setSelectedEvidenceType, extraControls, emptyEvidence, }) {
    const rangeUpdater = useSelectedTrendRangeUpdater(coraState, coraStateUpdater);
    const selectedTrendDomain = getTrendDomain(coraState);
    const { trendRangeType = "count" } = useCurrentSession();
    // Get the currently selected date range for the trend domain.
    const selectedDateRange = React.useMemo(() => {
        const range = getSelectedDateRange(coraState.rangeMetadata, selectedTrendDomain);
        const override = getSelectedDateRange(coraState.rangeMetadataOverride, selectedTrendDomain);
        return override ? override : range ? range : {};
    }, [coraState, selectedTrendDomain]);
    function handleDateChange(labels) {
        if (!trends)
            return;
        let range = undefined;
        if (labels.length === 1) {
            const period = findMatchingPeriod(trends.domain_buckets, labels[0]);
            if (period)
                range = period;
        }
        else if (labels.length > 1) {
            const firstPeriod = findMatchingPeriod(trends.domain_buckets, labels[0]);
            const firstStart = dateFromPeriod(firstPeriod);
            const secondPeriod = findMatchingPeriod(trends.domain_buckets, labels[1]);
            const secondStart = dateFromPeriod(secondPeriod);
            if (!firstStart || !secondStart)
                return;
            if (firstPeriod === secondPeriod) {
                range = firstPeriod;
            }
            else {
                const start = (firstStart === null || firstStart === void 0 ? void 0 : firstStart.getTime()) < (secondStart === null || secondStart === void 0 ? void 0 : secondStart.getTime())
                    ? firstPeriod === null || firstPeriod === void 0 ? void 0 : firstPeriod.start
                    : secondPeriod === null || secondPeriod === void 0 ? void 0 : secondPeriod.start;
                const end = (firstStart === null || firstStart === void 0 ? void 0 : firstStart.getTime()) < (secondStart === null || secondStart === void 0 ? void 0 : secondStart.getTime())
                    ? secondPeriod === null || secondPeriod === void 0 ? void 0 : secondPeriod.end
                    : firstPeriod === null || firstPeriod === void 0 ? void 0 : firstPeriod.end;
                if (!start || !end)
                    return;
                range = {
                    start,
                    end,
                };
            }
        }
        rangeUpdater(range);
    }
    const chartData = React.useMemo(() => {
        const trendsData = {};
        const periods = trends === null || trends === void 0 ? void 0 : trends.domain_buckets;
        if (!periods)
            return [];
        const unmentionedSeries = trends.series.find(x => x.type === EvidenceType.TOTAL);
        const isCount = trendRangeType === "count";
        trends === null || trends === void 0 ? void 0 : trends.series.filter(s => s.type !== EvidenceType.TOTAL).forEach((series) => {
            series.counts.forEach((value, idx) => {
                const bucket = periods[idx];
                const newVal = series.name.includes("Refuting") ? -value : value;
                const period = bucket.interval;
                const item = (isCount
                    ? {
                        period,
                        value: newVal,
                        lineName: series.name,
                    }
                    : {
                        period,
                        value: newVal / ((unmentionedSeries === null || unmentionedSeries === void 0 ? void 0 : unmentionedSeries.counts[idx]) || 1),
                        lineName: `${series.name.replace(" count", " ratio")}`,
                    });
                trendsData[bucket.name] = trendsData[bucket.name] || [];
                trendsData[bucket.name].push(item);
            });
        });
        mapValues(trendsData, items => {
            if (items.length == 2) {
                const value = items[0].value + items[1].value;
                items.push({
                    period: items[0].period,
                    value,
                    lineName: DIFFERENCE_LINE_NAME,
                });
            }
        });
        const formattedChartData = Object.keys(trendsData).map((key) => {
            const entry = { label: key };
            trendsData[key].forEach(x => {
                if (x.lineName)
                    entry[x.lineName] = x.value;
            });
            return entry;
        });
        return formattedChartData;
    }, [trends, trendRangeType]);
    function getEmpty(noSelection, trends, loading) {
        return noSelection ? ("No Query") : !trends ? (loading ? ("Loading trends") : !emptyEvidence ? (_jsxs("span", { children: ["No accurate trends for this query.", _jsx("span", Object.assign({ className: "align-super text-sm", style: { verticalAlign: "super" } }, { children: _jsx(InfoCircleOutlined, {}) }))] })) : ("No results for current query.")) : null;
    }
    return (_jsx("div", Object.assign({ className: "w-full h-full" }, { children: _jsx(Empty, Object.assign({ empty: getEmpty(noSelection, trends, loading), loading: loading, emptyTooltip: _jsxs("span", { children: ["When showing evidence, Cora shows highly confident and relevant results first, followed by less confident results. In the Trends, however, Cora only displays trend lines for the highly relevant results.", _jsx("br", {}), "In this case, there were no highly relevant results found, and so no Trends are computed."] }) }, { children: trends && !noSelection && (_jsx(TrendsChart, { data: chartData, title: trends.name, selectedDateRange: selectedDateRange, onSelectedDateChange: handleDateChange, trendsGranularities: trendsGranularities, setSelectedEvidenceType: setSelectedEvidenceType, extraControls: extraControls, getColorIdx: idx => (coraState.refuting ? 1 - idx : idx) })) })) })));
}
export function useSelectedTrendRangeUpdater(coraState, coraStateUpdater) {
    const selectedTrendDomain = getTrendDomain(coraState);
    return React.useCallback((range) => {
        selectedTrendDomain &&
            coraStateUpdater({
                rangeMetadataOverride: upsertOrRemove(coraState.rangeMetadataOverride, i => i.id, selectedTrendDomain === null || selectedTrendDomain === void 0 ? void 0 : selectedTrendDomain.id, range && {
                    id: selectedTrendDomain.id,
                    name: selectedTrendDomain === null || selectedTrendDomain === void 0 ? void 0 : selectedTrendDomain.name,
                    type: MetadataType.DATE,
                    value: {
                        start: range.start,
                        end: range.end,
                    },
                }),
            });
    }, [selectedTrendDomain, coraState.rangeMetadataOverride, coraStateUpdater]);
}
function getTrendsData({ domain_field, range_field, corpus_filter, selectedGranularity, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const granularityParams = domain_field && corpus_filter
            ? {
                corpus_filter,
                domain_field,
            }
            : undefined;
        const trendsGranularities = !granularityParams
            ? []
            : yield API.supported_granularities(granularityParams);
        const granularity = getCurrentGranularity(trendsGranularities, selectedGranularity);
        const groupByParams = domain_field && corpus_filter && range_field
            ? {
                corpus_filter,
                domain_field,
                granularity: selectedGranularity || trendsGranularities[0],
                range_field,
            }
            : undefined;
        const trendsGroupByFields = !groupByParams || groupByParams.granularity === undefined
            ? []
            : yield API.supported_group_by_fields(groupByParams);
        // TODO
        const range = {}; // TODO: don't set so that we get all results back
        const params = granularity && domain_field && range_field && corpus_filter
            ? {
                corpus_filter,
                domain_field,
                range_field,
                granularity,
                // ...(range.start && range.end && {range}),
            }
            : undefined;
        const trends = !params ? undefined : yield API.trends(params);
        return { granularity, trendsGranularities, trendsGroupByFields, trends, params };
    });
}
function useTrendsData(coraState, disabled) {
    var _a, _b;
    const noSelection = nothingSelected(coraState);
    const domain_field = ((_a = getTrendDomain(coraState)) === null || _a === void 0 ? void 0 : _a.id) || "";
    const corpus_filter = useTrendsCorpusFilter(coraState, domain_field);
    const range_field = ((_b = getTrendRange(coraState)) === null || _b === void 0 ? void 0 : _b.id) || "";
    const { selectedGranularity } = useCurrentSession();
    const trendData = useRemoteQuery({
        loader: getTrendsData,
        params: noSelection || disabled
            ? undefined
            : {
                domain_field,
                range_field,
                corpus_filter,
                selectedGranularity,
            },
    });
    return {
        trendData,
        noSelection,
        selectedGranularity,
    };
}
export function TrendsDataChart({ coraState, coraStateUpdater, disabled, setSelectedEvidenceType, emptyEvidence, }) {
    const { trendData, noSelection, selectedGranularity } = useTrendsData(coraState, disabled);
    const { trends = null, granularity, trendsGranularities = [], params, trendsGroupByFields = [], } = trendData.value || {};
    const DownloadTrendsDialog = useDownloadTrends(trendsGroupByFields);
    return (_jsx(Trends, { trends: trends, coraState: coraState, coraStateUpdater: coraStateUpdater, loading: trendData.loading, noSelection: noSelection, trendsGranularities: trendsGranularities, setSelectedEvidenceType: setSelectedEvidenceType, extraControls: DownloadTrendsDialog(params), emptyEvidence: emptyEvidence }));
}
