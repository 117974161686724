import { jsx as _jsx } from "react/jsx-runtime";
import { useNodesInitialized } from "reactflow";
import React, { useEffect } from "react";
import { useParams } from "../../../utils/hooks";
import { Emitter } from "../../../utils/Emitter";
import { noSize, validateActive, validateNodeOrder } from "./diagramUtils";
import { isEqual } from "lodash";
export const EMPTY_STATE = {
    nodes: [],
    edges: [],
    viewport: { x: 0, y: 0, zoom: 1 },
    triggersCommit: false,
};
const DiagramStateContext = React.createContext(null);
const DiagramStateUpdateContext = React.createContext(null);
export function DiagramState({ children }) {
    const [diagramState, updater] = useParams(EMPTY_STATE);
    const emitter = React.useRef(Emitter());
    const value = [
        diagramState,
        React.useMemo(() => {
            function commit(updateOrFn) {
                let newState;
                updater(state => {
                    const update = typeof updateOrFn === "function" ? updateOrFn(state) : updateOrFn;
                    const viewport = update.viewport || state.viewport;
                    const ne = validateActive({
                        nodes: update.nodes ? validateNodeOrder(update.nodes) : state.nodes,
                        edges: update.edges || state.edges,
                    });
                    const updated = Object.assign(Object.assign({ viewport }, ne), { 
                        // if there are no nodes with size, delay the commit to allow
                        // reactflow to render the nodes and calculate their size
                        //
                        triggersCommit: ne.nodes.some(noSize) });
                    if (isEqual(state, newState)) {
                        newState = undefined;
                        return state;
                    }
                    newState = updated;
                    return updated;
                });
                newState && !newState.triggersCommit && emitter.current.emit(newState);
            }
            return {
                update(updateOrFn) {
                    updater(state => {
                        const update = Object.assign(Object.assign({}, (typeof updateOrFn === "function"
                            ? updateOrFn(state)
                            : updateOrFn)), { triggersCommit: false });
                        return isEqual(state, update) ? state : update;
                    });
                },
                onCommit: emitter.current.on,
                commit: (arg = 250) => {
                    if (typeof arg === "number") {
                        setTimeout(() => commit({}), arg);
                    }
                    else {
                        commit(arg);
                    }
                },
                emitter() {
                    return emitter.current;
                },
            };
        }, [diagramState]),
    ];
    return (_jsx(DiagramStateUpdateContext.Provider, Object.assign({ value: value[1] }, { children: _jsx(DiagramStateContext.Provider, Object.assign({ value: value }, { children: children })) })));
}
export function useDiagramState() {
    const context = React.useContext(DiagramStateContext);
    if (!context) {
        throw new Error("useDiagramState must be used within a DiagramState");
    }
    return context;
}
export function useDiagramStateUpdater() {
    const context = React.useContext(DiagramStateUpdateContext);
    if (!context) {
        throw new Error("useDiagramStateUpdate must be used within a DiagramState");
    }
    return context;
}
export function useFireDiagramStateCommits({ nodes, edges, viewport, triggersCommit }, dUpdater) {
    const nodesInitialized = useNodesInitialized();
    useEffect(() => {
        if (nodesInitialized && triggersCommit) {
            // trigger commit once the nodes are initialized
            dUpdater.emitter().emit({ edges, nodes, viewport });
        }
    }, [nodesInitialized, triggersCommit]);
}
