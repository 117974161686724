var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "antd";
import { textPrompt } from "../../../shared/components/Propmpt";
import { showNotification } from "../../../shared/components/notifications";
import { sendFeedback } from "../../utils/feedback";
import { FeatureIdentifier, FeedbackType } from "../../api/types";
import { useQueryParams } from "../../../utils/routing";
import { useEffectIfDifferent } from "../../../utils/hooks";
import { isEmptyQuery } from "../../utils/semanticSearchUtil";
import { useCoraState } from "../CoraStateProvider";
export function OverallFeedback() {
    const [queryParams] = useQueryParams();
    const [coraState] = useCoraState();
    useEffectIfDifferent(() => { }, [], [queryParams]);
    return isEmptyQuery(coraState) ? null : (_jsx("div", Object.assign({ className: "flex space-x-2 py-1 px-4 rounded-md bg-gray-100" }, { children: _jsxs("div", Object.assign({ className: "flex space-x-2" }, { children: [_jsx("span", { children: "Do you find these results to be useful?" }), _jsx(Button, Object.assign({ size: "small", onClick: () => feedback(true) }, { children: "Yes" })), _jsx(Button, Object.assign({ size: "small", onClick: () => feedback(false) }, { children: "No" }))] })) })));
    function feedback(positive) {
        return __awaiter(this, void 0, void 0, function* () {
            const comments = yield textPrompt({
                title: positive
                    ? "Any additional comments?"
                    : "Why were these results not useful?",
                required: false,
                textArea: true,
                maxLength: 500,
                placeholder: "Optional comments.(max length 500)..",
                okText: "Submit",
            });
            if (comments === null)
                return;
            yield sendFeedback(FeatureIdentifier.OVERALL, "", positive ? FeedbackType.POSITIVE : FeedbackType.NEGATIVE, null, queryParams, undefined, comments);
            showNotification("Thank you for your feedback");
        });
    }
}
