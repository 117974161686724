export var EvidenceType;
(function (EvidenceType) {
    EvidenceType["SUPPORTING"] = "Supporting";
    EvidenceType["REFUTING"] = "Refuting";
    EvidenceType["TOTAL"] = "Total";
})(EvidenceType || (EvidenceType = {}));
export var ArgumentType;
(function (ArgumentType) {
    ArgumentType["CORE"] = "CORE";
    ArgumentType["MODIFIER"] = "MODIFIER";
})(ArgumentType || (ArgumentType = {}));
export var ClusteringMode;
(function (ClusteringMode) {
    ClusteringMode["NONE"] = "NONE";
    ClusteringMode["MINIMAL"] = "MINIMAL";
    ClusteringMode["MODERATE"] = "MODERATE";
    ClusteringMode["AGGRESSIVE"] = "AGGRESSIVE";
})(ClusteringMode || (ClusteringMode = {}));
export var Conjunction;
(function (Conjunction) {
    Conjunction["AND"] = "AND";
    Conjunction["OR"] = "OR";
})(Conjunction || (Conjunction = {}));
export var MetadataType;
(function (MetadataType) {
    MetadataType["DATE"] = "date";
    MetadataType["KEYWORD"] = "keyword";
    MetadataType["BOOLEAN"] = "boolean";
})(MetadataType || (MetadataType = {}));
export var ArgType;
(function (ArgType) {
    ArgType["CONCEPT"] = "CONCEPT";
    ArgType["TYPE"] = "TYPE";
})(ArgType || (ArgType = {}));
export var SimilarityMode;
(function (SimilarityMode) {
    SimilarityMode["MINIMAL"] = "MINIMAL";
    SimilarityMode["MODERATE"] = "MODERATE";
    SimilarityMode["AGGRESSIVE"] = "AGGRESSIVE";
})(SimilarityMode || (SimilarityMode = {}));
export var CRCDirection;
(function (CRCDirection) {
    CRCDirection["BOTH"] = "BOTH";
    CRCDirection["C1C2"] = "C1C2";
    CRCDirection["C2C1"] = "C2C1";
})(CRCDirection || (CRCDirection = {}));
export var FeedbackType;
(function (FeedbackType) {
    FeedbackType["POSITIVE"] = "POSITIVE";
    FeedbackType["IMPLICIT_POSITIVE"] = "IMPLICIT POSITIVE";
    FeedbackType["NEGATIVE"] = "NEGATIVE";
    FeedbackType["NEGATIVE_IN_CONTEXT"] = "NEGATIVE IN CONTEXT";
})(FeedbackType || (FeedbackType = {}));
export var FeatureIdentifier;
(function (FeatureIdentifier) {
    FeatureIdentifier["EVIDENCE_QUALIFIER"] = "EVIDENCE QUALIFIER";
    FeatureIdentifier["EVIDENCE_RELEVANCE"] = "EVIDENCE RELEVANCE";
    FeatureIdentifier["ASPECTS"] = "ASPECTS";
    FeatureIdentifier["SUGGEST_CONCEPTS"] = "SUGGEST CONCEPTS";
    FeatureIdentifier["SUGGEST_RELATIONS"] = "SUGGEST RELATIONS";
    FeatureIdentifier["SUGGEST_EVIDENCE_SUMMARY"] = "SUGGEST EVIDENCE SUMMARY";
    FeatureIdentifier["OVERALL"] = "OVERALL";
})(FeatureIdentifier || (FeatureIdentifier = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "ascending";
    SortOrder["DESC"] = "descending";
})(SortOrder || (SortOrder = {}));
