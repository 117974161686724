import Lazy from "lazy.js";
export function composedComparator(comparators) {
    return (a, b) => {
        var _a;
        return (_a = Lazy(comparators)
            .map(c => c(a, b))
            .find(c => c !== 0)) !== null && _a !== void 0 ? _a : 0;
    };
}
export function textComparator(a, b) {
    return ((a === null || a === void 0 ? void 0 : a.toLowerCase()) || "").localeCompare((b === null || b === void 0 ? void 0 : b.toLowerCase()) || "");
}
export function changeDir(comparator, dir) {
    return dir >= 0 ? comparator : (a, b) => -comparator(a, b);
}
export function spanComparator(a, b) {
    if (a === null || b === null) {
        // console.log(null)
    }
    const cmp = a.start - b.start;
    return cmp || b.end - a.end;
}
export function mapperComparator(mapper, comparator) {
    return (a, b) => comparator(mapper(a), mapper(b));
}
