var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { isEqual, uniqBy } from "lodash";
import { intercalate } from "../../utils/collections";
import Lazy from "lazy.js";
import { RetweetOutlined } from "@ant-design/icons";
import { RelatedSearch } from "../components/Icons";
import { DefaultMarkDown } from "../../shared/components/MarkDown";
import { API } from "../api/api";
import { CATEGORYCAL, CONCEPT_1_COLOR, CONCEPT_2_COLOR, CONTEXT_CONCEPT_COLOR, NEUTRAL_COLOR, RELATION_COLOR, } from "../components/lf";
import { useGlobalInfo } from "./globalInfo";
import { EMPTY_CRC_AND_CONTEXT } from "./coraState";
import { ArgumentType, ClusteringMode, CRCDirection, } from "../api/types";
import { invalidCorpusIds, useCurrentSource, useHasCapability, } from "./sourceManagement";
import { concatItemsAndOverrides, getConceptIds, getConceptsExpansionParams, getRelationsExpansionParams, } from "./semanticSearchUtil";
import { useRemoteQuery } from "../../utils/query/remoteQuery";
import { crcMenuExtension } from "../extensions/dropdownMenuExtensions";
import { CorpusCapability } from "../../utils/capabilities/capabilities";
export const CONCEPT_1_CAPTION = "Primary Concept";
export const CONCEPT_2_CAPTION = "Linked Concept";
export const CONTEXT_CAPTION = "Context Concept";
export const CONCEPT_CAPTIONS = {
    concepts1: CONCEPT_1_CAPTION,
    concepts2: CONCEPT_2_CAPTION,
    contextConcepts: CONTEXT_CAPTION,
};
export const EXTRA_CONCEPTS_CAPTION = (plural = true) => "Context Concept" + (plural ? "s" : "");
export function markdownForSynonyms(title, synonyms, filterText, markDownClass) {
    return !synonyms
        ? undefined
        : intercalate(uniqBy(Lazy(synonyms)
            .filter(s => (typeof title !== "string" ||
            s.toLowerCase() !== title.toLowerCase()) &&
            (!filterText ||
                !filterText.length ||
                s.toLowerCase().startsWith(filterText)))
            .first(15)
            .toArray(), s => s.toLowerCase()).map(s => (_jsx(DefaultMarkDown, { text: s, separator: filterText, prefix: true, markDownClass: markDownClass }, s))), ", ");
}
export var FixedArgs;
(function (FixedArgs) {
    FixedArgs["TEXT_MATCH"] = "TEXT_MATCH";
    FixedArgs["CONCEPT_1"] = "CONCEPT_1";
    FixedArgs["CONCEPT_2"] = "CONCEPT_2";
    FixedArgs["CONTEXT_CONCEPT"] = "CONTEXT_CONCEPT";
    FixedArgs["RELATION"] = "RELATION";
})(FixedArgs || (FixedArgs = {}));
export function getArgsInfo(source_id, globalInfo) {
    const info = source_id ? globalInfo.corporaById[source_id] : undefined;
    const qualifierClauses = new Set((info === null || info === void 0 ? void 0 : info.qualifier_clauses) || []);
    const argNames = (source_id && globalInfo.allArgumentNames[source_id]) || [];
    return {
        allArgumentNames: Lazy(argNames)
            .filter(a => a.type === ArgumentType.MODIFIER)
            .map(a => a.name)
            .toArray(),
        argColors: Object.assign({ [FixedArgs.TEXT_MATCH]: NEUTRAL_COLOR, [FixedArgs.CONCEPT_1]: CONCEPT_1_COLOR, [FixedArgs.CONCEPT_2]: CONCEPT_2_COLOR, [FixedArgs.CONTEXT_CONCEPT]: CONTEXT_CONCEPT_COLOR, [FixedArgs.RELATION]: RELATION_COLOR }, Object.fromEntries(Lazy(argNames)
            .filter(a => a.type === ArgumentType.MODIFIER)
            .map(a => a.name)
            .toArray()
            .map((arg, idx) => [arg, CATEGORYCAL[idx % CATEGORYCAL.length]]))),
        qualifierClauses,
    };
}
export function useArgsInfo(corpus_ids) {
    const currentSource = useCurrentSource(corpus_ids);
    const globalInfo = useGlobalInfo();
    return React.useMemo(() => getArgsInfo(currentSource === null || currentSource === void 0 ? void 0 : currentSource.id, globalInfo), [currentSource === null || currentSource === void 0 ? void 0 : currentSource.id, globalInfo]);
}
export function highlightsOutsideOfParse(evidence) {
    const highlightSpans = (evidence.highlight_spans || []).filter(s => s.match_type === FixedArgs.CONTEXT_CONCEPT);
    return !highlightSpans ? [] : highlightSpans.filter(s => s.span === null);
}
export function highlightWordsOutsideOfParse(evidence) {
    return highlightsOutsideOfParse(evidence).map(s => s.match_name);
}
export function highlightsOnlyOutsideOfParse(evidence) {
    const highlightSpans = (evidence.highlight_spans || []).filter(s => s.match_type === FixedArgs.CONTEXT_CONCEPT);
    const hasASpan = highlightSpans.filter(s => s.span).map(s => s.match_name);
    return highlightSpans.length > 0
        ? highlightSpans.filter(s => !hasASpan.includes(s.match_name))
        : [];
}
export function uniqueHighlightsOnlyOutsideOfParse(evidence) {
    const outsideOnlyHighlights = highlightsOnlyOutsideOfParse(evidence);
    // get unique match_ids, because these can be duplicated
    const outsideOnlyStrings = outsideOnlyHighlights.map(x => x.match_id);
    // find the first match, because we want a match_name to display
    const uniqueStrings = [...new Set(outsideOnlyStrings)];
    return uniqueStrings
        .map(h => outsideOnlyHighlights === null || outsideOnlyHighlights === void 0 ? void 0 : outsideOnlyHighlights.find(x => x.match_id === h))
        .filter(x => x);
}
export function hasHighlightsOnlyOutsideOfParse(evidence) {
    return highlightsOnlyOutsideOfParse(evidence).length > 0;
}
export function useEvidenceArgColorGetter(coraState, argColors) {
    return React.useCallback((evidence) => {
        function spanToKey({ start, end }) {
            return `${start}:${end}`;
        }
        function getConcepts(concepts, override) {
            return new Set(concepts
                .concat(Object.values(override).flat())
                .map(getConceptIds)
                .flat());
        }
        function getArgClause(span, argumentSpans) {
            var _a;
            const matchingSpan = argumentSpans.find(argSpan => {
                return (argSpan.span.start === span.start && argSpan.span.end === span.end);
            });
            return (_a = matchingSpan === null || matchingSpan === void 0 ? void 0 : matchingSpan.argument) === null || _a === void 0 ? void 0 : _a.name;
        }
        const qualifierNames = new Set(Object.keys(coraState.argClauses));
        const modifierSpans = Object.fromEntries([...(evidence.argument_spans || [])]
            .filter(a => a.argument.type === ArgumentType.MODIFIER &&
            qualifierNames.has(a.argument.name))
            .map(m => m.concept_spans.map(s => [spanToKey(s.span), m.argument.name]))
            .flat());
        const concepts1 = getConcepts(coraState.concepts1Possible || coraState.concepts1, coraState.concepts1Override);
        const concepts2 = getConcepts(coraState.concepts2Possible || coraState.concepts2, coraState.concepts2Override);
        const pars = coraState.queries.map(q => queryToPars(q));
        const c1 = pars.map(p => p.c1).flat();
        c1.forEach(c => {
            if (c !== undefined)
                concepts1.add(c);
        });
        const c2 = pars.map(p => p.c2).flat();
        c2.forEach(c => {
            if (c !== undefined)
                concepts2.add(c);
        });
        const extraConcepts = getConcepts(...concatItemsAndOverrides([coraState.extraConcepts, coraState.contextConcepts], [coraState.extraConceptsOverride, coraState.contextConceptsOverride]));
        return ({ match_type, match_id, span }) => {
            return argColors[getKey()];
            function getKey() {
                if (match_type === FixedArgs.CONTEXT_CONCEPT) {
                    return FixedArgs.CONTEXT_CONCEPT;
                }
                if (match_type === "CONCEPT_INSTANCE" ||
                    match_type === "CONCEPT" ||
                    match_type === "TYPE_INSTANCE") {
                    if (concepts1.has(match_id))
                        return FixedArgs.CONCEPT_1;
                    if (concepts2.has(match_id))
                        return FixedArgs.CONCEPT_2;
                    if (extraConcepts.has(match_id))
                        return FixedArgs.CONTEXT_CONCEPT;
                    const modifier = modifierSpans[spanToKey(span)];
                    if (modifier) {
                        match_type = "ARGUMENT";
                        match_id = modifier;
                    }
                }
                if (match_type === "ARGUMENT_CLAUSE") {
                    return evidence.argument_spans
                        ? getArgClause(span, evidence.argument_spans) || "ARGUMENT"
                        : "ARGUMENT";
                }
                const dir = coraState.crcDirection;
                return match_type === "ARGUMENT"
                    ? match_id === "subject"
                        ? dir === CRCDirection.C1C2
                            ? FixedArgs.CONCEPT_1
                            : FixedArgs.CONCEPT_2
                        : match_id === "object"
                            ? dir === CRCDirection.C1C2
                                ? FixedArgs.CONCEPT_2
                                : FixedArgs.CONCEPT_1
                            : match_id
                    : match_type;
            }
        };
    }, [[coraState, argColors]]);
}
export function getPopupMenuEntries(conceptsKey, coraState, coraStateUpdater) {
    return () => {
        const current = coraState[conceptsKey];
        return crcMenuExtension([
            conceptsKey !== "contextConcepts" && {
                caption: "Swap Primary and Linked Concepts",
                disabled: isEqual(coraState.concepts1, coraState.concepts2),
                action() {
                    coraStateUpdater({
                        concepts1: coraState.concepts2,
                        concepts2: coraState.concepts1,
                    });
                },
                icon: _jsx(RetweetOutlined, {}),
            },
            {
                caption: "Use as next structured query",
                disabled: !current.length,
                action() {
                    coraStateUpdater(Object.assign(Object.assign({}, EMPTY_CRC_AND_CONTEXT), { concepts1: current }));
                },
                icon: _jsx(RelatedSearch, {}),
            },
            conceptsKey !== "concepts1" && {
                caption: `Use as ${CONCEPT_1_CAPTION}`,
                disabled: !current.length,
                action() {
                    coraStateUpdater({ concepts1: current, [conceptsKey]: [] });
                },
                icon: _jsx(RelatedSearch, {}),
            },
            conceptsKey === "concepts1" && {
                caption: `Use as ${CONCEPT_2_CAPTION}`,
                disabled: !current.length,
                action() {
                    coraStateUpdater({ concepts2: current, [conceptsKey]: [] });
                },
                icon: _jsx(RelatedSearch, {}),
            },
        ], coraState, conceptsKey);
    };
}
export function queryToPars(query) {
    var _a, _b, _c, _d;
    const firstArgNane = (_b = (_a = query.concept_filter) === null || _a === void 0 ? void 0 : _a.filter[0]) === null || _b === void 0 ? void 0 : _b.argument_name;
    const dir = firstArgNane === "subject"
        ? CRCDirection.C1C2
        : firstArgNane === "object"
            ? CRCDirection.C2C1
            : CRCDirection.BOTH;
    const concepts = ((_c = query.concept_filter) === null || _c === void 0 ? void 0 : _c.filter.map(f => f.name)) || [];
    const c1 = concepts.length == 2 || dir !== CRCDirection.BOTH ? [concepts[0]] : [];
    const c2 = concepts.length == 1 && dir === CRCDirection.BOTH
        ? [concepts[0]]
        : concepts.length == 2
            ? [concepts[1]]
            : [];
    return Object.assign(Object.assign({ c1: !(c1 === null || c1 === void 0 ? void 0 : c1.length) ? c2 : c1 }, { c2: !(c1 === null || c1 === void 0 ? void 0 : c1.length) ? c1 : c2 }), { r: (_d = query.relation_filter) === null || _d === void 0 ? void 0 : _d.filter.map(f => f.name), dir });
}
export function conceptColorGetter({ c1, c2 }, colorForC1) {
    return colorForC1 ? CONCEPT_1_COLOR : CONCEPT_2_COLOR;
}
export function useConceptExpansions(concepts, corpus_ids, similarity_mode, aperture, enabled) {
    const hasCapability = useHasCapability(corpus_ids, CorpusCapability.SUGGEST_CONCEPTS) &&
        similarity_mode !== ClusteringMode.NONE;
    const hasParams = enabled && hasCapability && !invalidCorpusIds(corpus_ids);
    const params = !hasParams
        ? undefined
        : getConceptsExpansionParams({
            concepts,
            corpus_ids,
            similarity_mode: similarity_mode,
            aperture,
        });
    const remoteQuery = useRemoteQuery({
        loader: API.concept_expansion,
        params,
    });
    return { remote: hasCapability ? remoteQuery : null, params };
}
export function useRelationsExpansions(relations, corpus_ids, concepts1, concepts2, aperture, enabled) {
    const hasCapability = useHasCapability(corpus_ids, CorpusCapability.SUGGEST_RELATIONS);
    const hasParams = enabled &&
        hasCapability &&
        !invalidCorpusIds(corpus_ids) &&
        relations.length;
    const params = !hasParams
        ? undefined
        : getRelationsExpansionParams({
            concepts1,
            relations,
            concepts2,
            corpus_ids,
            aperture,
        });
    const remote = useRemoteQuery({
        loader: API.relation_expansion,
        params,
    });
    return { remote: hasCapability ? remote : null, params, hasCapability };
}
export function getConceptFromText({ text, corpus_ids, }) {
    return __awaiter(this, void 0, void 0, function* () {
        return (yield API.lookupConcepts({
            names: [text],
            corpus_ids: corpus_ids,
        })).concepts_found_by_name[0];
    });
}
export function getKbIdsAndNamesFromConcept({ concepts, corpus_ids, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const concept_ids_set = new Set();
        concepts.map(m => {
            concept_ids_set.add(m.id);
            m.kb_ids.forEach(k => concept_ids_set.add(k));
        });
        const concept_ids = Array.from(concept_ids_set);
        if (concept_ids) {
            const { kb_ids_and_names } = yield API.lookupKbIds({
                corpus_ids,
                concept_ids,
            });
            const idAndNameDict = concept_ids.reduce((acc, cur, idx) => {
                acc[cur] = kb_ids_and_names[idx];
                return acc;
            }, {});
            return concepts.map(m => (Object.assign(Object.assign({}, m), { id: idAndNameDict[m.id], kb_ids_and_names: m.kb_ids.map(id => idAndNameDict[id]) })));
        }
        return concepts.map(m => (Object.assign(Object.assign({}, m), { id: null, kb_ids_and_names: m.kb_ids.map(id => null) })));
    });
}
