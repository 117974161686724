var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Input } from "antd";
import { WarningOutlined } from "@ant-design/icons/lib";
import { MIN_TEXT_FILTER_LEN } from "../../cora/utils/semanticSearchUtil";
import { Tooltip } from "./Tooltip";
import { classes } from "../../utils/classes";
export function ValidationIcon({ error, className, }) {
    return error ? (_jsx(Tooltip, Object.assign({ content: error }, { children: _jsx(WarningOutlined, { className: classes("text-red-500", className) }) }))) : null;
}
export function FilterValidationIcon({ value, minFilterLength = MIN_TEXT_FILTER_LEN, }) {
    return (_jsx(ValidationIcon, { className: "flex items-center", error: value.trim().length > 0 && value.trim().length < minFilterLength
            ? "Type 3 characters or more to filter."
            : undefined }));
}
export const ValidatedInput = React.forwardRef(function (_a, fRef) {
    var { value, onChange, minFilterLength, prefix, className, clearIcon, focusOnMount } = _a, props = __rest(_a, ["value", "onChange", "minFilterLength", "prefix", "className", "clearIcon", "focusOnMount"]);
    React.useImperativeHandle(fRef, () => ({
        focus() {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        },
    }), []);
    const inputRef = React.useRef(null);
    // React.useEffect(() => inputRef.current?.focus(), [focusOnMount])
    return (_jsx(Input, Object.assign({ ref: inputRef, value: value, className: className, onChange: e => onChange(e.target.value), onKeyDown: e => {
            if (e.key === "Escape") {
                onChange("");
            }
        }, prefix: prefix }, props, { suffix: value.length > 0 ? (_jsxs("div", Object.assign({ className: "flex items-center justify-end" }, { children: [_jsx(FilterValidationIcon, { value: value, minFilterLength: minFilterLength }), _jsx("div", Object.assign({ onClick: e => {
                        e.preventDefault();
                        onChange("");
                    }, className: "ml-2" }, { children: clearIcon }))] }))) : (_jsx("span", {})) })));
});
