var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getEmptyQueryStateForCorpus, useQueryState } from "./queryState";
import React from "react";
import { useLocationChange } from "../../../utils/routing";
import { setLoading } from "../../../shared/components/Loading";
import { useEffectIfDifferent } from "../../../utils/hooks";
import { isEqual } from "lodash";
import { loadQueryState, QueryId, saveQueryState } from "./queryId";
export function useQueryIdSynchronizer() {
    const [state, updateState] = useQueryState();
    const latest = React.useRef({});
    useLocationChange(React.useCallback(function loadStateFromQueryId() {
        return __awaiter(this, void 0, void 0, function* () {
            const id = QueryId.get();
            if (id === latest.current.id)
                return;
            console.log("Loading queryId", id, state);
            try {
                setLoading(true);
                if (!id) {
                    updateState(state => {
                        latest.current = {
                            id,
                            state: getEmptyQueryStateForCorpus(state.corpus_ids),
                        };
                        return latest.current.state;
                    });
                    return;
                }
                latest.current = {
                    id,
                    state: yield loadQueryState(id),
                };
                updateState(latest.current.state);
            }
            catch (err) {
                console.error("Error loading queryId", id, err);
            }
            finally {
                setLoading(false);
            }
        });
    }, []));
    useEffectIfDifferent(function saveStateAndSetQueryId() {
        return __awaiter(this, void 0, void 0, function* () {
            if (isEqual(state, latest.current.state))
                return;
            latest.current = {
                id: yield saveQueryState(state),
                state,
            };
            // console.log("Saved queryId", latest.current.id || "EMPTY", state)
            QueryId.set(latest.current.id);
        });
    }, [], [state]);
}
