import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { invalidCorpusIds, useHasCapability } from "../../utils/sourceManagement";
import { EvidenceType } from "../../api/types";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
import { TrendsDataChart, useSelectedTrendRangeUpdater } from "./Trends";
import { Chart } from "../Icons";
import { SelectionButton } from "../../../shared/components/buttons/SelectionButton";
import { CorpusCapability } from "../../../utils/capabilities/capabilities";
export function useTrendsAddOn({ coraState, coraStateUpdater, index, evidenceAndTotals, }) {
    const { corpus_ids } = coraState;
    const supportsTrends = useHasCapability(corpus_ids, CorpusCapability.TRENDS);
    const disabled = invalidCorpusIds(corpus_ids);
    const { showTrends } = useCurrentSession();
    const rangeUpdater = useSelectedTrendRangeUpdater(coraState, coraStateUpdater);
    React.useEffect(() => {
        if (!showTrends) {
            rangeUpdater();
        }
    }, [showTrends, rangeUpdater]);
    return !supportsTrends
        ? undefined
        : {
            name: "trends",
            index,
            widget() {
                var _a;
                return showTrends ? (_jsx("div", Object.assign({ className: "relative flex flex-col items-center" }, { children: _jsx(TrendsDataChart, { coraState: coraState, coraStateUpdater: coraStateUpdater, disabled: disabled, setSelectedEvidenceType: type => coraStateUpdater({
                            refuting: type === EvidenceType.REFUTING,
                        }), emptyEvidence: evidenceAndTotals.loading
                            ? "loading"
                            : !((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.total_count) }) }), index)) : undefined;
            },
            selector(key) {
                return (_jsx(SelectionButton, { tooltip: "View Trends", tooltipPlacement: "top", icon: _jsx(Chart, {}), selected: showTrends, onMouseDown: () => updateCurrentSession({
                        showTrends: !showTrends,
                    }) }, key));
            },
        };
}
