import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CRCDirection, } from "../api/types";
import React from "react";
import { getTagStyle, } from "./lf";
import { intercalate } from "../../utils/collections";
import { TagsList } from "../../shared/components/TagsList";
import { getSize } from "../../utils/util";
import "./QueryRenderer.less";
import { mapValues } from "lodash";
import { itemsOrOverrides } from "../utils/semanticSearchUtil";
import { FixedArgs } from "../utils/coraUtil";
import { classes } from "../../utils/classes";
import { getArrow } from "./DirectionPicker";
import { ANY } from "../utils/queryState/DMParams";
import { getPartsAndColors, } from "./queryRenderer/queryRendererUtils";
export const ALL_OVERRIDES = [
    "concepts1",
    "concepts2",
    "contextConcepts",
    "extraConcepts",
];
export function coraStateToQueryParts({ concepts1 = [], concepts1Override = {}, concepts2 = [], concepts2Override = {}, relations = [], crcDirection = CRCDirection.BOTH, argClauses = {}, required_arguments = [], contextConcepts = [], contextConceptsOverride = {}, extraConcepts = [], extraConceptsOverride = {}, }, separateOverrides = []) {
    const c1 = itemsOrOverrides(concepts1, separateOverrides.includes("concepts1") ? {} : concepts1Override)
        .flat()
        .map(c => c.name);
    const c1Overrides = separateOverrides.includes("concepts1")
        ? mapValues(concepts1Override, c => c.map(c => c.name))
        : undefined;
    const c2 = itemsOrOverrides(concepts2, separateOverrides.includes("concepts2") ? {} : concepts2Override)
        .flat()
        .map(c => c.name);
    const c2Overrides = separateOverrides.includes("concepts2")
        ? mapValues(concepts2Override, c => c.map(c => c.name))
        : undefined;
    const r = relations.map(r => r.name);
    const context = itemsOrOverrides(contextConcepts, separateOverrides.includes("contextConcepts") ? {} : contextConceptsOverride)
        .flat()
        .map(c => c.name);
    const contextOverrides = separateOverrides.includes("contextConcepts")
        ? mapValues(contextConceptsOverride, c => c.map(c => c.name))
        : undefined;
    const extra = itemsOrOverrides(extraConcepts, separateOverrides.includes("extraConcepts") ? {} : extraConceptsOverride)
        .flat()
        .map(c => c.name);
    const extraOverrides = separateOverrides.includes("extraConcepts")
        ? Object.assign(Object.assign({}, Object.fromEntries(extraConcepts.map(c => [c.name, [ANY]]))), mapValues(extraConceptsOverride, c => c.map(c => c.name))) : undefined;
    const clauses = Object.assign(Object.assign({}, Object.fromEntries(required_arguments.map(r => [r, [ANY]]))), mapValues(argClauses, cl => cl.map(c => c.name)));
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (getSize(c1) && { c1 })), (getSize(c1Overrides) && { c1Overrides })), (getSize(c2) && { c2 })), (getSize(c2Overrides) && { c2Overrides })), (getSize(r) && { r })), (getSize(context) && { context })), (getSize(contextOverrides) && { contextOverrides })), (getSize(extra) && { extra })), (getSize(extraOverrides) && { extraOverrides })), (crcDirection !== CRCDirection.BOTH && { dir: crcDirection })), (getSize(clauses) && { argClauses: clauses }));
}
const NO_COLOR = "lightgray";
const SPACER_STYLE = { width: 0 };
export function QueryRenderer({ parts, suffix, conceptColorGetter, maxTags, className, colorProvider, styleProvider, noColor, arrowSize = 16, noQualifiers, }) {
    const entries = getPartsAndColors(parts, renderParts, conceptColorGetter, styleProvider);
    const { dir = CRCDirection.BOTH, extra = [], context = [], argClauses = {}, } = parts;
    return (_jsxs("span", Object.assign({ className: "QueryRenderer flex flex-row items-center py-1 overflow-auto" }, { children: [_jsxs("span", Object.assign({ className: "flex flex-row items-center py-1 flex-1" }, { children: [intercalate(entries, idx => getArrow(dir, {
                        key: idx + "ARROW",
                        style: { fontSize: arrowSize },
                    })), (getSize(argClauses) > 0 || extra.length > 0) && (_jsxs(_Fragment, { children: [_jsx("div", { style: SPACER_STYLE }), !noQualifiers &&
                                intercalate([
                                    _jsx("div", Object.assign({ className: "font-bold not-italic" }, { children: "\u254E" }), ":"),
                                    renderParts(extra.map(name => "Context: " + name), (colorProvider === null || colorProvider === void 0 ? void 0 : colorProvider[FixedArgs.CONTEXT_CONCEPT]) || NO_COLOR, "EXTRA", 0, styleProvider),
                                    ...Object.entries(argClauses).map(([argName, parts], idx) => renderParts(parts.length
                                        ? parts.map(p => argName + ": " + p)
                                        : [argName], (colorProvider === null || colorProvider === void 0 ? void 0 : colorProvider[argName]) || NO_COLOR, argName, 0, styleProvider)),
                                ], idx => _jsx("div", { style: SPACER_STYLE }, idx + "SPACE"))] }))] })), suffix] })));
    function renderParts(parts, color, key, idx, styleProvider) {
        return !parts.length ? null : (_jsx(TagsList, { items: parts, tagContent: v => v, tagStyle: () => styleProvider
                ? styleProvider(noColor ? NO_COLOR : color)
                : getTagStyle(noColor ? NO_COLOR : color), maxTags: maxTags, className: classes(className), setTagWidth: false }, key + idx));
    }
}
