import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
export var Mode;
(function (Mode) {
    Mode["normal"] = "normal";
    Mode["panning"] = "panning";
    Mode["selecting"] = "selecting";
    Mode["dragging"] = "dragging";
    Mode["staging"] = "staging";
    Mode["connecting"] = "connecting";
    Mode["merging"] = "merging";
})(Mode || (Mode = {}));
const DiagramModeContext = React.createContext(undefined);
export function DiagramModeProvider({ children }) {
    const mode = useCreateDiagramMode();
    return (_jsx(DiagramModeContext.Provider, Object.assign({ value: mode }, { children: children })));
}
export function useCreateDiagramMode() {
    const [stack, setStack] = React.useState([Mode.normal]);
    return React.useMemo(() => {
        function instance(mode) {
            const value = stack.at(-1) || Mode.normal;
            mode && setStack([mode]);
            return value;
        }
        instance.is = (mode, ...modes) => [mode, ...modes].includes(instance());
        instance.push = (mode) => setStack([...stack, mode]);
        instance.pop = () => setStack(stack.slice(0, -1));
        return instance;
    }, [stack]);
}
export function useDiagramMode() {
    return React.useContext(DiagramModeContext);
}
