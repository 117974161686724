import React from "react";
export function useShowDocument360() {
    React.useEffect(() => {
        const script = document.createElement("script");
        script.innerHTML = `

    function doc360_callback() {
    }

    function doc360_show_callback() {
    }

    function doc360_hide_callback() {
    }

    (function (w,d,s,o,f,js,fjs) {
      w['JS-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'mw', 'https://cdn.document360.io/static/js/widget.js'));
    mw('init', {
      apiKey: 'tusYiC7GcSm7yZIK9pDtyEASrXwq3sxy8ccwukAnAZtqAyGGmshkw1gN4J4PmUOaUbqAvBnQmEThH2HqmIpxgpk4PzVWpH3oH0sjzSmdL/9OzaJkEyp3pPuOPlSb7L4oshRkwKyupLn91RbzGBXFEQ==',
      callback:  doc360_callback,
      show_callback: doc360_show_callback,
      hide_callback: doc360_hide_callback

      })

      `;
        script.async = true;
        const style = document.createElement("style");
        style.innerHTML = `
       #assistantDoc360 iframe {
            display: inherit;
       }
       .HelpButtonHide {
        display: hidden;
       }
    `;
        document.body.appendChild(style);
        document.body.appendChild(script);
        return () => {
            script.remove();
            style.remove();
        };
    }, []);
    return () => document.getElementById("document360-widget-iframe").contentDocument
        .getElementById("doc360-button")
        .click();
}
