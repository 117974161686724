import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useGlobalInfo } from "../utils/globalInfo";
import { textComparator } from "../../utils/comparators";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import { Down, Globe } from "./Icons";
import { useCurrentSource } from "../utils/sourceManagement";
import { TabButton } from "../../shared/components/Tabs";
import { Tooltip } from "../../shared/components/Tooltip";
import { Radio } from "antd";
import { showPopupMenu } from "../../shared/actions/PopupMenu";
import { showSourceInfo } from "./SourceInfo";
import { TABS_BG } from "./lf";
import { limitLength } from "../../utils/util";
import { getEmptyQueryStateForCorpus, useQueryState, } from "../utils/queryState/queryState";
import { useCurrentSession } from "../utils/sessionManagement";
import { QueryId } from "../utils/queryState/queryId";
export function SourcePicker() {
    const [{ corpus_ids }, updater] = useQueryState();
    const corpora = Object.values(useGlobalInfo().corporaById);
    const selected = useCurrentSource(corpus_ids);
    const { lastQueryIdByCorpusId } = useCurrentSession();
    return corpora.length === 1 ? (_jsxs("div", Object.assign({ className: "flex items-center text-tiny font-medium pr-2", onContextMenu: e => handleContextMenuClick(e, corpora[0].id) }, { children: [_jsx(Globe, {}), "\u00A0", _jsx("span", { children: corpora[0].name })] }))) : (_jsx(SourcesDropDown, Object.assign({ selectedSourceId: selected === null || selected === void 0 ? void 0 : selected.id, onSelect: c => {
            const latestQueryId = lastQueryIdByCorpusId[c.id];
            if (latestQueryId) {
                QueryId.set(latestQueryId);
            }
            else {
                updater(getEmptyQueryStateForCorpus([c.id]));
            }
        } }, { children: _jsx(Tooltip, Object.assign({ content: "Evidence Corpus Source", placement: "bottom-end" }, { children: _jsxs(TabButton, Object.assign({ onContextMenu: selected && (e => handleContextMenuClick(e, selected.id)), className: TABS_BG, "data-test-id": "corpusId" }, { children: [_jsx(Globe, { className: "text-base " }), _jsx("div", Object.assign({ className: "" }, { children: limitLength(selected === null || selected === void 0 ? void 0 : selected.name, 24) || "No Source Selected" })), _jsx(Down, { className: "text-xs" })] })) })) })));
}
export function SourcesDropDown({ selectedSourceId, onSelect, children, caption, }) {
    const corpora = Object.values(useGlobalInfo().corporaById);
    return (_jsx(DropDownMenu, Object.assign({ menuTestId: "corpusList", title: "Source", hasClose: true, entries: () => {
            return corpora
                .sort((a, b) => textComparator(a.name, b.name))
                .map(c => {
                var _a;
                return ({
                    caption: (_a = caption === null || caption === void 0 ? void 0 : caption(c)) !== null && _a !== void 0 ? _a : c.name,
                    icon: () => _jsx(Radio, { checked: selectedSourceId === c.id }),
                    action() {
                        if (selectedSourceId !== c.id) {
                            onSelect(c);
                        }
                    },
                });
            });
        } }, { children: children })));
}
function handleContextMenuClick(e, corporaId) {
    e.preventDefault();
    showPopupMenu({
        left: e.clientX,
        top: e.clientY,
        entries: [
            {
                caption: "Show Info…",
                action() {
                    showSourceInfo(corporaId);
                },
            },
        ],
    });
}
