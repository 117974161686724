import { Bounds } from "./Bounds";
export function QTree(items, boundsGetter) {
    const index = new Map();
    const depth = 100;
    function calcBounds() {
        if (!items.length)
            return undefined;
        let bounds = boundsGetter(items[0]);
        for (let i = 1; i < items.length; i++) {
            bounds = Bounds.union(bounds, boundsGetter(items[i]));
        }
        return bounds;
    }
    const bounds = calcBounds();
    const root = bounds && new Quadrants(undefined, bounds);
    root &&
        items.forEach(item => root.add(item, boundsGetter(item), depth, index));
    return {
        bounds,
        getCollisions(bounds) {
            const acc = new Set();
            root && root.getItemsIntersecting(bounds, boundsGetter, acc);
            return Array.from(acc);
        },
    };
}
/**
 * Quadrants splits a rectangular area recursively.
 * every quadrant has a set of items associated with the quadrant.
 * if an object fully falls within one of the quadrants it is stored in that quadrant
 */
class Quadrants {
    constructor(parent, b) {
        this.quadrants = [];
        this.getOrCreateQuadrant = (idx) => {
            let quadrant = this.quadrants[idx];
            if (!quadrant) {
                const b = this.qBounds[idx];
                this.quadrants[idx] = quadrant = new Quadrants(this, b);
            }
            return quadrant;
        };
        this.add = (item, itemBounds, depth, index) => {
            // find if itemBounds are fully contained in one of the sub quadrants
            const quadrantIdx = depth <= 0
                ? -1
                : this.qBounds.findIndex(bounds => Bounds.contains(bounds, itemBounds));
            const subQuadrant = quadrantIdx < 0 ? undefined : this.getOrCreateQuadrant(quadrantIdx);
            if (!subQuadrant) {
                // story item in current
                if (!this.items) {
                    this.items = new Set();
                }
                this.items.add(item);
                index.set(item, this);
            }
            else {
                // itemBounds is fully contained and we have not reach the max depth, recurse
                subQuadrant.add(item, itemBounds, depth - 1, index);
            }
        };
        this.getItemsIntersecting = (bounds, boundsGetter, acc = new Set()) => {
            if (Bounds.intersects(bounds, this.bounds)) {
                this.items &&
                    this.items.forEach(item => Bounds.intersects(bounds, boundsGetter(item)) && acc.add(item));
                this.quadrants.forEach(q => q && q.getItemsIntersecting(bounds, boundsGetter, acc));
            }
            return acc;
        };
        this.parent = parent;
        this.bounds = b;
        const [x, y] = b;
        const w = Bounds.w(b) / 2;
        const h = Bounds.h(b) / 2;
        this.qBounds = [
            Bounds(x, y, w, h),
            Bounds(x + w, y, w, h),
            Bounds(x, y + h, w, h),
            Bounds(x + w, y + h, w, h),
        ];
    }
}
