import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { MetadataType, } from "../../../api/types";
import { useCurrentSource } from "../../../utils/sourceManagement";
import { keyBy } from "lodash";
import { FacetsForRange } from "./FacetsForRange";
import { useUpdateFacetCollapseCleanup } from "../facetsCollapsedState";
import { FacetsForKeyword } from "./FacetsForKeyword";
import { FacetsForBoolean } from "./FacetsForBolean";
import { isNully } from "../../../../utils/util";
import { FacetsTabContent } from "../FacetsTabContent";
export function MetadataFacets({ corpusFilterParams, updater, facetClassName, }) {
    var _a;
    const metadata = ((_a = useCurrentSource(corpusFilterParams.corpus_ids)) === null || _a === void 0 ? void 0 : _a.supported_metadata_fields) || [];
    const metaDataById = keyBy(metadata, "name");
    const { keywordMetadata, rangeMetadata, booleanMetadata } = corpusFilterParams;
    useUpdateFacetCollapseCleanup(corpusFilterParams.corpus_ids, "metadata", metadata.filter(m => m.type === MetadataType.KEYWORD).map(m => m.id));
    const byName = React.useMemo(() => {
        function update(key, type) {
            return (name, id, value) => {
                const param = corpusFilterParams[key];
                updater({
                    [key]: param
                        .filter(i => i.id !== id)
                        .concat(isNully(value) ? [] : [{ id, name, type, value }]),
                });
            };
        }
        return {
            [MetadataType.KEYWORD]: {
                Component: FacetsForKeyword,
                selection: keyBy(keywordMetadata, "name"),
                updateSelection: update("keywordMetadata", MetadataType.KEYWORD),
            },
            [MetadataType.DATE]: {
                Component: FacetsForRange,
                selection: keyBy(rangeMetadata, "name"),
                updateSelection: update("rangeMetadata", MetadataType.DATE),
            },
            [MetadataType.BOOLEAN]: {
                Component: FacetsForBoolean,
                selection: keyBy(booleanMetadata, "name"),
                updateSelection: update("booleanMetadata", MetadataType.BOOLEAN),
            },
        };
    }, [keywordMetadata, rangeMetadata, booleanMetadata, corpusFilterParams]);
    return (_jsx(FacetsTabContent, { facets: () => (_jsx(_Fragment, { children: metadata
                .map(m => m.name)
                .map(name => metaDataById[name])
                .map((m, idx) => {
                var _a;
                const { Component, selection, updateSelection } = byName[m.type];
                return (_jsx(Component, { corpusFilterParams: corpusFilterParams, fieldId: m.id, name: m.name, selection: (_a = selection[m.name]) === null || _a === void 0 ? void 0 : _a.value, updateSelection: updateSelection, className: facetClassName }, m.name + idx));
            }) })) }));
}
