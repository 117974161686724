export function plainRequestExecutor() {
    return ({ exec, onResponse, onError }) => {
        exec().then(onResponse).catch(onError);
    };
}
// if a request is being fulfilled and new request comes, the new request cones,
// it is queued to be executed after the current request is resolved.
// only the last pending request is kept, intermediate ones are discarded
export function debouncedRequestExecutor() {
    let pending = null;
    let busy = false;
    function execute(request) {
        if (!busy) {
            busy = true;
            request
                .exec()
                .then(request.onResponse)
                .catch(request.onError)
                .finally(() => {
                busy = false;
                if (pending) {
                    request = pending;
                    pending = null;
                    execute(request);
                }
            });
        }
        else {
            pending = request;
        }
    }
    return execute;
}
