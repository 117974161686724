import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import { WarningOutlined } from "@ant-design/icons";
import { Button } from "antd";
function FullPageError({ message }) {
    const onDismiss = React.useCallback(() => (window.location.href = window.location.origin), []);
    return (_jsx("div", Object.assign({ className: classes("absolute w-full h-full flex overflow-clip bg-neutral-100 items-center justify-center"), style: { backgroundColor: "#10002b" } }, { children: _jsxs("div", Object.assign({ className: classes("flex flex-col text-left m-1 max-w-[500px] rounded-xl shadow-lg p-4 bg-white") }, { children: [_jsx("p", Object.assign({ className: classes("p-2 flex flex-grow") }, { children: "An error was returned from the authorization server:" })), _jsxs("div", Object.assign({ className: classes("p-2 flex items-center") }, { children: [_jsx(WarningOutlined, { style: { fontSize: "18px" }, className: classes("mr-1 text-red-500 flex-shrink") }), _jsx("p", Object.assign({ className: classes("p-2 text-red-500") }, { children: _jsx("strong", { children: message }) }))] })), _jsx(Button, Object.assign({ className: classes("self-end mt-2"), onClick: onDismiss }, { children: "Back to Cora" }))] })) })));
}
export default FullPageError;
