var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { GeniusButton } from "../../shared/components/Genius";
import { FeatureIdentifier, FeedbackType, } from "../api/types";
import { showNotification } from "../../shared/components/notifications";
import { useEffectIfDifferent } from "../../utils/hooks";
import { isEmptyQuery, queryToCrc } from "../utils/semanticSearchUtil";
import { MultiSelectDropDown } from "../../shared/components/MultiSelectDropDown";
import { keyBy } from "lodash";
import { Space, Spin } from "antd";
import { CONCEPT_1_COLOR, CONCEPT_2_COLOR } from "./lf";
import { createFeedbackButton, sendFeedback } from "../utils/feedback";
import { QueryRenderer } from "./QueryRenderer";
import { FieldsetBorder } from "../../shared/components/FieldsetBorder";
import { useSuggestRelatedQueries } from "../utils/querySuggestions";
import { limitLength } from "../../utils/util";
import { CorpusCapability } from "../../utils/capabilities/capabilities";
function getQueryId(query) {
    var _a, _b;
    return ((((_a = query === null || query === void 0 ? void 0 : query.relation_filter) === null || _a === void 0 ? void 0 : _a.filter) || []).map(i => i.name).join(",") +
        ":" +
        (((_b = query === null || query === void 0 ? void 0 : query.concept_filter) === null || _b === void 0 ? void 0 : _b.filter) || []).map(i => i.name).join(","));
}
export function ExtraQueriesPicker({ coraState, coraStateUpdater }) {
    const noSelection = isEmptyQuery(coraState);
    useEffectIfDifferent(() => {
        if (noSelection) {
            coraStateUpdater({ queries: [] });
        }
    }, [], [noSelection]);
    const { remote, params } = useSuggestRelatedQueries(coraState);
    const suggested = remote.value || [];
    const selected = coraState.queries;
    const { selectedById, items } = React.useMemo(() => {
        const selectedById = keyBy(selected, getQueryId);
        const items = selected.concat(suggested.filter(q => !selectedById[getQueryId(q)]));
        return {
            selectedById,
            items,
        };
    }, [selected, suggested]);
    const [feedback, setFeedback] = React.useState({});
    function feedbackButton(item, type) {
        return createFeedbackButton(item, type, feedback[getQueryId(item)], () => __awaiter(this, void 0, void 0, function* () {
            setFeedback(f => (Object.assign(Object.assign({}, f), { [getQueryId(item)]: type })));
            yield sendFeedback(FeatureIdentifier.SUGGEST_CONCEPTS, CorpusCapability.SUGGEST_QUERIES_RELATED, type, item, params);
            showNotification("Feedback sent");
        }));
    }
    return (_jsx(MultiSelectDropDown, Object.assign({ title: "Similar Queries", maxHeight: 300, getItemKey: getQueryId, getItemRenderer: i => (_jsx(QueryRenderer, { parts: queryToPars(i), suffix: _jsxs(Space, Object.assign({ size: "small" }, { children: [feedbackButton(i, FeedbackType.POSITIVE), feedbackButton(i, FeedbackType.NEGATIVE)] })), conceptColorGetter: conceptColorGetter })), items: items, isSelected: i => !!selectedById[getQueryId(i)], updateSelection: queries => {
            coraStateUpdater({ queries });
        } }, { children: _jsx(FieldsetBorder, Object.assign({ title: "Similar Queries", className: " min-w-[125px]" }, { children: _jsxs("div", Object.assign({ className: "flex justify-between items-center space-x-4 text-white" }, { children: [_jsx("div", Object.assign({ className: "ml-2" }, { children: `${selected.length} Selected` })), _jsx("div", Object.assign({ className: "flex justify-end w-4" }, { children: remote.loading ? (_jsx(Spin, { size: "small" })) : (_jsx(GeniusButton, { entriesCount: suggested.length, iconClass: "text-lg" })) }))] })) })) })));
}
export function queryToPars(query, maxPartLen) {
    const { concepts1, relations, concepts2, crcDirection: dir } = queryToCrc(query);
    return Object.assign(Object.assign(Object.assign(Object.assign({}, (concepts1.length && {
        c1: concepts1.map(i => limitLength(i.name, maxPartLen)),
    })), (concepts2.length && {
        c2: concepts2.map(i => limitLength(i.name, maxPartLen)),
    })), (relations.length && {
        r: relations.map(i => limitLength(i.name, maxPartLen)),
    })), { dir });
}
export function conceptColorGetter({ c1, c2 }, colorForC1) {
    return colorForC1 || !(c1 === null || c1 === void 0 ? void 0 : c1.length) ? CONCEPT_1_COLOR : CONCEPT_2_COLOR;
}
