var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import chroma from "chroma-js";
import { HEADER_COLOR } from "../../cora/components/lf";
import { Tooltip } from "./Tooltip";
export function FieldsetBorder(_a) {
    var { children, className, bgColor = HEADER_COLOR, fieldTitle, titleColor, borderColor = "rgba(255,255,255,.5)", titleInside, zIndex = 0, tooltip } = _a, props = __rest(_a, ["children", "className", "bgColor", "fieldTitle", "titleColor", "borderColor", "titleInside", "zIndex", "tooltip"]);
    titleColor =
        titleColor || (chroma(bgColor).luminance() < 0.5 ? "white" : "black");
    return (_jsx(Tooltip, Object.assign({ content: tooltip, wrapperClassname: className }, { children: _jsxs("fieldset", Object.assign({ className: classes("px-2 py-1", className), style: {
                borderWidth: 1,
                borderColor,
                borderStyle: "solid",
                borderRadius: 8,
                backgroundColor: bgColor,
                zIndex,
            } }, props, { children: [fieldTitle && (_jsx("legend", Object.assign({ className: "text-sm font-bold px-1 select-none", style: Object.assign({ color: titleColor, width: "unset", marginBottom: 0 }, (titleInside && { paddingTop: 11 })) }, { children: fieldTitle }))), _jsx("div", Object.assign({ className: "flex space-x-1 items-center" }, { children: children }))] })) })));
}
