import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FacetsForConcepts } from "./FacetsForConcepts";
import { concept1argumentName, concept2argumentName, isEmptyQuery, } from "../../utils/semanticSearchUtil";
import { CONCEPT_1_CAPTION, CONCEPT_2_CAPTION, EXTRA_CONCEPTS_CAPTION, } from "../../utils/coraUtil";
import { FacetsTabContent } from "./FacetsTabContent";
import { referenceCreator, ReferenceType } from "../../utils/references";
import { getTopResultsField } from "../../utils/concepts";
import { getSelectionModelFactory } from "./ConceptFacetSelectionModel";
export function FacetsForCRCConcepts({ coraState, coraStateUpdater, }) {
    const [feedback, setFeedback] = React.useState({});
    const hasQuery = !isEmptyQuery(coraState);
    const { concepts1, concepts2 } = coraState;
    const topResultsField = getTopResultsField(coraState);
    const topIsC1 = topResultsField === "concepts1";
    const topIsC2 = topResultsField === "concepts2";
    const c1Count = topIsC1 ? 1 : concepts1.length;
    const c2Count = topIsC2 ? 1 : concepts2.length;
    const facetsForConcepts1 = (_jsx(FacetsForConcepts, { coraState: coraState, coraStateUpdater: coraStateUpdater, relevantConcepts: concepts1, selectedConcepts: concepts1, conceptsKey: "concepts1", conceptsOverridesKey: "concepts1Override", possibleConceptsKey: "concepts1Possible", otherConceptsKeys: ["concepts2", "extraConcepts", "contextConcepts"], otherConceptsOverrideKeys: [
            "concepts2Override",
            "extraConceptsOverride",
            "contextConceptsOverride",
        ], title: CONCEPT_1_CAPTION, argument_name: concept1argumentName(coraState.crcDirection), feedback: feedback, setFeedback: setFeedback, nameProvider: referenceCreator(0, ReferenceType.CONCEPT), selectionModelFactory: getSelectionModelFactory(false) }));
    const facetsForConcepts2 = (_jsx(FacetsForConcepts, { coraState: coraState, coraStateUpdater: coraStateUpdater, relevantConcepts: concepts2, selectedConcepts: concepts2, conceptsKey: "concepts2", conceptsOverridesKey: "concepts2Override", possibleConceptsKey: "concepts2Possible", otherConceptsKeys: ["concepts1", "extraConcepts", "contextConcepts"], otherConceptsOverrideKeys: [
            "concepts1Override",
            "extraConceptsOverride",
            "contextConceptsOverride",
        ], title: CONCEPT_2_CAPTION, argument_name: concept2argumentName(coraState.crcDirection), feedback: feedback, setFeedback: setFeedback, nameProvider: referenceCreator(c1Count, ReferenceType.CONCEPT), selectionModelFactory: getSelectionModelFactory(false) }));
    return (_jsx(FacetsTabContent, { description: !hasQuery && (_jsx("div", { children: "Run a Research Query to display the concepts that are central to your query, which you can select to filter your results." })), facets: () => (_jsxs(_Fragment, { children: [facetsForConcepts1, facetsForConcepts2, _jsx(FacetsForConcepts, { coraState: coraState, coraStateUpdater: coraStateUpdater, relevantConcepts: coraState.contextConcepts, selectedConcepts: coraState.contextConcepts, conceptsKey: "contextConcepts", conceptsOverridesKey: "contextConceptsOverride", possibleConceptsKey: "contextConceptsPossible", otherConceptsKeys: ["concepts1", "concepts2", "extraConcepts"], otherConceptsOverrideKeys: [
                        "concepts1Override",
                        "concepts2Override",
                        "extraConceptsOverride",
                    ], title: EXTRA_CONCEPTS_CAPTION(), feedback: feedback, setFeedback: setFeedback, nameProvider: referenceCreator(c1Count + c2Count, ReferenceType.CONCEPT), selectionModelFactory: getSelectionModelFactory(false) })] })) }));
}
