var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { omit } from "lodash";
import { upsertOrRemove } from "../../../utils/collections";
export function getSelectionModelFactory(overrideAddsConcept) {
    return ({ coraState, coraStateUpdater, conceptsKey, conceptsOverridesKey, concept, category, }) => {
        const _a = coraState[conceptsOverridesKey], _b = category, _c = _a[_b], overrides = _c === void 0 ? [] : _c, otherCatOverrides = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        const selectedConcepts = !concept
            ? overrides
            : overrides.filter((a) => a.name !== concept.name);
        const anySelected = overrides.length > 0;
        const selectionModel = {
            isReqSelected() {
                return anySelected;
            },
            setReq: concept &&
                (req => {
                    coraStateUpdater(Object.assign({ [conceptsOverridesKey]: req
                            ? Object.assign(Object.assign({}, otherCatOverrides), { [category]: [concept] }) : otherCatOverrides }, updateConcept(req)));
                }),
            getSelected() {
                return selectedConcepts;
            },
            setSelected(selected) {
                const overrides = omit(coraState[conceptsOverridesKey], category);
                coraStateUpdater(Object.assign({ [conceptsOverridesKey]: Object.assign(Object.assign({}, overrides), (selected.length && { [category]: selected })) }, updateConcept(selected.length > 0)));
            },
        };
        return selectionModel;
        function updateConcept(add) {
            return !overrideAddsConcept || !concept
                ? undefined
                : {
                    [conceptsKey]: upsertOrRemove(coraState[conceptsKey], c => c.name, concept.name, add ? concept : undefined),
                };
        }
    };
}
