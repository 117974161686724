import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import { Virtuoso } from "react-virtuoso";
export function Items({ items, horizontal, itemRender, className, gutter, innerClass = "mr-2", style, }) {
    return (_jsx("div", Object.assign({ className: classes(className, "flex items-stretch", horizontal ? "flex-row " : "flex-col", gutter !== "NONE"
            ? gutter === "NARROW"
                ? horizontal
                    ? "space-x-1"
                    : "space-y-1"
                : horizontal
                    ? "space-x-2"
                    : "space-y-2"
            : null), style: style }, { children: items.map((d, idx) => (_jsx("div", Object.assign({ className: innerClass }, { children: _jsx(_Fragment, { children: itemRender ? itemRender(d, idx, items) : d }) }), idx))) })));
}
export function VirtualizedItems({ items, horizontal, itemRender, className, style, }) {
    return (_jsx(Virtuoso, { className: classes(className, "flex items-stretch relative overflow-y-auto overflow-x-hidden", horizontal ? "flex-row " : "flex-col"), totalCount: items.length, itemContent: idx => (_jsx(_Fragment, { children: itemRender ? itemRender(items[idx], idx, items) : items[idx] })), style: style }));
}
