var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrderedMap } from "immutable";
export function createCache(maxSize, getter) {
    let cache = OrderedMap();
    return {
        get(params, noCache = false) {
            return __awaiter(this, void 0, void 0, function* () {
                if (noCache) {
                    return yield getter(params);
                }
                const key = JSON.stringify(params);
                function retrieve() {
                    return __awaiter(this, void 0, void 0, function* () {
                        if (cache.has(key)) {
                            return cache.get(key);
                        }
                        else {
                            return yield getter(params);
                        }
                    });
                }
                const value = yield retrieve();
                cache = cache.remove(key).set(key, value);
                if (cache.size > maxSize) {
                    const firstKey = cache.entries().next().value[0];
                    cache = cache.remove(firstKey);
                }
                return value;
            });
        },
    };
}
