var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { API } from "../api/api";
import { decodeQueryParams, useQueryParams } from "../../utils/routing";
import { merge } from "../../utils/collections";
import { isEqual, mapValues } from "lodash";
import { useEffectIfDifferent } from "../../utils/hooks";
import { getValue } from "../../utils/util";
import { ENCODED_QUERY_PARAM_KEY } from "./coraState";
import { migrateCoraState } from "./versionManagement";
export function useEncodedQueryParamsAsState(defValue) {
    const [urlParams, updateQueryParams, _, currentPathName] = useQueryParams();
    const [state, setState] = React.useState(defValue);
    // every time there is an update, query params are set to reflect the change
    const updater = React.useCallback((update, pathName) => {
        // the update can come with params for the state and other params
        const [stateUpdate, otherParams] = splitParams(getValue(state, update), defValue);
        const data = merge(state, stateUpdate);
        const clearStateParamsAndSetOthers = Object.assign(Object.assign({}, otherParams), clearParamsUpdate(defValue));
        if (isEqual(data, state)) {
            //  clear stateParams and set other params
            updateQueryParams(clearStateParamsAndSetOthers, pathName);
        }
        else if (isEqual(data, defValue)) {
            // remove dataId, clear stateParams and set other params
            updateQueryParams(Object.assign(Object.assign({}, clearStateParamsAndSetOthers), { [ENCODED_QUERY_PARAM_KEY]: undefined }), pathName);
        }
        else {
            // encode stateParams, set dataId, clear stateParams and set other params
            API.getUrlId({ data })
                .then(dataId => {
                updateQueryParams(Object.assign(Object.assign({}, clearStateParamsAndSetOthers), { [ENCODED_QUERY_PARAM_KEY]: dataId }), pathName);
            })
                .catch(e => {
                // if an error occurred while encoding, set state directly
                console.error(e);
                setState(data);
            });
        }
    }, [state, currentPathName, urlParams]);
    // when the query params change, update the state to reflect the changes
    const { dataId, stateParams } = getStateAndDataId(urlParams, defValue);
    useEffectIfDifferent(() => {
        getStateFromQueryParams(urlParams, defValue).then(v => setState(v));
    }, [urlParams], [dataId, stateParams], true);
    return [state, updater];
}
function splitParams(params, defValue) {
    const defValueKeys = new Set(Object.keys(defValue));
    return Object.entries(params).reduce((acc, [key, value]) => {
        ;
        acc[defValueKeys.has(key) ? 0 : 1][key] = value;
        return acc;
    }, [{}, {}]);
}
export function getStateFromQueryParams(urlParams, defValue) {
    return __awaiter(this, void 0, void 0, function* () {
        const { dataId, stateParams } = getStateAndDataId(urlParams, defValue);
        if (dataId) {
            // id there is a dataId, decode it and set state
            const decoded = yield API.getUrlData({ id: dataId });
            return migrateCoraState(Object.assign(Object.assign({}, defValue), decoded));
        }
        return stateParams;
    });
}
function getStateAndDataId(urlParams, defValue) {
    const _a = urlParams, _b = ENCODED_QUERY_PARAM_KEY, dataId = _a[_b], other = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    const stateParams = decodeQueryParams(defValue, other);
    return { dataId, stateParams };
}
function clearParamsUpdate(defValue) {
    return mapValues(defValue, () => undefined);
}
