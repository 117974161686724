import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useMemoIfEqual } from "../../utils/hooks";
import { classes } from "../../utils/classes";
import { AnnotatedString } from "./AnnotatedString";
import chroma from "chroma-js";
import colors from "../../assets/colors";
import { isEqual, sortBy, uniq, uniqBy } from "lodash";
import { Tooltip } from "./Tooltip";
export function MarkDown({ text, match, matchRenderer, noMatchRenderer, className, style, }) {
    return (_jsx("span", Object.assign({ className: className, style: style }, { children: match
            ? text
                .split(match)
                .map((part, idx) => idx % 2 === 0 ? (noMatchRenderer ? (noMatchRenderer(part, idx)) : (_jsx("span", { children: part }, idx))) : (matchRenderer(part, idx)))
            : noMatchRenderer
                ? noMatchRenderer(text, 0)
                : text })));
}
export function DefaultMarkDown({ text, separator, prefix, markDownClass = "bg-gray-300", markDownStyle, className, style, }) {
    const regExp = separator
        ? new RegExp(`(${prefix ? "\b" : ""}${regEscape(separator)})`, "ig")
        : undefined;
    return (_jsx(MarkDown, { text: text, className: className, style: style, match: regExp, matchRenderer: (text, idx) => (_jsx("span", Object.assign({ style: Object.assign({ overflow: "hidden" }, markDownStyle), className: markDownClass }, { children: text }), idx)) }));
}
export function EvidenceMarkDown({ evidence, text, className, annotationProvider, }) {
    return useMemo(() => {
        const additionalSpans = evidence.argument_spans.reduce((acc, s) => {
            acc.push({
                span: s.span,
                type: "argument",
                payload: s.argument,
            });
            acc.push(...s.concept_spans.map(c => ({
                span: c.span,
                type: "concept",
                payload: c,
            })));
            return acc;
        }, []);
        const spans = evidence.highlight_spans.reduce((acc, h) => {
            if (h.span) {
                acc.push({
                    span: h.span,
                    annotation: {
                        span: h.span,
                        match: h,
                        additionalSpans: additionalSpans.filter(aSpan => isEqual(aSpan.span, h.span)),
                    },
                });
            }
            return acc;
        }, []);
        const getHighlights = (spans) => {
            const anns = annotationProvider ? annotationProvider(spans) : undefined;
            const empty = {
                style: {},
                supStyle: {},
                concepts: [],
                relations: [],
                args: [],
            };
            const highlights = anns
                ? anns.reduce((acc, h) => {
                    if (h.type === "keyword_match") {
                        acc.style.backgroundColor = chroma("gray").alpha(0.25).css();
                    }
                    else if (h.type === "text_match") {
                        acc.style.fontWeight = 700;
                    }
                    else if (h.type === "concept") {
                        acc.style.color = colors.accent[500];
                        acc.style.fontWeight = 500;
                        acc.supStyle.color = colors.accent[500];
                        acc.supStyle.fontWeight = 700;
                        acc.concepts.push(h.value);
                    }
                    else if (h.type === "argument") {
                        acc.style.color = colors.accent[500];
                        acc.style.fontWeight = 500;
                        acc.supStyle.color = colors.accent[500];
                        acc.supStyle.fontWeight = 700;
                        acc.args.push(h.value);
                    }
                    else if (h.type === "relation") {
                        acc.style.color = colors.accent[500];
                        acc.style.fontWeight = 500;
                        acc.supStyle.color = colors.accent[500];
                        acc.supStyle.fontWeight = 700;
                        acc.relations.push(h.value);
                    }
                    return acc;
                }, empty)
                : empty;
            return highlights;
        };
        return (_jsx("span", Object.assign({ className: classes("MarkDown", className) }, { children: _jsx(AnnotatedString, { text: text, annotations: spans, renderAnnotation: ({ text, annotations, neighboringAnnotations }) => {
                    const highlights = getHighlights(annotations);
                    const nextHighlights = getHighlights(neighboringAnnotations.after);
                    const superscripts = uniq([
                        sortBy(highlights.concepts, "ordering")
                            .map(c => c.key)
                            .filter(c => !nextHighlights.concepts.map(c => c.key).includes(c)),
                        sortBy(highlights.relations, "key")
                            .map(r => r.key)
                            .filter(r => !nextHighlights.relations.map(r => r.key).includes(r)),
                        sortBy(highlights.args, "key")
                            .map(a => a.key)
                            .filter(a => !nextHighlights.args.map(a => a.key).includes(a)),
                    ].flat());
                    const tooltips = uniqBy([
                        highlights.concepts,
                        highlights.relations,
                        highlights.args,
                    ].flat(), "value");
                    const body = (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "whitespace-pre-wrap", style: highlights.style }, { children: text })), superscripts.length > 0 ? (_jsx("sup", Object.assign({ className: "select-none clearselect", style: highlights.supStyle }, { children: superscripts.map(s => `[${s}]`).join("") }))) : null] }));
                    if (!tooltips.length)
                        return body;
                    const sorter = (s) => sortBy(sortBy(s, "key"), "ordering");
                    const tooltipContent = (_jsx("div", { children: sorter(tooltips).map((t, idx) => (_jsxs("div", Object.assign({ className: "text-sm" }, { children: [_jsxs("b", { children: [t.context, " (", t.key, ")"] }), ": ", t.value] }), idx))) }));
                    return (_jsx(Tooltip, Object.assign({ wrapperClassname: "inline-block", content: tooltipContent }, { children: body })));
                } }) })));
    }, [useMemoIfEqual(evidence), annotationProvider, text, className]);
}
function regEscape(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
