var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { omit, pick } from "lodash";
import { filterDic } from "../../../utils/collections";
import { getActualActiveConstraintId, getConstraint, getConstraintReferredIds, isEmptyParams, removeConstraint, } from "./DMParams";
export function removeRefs(_a, ...ids) {
    var { dmParams, activeConstraintId, overrides, nodesInfo } = _a, rest = __rest(_a, ["dmParams", "activeConstraintId", "overrides", "nodesInfo"]);
    const { constraints, concepts, clauses, relations } = dmParams, dmRest = __rest(dmParams, ["constraints", "concepts", "clauses", "relations"]);
    const set = new Set(ids);
    return Object.assign(Object.assign({}, rest), { dmParams: Object.assign(Object.assign({}, dmRest), { concepts: omit(concepts, ids), clauses: omit(clauses, ids), relations: omit(relations, ids), constraints: constraints
                .map(removeFromConstraint)
                .filter(c => !isEmptyConstraint(c)) }), activeConstraintId, overrides: omit(overrides, ids), nodesInfo: omit(nodesInfo, ids) });
    function removeFromConstraint(c) {
        const source = c.source && set.has(c.source) ? null : c.source;
        const target = c.target && set.has(c.target) ? null : c.target;
        const relation = c.relation && set.has(c.relation) ? null : c.relation;
        const context = c.context.filter(c => !set.has(c));
        const qualifiers = filterDic(c.qualifiers, (_, v) => !set.has(v));
        return Object.assign(Object.assign({}, c), { source, target, relation, context, qualifiers });
    }
}
export function isEmptyConstraint(c) {
    return !c.source && !c.target && !c.relation && !c.context.length;
}
export function updateState(state, update) {
    return update.dmParams.constraints.reduce((acc, c) => !isEmptyConstraint(c)
        ? upsertConstraint(acc, update, c.id)
        : removeStateConstraint(acc, c.id), state);
}
export function removeStateConstraint(state, id) {
    const { dmParams, activeConstraintId, overrides, nodesInfo } = state, rest = __rest(state, ["dmParams", "activeConstraintId", "overrides", "nodesInfo"]);
    const c = getConstraint(dmParams, id);
    if (!c)
        return state;
    const [removed, idsToRemove] = removeConstraint(dmParams, id);
    return Object.assign(Object.assign({}, rest), { dmParams: removed, activeConstraintId: activeConstraintId === id
            ? getActualActiveConstraintId(removed)
            : activeConstraintId, overrides: omit(overrides, idsToRemove), nodesInfo: omit(nodesInfo, idsToRemove) });
}
function upsertConstraint(state, update, id) {
    const uParams = update.dmParams;
    const constraint = getConstraint(uParams, id);
    if (!constraint || isEmptyConstraint(constraint))
        return state;
    const { dmParams: p, activeConstraintId, overrides, nodesInfo } = state, rest = __rest(state, ["dmParams", "activeConstraintId", "overrides", "nodesInfo"]);
    const orgConstraint = getConstraint(p, id);
    const uRefs = getConstraintReferredIds(constraint).toArray();
    return Object.assign(Object.assign({}, rest), { dmParams: Object.assign(Object.assign({}, p), { constraints: !orgConstraint
                ? [...p.constraints, constraint]
                : p.constraints.map(c => (c.id === id ? constraint : c)), concepts: Object.assign(Object.assign({}, p.concepts), pick(uParams.concepts, uRefs)), relations: Object.assign(Object.assign({}, p.relations), pick(uParams.relations, uRefs)), clauses: Object.assign(Object.assign({}, p.clauses), pick(uParams.clauses, uRefs)) }), activeConstraintId: isEmptyParams(p) ? id : activeConstraintId, overrides: Object.assign(Object.assign({}, overrides), pick(update.overrides, uRefs)), nodesInfo: Object.assign(Object.assign({}, nodesInfo), pick(update.nodesInfo, uRefs)) });
}
