import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useMemoIfEqual } from "../../../../utils/hooks";
import { API } from "../../../api/api";
import moment from "moment";
import { useMetadataParamsGetter, } from "../../../utils/metatada";
import { useRemoteQuery } from "../../../../utils/query/remoteQuery";
import { classes } from "../../../../utils/classes";
import { FacetContainer } from "../FacetContainer";
import { DatePicker } from "./DatePicker";
import "./FacetsForRange.less";
export function FacetsForRange({ corpusFilterParams, fieldId, name, selection, updateSelection, className, }) {
    const { paramsGetter, disabled } = useMetadataParamsGetter(corpusFilterParams, fieldId);
    const range = useRemoteQuery({
        loader: API.searchMetadata,
        params: paramsGetter(1, undefined),
        mapper: m => m === null || m === void 0 ? void 0 : m.values,
        refreshLoader: paramsGetter,
    }).value;
    const actualSelection = useMemoIfEqual(!range || !selection ? selection : intersect(range, selection));
    return (_jsx(FacetContainer, Object.assign({ title: name, className: classes("bg-gray-100 mr-2 p-2", className), headerBottomMargin: true }, { children: _jsxs("div", Object.assign({ className: "FacetsForRange flex flex-1 space-x-1 items-center" }, { children: [_jsx(DatePicker, { field: "start", selectedRange: actualSelection, range: range, onSelectionChange: range => updateSelection(name, fieldId, range) }), _jsx("span", { children: "\u2192" }), _jsx(DatePicker, { field: "end", selectedRange: actualSelection, range: range, onSelectionChange: range => updateSelection(name, fieldId, range) })] })) })));
}
function intersect(a, b) {
    const aStart = moment(a.start).startOf("day");
    const aEnd = moment(a.end).endOf("day");
    const bStart = !b.start ? undefined : moment(b.start).startOf("day");
    const bEnd = !b.end ? undefined : moment(b.end).endOf("day");
    if (!bStart && !bEnd)
        return undefined;
    if (!bEnd)
        return aEnd < bStart
            ? undefined
            : { start: aStart < bStart ? b.start : a.start };
    if (!bStart)
        return aStart > bEnd ? undefined : { end: aEnd > bEnd ? b.end : a.end };
    return aEnd < bStart || aStart > bEnd
        ? undefined
        : {
            start: aStart < bStart ? b.start : a.start,
            end: aEnd > bEnd ? b.end : a.end,
        };
}
