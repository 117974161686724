import { Bounds, Diagonal } from "../../../utils/Bounds";
export function XY(x, y) {
    return [x, y];
}
export const ORIGIN = { x: 0, y: 0 };
export function Point(x, y) {
    return { x, y };
}
const TO_DEG = 180 / Math.PI;
export const TO_RAD = Math.PI / 180;
export function getX(p) {
    return p[0];
}
export function getY(p) {
    return p[1];
}
export function dist(x1, y1, x2 = 0, y2 = 0) {
    return Math.sqrt(distSq(x1, y1, x2, y2));
}
export function distSq(x1, y1, x2, y2) {
    return Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2);
}
export function atanDeg(y, x) {
    return Math.atan2(y, x) * TO_DEG;
}
export function hasXY(v) {
    return v && typeof v === "object" && "x" in v && "y" in v;
}
export function Rect(x1, y1, x2, y2) {
    const { x, y } = Point(Math.min(x1, x2), Math.min(y1, y2));
    const { x: xMax, y: yMax } = Point(Math.max(x1, x2), Math.max(y1, y2));
    return { x, y, xMax, yMax, width: xMax - x, height: yMax - y };
}
export function calcBounds(items, bounds) {
    const d = items.reduce((acc, i) => {
        const [x1, y1, x2, y2] = Bounds.toDiagonal(bounds(i));
        if (acc) {
            acc = Diagonal(Math.min(Diagonal.x1(acc), x1), Math.min(Diagonal.y1(acc), y1), Math.max(Diagonal.x2(acc), x2), Math.max(Diagonal.y2(acc), y2));
        }
        else {
            acc = Diagonal(x1, y1, x2, y2);
        }
        return acc;
    }, undefined);
    return d ? Bounds.fromDiagonal(...d) : Bounds();
}
