import ReactDOM from "react-dom/client";
export function showDialog(creator, div = document.createElement("div")) {
    return new Promise(resolve => {
        document.body.appendChild(div);
        const root = ReactDOM.createRoot(div);
        root.render(creator((value) => {
            setTimeout(() => {
                root.unmount();
                div.remove();
                resolve(value);
            });
        }));
    });
}
