import { useCurrentSession } from "../../utils/sessionManagement";
import { useCurrentSource } from "../../utils/sourceManagement";
export function getTrendDomain(coraState) {
    var _a;
    const { selectedTrendDomain } = useCurrentSession();
    const currentSourceDomains = (_a = useCurrentSource(coraState)) === null || _a === void 0 ? void 0 : _a.supported_trends_fields.domain;
    const currentTrendInCurrentSource = (currentSourceDomains === null || currentSourceDomains === void 0 ? void 0 : currentSourceDomains.find(d => d.id === (selectedTrendDomain === null || selectedTrendDomain === void 0 ? void 0 : selectedTrendDomain.id))) !==
        undefined;
    const selectedDomain = currentTrendInCurrentSource
        ? selectedTrendDomain
        : currentSourceDomains !== undefined
            ? currentSourceDomains[0]
            : undefined;
    return selectedDomain;
}
export function getTrendRange(coraState) {
    var _a;
    const { selectedTrendRange } = useCurrentSession();
    const currentSourceRanges = (_a = useCurrentSource(coraState)) === null || _a === void 0 ? void 0 : _a.supported_trends_fields.range;
    const currentTrendInCurrentSource = (currentSourceRanges === null || currentSourceRanges === void 0 ? void 0 : currentSourceRanges.find(d => d.id === (selectedTrendRange === null || selectedTrendRange === void 0 ? void 0 : selectedTrendRange.id))) !==
        undefined;
    const selectedRange = currentTrendInCurrentSource
        ? selectedTrendRange
        : currentSourceRanges !== undefined
            ? currentSourceRanges.find(r => r.id === "evidence") ||
                currentSourceRanges[0]
            : undefined;
    return selectedRange;
}
