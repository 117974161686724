import React from "react";
import { layoutSelection } from "../layoutNodes";
export function useLayoutActions({ store, nodes, selected, stage, flow, dUpdater, }) {
    return React.useCallback(() => ({
        global: [
            {
                caption: "Layout",
                disabled: nodes.length < 2 && "At least 2 Nodes Needed for Layout",
                subEntries: [
                    {
                        caption: "Horizontally",
                        shortcut: "alt+h",
                        action() {
                            layoutSelection(selected, true, dUpdater, flow, stage);
                        },
                    },
                    {
                        caption: "Vertically",
                        shortcut: "alt+v",
                        action() {
                            layoutSelection(selected, false, dUpdater, flow, stage);
                        },
                    },
                    {
                        caption: "Best Fit",
                        shortcut: "alt+l",
                        action() {
                            const { width, height } = store.getState();
                            layoutSelection(selected, width > height, dUpdater, flow, stage);
                        },
                    },
                ],
            },
        ],
    }), [nodes, selected, stage]);
}
