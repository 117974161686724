import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { matchPath, Route, useHistory, useLocation, } from "react-router-dom";
import { isEqual } from "lodash";
import { useMemoIfEqual } from "./hooks";
import { filterDic, merge } from "./collections";
import { mapEntries } from "./objects";
import { getValue } from "./util";
export function describeRoute(name, component, path, defaultPath) {
    const paths = (path = Array.isArray(path) ? path : [path]);
    const routes = paths.map(path => (_jsx(Route, { exact: true, path: path, component: component }, path)));
    return {
        name,
        component,
        path,
        matches(pathname) {
            return Boolean(paths.find(p => (p === "/" && p === pathname) || p.startsWith(pathname)));
        },
        defaultPath: defaultPath || paths[0],
        routes,
        findMatch(path) {
            return Lazy(paths)
                .map(p => {
                return matchPath(path, { path: p, exact: true });
            })
                .filter(Boolean)
                .first();
        },
    };
}
export function findMatch(path, routes) {
    return Lazy(routes)
        .map(r => ({ route: r, match: r.findMatch(path) }))
        .filter(({ match }) => Boolean(match))
        .first();
}
export function createTransmuter(type, toQuery, toValue, valid) {
    return {
        type,
        toQuery: value => (value === undefined ? undefined : toQuery(value)),
        toValue: query => {
            if (query === undefined)
                return undefined;
            const value = toValue(query);
            if (!valid(value))
                throw `[${value}] is not ${type}`;
            return value;
        },
        valid,
    };
}
const OBJ = createTransmuter("object", value => JSON.stringify(value), query => JSON.parse(query), () => true);
export const Transmuters = {
    NULL: createTransmuter("null", value => OBJ.toQuery(value), query => OBJ.toValue(query), value => value === null),
    STR: createTransmuter("string", value => value, query => query, value => typeof value === "string"),
    NUM: createTransmuter("number", value => `${value}`, query => Number(query), value => typeof value === "number" && !isNaN(value)),
    BOOL: createTransmuter("boolean", value => `${value}`, query => {
        if (query === "true" || query === "false")
            return query === "true";
        throw `value [${query}] is cannot be parsed into a boolean`;
    }, value => typeof value === "boolean"),
    ARR: createTransmuter("array", value => OBJ.toQuery(value), query => OBJ.toValue(query), value => Array.isArray(value)),
    OBJ,
};
export function objectTransmuter() {
    return Transmuters.OBJ;
}
export function selectTransmuter(value) {
    const transmuter = Object.values(Transmuters).find((t) => t.valid(value));
    return transmuter || objectTransmuter();
}
export function createPath(pathname, params) {
    const query = Object.entries(params)
        .filter(([, v]) => v !== undefined)
        .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
        .join("&");
    return `${pathname}${query.length ? `?${query}` : ""}`;
}
export function navigateTo(history, pathname, params = {}) {
    history.push(createPath(pathname, params));
}
export function useCurrentUrl() {
    const { search, pathname } = useLocation();
    const params = React.useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const params = {};
        for (const p of searchParams.entries()) {
            params[p[0]] = p[1];
        }
        return params;
    }, [search]);
    return { params, pathname };
}
export function useQueryParams() {
    const history = useHistory();
    const { params, pathname: currentPathName } = useCurrentUrl();
    const update = React.useCallback((paramsUpdate, pathName, setInsteadOfUpdate = false) => {
        pathName = pathName === undefined ? currentPathName : pathName;
        const updated = setInsteadOfUpdate
            ? filterDic(paramsUpdate, (k, v) => v !== undefined)
            : merge(params, paramsUpdate);
        if (!isEqual(updated, params) || pathName !== currentPathName) {
            navigateTo(history, pathName, updated);
        }
    }, [history, currentPathName, params]);
    const getPath = React.useCallback((paramsUpdate) => {
        const updated = merge(params, paramsUpdate);
        return createPath(currentPathName, updated);
    }, [currentPathName, params]);
    return [params, update, getPath, currentPathName];
}
export function useQueryParam(name, transmuter, defValue) {
    const [params, updateParams] = useQueryParams();
    defValue = React.useMemo(() => defValue, []);
    const value = useMemoIfEqual(React.useMemo(() => transmuter.toValue(params[name]), [transmuter, params, name]) || defValue);
    const setValue = React.useCallback((v) => {
        const newVal = typeof v === "function" ? v(value) : v;
        updateParams({
            [name]: isEqual(newVal, defValue)
                ? undefined
                : transmuter.toQuery(newVal),
        });
    }, [transmuter, updateParams, value]);
    return [value, setValue];
}
export function decodeQueryParams(defValue, queryParams, queryParamPrefix = "") {
    return Object.entries(queryParams).reduce((acc, [paramName, paramValue]) => {
        const key = toKeyName(paramName);
        if (key in defValue) {
            const transmuter = selectTransmuter(defValue[key]);
            const accAsAny = acc;
            try {
                accAsAny[key] = transmuter.toValue(paramValue);
            }
            catch (e) {
                console.error(`Error Decoding URL. param[${key}]`, paramValue, e);
            }
        }
        return acc;
    }, Object.assign({}, defValue));
    function toKeyName(queryParamName) {
        return queryParamName.slice(queryParamPrefix.length);
    }
}
export function useQueryParamsAsState(defValue, queryParamPrefix = "") {
    const [params, updateParams] = useQueryParams();
    defValue = React.useMemo(() => defValue, []);
    function toParamName(key) {
        return queryParamPrefix + key;
    }
    const value = useMemoIfEqual(React.useMemo(() => decodeQueryParams(defValue, params, queryParamPrefix), [params]));
    const update = React.useCallback((updateOrFn, pathName) => {
        const update = getValue(value, updateOrFn);
        const entries = mapEntries(update, ([key, v]) => {
            const paramName = toParamName(key);
            return [
                paramName,
                isEqual(v, defValue[key])
                    ? undefined
                    : selectTransmuter(v).toQuery(v),
            ];
        });
        updateParams(entries, pathName);
    }, [updateParams, value]);
    return [value, update];
}
export function useCurrentPathName() {
    const { pathname } = useLocation();
    return pathname;
}
export function useLocationChange(callback, triggerOnMount = false) {
    React.useEffect(() => {
        triggerOnMount && callback(window.location.href);
    }, []);
    React.useEffect(() => {
        const listener = () => callback(window.location.href);
        window.addEventListener("popstate", listener);
        return () => window.removeEventListener("popstate", listener);
    }, [callback]);
}
export function reloadToHomePage() {
    const url = new URL(window.location.href);
    url.searchParams.forEach((v, k) => url.searchParams.delete(k));
    history.pushState(null, "", url.href);
    location.reload();
}
export function getURLParams() {
    const params = {};
    new URL(window.location.href).searchParams.forEach((v, k) => (params[k] = v));
    return params;
}
export function setURLParams(params) {
    const current = getURLParams();
    if (isEqual(current, params))
        return;
    const url = new URL(window.location.href);
    url.searchParams.forEach((v, k) => url.searchParams.delete(k));
    Object.entries(params).forEach(([k, v]) => url.searchParams.set(k, v));
    history.pushState(null, "", url.href);
}
