import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Modal } from "antd";
import { Beacon, useBeacon } from "../../../utils/Beacon";
import { Close } from "../../../cora/components/Icons";
import { useGlobalEscape } from "../../../utils/hooks";
import { VideoPlayer } from "../VideoPlayer";
import "./VideoPopup.less";
const beacon = Beacon({
    initial: "",
});
const padding = 16;
const width = 640;
const height = 320;
export function VideoPopup() {
    const videoUrl = useBeacon(beacon)[0];
    useGlobalEscape(() => VideoPopup.show(null), !!videoUrl);
    return videoUrl ? (_jsx(Modal, Object.assign({ closable: false, open: true, destroyOnClose: true, className: "VideoPopup", centered: true, footer: null, width: width + 2 * padding }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch space-y-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between mb-2" }, { children: [_jsx("div", {}), _jsx(Close, { className: "cursor-pointer text-white", onClick: () => VideoPopup.show(null) })] })), _jsx(VideoPlayer, { className: "self-center mb-2", width: width, height: height, url: videoUrl })] })) }))) : null;
}
VideoPopup.show = beacon.value;
