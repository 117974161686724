var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { updateCurrentSession, useCurrentSession } from "./sessionManagement";
import { useQueryState } from "./queryState/queryState";
import { useEffectIfDifferent } from "../../utils/hooks";
import { isEqual, pick } from "lodash";
import { keysFor } from "../../utils/util";
import { isEmptyParams } from "./queryState/DMParams";
import { METADATA } from "./commonStateParams";
import { getQueryStateHistoryEntry } from "./history";
const MAX_HISTORY_COUNT = 100;
// update query history every time there is a new QueryState
export function useHistoryUpdater() {
    const { queryHistory } = useCurrentSession();
    const [queryState] = useQueryState();
    const last = getComparablePart(queryHistory.last());
    useEffectIfDifferent(() => __awaiter(this, void 0, void 0, function* () {
        if (isEmptyParams(queryState.dmParams))
            return;
        const entry = yield getQueryStateHistoryEntry(queryState);
        const comp = getComparablePart(entry);
        const corpusIds = entry.corpus_ids;
        if (isEqual(comp, last) || !corpusIds.length)
            return;
        updateCurrentSession(session => {
            const queryId = entry.queryId;
            const lastQueryIdByCorpusId = Object.assign(Object.assign({}, session.lastQueryIdByCorpusId), { [corpusIds[0]]: queryId });
            const queryHistory = isEmptyParams(queryState.dmParams)
                ? session.queryHistory
                : session.queryHistory
                    .sort((a, b) => b.time - a.time)
                    .take(MAX_HISTORY_COUNT)
                    .push(entry);
            return {
                queryHistory,
                lastQueryIdByCorpusId,
            };
        });
    }), [last], [queryState], false);
    function getComparablePart(entry) {
        const keys = [
            ...keysFor(METADATA),
            "corpus_ids",
            "queryParts",
            "queryId",
        ];
        return pick(entry || {}, keys);
    }
}
