var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Mentions, Select } from "antd";
import React from "react";
import { ALL, EXACTLY, SOME } from "../../utils/filters";
import { classes } from "../../utils/classes";
import { RefCount } from "../../utils/RefCount";
export const tagFilterProvidersByType = { ALL, EXACTLY, SOME };
const typeCaptions = {
    ALL: "ALL",
    EXACTLY: "EXACT",
    SOME: "SOME",
};
export function TagsFilter(_a) {
    var { allTags, filterType, setFilterType, className } = _a, props = __rest(_a, ["allTags", "filterType", "setFilterType", "className"]);
    return (_jsx(Select, Object.assign({ style: { backgroundColor: "transparent", borderWidth: "0 !important" }, className: classes("border-0", className), bordered: false, allowClear: true, mode: "multiple", dropdownRender: filterType &&
            setFilterType &&
            (tagsContent => (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex items-center p-4 space-x-4" }, { children: [_jsx("div", { children: "Filter type:" }), _jsx(Select, Object.assign({ className: "flex-1 kkk", size: "small", value: filterType, onChange: setFilterType }, { children: Object.keys(tagFilterProvidersByType).map(type => (_jsx(Mentions.Option, Object.assign({ value: type }, { children: typeCaptions[type] }), type))) }))] })), tagsContent] }))) }, props, { children: allTags.map(t => typeof t !== "string" ? (t) : (_jsx(Select.Option, Object.assign({ value: t }, { children: t }), t))) })));
}
export function tagsExtractor(arr, tagExtractor) {
    return arr.reduce((acc, item) => {
        const tagOrTags = tagExtractor(item);
        if (Array.isArray(tagOrTags)) {
            tagOrTags.forEach(tag => acc.add(tag));
        }
        else {
            acc.add(tagOrTags);
        }
        return acc;
    }, RefCount());
}
