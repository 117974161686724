import { getGlobalInfo, useGlobalInfo } from "./globalInfo";
import React from "react";
import { useMemoIfEqual } from "../../utils/hooks";
import { message } from "antd";
import { useQueryState } from "./queryState/queryState";
import { CorpusCapability } from "../../utils/capabilities/capabilities";
const INVALID_CORPUS_ID = "INVALID_CORPUS_ID";
export function useWarnInvalidSource() {
    const [{ corpus_ids }] = useQueryState();
    React.useEffect(() => {
        if (corpus_ids.indexOf(INVALID_CORPUS_ID) >= 0) {
            message.error({
                key: "useWarnInvalidSource",
                content: "Source not Available. Select a Source.",
                duration: 10,
                onClick: () => message.destroy("useWarnInvalidSource"),
            });
        }
    }, useMemoIfEqual([corpus_ids]));
}
function getCorpusIds(corporaById, corpus_ids) {
    const normalized = corpus_ids.map(id => corporaById[id] ? id : INVALID_CORPUS_ID);
    const first = Object.values(corporaById)[0];
    return normalized.length ? normalized : first ? [first.id] : [];
}
export function useDefaultCorpusIds() {
    const { corporaById } = useGlobalInfo();
    const first = Object.values(corporaById)[0];
    return first ? [first.id] : [];
}
export function getAllCorpora() {
    return getGlobalInfo().corporaById;
}
export function useValidatedCorpusIds(corpus_ids) {
    const { corporaById } = useGlobalInfo();
    return useMemoIfEqual(getCorpusIds(corporaById, corpus_ids));
}
export function invalidCorpusIds(corpus_ids) {
    return !corpus_ids.length || corpus_ids.indexOf(INVALID_CORPUS_ID) >= 0;
}
export function isInvalidCorpusId(corpusId) {
    return corpusId === INVALID_CORPUS_ID || !corpusId;
}
export function useCurrentSource(value) {
    const corpus_ids = Array.isArray(value) ? value : value.corpus_ids;
    const { corporaById } = useGlobalInfo();
    const normalized = getCorpusIds(corporaById, corpus_ids);
    return corporaById[normalized[0]];
}
export function getCurrentSource(value) {
    const corpus_ids = Array.isArray(value) ? value : value.corpus_ids;
    const { corporaById } = getGlobalInfo();
    const normalized = getCorpusIds(corporaById, corpus_ids);
    return corporaById[normalized[0]];
}
export function useHasCapability(coraState, capability) {
    return useHasCapabilities(coraState, capability)[0];
}
export function useHasCapabilities(coraState, ...capabilities) {
    const currentSource = useCurrentSource(coraState);
    return capabilities.map(capability => (currentSource === null || currentSource === void 0 ? void 0 : currentSource.supported_apis.includes(capability)) || false);
}
export function useHasBackgroundKnowledge(coraState) {
    const suggestEvidenceQuestions = useHasCapability(coraState, CorpusCapability.SUGGEST_QUERY_NEXT_EVIDENCE);
    const suggestConceptQuestions = useHasCapability(coraState, CorpusCapability.SUGGEST_QUERY_NEXT_EVIDENCE);
    return suggestEvidenceQuestions && suggestConceptQuestions;
}
export function useHasRefutingConcepts(coraState) {
    return useHasCapability(coraState, CorpusCapability.SEARCH_CONCEPTS_REFUTING);
}
