import { ANY, getConstraint } from "./DMParams";
import { arrDelta } from "../../../utils/collections";
import { genClustersId } from "../semanticSearchUtil";
import { getSize } from "../../../utils/util";
import { mapEntries, replaceKeys } from "../../../utils/objects";
export function getIdReplacements(params, originalParams, replaceId) {
    const reps = {};
    const org = getConstraint(originalParams, replaceId);
    const c = params.constraints[0];
    if (org || c) {
        replaceField("source");
        replaceField("target");
        replaceField("relation");
        replaceContext();
        replaceQualifiers();
    }
    return reps;
    function replaceField(field) {
        const id = c[field];
        const rep = org === null || org === void 0 ? void 0 : org[field];
        if (id && rep && rep !== id) {
            reps[id] = rep;
        }
    }
    function replaceContext() {
        // ignore ids that are on both sides
        const orgContext = (org === null || org === void 0 ? void 0 : org.context) || [];
        const both = new Set(arrDelta(c.context, orgContext).both);
        const orgIdsByNormalId = Object.fromEntries(orgContext
            .filter(id => !both.has(id))
            .map(id => [genClustersId(originalParams.concepts[id]), id]));
        const unmatched = [];
        const usedOrgIds = new Set();
        c.context
            .filter(id => !both.has(id))
            .forEach(id => {
            // For ids that are not on both sides, use values to guess replacements
            const orgId = orgIdsByNormalId[genClustersId(params.concepts[id])];
            if (orgId) {
                reps[id] = orgId;
                usedOrgIds.add(orgId);
            }
            else {
                unmatched.push(id);
            }
        });
        const unusedOrgIds = orgContext.filter(id => !usedOrgIds.has(id));
        unmatched.forEach((id, idx) => {
            // use positions to guess replacements for unmatched ids
            const orgId = unusedOrgIds[idx];
            if (orgId)
                reps[id] = unusedOrgIds[idx] || id;
        });
    }
    function replaceQualifiers() {
        Object.entries(c.qualifiers).forEach(([argName, id]) => {
            const rep = org === null || org === void 0 ? void 0 : org.qualifiers[argName];
            if (id !== ANY && rep && rep !== id && rep !== ANY) {
                reps[id] = rep;
            }
        });
    }
}
export function replaceRefs(params, replacements) {
    if (!params.constraints.length || !getSize(replacements))
        return params;
    return {
        concepts: replaceKeys(params.concepts, replacements),
        relations: replaceKeys(params.relations, replacements),
        clauses: replaceKeys(params.clauses, replacements),
        constraints: params.constraints.map(c => ({
            id: replacements[c.id] || c.id,
            source: replaceId(c.source),
            target: replaceId(c.target),
            relation: replaceId(c.relation),
            context: c.context.map(id => replacements[id] || id),
            qualifiers: mapEntries(c.qualifiers, ([k, v]) => [
                k,
                v === ANY ? v : replacements[v] || v,
            ]),
            text: c.text,
            is_directed: c.is_directed,
        })),
    };
    function replaceId(id) {
        return (id && replacements[id]) || id;
    }
}
function getRefIdsByNormalizedClusterId({ concepts, clauses, relations, }) {
    return Object.assign(Object.assign(Object.assign({}, byNormalizedClusterId(relations)), byNormalizedClusterId(concepts)), byNormalizedClusterId(clauses));
    function byNormalizedClusterId(dic) {
        return Object.fromEntries(Object.entries(dic).map(([id, items]) => [genClustersId(items), id]));
    }
}
