import { jsx as _jsx } from "react/jsx-runtime";
import { Tabs } from "antd";
import React from "react";
import { History } from "./History";
import { OldGraph } from "../../../toBeRemoved/graph/OldGraph";
import { pushModal } from "../../../../shared/components/Modal";
import { bgBrand700 } from "../../lf";
import { Fill } from "../../../redesign/generic/Fill";
import "./combinedHistory.less";
import { ModalType, closeModal, openModal } from "../useModalState";
export function CombinedHistory() {
    return (_jsx(Tabs, { id: "CombinedHistory", className: "flex flex-col flex-1 h-full", items: [
            {
                key: "0",
                label: _jsx("span", { children: "List" }),
                children: (_jsx(Fill, Object.assign({ flexCol: true }, { children: _jsx(History, {}) }))),
            },
            {
                key: "1",
                label: _jsx("span", { children: "Graph" }),
                children: (_jsx(Fill, { children: _jsx(OldGraph, {}) })),
            },
        ] }));
}
export function showCombinedHistory() {
    openModal(ModalType.HISTORY);
    pushModal({
        title: "History",
        titleColorClass: bgBrand700,
        content: _jsx(CombinedHistory, {}),
        easyClose: () => false,
        onClose: () => closeModal(ModalType.HISTORY),
        className: "min-w-[90%] max-w-[90%]",
    });
}
