import { Beacon } from "./Beacon";
import React from "react";
let defaultErrorType = false;
export function setDefaultErrorType(fatal) {
    defaultErrorType = fatal;
}
const errorBeacon = Beacon({ initial: null });
export const addErrorHandler = errorBeacon.on;
export function pushError(error) {
    errorBeacon.value(error === null
        ? null
        : Object.assign(Object.assign({}, error), { isFatal: error.fatal !== undefined ? error.fatal : defaultErrorType, timestamp: Date.now() }));
}
export function useErrorType(fatal) {
    React.useEffect(() => setDefaultErrorType(fatal), [fatal]);
}
