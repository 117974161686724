import { useDiagramStateUpdater } from "../DiagramState";
import { useReactFlow, useStoreApi } from "reactflow";
import { useStage } from "../Stage";
import React from "react";
import { isActive } from "../diagramUtils";
import { GraphAnalysis } from "../GraphAnalysis";
export function useActionParams(nodes, edges, mode) {
    const dUpdater = useDiagramStateUpdater();
    const flow = useReactFlow();
    const stage = useStage();
    const store = useStoreApi();
    return React.useMemo(() => {
        const nodes = flow.getNodes();
        const selected = nodes.filter(n => n.selected);
        return {
            store,
            selected,
            stage,
            mode,
            flow,
            dUpdater,
            nodes,
            edges,
            selectedNode: selected.length === 1 ? selected[0] : undefined,
            active: nodes.filter(isActive),
            selectedIds: new Set(selected.map(n => n.id)),
            node: selected.length === 1 ? selected[0] : undefined,
            noSelection: !selected.length,
            ga: GraphAnalysis(flow.getNodes(), flow.getEdges()),
        };
    }, [mode, nodes, edges, stage]);
}
