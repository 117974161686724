import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bgBrand700 } from "../lf";
import React from "react";
import { popModal, pushModal } from "../../../shared/components/Modal";
import { FindingEditor } from "../FindingEditor";
import { showNotification } from "../../../shared/components/notifications";
import { CancelOkButtons } from "./CancelOkButtons";
import { isEqual } from "lodash";
import { upsertFinding } from "../../utils/findings";
import { useEffectIfDifferent } from "../../../utils/hooks";
import { evictSuggestedEvidenceFor } from "../../utils/suggestedEvidence";
import { suggestQuestions } from "../../../shared/components/SuggestedQuestions";
import { scrollToElement } from "../../../utils/util";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
function FindingEditorModal({ finding: initial, newFinding, onFindingUpsert, suggest, disabled, coraState, }) {
    const [finding, setFinding] = React.useState(initial);
    useEffectIfDifferent(() => evictSuggestedEvidenceFor(finding.id), [], [finding.payload.selectedText]);
    const { suggestedQuestionsHistory } = useCurrentSession();
    return (_jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-stretch space-y-2" }, { children: [_jsx(FindingEditor, { finding: finding, setFinding: setFinding, disabled: disabled, coraState: coraState }), _jsx(CancelOkButtons, { buttonCaption: "Save Changes", buttonColorClass: bgBrand700, disabled: !newFinding &&
                    (isEqual(initial, finding) ||
                        !finding.payload.selectedText.trim().length), action: () => {
                    popModal(true);
                    updateCurrentSession(({ findings }) => ({
                        findings: upsertFinding(findings, finding),
                    }));
                    const notificationText = newFinding
                        ? "Finding Created"
                        : "Finding Changed";
                    showNotification(notificationText);
                    onFindingUpsert === null || onFindingUpsert === void 0 ? void 0 : onFindingUpsert(finding);
                    suggest &&
                        newFinding &&
                        suggestQuestions(finding, suggestedQuestionsHistory);
                    newFinding &&
                        scrollToElement(() => document.getElementById(finding.id), 1000);
                } })] })));
}
export function showFindingEditor(finding, newFinding, suggest, disabled, coraState, onFindingUpsert) {
    pushModal({
        title: newFinding ? "Add New Finding" : "Edit Finding",
        titleColorClass: bgBrand700,
        className: "w-full",
        content: (_jsx(FindingEditorModal, { finding: finding, newFinding: newFinding, onFindingUpsert: onFindingUpsert, suggest: suggest, disabled: disabled, coraState: coraState })),
    });
}
