//TODO - To fix this issue, replace the usage of navigator.userAgent, navigator.appVersion, and navigator.platform with feature detection, progressive enhancement, or migrate to navigator.userAgentData.
import React from "react";
import { isMacOS } from "get-os-name";
export const IS_MAC = typeof navigator != "undefined" ? /Mac/.test(navigator.platform) : false;
export function addWindowEventHandlers(handlers) {
    Object.entries(handlers).forEach(([key, handler]) => window.addEventListener(key, handler));
    return () => Object.entries(handlers).forEach(([key, handler]) => window.removeEventListener(key, handler));
}
export function getPosition(element, e) {
    const { left, top } = element.getBoundingClientRect();
    return { x: e.clientX - left, y: e.clientY - top };
}
export function getEventModifiers(e) {
    return {
        shift: e.shiftKey,
        ctrl: e.ctrlKey,
        alt: e.altKey,
        meta: e.metaKey,
    };
}
export function keyToStr(e) {
    return keyStroke(e.key, getEventModifiers(e));
}
export function keyStroke(name, mod) {
    if (mod.alt)
        name = "Alt-" + name;
    if (mod.ctrl)
        name = "Ctrl-" + name;
    if (mod.meta)
        name = "Meta-" + name;
    if (mod.shift)
        name = "Shift-" + name;
    return name;
}
export function useOnGlobalKeyDown(enabled, listener) {
    React.useEffect(() => {
        if (enabled) {
            return addWindowEventHandlers({
                keydown: listener,
            });
        }
    }, [enabled, listener]);
}
export function useOnGlobalEscape(enabled, onEscape) {
    useOnGlobalKeyDown(enabled, e => {
        if (keyToStr(e) === "Escape") {
            onEscape();
        }
    });
}
export function isExtendMouseEvent(e) {
    return e.shiftKey || e.altKey || e.metaKey;
}
export function isContextMenuTrigger(e) {
    return e.button === 2 || (e.ctrlKey && e.button === 0 && isMacOS());
}
export function terminateEvent(event) {
    var _a, _b, _c;
    const e = event;
    e === null || e === void 0 ? void 0 : e.preventDefault();
    e === null || e === void 0 ? void 0 : e.stopPropagation();
    (_a = e === null || e === void 0 ? void 0 : e.stopImmediatePropagation) === null || _a === void 0 ? void 0 : _a.call(e);
    (_b = e.nativeEvent) === null || _b === void 0 ? void 0 : _b.stopPropagation();
    (_c = e.nativeEvent) === null || _c === void 0 ? void 0 : _c.stopImmediatePropagation();
}
