var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { RefCount } from "../../../utils/RefCount";
import { mapValues, omit, pick } from "lodash";
import { genClustersId, toConceptOrCustom } from "../semanticSearchUtil";
import { getSize } from "../../../utils/util";
import { isEmptyConstraint } from "./mutations";
import { EMPTY_CONSTRAINT } from "./constraints";
export const ANY = "?";
export function getEmptyParams() {
    return {
        concepts: {},
        clauses: {},
        relations: {},
        constraints: [],
    };
}
export function isEmptyParams(params) {
    return params.constraints.every(isEmptyConstraint);
}
export function getReferredIds(value) {
    const constraints = Array.isArray(value) ? value : (value === null || value === void 0 ? void 0 : value.constraints) || [];
    return constraints.reduce((acc, c) => getConstraintReferredIds(c, acc), RefCount());
}
export function getQualifierAnyRefId(constraintId, argName) {
    return `${constraintId}-${argName}-${ANY}}`;
}
export function getConstraintReferredIds({ id, source, relation, target, context, qualifiers, } = EMPTY_CONSTRAINT, ids = RefCount()) {
    ids.addAll([
        source,
        target,
        relation,
        ...context,
        ...Object.values(qualifiers || {}).map(([k, v]) => v === ANY ? getQualifierAnyRefId(id, k) : v),
    ].filter(Boolean));
    return ids;
}
export function removeConstraint(params, id) {
    const constraint = params.constraints.find(c => c.id === id);
    if (!constraint)
        return [params, []];
    const { concepts, clauses, relations } = params;
    const constraints = params.constraints.filter(c => c.id !== id);
    const idsToRemove = RefCount.toRemove(getReferredIds(params), getConstraintReferredIds(constraint));
    return [
        Object.assign(Object.assign({}, params), { constraints, concepts: omit(concepts, idsToRemove), clauses: omit(clauses, idsToRemove), relations: omit(relations, idsToRemove) }),
        idsToRemove,
    ];
}
/*
  replaces in dmParams the constraint with idToUpdate with the updated constraint
  if updated is empty, the constraint is removed
 */
export function updateConstraint(dmParams, idToUpdate, updated // empty or one constraint
) {
    if (idToUpdate === "")
        idToUpdate = updated.constraints[0].id;
    const constraint = updated.constraints.length
        ? Object.assign(Object.assign({}, updated.constraints[0]), { id: idToUpdate }) : undefined;
    const orgC = dmParams.constraints.find(c => c.id === idToUpdate);
    const idsToRemove = getConstraintReferredIds(orgC)
        .removeAll(getConstraintReferredIds(constraint))
        .toArray();
    const constraints = !constraint
        ? dmParams.constraints.filter(c => c.id !== idToUpdate)
        : !orgC
            ? [...dmParams.constraints, constraint]
            : dmParams.constraints.map(c => (c.id === idToUpdate ? constraint : c));
    return [
        {
            concepts: Object.assign(Object.assign({}, omit(dmParams.concepts, idsToRemove)), updated.concepts),
            relations: Object.assign(Object.assign({}, omit(dmParams.relations, idsToRemove)), updated.relations),
            clauses: Object.assign(Object.assign({}, omit(dmParams.clauses, idsToRemove)), updated.clauses),
            constraints,
        },
        idsToRemove,
    ];
}
export function getConstraint(params, id) {
    return id ? params.constraints.find(c => c.id === id) : undefined;
}
export function getActualActiveConstraintId(params, activeConstraintId) {
    var _a;
    if (activeConstraintId && !getConstraint(params, activeConstraintId))
        activeConstraintId = "";
    return activeConstraintId || ((_a = params.constraints[0]) === null || _a === void 0 ? void 0 : _a.id) || "";
}
export function domainModelToDMParams(_a) {
    var { description, inference_rules } = _a, dmParams = __rest(_a, ["description", "inference_rules"]);
    return cleanupDMParams(dmParams);
}
export function cleanupDMParams(params) {
    return Object.assign(Object.assign({}, params), { constraints: cleanupConstraints(params.constraints) });
}
export function cleanupConstraints(constraints) {
    return constraints.map(c => (Object.assign(Object.assign({}, c), { source: c.source || null, target: c.target || null, relation: c.relation || null, qualifiers: c.qualifiers || {}, context: c.context || [], text: c.text || "" })));
}
export function createConstraint(id, source, text) {
    return {
        id,
        source,
        relation: null,
        target: null,
        context: [],
        qualifiers: {},
        text,
    };
}
export const EMPTY_CONSTRAINT_ID = "EMPTY";
export function getEmptyParamsQuestion({ constraints }) {
    var _a;
    return ((_a = constraints[0]) === null || _a === void 0 ? void 0 : _a.id) === EMPTY_CONSTRAINT_ID
        ? constraints[0].text
        : undefined;
}
export function conjunctiveToDMParams({ query_concepts, is_bag_of_concepts, analyzed_query }, question) {
    if (analyzed_query && !is_bag_of_concepts) {
        const { concepts, relations, clauses, constraints } = analyzed_query;
        return {
            concepts: mapValues(concepts, i => [i]),
            relations: mapValues(relations, i => [i]),
            clauses: mapValues(clauses, i => [i]),
            constraints: cleanupConstraints(constraints),
        };
    }
    const conceptsOrCustom = (query_concepts === null || query_concepts === void 0 ? void 0 : query_concepts.map(c => toConceptOrCustom(c))) || [];
    const id = genClustersId(conceptsOrCustom);
    const concepts = conceptsOrCustom.length ? { [id]: conceptsOrCustom } : {};
    const constraints = !getSize(concepts)
        ? [createConstraint(EMPTY_CONSTRAINT_ID, null, question)]
        : [createConstraint("BOC", id, question)];
    return {
        relations: {},
        clauses: {},
        concepts,
        constraints,
    };
}
export function filterDMParams(params, filter) {
    const constraints = params.constraints.filter(filter);
    const ref = getReferredIds(constraints).toArray();
    return {
        concepts: pick(params.concepts, ref),
        relations: pick(params.relations, ref),
        clauses: pick(params.clauses, ref),
        constraints,
    };
}
