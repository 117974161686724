const ctxClass = window.AudioContext || window.webkitAudioContext;
const ctx = ctxClass && new ctxClass();
export function beep(duration = 5) {
    if (!ctx)
        return;
    const osc = ctx.createOscillator();
    osc.connect(ctx.destination);
    osc.start();
    setTimeout(function () {
        if (osc.stop)
            osc.stop();
    }, duration);
}
