import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Empty } from "../../../shared/components/Empty";
import { Checkbox } from "antd";
import { classes } from "../../../utils/classes";
import { toggle } from "../../../utils/collections";
import { Scroller } from "../../../shared/components/Scroller";
export var PickerMode;
(function (PickerMode) {
    PickerMode[PickerMode["normal"] = 0] = "normal";
    PickerMode[PickerMode["selectionOnTop"] = 1] = "selectionOnTop";
    PickerMode[PickerMode["hideSelection"] = 2] = "hideSelection";
})(PickerMode || (PickerMode = {}));
export function ItemsPicker({ entries, getId, selected, updateSelection, renderItem, emptyMessage = "Empty", title, headerCheckbox = false, checkboxClassName, listClassName, itemRowClassName, selectedRowStyle, wrapperClassName = "flex-1", headerClass, style, emptyClassName = "text-tiny", itemsFilter = (x) => x || [], showIndeterminate = false, showIcon = true, loading, mode = PickerMode.normal, noScroller, disabled, }) {
    const selectedIds = new Set(selected.map(getId));
    function getItems() {
        const filtered = itemsFilter(entries || []) || [];
        if (mode === PickerMode.normal)
            return filtered;
        const noSelected = filtered.filter(i => !selectedIds.has(getId(i)));
        return mode === PickerMode.hideSelection
            ? noSelected
            : selected.concat(noSelected);
    }
    const items = getItems();
    const isChecked = React.useMemo(() => (item) => selectedIds.has(getId(item)), [selectedIds]);
    const matches = React.useMemo(() => items.filter(item => isChecked(item)), [items, isChecked]);
    const indeterminate = React.useMemo(() => {
        return (showIndeterminate && matches.length > 0 && matches.length !== items.length);
    }, [items, matches]);
    const checkAll = React.useMemo(() => {
        return matches.length === items.length;
    }, [items, matches]);
    const onCheckAllChange = (e) => {
        e.target.checked ? updateSelection(items) : updateSelection([]);
    };
    const titleContent = title ? (_jsxs("div", Object.assign({ className: "text-tiny" }, { children: [title, items && (_jsxs("span", Object.assign({ className: "font-normal ml-1 text-neutral-600" }, { children: ["(", matches.length, ")"] })))] }))) : null;
    const content = (_jsxs("div", Object.assign({ className: classes("flex flex-col space-y-1 text-tiny", listClassName || "ml-4") }, { children: [items.length > 0 && headerCheckbox && (_jsx(Checkbox, Object.assign({ indeterminate: indeterminate, onChange: onCheckAllChange, checked: checkAll, className: checkboxClassName, disabled: disabled }, { children: _jsx("span", Object.assign({ className: "text-tiny" }, { children: "All" })) }))), items.map((item, idx) => (_jsxs("div", Object.assign({ className: classes("flex flex-row space-x-2 pr-2", "hover:bg-[rgba(0,0,0,.05)]", !disabled && "cursor-pointer", itemRowClassName), style: isChecked(item) && selectedRowStyle ? selectedRowStyle : undefined, onMouseDownCapture: disabled
                    ? undefined
                    : e => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateSelection(toggle(selected, item));
                    } }, { children: [_jsx(Checkbox, { checked: isChecked(item), className: checkboxClassName, disabled: disabled }), _jsx("div", Object.assign({ className: "flex-1 flex overflow-hidden text-left" }, { children: renderItem(item) }))] }), getId(item) + idx)))] })));
    return (_jsxs("div", Object.assign({ className: classes("flex flex-col space-y-4", wrapperClassName), style: style }, { children: [titleContent && (_jsx("div", Object.assign({ className: classes("font-semibold border-b pb-2", headerClass || "pl-4") }, { children: titleContent }))), _jsx(Empty, Object.assign({ empty: loading ? "Loading..." : !items.length && emptyMessage, loading: loading, size: 64, className: emptyClassName, showIcon: showIcon }, { children: noScroller ? content : _jsx(Scroller, { children: content }) }))] })));
}
