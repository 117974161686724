import { difference } from "lodash";
export function RefCount(initial = []) {
    const map = Array.isArray(initial)
        ? initial.reduce((acc, v) => {
            acc[v] = (acc[v] || 0) + 1;
            return acc;
        }, {})
        : Object.assign({}, initial);
    function bulkOp(vs, count, op) {
        vs.forEach(v => op(v, count(v)));
        return inst;
    }
    const inst = {
        get(v) {
            var _a;
            return (_a = map[v]) !== null && _a !== void 0 ? _a : 0;
        },
        has(v) {
            return v in map;
        },
        add(v, count = 1) {
            count = Math.floor(inst.get(v) + count);
            map[v] = count;
            return count === 1;
        },
        addAll(v) {
            if (RefCount.isRefCount(v)) {
                return bulkOp(v.toArray(), v.get, inst.add);
            }
            else {
                return bulkOp(v, () => 1, inst.add);
            }
        },
        remove(v, count = 1) {
            const newCount = Math.max(0, Math.floor(inst.get(v) - count));
            if (newCount) {
                map[v] = newCount;
            }
            else {
                delete map[v];
            }
            return newCount === 0;
        },
        removeAll(v) {
            if (RefCount.isRefCount(v)) {
                return bulkOp(v.toArray(), v.get, inst.remove);
            }
            else {
                return bulkOp(v, () => 1, inst.remove);
            }
        },
        clear(v) {
            if (!inst.has(v))
                return false;
            delete map[v];
            return true;
        },
        [Symbol.iterator]() {
            return inst.toArray()[Symbol.iterator]();
        },
        toArray() {
            return Object.keys(map);
        },
        toObject() {
            return Object.assign({}, map);
        },
    };
    return inst;
}
RefCount.isRefCount = (v) => {
    return (typeof v === "object" && v !== null && typeof v.get === "function");
};
RefCount.toRemove = (a, b) => {
    const inA = a.toArray();
    return difference(inA, RefCount(a.toObject()).removeAll(b).toArray());
};
