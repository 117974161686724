import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useArgsInfo } from "../utils/coraUtil";
import { useCurrentSession } from "../utils/sessionManagement";
import { classes } from "../../utils/classes";
import { landmarkIdTag } from "../../shared/components/assist/Tour";
import { MyFindings } from "./MyFindings";
import { Toolbar } from "./Toolbar";
import { getTwColor, globalBgClass } from "./lf";
import { CRCFacets } from "./facet/CRCFacets";
import { EvidencePanel } from "./evidence/EvidencePanel";
import { ModelBuilder } from "./modelBuilder/ModelBuilder";
import { Allotment } from "allotment";
import { Fill } from "../redesign/generic/Fill";
import { ConstraintsBar } from "./ConstraintsBar";
import { useCoraState } from "./CoraStateProvider";
export function CoraMainContent() {
    const session = useCurrentSession();
    const { showFilters, modelBuilderMode, modelBuilderAvailable, showMyFindings } = session;
    const modelBuilderVisible = modelBuilderMode && modelBuilderAvailable;
    const [{ corpus_ids }] = useCoraState();
    const argsInfo = useArgsInfo(corpus_ids);
    const facets = showFilters && !modelBuilderVisible && (_jsx("div", Object.assign({ className: classes("flex flex-col space-y-4 justify-center px-2 h-full") }, landmarkIdTag("tour-filters"), { id: "Facets", style: {
            borderRightWidth: 1,
            borderColor: getTwColor("gray-300"),
        } }, { children: _jsx(CRCFacets, { argsInfo: argsInfo }) })));
    const evidence = (_jsx(Fill, Object.assign({ flexRow: true, className: "flex-1 justify-center" }, landmarkIdTag("tour-evidence"), { children: _jsx(EvidencePanel, { argsInfo: argsInfo, extraAddOns: [] }) })));
    return (_jsxs("div", Object.assign({ className: "flex flex-1" }, { children: [_jsx(Toolbar, {}), _jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-stretch" }, { children: [_jsx(ConstraintsBar, {}), _jsx("div", Object.assign({ className: classes("flex-1 pl-0 flex flex-row items-stretch", globalBgClass) }, { children: _jsxs("div", Object.assign({ className: "flex-1 flex flex-row items-stretch space-x-4" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex items-stretch space-x-2 mr-2" }, { children: _jsxs(Allotment, { children: [_jsx(Allotment.Pane, Object.assign({}, (modelBuilderVisible
                                                ? {
                                                    minSize: 400,
                                                    preferredSize: 800,
                                                    visible: true,
                                                }
                                                : {
                                                    minSize: 225,
                                                    maxSize: 400,
                                                    preferredSize: 380,
                                                    visible: showFilters,
                                                }), { children: _jsx(Fill, { children: modelBuilderVisible ? _jsx(ModelBuilder, {}) : facets }) })), _jsx(Allotment.Pane, Object.assign({}, {
                                                minSize: 400,
                                                preferredSize: 800,
                                            }, { children: _jsx(Fill, Object.assign({ className: "pl-2" }, { children: evidence })) }))] }, modelBuilderVisible ? "withGraph" : "withFacets") })), showMyFindings && _jsx(MyFindings, {})] })) }))] }))] })));
}
