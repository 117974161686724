export function Random(seed = Math.random()) {
    let current = typeof seed === "number"
        ? seed
        : [...seed].reduce((acc, c) => acc + c.charCodeAt(0), 0);
    function next(min, max) {
        if (min === max)
            return min;
        const x = Math.sin(current++) * 10000;
        return min + (x - Math.floor(x)) * (max - min);
    }
    const { rnd } = {
        rnd(a, b) {
            var _a, _b;
            if (Array.isArray(a)) {
                return a[Math.floor(next(0, a.length))];
            }
            const min = (_a = (a === undefined || b === undefined ? 0 : a)) !== null && _a !== void 0 ? _a : 0;
            const max = (_b = (a === undefined && b === undefined ? 1 : b === undefined ? a : b)) !== null && _b !== void 0 ? _b : 1;
            return next(min, max);
        },
    };
    return rnd;
}
