import { mapKeys, mapValues } from "lodash";
import { ANY } from "./queryState/DMParams";
// Replaces and sort all ids in the query with new ids generated by the replacer function
// This is done so if the only difference between two queries is the ids, or the order,
// they will be considered equal.
export function normalizeConjunctiveQuery(q, replacer) {
    return {
        types: sortByKey(mapKeys(q.types, (_, id) => replacer(id))),
        concepts: sortByKey(mapKeys(q.concepts, (_, id) => replacer(id))),
        relations: sortByKey(mapKeys(q.relations, (_, id) => replacer(id))),
        clauses: sortByKey(mapKeys(q.clauses, (_, id) => replacer(id))),
        bindings_for: q.bindings_for
            .map(b => typeof b === "string" ? replacer(b) : Object.assign(Object.assign({}, b), { id: replacer(b.id) }))
            .sort(tOrSComparator),
        constraints: q.constraints
            .map(c => {
            var _a;
            return (Object.assign(Object.assign(Object.assign(Object.assign({}, c), { relation: c.relation ? replacer(c.relation) : null, source: c.source ? replacer(c.source) : null, target: c.target ? replacer(c.target) : null }), (((_a = c.context) === null || _a === void 0 ? void 0 : _a.length) && {
                context: c.context.map(id => replacer(id)).sort(),
            })), (c.qualifiers && {
                qualifiers: sortByKey(mapValues(c.qualifiers, v => (v === ANY ? ANY : replacer(v)))),
            })));
        })
            .sort(cComparator)
            .map(c => (Object.assign(Object.assign({}, c), { id: replacer(c.id) }))),
    };
    function sortByKey(d) {
        return Object.fromEntries(Object.entries(d).sort(([a], [b]) => a.localeCompare(b)));
    }
    function tOrSComparator(a, b) {
        if (typeof a === "string") {
            if (typeof b === "string")
                return a.localeCompare(b);
            return -1;
        }
        if (typeof b === "string")
            return 1;
        return a.id.localeCompare(b.id);
    }
    function cComparator(a, b) {
        return toStr(a).localeCompare(toStr(b));
        function toStr(c) {
            var _a, _b;
            return `${c.source}-${c.relation}-${c.target}:${(_a = c.context) === null || _a === void 0 ? void 0 : _a.join(",")}:${(_b = Object.keys(c.qualifiers || {})) === null || _b === void 0 ? void 0 : _b.join(",")}:${c.is_directed}`;
        }
    }
}
