var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useInitializer, useMemoIfEqual, } from "../../utils/hooks";
import { API } from "../api/api";
import { IGNORED_SESSION_IDS } from "../../utils/globalRegistry";
import React from "react";
import { Beacon, useBeacon } from "../../utils/Beacon";
import { useQueryState } from "./queryState/queryState";
import { pushError } from "../../utils/errorStream";
import { pick } from "lodash";
export const MODELS_DIRECTORY_ID = "MODELS__DIRECTORY";
const modelsBeacon = Beacon({ initial: [] });
export function useModels() {
    const [models, update] = useBeacon(modelsBeacon);
    const [state, updater] = useQueryState();
    const corpus_id = state.corpus_ids[0];
    const data = useMemoIfEqual(pick(state, ["dmParams", "overrides", "nodesInfo", "viewport"]));
    return React.useMemo(() => {
        return {
            models: models.filter(m => corpus_id.includes(m.corpus_id)),
            save(name) {
                return __awaiter(this, void 0, void 0, function* () {
                    const cid = yield API.getUrlId({ data });
                    yield save(models
                        .filter(removeFromModels(name))
                        .concat({ name, cid, timestamp: Date.now(), corpus_id }));
                });
            },
            delete(name) {
                return __awaiter(this, void 0, void 0, function* () {
                    yield save(models.filter(removeFromModels(name)));
                });
            },
            load(e) {
                return __awaiter(this, void 0, void 0, function* () {
                    const data = yield API.getUrlData({ id: e.cid });
                    if (!data) {
                        const error = `Unable to load model "${e.name}"`;
                        pushError({ error, showMessage: true });
                        return;
                    }
                    updater(data);
                });
            },
        };
        function removeFromModels(name) {
            return (m) => m.name !== name && m.corpus_id !== corpus_id;
        }
        function save(models) {
            return __awaiter(this, void 0, void 0, function* () {
                yield API.upsertUserData({
                    id: MODELS_DIRECTORY_ID,
                    data: {
                        id: MODELS_DIRECTORY_ID,
                        name: MODELS_DIRECTORY_ID,
                        models,
                    },
                });
                update(models);
            });
        }
    }, [corpus_id, data]);
}
export function useInitModels() {
    React.useMemo(() => {
        return IGNORED_SESSION_IDS([MODELS_DIRECTORY_ID]);
    }, []);
    return useInitializer(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        modelsBeacon.value(((_a = (yield API.loadUserData(MODELS_DIRECTORY_ID))) === null || _a === void 0 ? void 0 : _a.models) || []);
    }));
}
