import React from "react";
import { cleanupNodes, replaceIds, setNodeExpansion, } from "../diagramUtils";
import { copyToClipboard, readFromClipboard } from "../../../../utils/clipboard";
import { updateItems } from "../../../../utils/collections";
import { trimGraph } from "../trimGraph";
import { Menu } from "../../../../shared/actions/Menu";
export function useEditActions({ nodes, selectedIds, selected, stage, noSelection, flow, dUpdater, }) {
    return React.useCallback(() => {
        function copySelection() {
            const { nodes: n, edges } = trimGraph(flow, nodes.filter(n => !selectedIds.has(n.id)), false);
            if (n.length) {
                const toCopy = {
                    nodes: setNodeExpansion(cleanupNodes(n), false),
                    edges,
                };
                copyToClipboard(JSON.stringify(toCopy, null, 2));
            }
        }
        function deleteSelection() {
            dUpdater.commit(trimGraph(flow, selected, true));
        }
        return {
            global: [
                {
                    caption: "Edit",
                    subEntries: [
                        {
                            caption: "Undo",
                            shortcut: "mod+z",
                            action() {
                                window.history.go(-1);
                            },
                        },
                        {
                            caption: "Redo",
                            shortcut: "mod+shift+z",
                            action() {
                                window.history.go(1);
                            },
                        },
                        Menu.SEPARATOR,
                        {
                            caption: "Cut",
                            shortcut: "mod+x",
                            disabled: noSelection,
                            action() {
                                copySelection();
                                deleteSelection();
                            },
                        },
                        {
                            caption: "Copy",
                            shortcut: "mod+c",
                            disabled: noSelection,
                            action: copySelection,
                        },
                        {
                            caption: "Paste",
                            shortcut: "mod+v",
                            action() {
                                readFromClipboard().then(text => {
                                    if (!text)
                                        return;
                                    try {
                                        const obj = JSON.parse(text);
                                        if (obj.nodes && obj.edges) {
                                            const { nodes, edges } = obj;
                                            stage(replaceIds({ nodes: cleanupNodes(nodes), edges }));
                                        }
                                    }
                                    catch (e) { }
                                });
                            },
                        },
                        {
                            caption: "Delete",
                            disabled: noSelection,
                            shortcut: "backspace",
                            action: deleteSelection,
                        },
                        {
                            caption: "Select All",
                            shortcut: "mod+a",
                            action() {
                                dUpdater.commit({
                                    nodes: updateItems(flow.getNodes(), {
                                        selected: true,
                                    }),
                                });
                            },
                        },
                        {
                            caption: "Clear Selection",
                            shortcut: "mod+d",
                            action() {
                                dUpdater.commit({
                                    nodes: updateItems(flow.getNodes(), {
                                        selected: false,
                                    }),
                                });
                            },
                        },
                    ],
                },
            ],
        };
    }, [nodes, selectedIds, selected, stage, noSelection]);
}
