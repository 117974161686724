import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import { Switch } from "antd";
import { CRCDirection } from "../api/types";
import { ArrowLeft, ArrowRight, Line } from "./Icons";
export function getArrow(direction, props = {}, fontSizeVal) {
    const fontSize = fontSizeVal || 16;
    return direction === CRCDirection.BOTH ? (_jsx(Line, Object.assign({ style: { fontSize } }, props))) : direction === CRCDirection.C1C2 ? (_jsx(ArrowRight, Object.assign({ style: { fontSize } }, props))) : (_jsx(ArrowLeft, Object.assign({ style: { fontSize } }, props)));
}
export function DirectionPickerToggle({ direction, setDirection, disabled, className, }) {
    function applyDirection(toggleOn) {
        const newDirection = toggleOn ? CRCDirection.C1C2 : CRCDirection.BOTH;
        setDirection(newDirection);
    }
    return (_jsxs("div", Object.assign({ className: classes("flex self-stretch items-center text-tiny space-x-1  mt-2", className) }, { children: [_jsx(Switch, { checked: direction !== CRCDirection.BOTH, onChange: toggleOn => applyDirection(toggleOn), size: "small", disabled: disabled }), _jsx("label", { children: "Directed Query" })] })));
}
