import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Radio } from "antd";
import { Field } from "../../../../shared/components/Field";
import { toYesNo } from "../../../utils/metatada";
import { classes } from "../../../../utils/classes";
export function FacetsForBoolean({ fieldId, name, selection, updateSelection, className, }) {
    function button(v) {
        return (_jsx(Radio.Button, Object.assign({ value: v, className: "px-4", onClick: () => updateSelection(name, fieldId, selection === v ? null : v) }, { children: _jsx("span", Object.assign({ className: "px-4" }, { children: toYesNo(v) })) })));
    }
    return (_jsx(Field, { title: _jsx("div", Object.assign({ className: "text-xs font-medium ml-2" }, { children: name })), className: classes("bg-gray-100 mr-2", className), contentClassName: "flex-col items-center", noBorder: true, content: _jsxs(Radio.Group, Object.assign({ size: "small", value: selection, buttonStyle: "solid" }, { children: [button(true), button(false)] })) }));
}
