import chroma from "chroma-js";
import customColors from "../../assets/colors";
export const columnMinWidthClass1_25 = "min-w-[285px]";
export const columnMinWidthClass1_5 = "min-w-[342px]";
export const columnMinWidthClass1 = "min-w-[228px]";
export const columnWidthClass1 = "w-[228px] min-w-[228px] max-w-[228px]";
export const columnWidthClass1_25 = "w-[285px] min-w-[285px] max-w-[285px]";
export const columnWidthClass2 = "w-[440px] min-w-[440px] max-w-[440px]";
export const columnWidthClass3 = "w-[660px] min-w-[660px] max-w-[660px]";
export const columnWidthClass4 = "w-[880px] min-w-[880px] max-w-[880px]";
export const toolbarWidthClass = "w-[72px] min-w-[72px]";
export const headerHeightClass = "h-[72px]";
export const mainHeaderHeightClass = "h-[122px]";
export const bgBrand700 = "bg-brand-700";
export const bgBrand500 = "bg-brand-500";
export const bgBrand100 = "bg-brand-100";
export const bgBrand200 = "bg-brand-200";
export const bgBrand300 = "bg-brand-300";
export const bgBrand400 = "bg-brand-400";
export const brandPurple500 = "accent-500";
export const bgNeutral200 = "bg-neutral-200";
export const bgNeutral300 = "bg-neutral-300";
export const neutral300 = "neutral-300";
export const neutral600 = "neutral-600";
export const notificationBlue600 = "notification_blue-600";
export const borderBrand500 = "border-brand-400";
export const bgFinding500 = "bg-notification_blue-500";
export const bgFinding100 = "bg-notification_blue-100";
export const bgFinding200 = "bg-notification_blue-200";
export const borderFinding500 = "border-notification_blue-500";
export const borderEvidence500 = "border-alert_green-500";
export const borderEvidence700 = "border-alert_green-700";
export const bgEvidence500 = "bg-alert_green-500";
export const bgEvidence700 = "bg-alert_green-700";
export const bgEvidence100 = "bg-alert_green-100";
export const bgEvidence200 = "bg-alert_green-200";
export const bgGlossary500 = "bg-purple-500";
export const bgGlossary100 = "bg-purple-100";
export const popoverBackgroundColor = bgNeutral200;
export const brandBackgroundColor1 = bgEvidence500;
export const findingBorderClass = "border-brand-400 placeholder-brand-500";
export const brandBorderClass = "border-brand-400 placeholder-brand-500";
export const glossaryBorderClass = "border-purple-500 placeholder-purple-500";
export const greenButtonClass = "text-white focus:text-white focus:bg-alert_green-500 focus:border-alert_green-500 bg-alert_green-500 border-alert_green-500 hover:bg-alert_green-400 hover:border-alert_green-400";
export const brandButtonClass = "text-white focus:text-white focus:bg-brand-500 focus:border-brand-500 bg-brand-500 border-brand-500 hover:bg-brand-400 hover:border-brand-400";
export const blueButtonClass = "text-white focus:text-white focus:bg-brand_blue-500 focus:border-brand_blue-500 bg-brand_blue-500 border-brand_blue-500 hover:bg-brand_blue-400 hover:border-brand_blue-400";
export const searchFieldClass = "border-brand-600 bg-brand-400 placeholder-brand-900 text-white children:bg-brand-400 children:text-white";
export const coloredTextInfoClass = "border-brand_blue-300 bg-brand_blue-200";
export const hoverSelectedCardClass = "hover:z-10 hover:shadow-2xl ";
// "hover:z-10 hover:shadow-2xl hover:translate-x-[-1px] hover:translate-y-[-1px]"
export const selectedCardClass = "z-10 shadow-2xl ";
// "z-10 shadow-2xl translate-x-[-1px] translate-y-[-1px]"
export function lineClampStyle(lineClamp) {
    return {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: lineClamp,
        lineClamp,
    };
}
export const headerInputClass = "HeaderInput border-brand-500 bg-brand-500 children:bg-brand-500 children:text-white children:text-right text-right";
export function getTwColor(name) {
    const parts = name.split("-");
    let result = customColors;
    for (let i = 0; i < parts.length; i++) {
        result = result[parts[i]];
    }
    return result;
}
export const HEADER_COLOR = customColors.accent[900];
export const headerBgClass = "bg-brand-100";
export const globalBgClass = "bg-gray-200";
export const BRAND_COLOR_VALS = customColors.accent;
export const CONCEPT_1_COLOR_VALS = customColors.notification_blue;
export const CONCEPT_1_COLOR = CONCEPT_1_COLOR_VALS[500];
export const CONCEPT_2_COLOR_VALS = customColors.alert_yellow;
export const CONCEPT_2_COLOR = CONCEPT_2_COLOR_VALS[500];
export const RELATION_COLOR_VALS = customColors.alert_green;
export const RELATION_COLOR = RELATION_COLOR_VALS[500];
export const CONTEXT_CONCEPT_COLOR_VALS = customColors.accent;
export const CONTEXT_CONCEPT_COLOR = CONTEXT_CONCEPT_COLOR_VALS[600];
export const NEUTRAL_COLOR = "gray";
export const NEUTRAL_COLOR_VALS = customColors.neutral;
export const CONCEPT_COLOR_BORDER = CONTEXT_CONCEPT_COLOR;
export const CONCEPT_COLOR_BG = customColors.accent[100];
export const TABS_BG = "bg-accent-900 text-white";
export const selectedBg = customColors.accent[500];
export const selectedBgClass = "bg-accent-500 text-white";
export const CATEGORYCAL = [
    "#ff8fb2",
    "#00B3A4",
    "#DB2C6F",
    "#7157D9",
    "#96622D",
    "#D13913",
    "#8F398F",
];
export function getTagStyle(tagColor = customColors.neutral[550]) {
    return {
        backgroundColor: chroma(tagColor).mix("white", 0.6).css(),
        borderColor: tagColor,
    };
}
export function getTagBaseStyle(tagVals) {
    const tagColor = tagVals["500"];
    return getTagStyle(tagColor);
}
export function getTagBaseClass(baseColor = "neutral") {
    return `bg-${baseColor}-100 hover:bg-${baseColor}-200 border border-${baseColor}-500`;
}
