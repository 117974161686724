var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useMemoIfEqual } from "../../utils/hooks";
import { Random } from "../../utils/Random";
import chroma from "chroma-js";
function toXY(r, ang, orgX = 0, orgY = 0) {
    const rads = ang * (Math.PI / 180);
    return [orgX + r * Math.cos(rads), orgY + r * Math.sin(rads)];
}
function createShape(getPath) {
    return (_a) => {
        var { params } = _a, props = __rest(_a, ["params"]);
        const d = React.useMemo(() => getPath(params), [useMemoIfEqual(params)]);
        return _jsx("path", Object.assign({ d: d }, props));
    };
}
const Star = createShape(({ x = 0, y = 0, r1, r2, count, ang = 0, noise = () => 0, }) => {
    count = Math.floor(count);
    const inc = 360 / (count * 2);
    const xys = [...Array(count * 2)]
        .map((_, idx) => toXY((idx % 2 == 0 ? r2 : r1) * (1 - noise()), ang + inc * idx - 90, x, y))
        .flat();
    return `M${xys}Z`;
});
const Circle = createShape(({ x = 0, y = 0, r = 1 }) => `M ${x} ${y} 
    m ${-r}, 0 
    a ${r},${r} 0 1,1 ${r * 2},0 
    a ${r},${r} 0 1,1 ${-r * 2},0 Z`);
const Petals = createShape(({ x = 0, y = 0, petals = 5, size = 1, innerR = 0.25, controlR = 0.8, ang: initialAng = 270, noise = 0.1, rnd, }) => {
    const path = [];
    let ang = initialAng;
    let lastCP = [0, 0];
    const step = 180 / petals;
    for (let i = 0; i <= petals; i++) {
        const errF = i === 0 || i === petals ? 1 : 1 - rnd(noise);
        const p1 = toXY(errF * size, ang, x, y);
        ang += step;
        const p2 = toXY(innerR * size, ang, x, y);
        const cp = toXY(errF * controlR * size, ang, x, y);
        ang += step;
        if (i == 0) {
            path.push(`M ${p1[0]} ${p1[1]}`);
        }
        else {
            path.push(`Q ${lastCP[0]} ${lastCP[1]} ${p1[0]} ${p1[1]}`);
        }
        path.push(`Q ${cp[0]} ${cp[1]} ${p2[0]} ${p2[1]}`);
        lastCP = cp;
    }
    path.push("Z");
    return path.join(" ");
});
export function Flower({ seed }) {
    const rnd = Random(seed);
    return (_jsx("svg", Object.assign({ viewBox: "-1 -1 2 2" }, { children: _jsxs("g", { children: [_jsx("animateTransform", { attributeName: "transform", attributeType: "XML", type: "rotate", from: "0 0 0", to: "360 0 0", dur: "20s", repeatCount: "indefinite" }), _jsx(Petals, { params: {
                        rnd,
                        petals: Math.floor(rnd(4, 35)),
                        ang: rnd(360),
                        innerR: rnd(0.2, 0.4),
                        controlR: rnd(0.7, 1.2),
                    }, fill: color(0.9) }), _jsx(Star, { params: { r1: 0.2, r2: rnd(0.2, 0.8), count: Math.floor(rnd(10, 50)) }, fill: color() }), _jsx(Circle, { params: { r: rnd(0.2, 0.4) }, fill: color() })] }) })));
    function color(a = 1) {
        return chroma.hsv(rnd(360), rnd(0.6, 1), rnd(0.5, 1)).alpha(1).css();
    }
}
