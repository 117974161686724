import React from "react";
export function useEvidenceItems(evidenceSearch, filter, sortField, sortOrder) {
    var _a;
    const { evidenceAndTotals, noSelection } = evidenceSearch;
    const evidences = ((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.evidence) || [];
    const filteredEvidence = React.useMemo(() => evidences.filter(filter), [evidences, filter]);
    return {
        filteredEvidence,
        noSelection,
        items: filteredEvidence,
        evidenceAndTotals,
    };
}
