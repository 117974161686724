var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useCurrentSource } from "../../utils/sourceManagement";
import { useCurrentSession } from "../../utils/sessionManagement";
import { classes } from "../../../utils/classes";
import { DocumentSearchResults } from "./DocumentSearchResults";
import { EvidenceSearchResults } from "./EvidenceSearchReults";
import { useAnnotationProvider } from "../../utils/references";
import { Dropdown, Tooltip } from "antd";
import { FeatureIdentifier, FeedbackType, } from "../../api/types";
import { sendFeedback } from "../../utils/feedback";
import { showNotification } from "../../../shared/components/notifications";
import { EllipsisOutlined } from "@ant-design/icons";
import { getUrlOpener } from "./urlOpener";
import { ThumbsDown, ThumbsUp } from "../Icons";
import { BorderlessButton } from "../../redesign/generic/BorderlessButton";
import { FindingsDropdown } from "./FindingsDropdown";
import { Finding, FindingFilled } from "../Icons";
import { LabeledSection } from "./LabeledSection";
import { isEmptyQuery } from "../../utils/semanticSearchUtil";
import { useQueryState } from "../../utils/queryState/queryState";
function commaBigNumbers(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function sForPlural(n) {
    return n === 1 ? "" : "s";
}
export function EvidenceResults({ argsInfo, results, coraState, coraStateUpdater, corpus_filter, evidence, documents, disabled, evidenceSearch, evidenceState, evidenceStateUpdater, totalEvidenceCount, filteredBySummary, }) {
    const { groupEvidenceByDocument, findings, showStructuredQuery, structuredQuery, } = useCurrentSession();
    const annotationProvider = useAnnotationProvider(coraState);
    const showingStructuredQuery = structuredQuery && showStructuredQuery;
    const source = useCurrentSource(coraState);
    const [queryState] = useQueryState();
    function sendEvidenceFeedback(item, type, context) {
        return __awaiter(this, void 0, void 0, function* () {
            yield sendFeedback(context
                ? FeatureIdentifier.EVIDENCE_QUALIFIER
                : FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/search/evidence", type, item, corpus_filter, context);
            evidenceStateUpdater.update({
                feedback: Object.assign(Object.assign({}, evidenceState.feedback), { [item.id]: type }),
            });
            showNotification("Feedback sent");
        });
    }
    const isEvidenceSelected = React.useCallback((item) => evidenceState.selection.has(item.id), [evidenceState]);
    const evidenceDescription = React.useMemo(() => {
        const description = [
            `${groupEvidenceByDocument ? "gathered from" : "showing"} the top ${commaBigNumbers(evidence.length)} out of ${commaBigNumbers(totalEvidenceCount)} total result${sForPlural(totalEvidenceCount)}`,
        ];
        if (groupEvidenceByDocument) {
            description.unshift(`showing ${commaBigNumbers(documents.length)} document group${sForPlural(documents.length)}`);
        }
        if (filteredBySummary) {
            description.push(`filtered by summary`);
        }
        if (evidence.length > 1) {
            description.push(`sorted by relevance`);
        }
        return description;
    }, [evidence, documents, filteredBySummary]);
    const renderEvidenceActions = React.useCallback((evidence, documentId) => {
        return (_jsxs(_Fragment, { children: [_jsx(FindingsDropdown, { evidence: [evidence], disabledDropdown: false, renderDropdownAnchor: (openMenu, hasExistingFinding) => {
                        const FindingIcon = hasExistingFinding ? FindingFilled : Finding;
                        return (_jsx(BorderlessButton, Object.assign({ onClick: openMenu, className: "flex flex-1 justify-center items-center hover:bg-neutral-300 cursor-pointer rounded-full" }, { children: _jsx(FindingIcon, { className: classes("text-base my-auto px-1 py-1") }) })));
                    } }), _jsx(Dropdown, Object.assign({ menu: {
                        items: [
                            {
                                label: "View in document",
                                key: "viewInDoc",
                                onClick: () => {
                                    getUrlOpener(coraState, queryState, coraStateUpdater, findings, showingStructuredQuery, source)(evidence, evidence.instances.find(i => {
                                        return i.document_id === documentId;
                                    }));
                                },
                                disabled: false,
                            },
                            {
                                label: (_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("span", { children: "Feedback" }), _jsx(BorderlessButton, Object.assign({ className: classes(evidenceState.feedback[evidence.id] ===
                                                FeedbackType.POSITIVE && "text-alert_green-500", "flex items-center hover:text-alert_green-500"), onClick: () => {
                                                sendEvidenceFeedback(evidence, FeedbackType.POSITIVE);
                                            } }, { children: _jsx(ThumbsUp, { className: "" }) })), _jsx(BorderlessButton, Object.assign({ className: classes(evidenceState.feedback[evidence.id] ===
                                                FeedbackType.NEGATIVE && "text-alert_red-500", "flex items-center  hover:text-alert_red-500"), onClick: () => {
                                                sendEvidenceFeedback(evidence, FeedbackType.NEGATIVE);
                                            } }, { children: _jsx(ThumbsDown, { className: "" }) }))] }))),
                                key: "giveFeedback",
                            },
                        ],
                    } }, { children: _jsx(Tooltip, Object.assign({ title: "More actions" }, { children: _jsx(BorderlessButton, Object.assign({ className: "flex flex-1 justify-center items-center hover:bg-neutral-300 cursor-pointer rounded-full" }, { children: _jsx(EllipsisOutlined, { className: "px-1 py-1" }) })) })) }))] }));
    }, [evidenceState, isEvidenceSelected, sendEvidenceFeedback, queryState]);
    const label = isEmptyQuery(coraState) ? null : (_jsx("div", Object.assign({ className: "flex flex-col items-stretch" }, { children: evidence.length > 0 ? evidenceDescription.join(", ") : null })));
    return (_jsx(LabeledSection, Object.assign({ style: { flexGrow: "1", minWidth: "28rem" }, label: label }, { children: groupEvidenceByDocument ? (_jsx(DocumentSearchResults, { className: "ml-1", argsInfo: argsInfo, coraState: coraState, coraStateUpdater: coraStateUpdater, results: results
                .filter(r => r.type === "document")
                .map(r => r.value), disabled: disabled, evidenceSearch: evidenceSearch, evidenceState: evidenceState, evidenceStateUpdater: evidenceStateUpdater, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions })) : (_jsx(EvidenceSearchResults, { className: "ml-1", argsInfo: argsInfo, coraState: coraState, coraStateUpdater: coraStateUpdater, results: results
                .filter(r => r.type === "evidence")
                .map(r => r.value), disabled: disabled, evidenceSearch: evidenceSearch, evidenceState: evidenceState, evidenceStateUpdater: evidenceStateUpdater, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions })) })));
}
