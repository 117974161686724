var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { keyBy } from "lodash";
import { getTagBaseStyle } from "../lf";
import { ClusterCard } from "../../../shared/components/PickerList";
import { DropDownMenu } from "../../../shared/components/DropDownMenu";
import { union } from "../../../utils/collections";
import { FeedbackWidget } from "../../utils/feedback";
import { Checkbox } from "antd";
import { classes } from "../../../utils/classes";
import { ItemsPicker } from "./ItemsPicker";
import { showNotification } from "../../../shared/components/notifications";
export const LIST_HEIGHT = 300;
export function useValuesAndSelection(remote, selected, mapper, setSelected) {
    const { items, remoteByKey, selectedByName } = React.useMemo(() => {
        const remoteValues = remote.value || [];
        const selectedByName = keyBy(selected, i => i.name);
        const remoteByKey = keyBy(remoteValues, i => i.name);
        return {
            remoteByKey,
            selectedByName,
            items: remoteValues.map(mapper),
        };
    }, [remote.value, selected]);
    const isSelected = React.useCallback((item) => Boolean(selectedByName[item.name]), [selectedByName]);
    const changeSelection = React.useCallback((item, select) => {
        const selection = select
            ? isSelected(item)
                ? selected.map(i => (i.name === item.name ? item : i))
                : selected.concat([item])
            : selected.filter(i => i !== item);
        setSelected(selection, selection.map(item => remoteByKey[item.name]).filter(Boolean));
    }, [selected, setSelected, remoteByKey]);
    return { items, remoteByKey, isSelected, changeSelection };
}
export function ClusterCheckList({ remote, emptyMessage, filter, selected, setSelected, synonyms, updateSynonyms, mapper, sendFeedback, tagColorVals, prefix, hideHeaderList = false, style, checkboxClassName, itemFilter, confidence, renderName, }) {
    const [feedback, setFeedback] = React.useState({});
    function sendClusterFeedback(item, type, context) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!type || !sendFeedback)
                return;
            setFeedback((f) => (Object.assign(Object.assign({}, f), { [item.name]: type })));
            yield sendFeedback(item, type);
            showNotification("Feedback sent");
        });
    }
    const { items, remoteByKey, isSelected, changeSelection } = useValuesAndSelection(remote, selected, mapper, setSelected);
    const wrapWithSynonymsPicker = useSelectIdsWrapper(changeSelection, remoteByKey, synonyms, updateSynonyms);
    return (_jsx(ItemsPicker, { getId: i => i.name, entries: itemFilter ? items.filter(i => itemFilter(i)) : items, loading: remote.loading, selected: selected, updateSelection: selected => remote.value && setSelected(selected, remote.value), listClassName: "ml-2", itemRowClassName: classes("rounded px-2 mr-2 border border-neutral-200 py-1"), selectedRowStyle: tagColorVals && getTagBaseStyle(tagColorVals), wrapperClassName: "flex-1 mr-2", renderItem: item => {
            const content = (_jsxs("div", Object.assign({ className: "w-full flex items-center space-x-1 auto_hide_container" }, { children: [_jsx(ClusterCard, { className: "flex-1 overflow-hidden", titleClassName: "font-normal", noRiseOnHover: true, cardLookClass: "", spacingClass: "px-2 py-1", item: item, filterText: (filter || "").length >= 3 ? filter : undefined, synonyms: synonyms, infoExtractor: () => {
                            return {
                                title: renderName ? renderName(item) : item.name,
                                className: "mr-2",
                            };
                        } }), sendFeedback && (_jsx(FeedbackWidget, { item: Object.assign({ id: item.name }, item), expanded: false, sendFeedbackFn: (item, type, context) => sendClusterFeedback(item, type, context), feedback: feedback }))] })));
            return wrapWithSynonymsPicker(content, item, undefined, "w-full flex flex-1");
        }, emptyMessage: emptyMessage, checkboxClassName: checkboxClassName, style: style }));
}
export function useSelectIdsWrapper(changeSelection, remoteByKey, synonyms, updateSynonyms) {
    return React.useCallback((content, item, idx, containerClassName) => {
        const syns = synonyms(item);
        const re = remoteByKey[item.name];
        const reSyns = union(re ? synonyms(re) : [], syns);
        return reSyns.length < 1 ? (content) : (_jsx(DropDownMenu, Object.assign({ containerClassName: containerClassName, trigger: "contextMenu", maxHeight: LIST_HEIGHT, entries: () => {
                const set = new Set(syns);
                return reSyns.map(s => ({
                    caption: s,
                    disabled: s === item.name,
                    action(e) {
                        e.preventDefault();
                        const newSyns = set.has(s)
                            ? syns.filter(i => i !== s)
                            : syns.concat([s]);
                        changeSelection(updateSynonyms(item, newSyns), true);
                    },
                    icon: () => _jsx(Checkbox, { checked: set.has(s) }),
                }));
            } }, { children: content }), idx));
    }, [changeSelection, remoteByKey, synonyms, updateSynonyms]);
}
