import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import emptyImg from "../../assets/svg/empty.svg";
import { Spin } from "antd";
import { classes } from "../../utils/classes";
import { Tooltip } from "./Tooltip";
export function Empty({ loading, empty, children, size = 100, wrapperClassName = "", className, showIcon = true, skeleton, emptyTooltip, }) {
    const emptyMessage = typeof empty === "boolean" ? null : empty;
    const loadingMessage = typeof loading === "boolean" ? null : loading;
    const message = loadingMessage || emptyMessage;
    const content = message || loading ? (_jsx("div", Object.assign({ className: classes("flex-1 flex flex-row h-full items-center justify-center", className) }, { children: _jsxs("div", Object.assign({ className: "flex flex-col justify-center items-center h-full" }, { children: [loading
                    ? skeleton || _jsx(Spin, { size: "large" })
                    : showIcon && _jsx("img", { src: emptyImg, alt: "empty", width: size }), message && (_jsx("div", Object.assign({ className: "text-center text-gray-500" }, { children: message })))] })) }))) : (_jsx(_Fragment, { children: children }));
    const element = (wrapperClassName === null || wrapperClassName === void 0 ? void 0 : wrapperClassName.length) ? (_jsx("div", Object.assign({ className: wrapperClassName }, { children: content }))) : (content);
    return emptyTooltip && message && !loading ? (_jsx(Tooltip, Object.assign({ content: emptyTooltip }, { children: element }))) : (element);
}
