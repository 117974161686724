import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import "../../App.less";
import { Modals } from "../../shared/components/Modal";
import "./Cora.less";
import { clearLoading, setLoading, useLoading, } from "../../shared/components/Loading";
import { useSynchronizeAllTags } from "../utils/allTags";
import { useCurrentSession, useIsSessionSaved, useSessionSaver, } from "../utils/sessionManagement";
import { useWarnInvalidSource } from "../utils/sourceManagement";
import { useInfoStream } from "../../shared/components/InfoStream";
import { useErrorType } from "../../utils/errorStream";
import { Header } from "./Header";
import { PopDF } from "../PopDF";
import { Tour, TOUR_STORAGE_KEY } from "../../shared/components/assist/Tour";
import tourLandmarks from "../../shared/components/assist/tourLandmarks.json";
import { FAQ } from "../../shared/components/assist/FAQ";
import { VideoPopup } from "../../shared/components/assist/VideoPopup";
import { useWarnReload } from "../utils/useWarnReload";
import { CoraMainContent } from "./CoraMainContent";
import { QueryBar } from "./QueryBar";
import { useRegisterKBMenuExtensions } from "../extensions/KBLinks";
import { useLogRocket } from "../../shared/logRocket";
import { useGlobalPopup } from "../../shared/components/GlobalPopUp";
import { ModelBuilderContext } from "./modelBuilder/ModelBuilderContext";
import { SuggestedQuestions } from "../../shared/components/SuggestedQuestions";
import { QueryStateProvider } from "../utils/queryState/queryState";
import { useHistoryUpdater } from "../utils/historyUpdater";
import { CoraStateProvider } from "./CoraStateProvider";
import { useActivityMonitor } from "../../shared/components/ActivityMonitor";
import { useQueryIdSynchronizer } from "../utils/queryState/queryIdSynchronizer";
export function CoraWrapper({ queryState }) {
    const { modelBuilderMode, modelBuilderAvailable } = useCurrentSession();
    return (_jsx(QueryStateProvider, Object.assign({ initial: queryState }, { children: _jsx(CoraStateProvider, { children: _jsx(ModelBuilderContext, { children: _jsx(Cora, {}) }) }) })));
}
export function Cora() {
    useLogRocket();
    useQueryIdSynchronizer();
    useHistoryUpdater();
    useActivityMonitor();
    useErrorType(false);
    const sessionSaved = useIsSessionSaved();
    useWarnReload(!sessionSaved);
    const loading = useLoading();
    const PopupMenu = useGlobalPopup();
    useSynchronizeAllTags();
    useWarnInvalidSource();
    useSessionSaver();
    useRegisterKBMenuExtensions();
    const modalInfo = useInfoStream("MODAL");
    React.useEffect(() => {
        (modalInfo === null || modalInfo === void 0 ? void 0 : modalInfo.message) ? setLoading(modalInfo.message) : clearLoading();
    }, [modalInfo === null || modalInfo === void 0 ? void 0 : modalInfo.message]);
    const { onboardingTour, disableTourAutoLaunch } = useFlags();
    const hasStartedTour = window.localStorage.getItem(TOUR_STORAGE_KEY);
    const { structuredQuery, showStructuredQuery, modelBuilderAvailable } = useCurrentSession();
    if (onboardingTour && hasStartedTour !== "true" && !disableTourAutoLaunch)
        Tour.start(tourLandmarks.landmarks);
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch absolute w-full h-full overflow-clip bg-gray-200" }, { children: [_jsx("div", Object.assign({ className: "flex flex-col items-stretch w-full" }, { children: _jsx(Header, Object.assign({ controls: structuredQuery &&
                        showStructuredQuery &&
                        !modelBuilderAvailable && _jsx(SuggestedQuestions, {}) }, { children: _jsx("div", Object.assign({ className: "flex space-x-1 items-center flex-1" }, { children: _jsx(QueryBar, {}) })) })) })), _jsx(CoraMainContent, {}), _jsx(PopupMenu, {}), _jsx(Modals, {}), loading, _jsx(PopDF, {}), _jsx(FAQ, {}), _jsx(VideoPopup, {}), _jsx(Tour, {})] })));
}
