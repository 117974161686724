import { CRCDirection, } from "../../api/types";
import { ANY, getActualActiveConstraintId } from "./DMParams";
import { EMPTY_QUERY_PARAMS } from "../coraState";
const EMPTY_CONSTRAINT = {
    source: null,
    relation: null,
    target: null,
    context: [],
    qualifiers: {},
    id: "",
    text: "",
};
export function dmParamsToQueryParams(params, activeConstraintId, overrides, possible, extra, extraOverrides, excludeIds = []) {
    const { concepts, relations, clauses, constraints } = params;
    getActualActiveConstraintId(params, activeConstraintId || "");
    if (activeConstraintId === null || !constraints.length)
        return EMPTY_QUERY_PARAMS;
    const constraint = params.constraints.find(c => c.id === activeConstraintId);
    const { source, relation, target, qualifiers, context, is_directed } = constraint || EMPTY_CONSTRAINT;
    const argClauses = Object.fromEntries(Object.entries(qualifiers || {})
        .filter(([_, id]) => id !== ANY &&
        !excludeIds.includes(id) &&
        (clauses[id] || concepts[id]))
        .map(([argName, id]) => [
        argName,
        clauses[id] || concepts[id].map(conceptAsClause), // TODO how to treat concepts?
    ]));
    const required_arguments = Object.entries(qualifiers || {})
        .filter(([_, id]) => id === ANY)
        .map(([argName]) => argName);
    const concepts1 = getExcluding(concepts, source);
    const concepts2 = getExcluding(concepts, target);
    const contextIds = (context || []).filter(id => !excludeIds.includes(id));
    return {
        concepts1,
        concepts1Override: getOverrides(source),
        concepts1Possible: (source && (possible === null || possible === void 0 ? void 0 : possible[source])) || null,
        concepts2,
        concepts2Override: getOverrides(target),
        concepts2Possible: (target && (possible === null || possible === void 0 ? void 0 : possible[target])) || null,
        relations: relation ? relations[relation] : [],
        contextConcepts: contextIds.map(id => concepts[id]).flat(),
        argClauses,
        required_arguments,
        contextConceptsOverride: contextIds.reduce((acc, id) => {
            const ovs = getOverrides(id) || {};
            return Object.assign(Object.assign({}, acc), ovs);
        }, {}),
        crcDirection: is_directed ? CRCDirection.C1C2 : CRCDirection.BOTH,
        refuting_relations: null,
        refuting_concepts: null,
        extraConceptsPossible: null,
        text: (constraint === null || constraint === void 0 ? void 0 : constraint.text) || "",
        extraConcepts: extra,
        extraConceptsOverride: extraOverrides,
        contextConceptsPossible: null,
    };
    function getExcluding(items, id) {
        return !id || excludeIds.includes(id) ? [] : items[id] || [];
    }
    function getOverrides(key) {
        return !key || excludeIds.includes(key) || !overrides[key]
            ? {}
            : overrides[key];
    }
}
export function conceptAsClause({ name, members, }) {
    return {
        name,
        members: members.map(({ id, type_names }) => ({
            id,
            surface_forms: type_names,
        })),
    };
}
