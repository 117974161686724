import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { bgFinding500, blueButtonClass } from "../lf";
import React from "react";
import { pushModal } from "../../../shared/components/Modal";
import { SelectItemsModal } from "./SelectItemsModal";
import { addEvidenceToFinding } from "../../utils/findings";
import { EvidenceSummaryBanner } from "../evidence/evidenceBanners";
import { updateCurrentSession, useCurrentSession, } from "../../utils/sessionManagement";
function AttachEvidenceToFindings({ evidence }) {
    const { findings: items } = useCurrentSession();
    return (_jsx(SelectItemsModal, { items: items, brandingColorClass: bgFinding500, buttonColorClass: blueButtonClass, title: "My Findings", buttonCaption: "Attach Evidence", noItemsMessage: "No Findings", notificationMessage: "Evidence Attached", contextText: evidence.length == 1
            ? evidence[0].text
            : `Adding ${evidence.length} new Evidence`, onSelected: selectedItems => {
            const selectedIds = new Set(selectedItems.map(item => item.id));
            updateCurrentSession(({ findings }) => ({
                findings: findings.map(f => !selectedIds.has(f.id) ? f : addEvidenceToFinding(f, evidence)),
            }));
        }, infoExtractor: (item, idx, actions) => ({
            title: (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "flex justify-end" }, { children: actions })), _jsx("div", { children: item.payload.selectedText })] })),
            tags: _jsx(EvidenceSummaryBanner, { evidence: item.payload.evidence }),
        }) }));
}
export function showAttachEvidenceToFindings(props) {
    pushModal({
        title: "Attach Evidence",
        titleColorClass: bgFinding500,
        content: _jsx(AttachEvidenceToFindings, Object.assign({}, props)),
    });
}
