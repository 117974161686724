import { jsx as _jsx } from "react/jsx-runtime";
import { classes } from "../../../../utils/classes";
import { Closed, Opened } from "../../Icons";
import React from "react";
import { NO_ACTIVATE_ON_CLICK } from "../diagramUtils";
export const Expander = React.memo(function NodeExpander({ expanded, onChange, }) {
    const onMouseDownCapture = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(!expanded);
    }, [expanded]);
    return (_jsx("div", Object.assign({ className: classes(NO_ACTIVATE_ON_CLICK, "flex items-center justify-center text-xs cursor-pointer"), onMouseDownCapture: onMouseDownCapture }, { children: expanded ? (_jsx(Opened, { className: "node-expansion" })) : (_jsx(Closed, { className: "node-expansion" })) })));
});
