import { DiagramModel, LinkType, } from "./DiagramModel";
import { clearSelectedAndTop } from "./diagramUtils";
import { setNodeProperties } from "../../../shared/components/gojs/gojs";
import { ANY, getReferredIds } from "../../utils/queryState/DMParams";
import { getSize } from "../../../utils/util";
export function updateModel(params, overrides, topConceptsByKey, model = new DiagramModel()) {
    model.newUpdate();
    const { concepts: conceptsByKey, relations: relationsByKey, clauses: clausesByKey, constraints, } = params;
    const refIds = getReferredIds(params);
    constraints.forEach(updateFromConstraint);
    // updateInferenceRules()
    clearSelectedAndTop(model);
    updateOverrides();
    updateTopConcepts();
    model.removePreviousGens();
    return model;
    function updateFromConstraint(constraint) {
        const { id, source: from, target: to, relation, is_directed = false, qualifiers, context, text, } = constraint;
        const statementVisible = Boolean(relation ||
            (from && to) ||
            (from && refIds.get(from) > 1) ||
            (to && refIds.get(to) > 1) ||
            getSize(context) ||
            getSize(qualifiers || {}));
        const n1 = statementVisible || from ? createTypeNode(from) : undefined;
        const n2 = statementVisible || to ? createTypeNode(to) : undefined;
        const sNode = model.getOrCreateStatementNode(constraint.id, relation ? [relation] : [], relation ? relationsByKey[relation] : [], n1, n2, [], is_directed, !statementVisible, text !== null && text !== void 0 ? text : "");
        if (n1)
            model.getOrCreateLink(n1, sNode, LinkType.ARROW, is_directed, undefined);
        if (n2)
            model.getOrCreateLink(sNode, n2, LinkType.ARROW, is_directed, undefined);
        model.set(sNode, "qualifiers", Object.entries(qualifiers || {}).map(([argName, qualifier]) => {
            const n = createTypeNode(qualifier === ANY ? getAnyClauseId(id, argName) : qualifier);
            return model.getOrCreateLink(sNode, n, LinkType.QUALIFIER, false, {
                argName,
                types: [qualifier[0]],
            }, argName);
        }));
        if (context === null || context === void 0 ? void 0 : context.length)
            model.set(sNode, "context", createContextNodes(sNode));
        function createContextNodes(fromNode) {
            return context.map(id => {
                const n = createTypeNode(id);
                return createContextLink(fromNode, n);
            });
        }
        function createTypeNode(id) {
            var _a;
            const concepts = !id
                ? []
                : conceptsByKey[id]
                    ? conceptsByKey[id]
                    : (_a = clausesByKey[id]) === null || _a === void 0 ? void 0 : _a.map(clauseAsConcept);
            if (!(concepts === null || concepts === void 0 ? void 0 : concepts.length) && id) {
                console.error("Invalid concept or clause reference", id);
            }
            return model.getOrCreateTypeNode(concepts, id);
        }
        function createContextLink(from, to) {
            return model.getOrCreateLink(from, to, LinkType.CONTEXT, false, {}, "Context");
        }
    }
    function updateTopConcepts() {
        Object.entries(topConceptsByKey).forEach(([groupKey, topConcepts]) => {
            setNodeProperties(model, { topConcepts }, groupKey);
        });
    }
    function updateOverrides() {
        const referred = getReferredIds(params);
        Object.entries(overrides)
            .filter(([groupKey]) => referred.has(groupKey))
            .forEach(([groupKey, concepts]) => {
            Object.values(concepts)
                .flat()
                .forEach(c => model.getOrCreateConceptNode(c, null, groupKey));
            model.setDataProperty(model.findNodeDataForKey(groupKey), "overrideConcepts", concepts);
        });
    }
}
export function getAnyClauseId(constraintId, argName) {
    return ANY + "_" + constraintId + "_" + argName;
}
export function clauseAsConcept({ name, members, }) {
    return {
        name,
        members: members.map(({ id, surface_forms }) => ({
            id,
            name: surface_forms[0],
            type_names: surface_forms,
            kb_ids: [],
        })),
    };
}
