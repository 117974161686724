var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { DropDownMenu } from "../../../shared/components/DropDownMenu";
import { Down } from "../Icons";
import { PillButton } from "../../../shared/components/buttons/Buttons";
import { useDomainModelDescriptors } from "./domainModelDescriptors";
import { useQueryState } from "../../utils/queryState/queryState";
import { API } from "../../api/api";
import { domainModelToDMParams, getActualActiveConstraintId, } from "../../utils/queryState/DMParams";
import { setPossibleValues } from "../../utils/possibleValues";
export function DMPicker({ onLoading }) {
    var _a;
    const [{ corpus_ids }, stateUpdater] = useQueryState();
    const descriptors = useDomainModelDescriptors(corpus_ids);
    const disabled = descriptors.loading;
    const button = (_jsx(PillButton, Object.assign({ disabled: disabled, className: "bg-gray-300" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row space-x-1 items-center" }, { children: [_jsx("div", { children: !descriptors.loading && !((_a = descriptors.value) === null || _a === void 0 ? void 0 : _a.length)
                        ? "No Models"
                        : "Load Model" }), _jsx(Down, {})] })) })));
    return disabled ? (button) : (_jsx(DropDownMenu, Object.assign({ title: "Domain Model", hasClose: true, entries: () => {
            return descriptors.value.map(desc => ({
                caption: desc.name || desc.id,
                action() {
                    return __awaiter(this, void 0, void 0, function* () {
                        try {
                            onLoading(true);
                            const dmParams = domainModelToDMParams(yield API.getDomainModel({ id: desc.id, corpus_ids }));
                            setPossibleValues(null);
                            stateUpdater({
                                dmParams,
                                activeConstraintId: getActualActiveConstraintId(dmParams),
                                overrides: {},
                            });
                        }
                        finally {
                            onLoading(false);
                        }
                    });
                },
            }));
        } }, { children: button })));
}
