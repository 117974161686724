import { jsx as _jsx } from "react/jsx-runtime";
import { API } from "../../api/api";
import { bgBrand700, brandBorderClass, columnWidthClass2 } from "../lf";
import { Card } from "../../../shared/components/Card";
import React from "react";
import { pushModal } from "../../../shared/components/Modal";
import { FilteredCardList } from "../../../shared/components/CardsList";
import { DefaultMarkDown } from "../../../shared/components/MarkDown";
import { InfoRow } from "../InfoRow";
import { WEB_SOURCE_ID } from "../../utils/globalInfo";
import { Beacon, useBeacon } from "../../../utils/Beacon";
import { useRemoteQuery } from "../../../utils/query/remoteQuery";
function Glossary({ corpus_id, span_in_evidence, concept_cluster, context, coraStateUpdater, }) {
    const glossary = useRemoteQuery({
        loader: API.search_glossary,
        params: concept_cluster // prefer concept cluster, if available
            ? {
                corpus_id,
                concept_cluster,
            }
            : span_in_evidence
                ? {
                    corpus_id,
                    span_in_evidence,
                }
                : undefined,
    });
    const terms = (glossary.value || []).filter(t => t.title && t.gloss);
    return (_jsx("div", Object.assign({ className: "flex-1 flex items-stretch space-x-[144px]" }, { children: _jsx(FilteredCardList, { className: columnWidthClass2, filterBorderClass: brandBorderClass, title: "Glossary", loading: glossary.loading, items: terms, noItemsMessage: "No Definitions", brandingColorClass: bgBrand700, itemTexts: item => [item.title, item.gloss], itemRender: (item, idx, items, filterText) => (_jsx(Card, { title: _jsx(DefaultMarkDown, { text: item.title, separator: filterText, className: "font-bold" }), content: _jsx(DefaultMarkDown, { text: item.gloss, separator: filterText }), brandingColorClass: bgBrand700, lineClamp: terms.length !== 1 ? 3 : undefined, banners: item.url && (_jsx(InfoRow, { sources: [
                        {
                            url: item === null || item === void 0 ? void 0 : item.url,
                            external_url: item === null || item === void 0 ? void 0 : item.url,
                            corpus_id: WEB_SOURCE_ID,
                            document_id: "",
                            title: "",
                            id: "",
                        },
                    ] })), actions: null })) }) })));
}
export function showGlossary({ corpus_id, span_in_evidence, concept_cluster, context, coraStateUpdater, }) {
    pushModal({
        title: "Glossary",
        titleColorClass: bgBrand700,
        content: (_jsx(Glossary, { corpus_id: corpus_id, span_in_evidence: span_in_evidence, concept_cluster: concept_cluster, context: context, coraStateUpdater: coraStateUpdater })),
        easyClose: () => true,
    });
}
const globalGlossaryOpener = Beacon({
    initial: undefined,
});
export function useGlobalGlossaryOpener() {
    return useBeacon(globalGlossaryOpener)[0];
}
export const setGlobalGlossaryOpener = globalGlossaryOpener.value;
