import React from "react";
import { stageNewNode } from "../diagramUtils";
import { useQueryState } from "../../../utils/queryState/queryState";
import { setPossibleValues } from "../../../utils/possibleValues";
export function useGraphActions({ nodes, selected, active, stage, flow, dUpdater, }) {
    const [, updater] = useQueryState();
    return React.useCallback(() => ({
        global: [
            {
                caption: "Graph",
                subEntries: [
                    {
                        caption: "Add Concept",
                        shortcut: "c",
                        action() {
                            stageNewNode(stage);
                        },
                    },
                    {
                        caption: selected.length ? "Fit Selection" : "Fit",
                        shortcut: "alt+t",
                        action() {
                            if (!nodes.length) {
                                flow.zoomTo(1, { duration: 200 });
                            }
                            flow.fitView(Object.assign({ duration: 200 }, (selected.length && { nodes: selected })));
                        },
                    },
                    {
                        caption: "Fit Query",
                        shortcut: "alt+shift+t",
                        disabled: !active.length,
                        action() {
                            flow.fitView({
                                duration: 200,
                                nodes: active,
                            });
                        },
                    },
                    {
                        caption: "Clear",
                        disabled: !nodes.length,
                        action() {
                            dUpdater.commit({ nodes: [], edges: [] });
                            setPossibleValues(null);
                        },
                    },
                    {
                        caption: "Reset",
                        desc: "Reset the graph to its initial state.",
                        disabled: !nodes.length,
                        action() {
                            setPossibleValues(null);
                            updater({ overrides: {} });
                        },
                    },
                ],
            },
        ],
    }), [nodes, selected, active, stage]);
}
