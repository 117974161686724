import React from "react";
import { useReactFlow, useStoreApi, } from "reactflow";
import { useOnGlobalEscape } from "../../../utils/events";
import { clearUnavailable, hasUnavailable, markAsUnavailable, } from "./unavailable";
import { createNodeFromSource } from "./createNodeFromSource";
import { createRelation, typesAre } from "./diagramUtils";
import { CONTEXT, ElementType, upsertEdge } from "./model";
import { Mode, useDiagramMode } from "./DiagramMode";
import { useDiagramStateUpdater } from "./DiagramState";
function connectNodes(flow, dUpdater, connection) {
    const source = connection.source;
    const target = connection.target;
    const sNode = flow.getNode(source);
    const tNode = flow.getNode(target);
    const { type: sType, data: { argName: sArgName }, } = sNode;
    const { type: tType, data: { argName: tArgName }, } = tNode;
    if (typesAre(sType, tType, ElementType.RELATION, ElementType.RELATION)) {
        return;
    }
    let appended;
    if (typesAre(sType, tType, ElementType.CONCEPT, ElementType.CONCEPT)) {
        const nes = createRelation(sNode, tNode);
        appended = {
            nodes: [...flow.getNodes(), nes.node],
            edges: [...flow.getEdges(), ...nes.edges],
        };
    }
    else {
        const edge = upsertEdge({
            source: tType === ElementType.RELATION ? target : source,
            target: tType === ElementType.RELATION ? source : target,
            type: ElementType.QUALIFIER,
            label: sArgName || tArgName || CONTEXT,
        });
        appended = {
            nodes: flow.getNodes(),
            edges: [...flow.getEdges(), edge],
        };
    }
    dUpdater.commit(clearUnavailable(appended));
}
export function useConnection(reactFlowWrapper) {
    const dUpdater = useDiagramStateUpdater();
    const flow = useReactFlow();
    const store = useStoreApi();
    const sourceId = React.useRef(null);
    const mode = useDiagramMode();
    useOnGlobalEscape(!mode.is(Mode.connecting), () => {
        store.getState().cancelConnection();
        sourceId.current = null;
    });
    return {
        onConnect: React.useCallback((connection) => {
            sourceId.current = null;
            connectNodes(flow, dUpdater, connection);
        }, []),
        isValidConnection: React.useCallback((connection) => {
            const source = flow.getNode(connection.source);
            const target = flow.getNode(connection.target);
            return source !== target && !hasUnavailable(target.className);
        }, []),
        onConnectStart: React.useCallback((_, { nodeId }) => {
            sourceId.current = nodeId;
            //mode(Mode.staging)
            markAsUnavailable(flow, nodeId);
        }, []),
        onConnectEnd: React.useCallback((event) => {
            const source = sourceId.current
                ? flow.getNode(sourceId.current)
                : undefined;
            sourceId.current = null;
            mode(Mode.normal);
            createNodeFromSource(flow, dUpdater, event, reactFlowWrapper.current.getBoundingClientRect(), source);
        }, [flow.project]),
    };
}
