import { diagramToQueryState } from "./diagramToQueryState";
import { getConstraintsContaining } from "../../../utils/queryState/constraints";
import { EMPTY_VIEWPORT, filterQueryState, } from "../../../utils/queryState/queryState";
import { mergeNamedMembers, mergeRelationClusters, } from "../../../utils/semanticSearchUtil";
import { mapValues } from "lodash";
import { filterByValue } from "../../../../utils/objects";
export function diagramToConjunctive(state, node, nodes, edges, prefix = "") {
    const diagramQS = diagramToQueryState({ nodes, edges, viewport: EMPTY_VIEWPORT }, state);
    const nodeConstraints = new Set(getConstraintsContaining(diagramQS.dmParams.constraints, node.id));
    const qs = filterQueryState(diagramQS, c => nodeConstraints.has(c.id));
    const { dmParams, overrides } = qs;
    const concepts = Object.fromEntries(Object.entries(dmParams.concepts)
        .filter(([id]) => id !== node.id && overrides[id])
        .map(([id]) => [
        id,
        mergeNamedMembers(Object.values(overrides[id]).flat())[0],
    ]));
    const relations = mapValues(filterByValue(dmParams.relations, r => r.length > 0), r => mergeRelationClusters(r)[0]);
    const clauses = mapValues(dmParams.clauses, c => mergeNamedMembers(c)[0]);
    const types = Object.fromEntries(Object.entries(dmParams.concepts)
        .filter(([id]) => id === node.id || !overrides[id])
        .map(([id, cs]) => [id, cs.map(c => c.members.map(m => m.id)).flat()]));
    const bindings_for = Object.keys(types)
        .sort()
        .map(id => (id === node.id && prefix.length ? { id, prefix } : { id }));
    return {
        concepts,
        relations,
        clauses,
        types,
        constraints: dmParams.constraints.map(c => !c.relation || relations[c.relation] ? c : Object.assign(Object.assign({}, c), { relation: null })),
        bindings_for,
    };
}
