var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useEffectIfDifferent, useMemoIfEqual, useParams, } from "../../../utils/hooks";
import { useValidatedCorpusIds } from "../sourceManagement";
import { Beacon, ParamsBeacon } from "../../../utils/Beacon";
import { createDependencies } from "../../../utils/dependencies";
import { keysFor } from "../../../utils/util";
import { pick } from "lodash";
import { EMPTY_COMMON_STATE_PARAMS, METADATA, REFUTING_QUERY_PARAMS, } from "../commonStateParams";
import { filterDMParams, getConstraint, getReferredIds } from "./DMParams";
import { Emitter } from "../../../utils/Emitter";
export const EMPTY_VIEWPORT = { x: 0, y: 0, zoom: 1 };
export const EMPTY_QUERY_STATE = Object.assign(Object.assign({}, EMPTY_COMMON_STATE_PARAMS), { overrides: {}, extra: [], extraOverrides: {}, nodesInfo: {}, viewport: EMPTY_VIEWPORT });
export function getEmptyQueryStateForCorpus(corpus_ids) {
    return Object.assign(Object.assign({}, EMPTY_QUERY_STATE), (corpus_ids && { corpus_ids }));
}
export function filterCoraStateQueryParams(update) {
    return pick(update, ...Object.keys(EMPTY_QUERY_STATE));
}
const extraDependenciesBeacon = ParamsBeacon(Beacon({
    initial: {},
}));
export var QueryStateEventSource;
(function (QueryStateEventSource) {
    QueryStateEventSource["CONSTRAINTS_BAR"] = "CONSTRAINTS_BAR";
})(QueryStateEventSource || (QueryStateEventSource = {}));
function useQueryStateUpdater(updater) {
    return React.useCallback((updateOrFn, event_source = null) => {
        updater(state => {
            const value = typeof updateOrFn === "function" ? updateOrFn(state) : updateOrFn;
            const update = filterCoraStateQueryParams(value);
            const extraDependencies = extraDependenciesBeacon.value();
            function calcUpdate() {
                function getDependencies() {
                    const deps = createDependencies()
                        .define("corpus_ids", [
                        ...keysFor(METADATA),
                        "dmParams",
                        "activeConstraintId",
                        "evidenceFilter",
                        "sortFieldId",
                        "sortOrder",
                    ])
                        .define(["dmParams"], ["evidenceFilter", "overrides"])
                        .define([...keysFor(REFUTING_QUERY_PARAMS)], ["rangeMetadataOverride"]);
                    Object.values(extraDependencies).forEach(v => deps.define(v.items, v.dependencies));
                    return deps.dependencies(...Object.keys(update));
                }
                const keysToUpdate = getDependencies();
                return keysToUpdate.reduce((acc, k) => {
                    acc[k] = k in update ? update[k] : EMPTY_QUERY_STATE[k];
                    return acc;
                }, {});
            }
            const enhancedUpdate = calcUpdate();
            // console.trace("QUERY UPDATE", enhancedUpdate)
            return Object.assign(Object.assign({}, enhancedUpdate), { event_source });
        });
    }, [updater]);
}
function useCreateQueryState(initial = EMPTY_QUERY_STATE) {
    const [_a, updater] = useParams(Object.assign(Object.assign({}, initial), { event_source: null })), { event_source } = _a, state = __rest(_a, ["event_source"]);
    const corpus_ids = useValidatedCorpusIds(state.corpus_ids);
    const validatedState = useMemoIfEqual(Object.assign(Object.assign({}, state), { corpus_ids }));
    return [validatedState, event_source, useQueryStateUpdater(updater)];
}
export function QueryStateProvider({ initial, children, }) {
    const [queryState, event_source, updater] = useCreateQueryState(initial);
    const eventsEmitter = React.useRef(Emitter());
    useEffectIfDifferent(() => {
        //set timeout so the event is thrown after React updates
        setTimeout(() => eventsEmitter.current.emit([queryState, event_source]));
    }, [], [queryState, event_source]);
    return (_jsx(QueryStateEventListenerContext.Provider, Object.assign({ value: eventsEmitter.current.on }, { children: _jsx(QueryStateContext.Provider, Object.assign({ value: [queryState, updater] }, { children: children })) })));
}
const QueryStateContext = React.createContext(undefined);
const QueryStateEventListenerContext = React.createContext(undefined);
export function useQueryStateEventListener() {
    const listener = React.useContext(QueryStateEventListenerContext);
    if (!listener)
        throw new Error("useQueryStateEventListener must be used within a QueryStateProvider");
    return listener;
}
export function useQueryState() {
    const queryState = React.useContext(QueryStateContext);
    if (!queryState)
        throw new Error("useQueryState must be used within a QueryStateProvider");
    return queryState;
}
export function filterQueryState(state, filter) {
    const dmParams = filterDMParams(state.dmParams, filter);
    const refIds = getReferredIds(dmParams).toArray();
    return Object.assign(Object.assign({}, state), { dmParams, activeConstraintId: !getConstraint(dmParams, state.activeConstraintId)
            ? ""
            : state.activeConstraintId, overrides: pick(state.overrides, refIds), nodesInfo: pick(state.nodesInfo, refIds) });
}
