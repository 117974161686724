import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Tag } from "antd";
export function XTag(props) {
    // const {onClose, closable, closeIcon, style, children, cap} = props
    // const w = 7,
    //   h = 23
    // // const d = `M -1 1 L ${w - 1} 1 L ${1} ${h - 1} L 0 ${h - 1} `
    // const d = `M -1 1 L 1 1 L ${w - 1} ${h / 2} L ${1} ${h - 1} L 0 ${h - 1} `
    //
    // function getSvg(startCap: boolean) {
    //   const svg = (
    //     <svg
    //       width={w}
    //       height={h}
    //       style={startCap ? {transform: "rotate(180deg)"} : undefined}
    //     >
    //       <path
    //         d={d + " z"}
    //         stroke={style?.borderColor}
    //         strokeWidth="2"
    //         fill="none"
    //       />
    //       <path d={d} fill={style?.backgroundColor} />
    //     </svg>
    //   )
    //   return svg
    // }
    //
    // return (
    //   <div className="flex items-stretch mr-1">
    //     {(cap === "RIGHT" || cap === "BOTH") && getSvg(true)}
    //     <div
    //       className={classes(
    //         "flex-row space-x-2 flex items-center px-1 text-tiny font-semibold ",
    //         !cap && "border rounded-md"
    //       )}
    //       style={{
    //         ...style,
    //         ...(cap && {
    //           borderTopWidth: 1,
    //           borderTopStyle: "solid",
    //           borderBottomWidth: 1,
    //           borderBottomStyle: "solid",
    //           borderLeftWidth: cap === "LEFT" ? 1 : 0,
    //           borderRightWidth: cap === "RIGHT" ? 1 : 0,
    //           borderLeftStyle: "solid",
    //         }),
    //       }}
    //     >
    //       <div className="flex space-x-1" style={{marginTop: 0}}>
    //         {children}
    //       </div>
    //       {closable && (
    //         <span
    //           className="cursor-pointer text-xs ml-1"
    //           style={{marginBottom: 4}}
    //           onMouseDown={
    //             onClose &&
    //             (e => {
    //               e.preventDefault()
    //               onClose(e)
    //             })
    //           }
    //         >
    //           {closeIcon || <Close />}
    //         </span>
    //       )}
    //     </div>
    //     {(cap === "LEFT" || cap === "BOTH") && getSvg(false)}
    //   </div>
    // )
    return _jsx(Tag, Object.assign({}, props));
}
