const conceptMenuExtensions = [];
const crcMenuExtensions = [];
export function registerConceptMenuExtension(extension) {
    conceptMenuExtensions.push(extension);
    return () => {
        conceptMenuExtensions.splice(conceptMenuExtensions.indexOf(extension), 1);
    };
}
export function registerCrcMenuExtension(extension) {
    crcMenuExtensions.push(extension);
    return () => {
        crcMenuExtensions.splice(crcMenuExtensions.indexOf(extension), 1);
    };
}
export function conceptMenuExtension(entries, concepts, coraState, coraStateUpdater) {
    return conceptMenuExtensions.reduce((acc, extension) => extension(acc, concepts, coraState, coraStateUpdater), entries);
}
export function crcMenuExtension(entries, coraState, field) {
    return crcMenuExtensions.reduce((acc, extension) => extension(acc, coraState, field), entries);
}
