var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Immutable from "immutable";
import { merge, toSet } from "../../utils/collections";
import { cleanupHtmlTags, uuidv4 } from "../../utils/util";
import { Beacon } from "../../utils/Beacon";
import React from "react";
import { getCorpusFilter, getStateNlg, isEmptyQuery } from "./semanticSearchUtil";
import * as toi from "@toi/toi";
import { getQueryStateHistoryEntry, restoreQuery } from "./history";
import { coraStateToQueryParts } from "../components/QueryRenderer";
import { loadQueryState } from "./queryState/queryId";
import { queryStateToCoraState } from "./queryState/queryStateToCoraState";
// export async function createNewFinding({
//   selection,
//   useFindingsNlg,
//   hasBackgroundKnowledge,
//   updateParams,
//   coraState,
//   corpus_filter,
// }: CreateFindingProps) {
//   const evidence = selection
//   const {refuting} = coraState
//   const evidenceType = evidenceTypeFromRefuting(refuting)
//   const disabled = invalidCorpusIds(coraState.corpus_ids)
//
//   const suggestFindings = await API.suggestFindings({
//     corpus_filter,
//     evidence_ids: evidence.map(e => e.id),
//     ...(evidenceType === EvidenceType.REFUTING && {
//       evidence_type: EvidenceType.REFUTING,
//     }),
//   })
//   const selectedText = useFindingsNlg
//     ? suggestFindings[0]?.text || ""
//     : evidence[0].text
//   const finding = await newFinding({
//     selectedText,
//     context: null,
//     coraState,
//     evidence,
//     suggestFindings,
//     corpus_filter,
//   })
//   showFindingEditor(
//     finding,
//     true,
//     hasBackgroundKnowledge,
//     disabled,
//     coraState,
//     () => updateParams({selection: Set()})
//   )
// }
export function setFindingEvidence(finding, evidence) {
    return Object.assign(Object.assign({}, finding), { updateTimestamp: Date.now(), payload: Object.assign(Object.assign({}, finding.payload), { evidence }) });
}
export function addEvidenceToFinding(finding, evidence) {
    const evidenceToAdd = getAvailableEvidence(evidence, finding.payload.evidence);
    return !(evidence === null || evidence === void 0 ? void 0 : evidence.length)
        ? finding
        : setFindingEvidence(finding, [
            ...finding.payload.evidence,
            ...evidenceToAdd,
        ]);
}
export function removeEvidenceFromFinding(finding, evidence) {
    return setFindingEvidence(finding, finding.payload.evidence.filter(e => !evidence.map(e => e.id).includes(e.id)));
}
export function existsInFindings(findings, evidence) {
    return findings.some(f => f.payload.evidence.map(e => e.id).includes(evidence.id));
}
export function countEvidenceInFindings(findings, evidence) {
    return findings
        .reduce((acc, finding) => {
        const ids = finding.payload.evidence.map(e => e.id);
        return acc.concat(ids);
    }, Immutable.Set())
        .filter(eId => evidence.map(e => e.id).includes(eId)).size;
}
export function updateFindingPayload(finding, payload) {
    return Object.assign(Object.assign({}, finding), { updateTimestamp: Date.now(), payload: merge(finding.payload, payload) });
}
export function upsertFinding(findings, finding) {
    let replaced = false;
    const mapped = findings.map(f => {
        const found = f.id === finding.id;
        if (found) {
            replaced = true;
            return finding;
        }
        return f;
    });
    return replaced ? mapped : [...mapped, finding];
}
export function getAvailableEvidence(suggested, current) {
    if (!(suggested === null || suggested === void 0 ? void 0 : suggested.length))
        return [];
    const set = toSet(current.map(e => e.text));
    return suggested.filter(e => !set.has(e.text));
}
export function newFinding({ selectedText, queryState, context, evidence = [], suggestFindings, title, }) {
    return __awaiter(this, void 0, void 0, function* () {
        return {
            id: uuidv4(),
            title,
            creationTimestamp: Date.now(),
            updateTimestamp: Date.now(),
            context: {
                historyEntry: yield getQueryStateHistoryEntry(queryState),
                contextTitle: currentContextTitle.value(),
            },
            payload: {
                selectedText,
                context,
                evidence,
                tags: [],
                notes: "",
                foundTime: Date.now(),
                suggestFindings,
            },
        };
    });
}
export function getContextUrl(context) {
    return (context === null || context === void 0 ? void 0 : context.instances[0].url) || null;
}
const currentContextTitle = Beacon({ initial: "" });
export function useUpdateCurrentContextTitle(coraState) {
    // const currentSource = useCurrentSource(coraState)
    const semanticGroupName = getStateNlg(coraState);
    // const prefix = `${currentSource!.name} - ${getClustersName(
    //   coraState.context
    // )}`
    const title = semanticGroupName;
    React.useEffect(() => {
        currentContextTitle.value(cleanupHtmlTags(title));
    }, [title]);
    return title;
}
export function JsonFileToFindings(file) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const json = yield file.text();
            const obj = JSON.parse(json);
            validateFindings(obj);
            const arr = obj;
            validateFinding(arr[0]);
            return arr;
        }
        catch (e) {
            console.error(e);
        }
        return "File has to contain a JSON array of findings";
    });
}
export const validateFinding = toi
    .required()
    .and(toi.obj.isplain())
    .and(toi.obj.keys({
    id: toi.required().and(toi.str.is()),
    creationTimestamp: toi.optional(),
    context: toi.optional(),
    payload: toi.optional(),
}));
export const validateFindings = toi.required().and(toi.array.is());
export function isLegacyFindingContext(context) {
    return "coraState" in context;
}
export function restoreFindingContext(context) {
    return __awaiter(this, void 0, void 0, function* () {
        yield restoreQuery(isLegacyFindingContext(context) ? context.coraState : context.historyEntry);
    });
}
export function getContextQueryParts(context) {
    return isLegacyFindingContext(context)
        ? coraStateToQueryParts(context.coraState)
        : context.historyEntry.queryParts;
}
export function getContextCorpusIds(context) {
    return isLegacyFindingContext(context)
        ? context.coraState.corpus_ids
        : context.historyEntry.corpus_ids;
}
export function getContextText(context) {
    return isLegacyFindingContext(context)
        ? context.coraState.text
        : context.historyEntry.text;
}
export function getContextCorpusFilter(context) {
    return __awaiter(this, void 0, void 0, function* () {
        if (isLegacyFindingContext(context))
            return context.corpus_filter;
        const queryState = yield loadQueryState(context.historyEntry.queryId);
        const coraState = queryStateToCoraState(queryState);
        return isEmptyQuery(coraState) ? undefined : getCorpusFilter(coraState);
    });
}
