var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { every, keyBy, mapValues, partition, pick, take, uniq } from "lodash";
import { CRCDirection, } from "../api/types";
import { pickFromObject, removeItems, union, unionUsingKey, upsertArray, } from "../../utils/collections";
import { API } from "../api/api";
import { useMemoIfEqual } from "../../utils/hooks";
import { invalidCorpusIds, useValidatedCorpusIds } from "./sourceManagement";
import { wordMatches } from "../../utils/filters";
import { EMPTY_QUERY_PARAMS } from "./coraState";
import { getSize } from "../../utils/util";
function cleanupMetadata(...values) {
    return values
        .filter(v => v)
        .flat()
        .map(m => ({
        id: m.id,
        value: m.value,
    }));
}
function addArgument(argument_name) {
    return c => (Object.assign(Object.assign({}, c), { argument_name }));
}
function addOptionalArgument(argument_name) {
    return !(argument_name === null || argument_name === void 0 ? void 0 : argument_name.length) ? c => c : addArgument(argument_name);
}
export function itemsOrOverrides(items, overrides = [], mergeOverrides = false) {
    const overridesArr = Array.isArray(overrides)
        ? overrides
        : Object.values(overrides).flat();
    return overridesArr.length
        ? mergeOverrides
            ? mergeNamedMembers(overridesArr)
            : overridesArr
        : items;
}
function indexConcepts(concepts, overridesByConceptName) {
    const allConceptNames = uniq(concepts.map(c => c.name).concat(Object.keys(overridesByConceptName)));
    const conceptsByName = keyBy(concepts, "name");
    return { allConceptNames, conceptsByName };
}
export function queryToCrc(query) {
    var _a, _b, _c, _d;
    const firstArgNane = (_b = (_a = query.concept_filter) === null || _a === void 0 ? void 0 : _a.filter[0]) === null || _b === void 0 ? void 0 : _b.argument_name;
    const crcDirection = firstArgNane === "subject"
        ? CRCDirection.C1C2
        : firstArgNane === "object"
            ? CRCDirection.C2C1
            : CRCDirection.BOTH;
    const concepts = ((_c = query.concept_filter) === null || _c === void 0 ? void 0 : _c.filter.map(i => ({
        name: i.name,
        members: i.members,
    }))) || [];
    const concepts1 = concepts.length == 2 || crcDirection !== CRCDirection.BOTH
        ? [concepts[0]]
        : [];
    const concepts2 = concepts.length == 1 && crcDirection === CRCDirection.BOTH
        ? [concepts[0]]
        : concepts.length == 2
            ? [concepts[1]]
            : [];
    return {
        concepts1,
        concepts2,
        relations: ((_d = query.relation_filter) === null || _d === void 0 ? void 0 : _d.filter) || [],
        crcDirection,
    };
}
function makeRequired(c, is_required = true) {
    return is_required ? Object.assign(Object.assign({}, c), { is_required }) : c;
}
export function getQuery(params) {
    const { relations, concepts1, concepts2, concepts1Override, concepts2Override, crcDirection, refuting_relations, refuting_concepts, } = Object.assign(Object.assign({}, EMPTY_QUERY_PARAMS), params);
    const conceptFilter = conceptArgs();
    const relationFilter = relationArgs();
    return conceptFilter || relationFilter
        ? Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, getTextQueryParam(params)), conceptFilter), relationFilter), (refuting_relations && {
            refuting_relations: mergeRelationClusters(refuting_relations),
        })), (refuting_concepts && {
            refuting_concepts: mergeConceptClusters(refuting_concepts),
        })) : Object.assign({}, getTextQueryParam(params));
    function conceptArgs() {
        //todo: remove this hack once flag has been tested
        const filter = [
            ...getConcepts(concepts1, concepts1Override, true, concept1argumentName(crcDirection)),
            ...getConcepts(concepts2, concepts2Override, true, concept2argumentName(crcDirection)),
        ];
        return (filter.length && {
            concept_filter: {
                filter,
            },
        });
    }
    function relationArgs() {
        return (!!(relations === null || relations === void 0 ? void 0 : relations.length) && {
            relation_filter: {
                filter: mergeRelationClusters(relations),
            },
        });
    }
}
function getConcepts(concepts, overrides, useAnd, argName) {
    concepts = toConceptOrCustom(concepts);
    overrides = mapValues(overrides, toConceptOrCustom);
    return cleanupConcepts(groupByArgName(splitByArgName(concepts, overrides, argName || ""), useAnd));
    function groupByArgName(splitByArgName, useAnd) {
        return Object.entries(splitByArgName)
            .map(([argName, { concepts, overrides }]) => {
            const grouped = useAnd
                ? applyOverrides(concepts, overrides)
                : itemsOrOverrides(concepts, overrides, true);
            return grouped
                .map(addOptionalArgument(argName))
                .map(c => makeRequired(c, !useAnd && getSize(overrides) > 0));
        })
            .flat();
    }
    function splitByArgName(concepts, overridesByConceptName = {}, defaultArgName = "") {
        const { allConceptNames, conceptsByName } = indexConcepts(concepts, overridesByConceptName);
        return allConceptNames.reduce((acc, name) => {
            var _a;
            const argName = ((_a = conceptsByName[name]) === null || _a === void 0 ? void 0 : _a.argument_name) || defaultArgName;
            const current = acc[argName] || { concepts: [], overrides: {} };
            if (conceptsByName[name])
                current.concepts.push(conceptsByName[name]);
            if (name === "0") {
                console.log(">>>> SPLIT", name, overridesByConceptName, current);
            }
            if (overridesByConceptName[name])
                current.overrides[name] = [
                    ...(current.overrides[name] || []),
                    ...overridesByConceptName[name],
                ];
            acc[argName] = current;
            return acc;
        }, {});
    }
}
function getTextQueryParam({ text = "", concepts1Override: c1o = {}, concepts2Override: c2o = {}, contextConceptsOverride: ctxO = {}, extraConcepts: ex = [], extraConceptsOverride: exO = {}, }) {
    text = text.trim();
    return !text.length ||
        getSize(c1o) ||
        getSize(c2o) ||
        getSize(ctxO) ||
        getSize(ex) ||
        getSize(exO)
        ? undefined
        : { text: text };
}
export function getMetadataFilter({ keywordMetadata, rangeMetadata, booleanMetadata, rangeMetadataOverride, }) {
    const cleaned = cleanupMetadata(keywordMetadata, rangeMetadata, booleanMetadata);
    const cleanedOverride = cleanupMetadata(rangeMetadataOverride);
    const { result } = upsertArray(cleaned, cleanedOverride, m => m.id);
    return result.length ? { metadata: result } : undefined;
}
function cleanupConcepts(concepts) {
    return concepts.map((_a) => {
        var { isCustom } = _a, c = __rest(_a, ["isCustom"]);
        return c;
    });
}
export function isEmptyQuery({ concepts1, concepts2, relations, contextConcepts, text, }) {
    return (!(contextConcepts === null || contextConcepts === void 0 ? void 0 : contextConcepts.length) &&
        !(concepts1 === null || concepts1 === void 0 ? void 0 : concepts1.length) &&
        !(concepts2 === null || concepts2 === void 0 ? void 0 : concepts2.length) &&
        !(relations === null || relations === void 0 ? void 0 : relations.length) &&
        !(text === null || text === void 0 ? void 0 : text.length));
}
export function getCorpusFilter(cfp) {
    const { corpus_ids, extraConcepts = [], extraConceptsOverride = {}, contextConcepts = [], contextConceptsOverride = {}, required_arguments, keywordMetadata, booleanMetadata, rangeMetadata, rangeMetadataOverride, queries: otherQueries = [], argClauses = {}, aperture } = cfp, params = __rest(cfp, ["corpus_ids", "extraConcepts", "extraConceptsOverride", "contextConcepts", "contextConceptsOverride", "required_arguments", "keywordMetadata", "booleanMetadata", "rangeMetadata", "rangeMetadataOverride", "queries", "argClauses", "aperture"]);
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, getCommon()), getQueries()), getContext()), requiredArgs()), clauses());
    function getCommon() {
        return Object.assign(Object.assign({ corpus_ids }, getMetadataFilter(pickFromObject(cfp, "keywordMetadata", "rangeMetadata", "booleanMetadata", "rangeMetadataOverride"))), (aperture !== undefined && { aperture }));
    }
    function concatContextAndExtra() {
        return concatItemsAndOverrides([
            // if extra concepts are present they are required
            toConceptOrCustom(extraConcepts).map(c => makeRequired(c)),
            mergeNamedMembers(toConceptOrCustom(contextConcepts), mapValues(contextConceptsOverride, arr => arr.map(c => makeRequired(toConceptOrCustom(c))))),
        ], [
            mapValues(extraConceptsOverride, arr => arr.map(c => makeRequired(toConceptOrCustom(c)))),
        ]);
    }
    function getContext() {
        const context = [
            ...getConcepts(contextConcepts, contextConceptsOverride, true),
            ...getConcepts(extraConcepts, extraConceptsOverride, true),
        ];
        return (context === null || context === void 0 ? void 0 : context.length) ? { context } : undefined;
    }
    function requiredArgs() {
        return (required_arguments === null || required_arguments === void 0 ? void 0 : required_arguments.length)
            ? {
                required_arguments_filter: {
                    filter: required_arguments,
                },
            }
            : {};
    }
    function clauses() {
        const filter = Object.entries(argClauses)
            .map(([argument_name, clauses]) => mergeNamedMembers(clauses).map(addArgument(argument_name)))
            .flat();
        return filter.length ? { argument_clause_filter: { filter } } : {};
    }
    function getQueries() {
        const query = getQuery(cfp);
        const queries = [
            ...(query ? [query] : []),
            ...otherQueries.map(applyOverridesToQuery).filter(Boolean),
        ];
        return queries.length ? { queries } : {};
        function applyOverridesToQuery(query) {
            // if there are no overrides or there are overrides and the query
            // has concepts for the overrides, apply overrides otherwise skip query
            function hasOverrideKeys(concepts, overrides = {}) {
                const conceptsByName = keyBy(concepts, "name");
                const overrideKeys = Object.keys(overrides);
                return every(overrideKeys, k => conceptsByName[k]);
            }
            const crc = queryToCrc(query);
            const { concepts1, concepts2 } = crc;
            const { concepts1Override, concepts2Override } = params;
            return hasOverrideKeys(concepts1, concepts1Override) &&
                hasOverrideKeys(concepts2, concepts2Override)
                ? getQuery(Object.assign(Object.assign({}, cfp), crc))
                : null;
        }
    }
}
export function applyOverrides(concepts, overrides) {
    return concepts.map(c => overrides[c.name] ? mergeNamedMembers(overrides[c.name], {}, true)[0] : c);
}
export function concatItemsAndOverrides(items, overrides) {
    return [
        items.flat(),
        Object.fromEntries(overrides.map(o => Object.entries(o)).flat()),
    ];
}
export function buildCorpusFilter(corpusFilterParams, corpus_ids, ...keys) {
    return getCorpusFilter(Object.assign({ corpus_ids }, pickFromObject(corpusFilterParams, ...keys)));
}
export function concept1argumentName(dir) {
    return dir === CRCDirection.C1C2
        ? "subject"
        : dir === CRCDirection.C2C1
            ? "object"
            : undefined;
}
export function concept2argumentName(dir) {
    return dir === CRCDirection.C2C1
        ? "subject"
        : dir === CRCDirection.C1C2
            ? "object"
            : undefined;
}
export function useCorpusFilters(coraState) {
    const corpus_ids = useValidatedCorpusIds(coraState.corpus_ids);
    const keys = [
        "crcDirection",
        "concepts1",
        "concepts2",
        "relations",
    ];
    return {
        context: buildCorpusFilter(coraState, corpus_ids),
        concepts1: buildCorpusFilter(coraState, corpus_ids, ...removeItems(keys, "concepts1")),
        concepts2: buildCorpusFilter(coraState, corpus_ids, ...removeItems(keys, "concepts2")),
        relations: buildCorpusFilter(coraState, corpus_ids, ...removeItems(keys, "relations")),
    };
}
export function getConceptIds(concept) {
    return concept.members.map(m => m.id);
}
export function getNamedName(named, collapseName = true) {
    return collapseName
        ? named[0].name
        : named.length < 2
            ? named.map(c => c.name).join("/")
            : `${named[0].name}…`;
}
export function concatAllNames(named) {
    return named
        .map(c => c.name)
        .sort()
        .join("/");
}
export function mergeRelationClusters(relations, collapseName = true) {
    return !relations.length
        ? []
        : [
            {
                name: getNamedName(relations),
                ids: union(...relations.map(r => r.ids)),
            },
        ];
}
export function createRelation(id) {
    return { name: id, ids: [id] };
}
export function createClause(name) {
    return {
        name,
        members: [
            {
                id: name,
                surface_forms: [name],
            },
        ],
    };
}
export function splitMergedCluster(relations) {
    return relations.length === 1 && relations[0].ids.length > 1
        ? relations[0].ids.map(createRelation)
        : relations;
}
export function mergeConceptClusters(concepts) {
    if (!concepts.length)
        return [];
    const conceptsByConcept = {};
    concepts.forEach(conceptTuple => {
        const key = conceptTuple.concept_cluster.name;
        if (!conceptsByConcept[key]) {
            conceptsByConcept[key] = {
                concept_cluster: conceptTuple.concept_cluster,
                refuting_concept_clusters: [conceptTuple.refuting_concept_cluster],
            };
        }
        else {
            conceptsByConcept[key].refuting_concept_clusters.push(conceptTuple.refuting_concept_cluster);
        }
    });
    return Object.values(conceptsByConcept);
}
export function mergeNamedMembers(items, override = {}, addRequired = false) {
    const actualItems = itemsOrOverrides(items, override);
    const required = addRequired ||
        getSize(override) > 0 ||
        actualItems.some(c => c.is_required);
    return !actualItems.length
        ? []
        : [
            Object.assign({ name: getNamedName(actualItems), members: unionUsingKey(c => c.id, // TODO HACK
                ...actualItems.map(i => i.members || [])) }, (required && { is_required: true })),
        ];
}
export function toConceptOrCustom(items) {
    if (!Array.isArray(items))
        return pick(items, ["name", "members", "isCustom"]);
    return items.map(toConceptOrCustom);
}
export function toRelationCluster({ name, ids, }) {
    return {
        name,
        ids,
    };
}
export function getSubconceptsForClusterParams({ cluster, prefix, params, check_if_concepts_have_children, }) {
    return Object.assign(Object.assign(Object.assign({}, params), ((prefix === null || prefix === void 0 ? void 0 : prefix.length) && { prefix })), { check_if_concepts_have_children, instance_of: uniq(getConceptIds(cluster).filter(x => x !== undefined)) });
}
function customConceptListToConceptCluster(customConceptList) {
    const merged = mergeNamedMembers([customConceptList])[0];
    return Object.assign({}, merged);
}
export function getSearchConceptsMembers({ customConceptList, corpus_filter, prefix, count, argument_name, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const concept_cluster = customConceptListToConceptCluster(customConceptList);
        const concepts = yield API.searchConceptsMembers({
            corpus_filter,
            concept_cluster,
            argument_name,
            count: 100,
        });
        const filter = prefix && wordMatches(prefix, false);
        return take(!filter ? concepts : concepts.filter(c => filter(c.name)), count);
    });
}
export function getSubconceptsForAllClusters(entries) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield Promise.all(entries.map(({ cluster, params }) => !params
            ? null
            : API.searchConcepts(getSubconceptsForClusterParams({ cluster, params }))));
    });
}
export function getSpecificConceptsParam(specificConcepts) {
    return specificConcepts && { kinds: ["entity"] };
}
export const default_count = 10;
const expansion_count = 15;
export function getRelationsExpansionParams({ relations, concepts1, concepts2, corpus_ids, aperture, }) {
    return {
        corpus_ids,
        cluster: mergeRelationClusters(relations)[0],
        context_concepts: mergeNamedMembers(concepts1).concat(mergeNamedMembers(concepts2)),
        count: expansion_count,
        aperture,
    };
}
export function getConceptsExpansionParams({ concepts, corpus_ids, similarity_mode, aperture, }) {
    return {
        corpus_ids,
        cluster: mergeNamedMembers(concepts)[0],
        similarity_mode,
        count: expansion_count,
        aperture,
    };
}
export function nothingSelected({ concepts1 = [], concepts2 = [], relations = [], contextConcepts = [], extraConcepts = [], }) {
    return !(concepts1.length +
        concepts2.length +
        relations.length +
        contextConcepts.length +
        extraConcepts.length);
}
export function getStateNlg(coraState) {
    const { concepts1, concepts2Override, concepts2, concepts1Override, relations, crcDirection, } = coraState;
    const dir = crcDirection === CRCDirection.BOTH ? "--" : "->";
    const r = mergeRelationClusters(relations);
    let c1 = mergeNamedMembers(concepts1, concepts1Override);
    let c2 = mergeNamedMembers(concepts2, concepts2Override);
    if (crcDirection === CRCDirection.C2C1) {
        const tmp = c1;
        c1 = c2;
        c2 = tmp;
    }
    const has1 = c1.length > 0;
    const has2 = c2.length > 0;
    const hasR = r.length > 0;
    const n1 = has1 ? getNamedName(c1) : "?";
    const n2 = has2 ? getNamedName(c2) : "?";
    const nr = hasR ? getNamedName(r) : "?";
    return hasR
        ? `${n1} ${dir} ${nr} ${dir} ${n2}`
        : has1 && has2
            ? `${n1}, ${n2}`
            : has1
                ? n1
                : has2
                    ? n2
                    : "any";
}
export function useTrendsCorpusFilter(corpusFilterParams, field) {
    const { corpus_ids, rangeMetadataOverride = [] } = corpusFilterParams;
    const disabled = invalidCorpusIds(corpusFilterParams.corpus_ids);
    const noSelection = nothingSelected(corpusFilterParams);
    return useMemoIfEqual(!field || disabled || noSelection
        ? undefined
        : getCorpusFilter(Object.assign(Object.assign({ corpus_ids }, pickFromObject(corpusFilterParams, "concepts1", "concepts2Override", "concepts2", "concepts1Override", "relations", "crcDirection", "required_arguments", "argClauses", "keywordMetadata", "rangeMetadata", "refuting_relations", "refuting_concepts", "booleanMetadata", "queries", "extraConcepts", "extraConceptsOverride", "contextConcepts", "contextConceptsOverride", "aperture")), { rangeMetadataOverride: rangeMetadataOverride.filter(m => m.id !== field) })));
}
export const MIN_TEXT_FILTER_LEN = 3;
export function validateTextFilter(value, minLen = MIN_TEXT_FILTER_LEN) {
    value = value.trim();
    return value.length < minLen ? "" : value;
}
export function getCurrentGranularity(trendsGranularities, selectedGranularity) {
    return trendsGranularities.indexOf(selectedGranularity || "") >= 0
        ? selectedGranularity
        : trendsGranularities[0];
}
export const TOP_RESULTS_KEY = "Top Results";
export function splitIntoConceptsAndCustomLists(items) {
    return partition(items, c => !c.isCustom);
}
export function corpusFilterToQueryParams(corpus_filter) {
    const { context, queries } = corpus_filter;
    const { relation_filter, concept_filter } = (queries === null || queries === void 0 ? void 0 : queries[0]) || {};
    const filter = concept_filter === null || concept_filter === void 0 ? void 0 : concept_filter.filter;
    const noArgNames = !!(filter === null || filter === void 0 ? void 0 : filter.some(c => !c.argument_name));
    const [concepts1, concepts2] = !filter
        ? [[], []]
        : noArgNames
            ? [[filter[0]], filter.length < 1 ? [] : [filter[1]]]
            : partition(concept_filter === null || concept_filter === void 0 ? void 0 : concept_filter.filter, (a) => a.argument_name === "subject");
    const selectedRelations = (relation_filter === null || relation_filter === void 0 ? void 0 : relation_filter.filter.map(toRelationCluster)) || [];
    return Object.assign(Object.assign({}, (!context
        ? { extraConcepts: [], extraConceptsOverride: {} }
        : {
            extraConcepts: context,
            extraConceptsOverride: Object.fromEntries(context.map(c => [c.name, [c]])),
        })), { relations: selectedRelations, concepts1,
        concepts2, crcDirection: noArgNames || !selectedRelations.length
            ? CRCDirection.BOTH
            : CRCDirection.C1C2 });
}
export function genClustersId(values) {
    return values
        .map(c => c.name)
        .sort()
        .join("/");
}
