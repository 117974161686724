import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AutoComplete, Input } from "antd";
import React from "react";
import { getTwColor } from "../lf";
import { Close } from "../Icons";
import { isValidQuestion } from "./queryAnalysis";
import { useQuestionHistory } from "./useQuestionHistory";
import { BorderlessButton } from "../../redesign/generic/BorderlessButton";
import { useSuggestedQuestions } from "../../../shared/components/SuggestedQuestions";
import { Tooltip } from "../../../shared/components/Tooltip";
import { ClockCircleOutlined } from "@ant-design/icons";
import { partition, take, uniq } from "lodash";
import "./QuestionInput.less";
import { classes } from "../../../utils/classes";
import { AnnotatedString, findSubstrings, } from "../../../shared/components/AnnotatedString";
import { useNLQuestion } from "./useNLQuestion";
import { useCoraState } from "../CoraStateProvider";
import colors from "../../../assets/colors";
export function QuestionInput({ analyze, }) {
    const { questionText, setQuestionText } = useNLQuestion();
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const inputRef = React.useRef(null);
    const { history, remove: removeQuestionFromHistory, clear: clearHistory, } = useQuestionHistory();
    const [coraState] = useCoraState();
    const { backgroundKnowledge, suggestedQuestionsBeacon } = useSuggestedQuestions(coraState);
    function renderFilteredQuestion(question, filterString, options = {}) {
        var _a;
        const filterSpans = filterString.length
            ? findSubstrings(question, filterString)
            : [];
        const highlightSpans = ((_a = options.highlightString) === null || _a === void 0 ? void 0 : _a.length)
            ? findSubstrings(question, options.highlightString)
            : [];
        const styles = [
            ...filterSpans.map(span => ({ span, annotation: "font-bold" })),
            ...highlightSpans.map(span => ({
                span,
                annotation: options.highlightClassName || "text-accent-500",
            })),
        ];
        return (_jsx(AnnotatedString, { text: question, annotations: styles, renderAnnotation: ({ text, annotations }) => {
                return _jsx("span", Object.assign({ className: classes(...uniq(annotations)) }, { children: text }));
            } }));
    }
    const questionHistory = uniq(partition(history, q => q.toLowerCase().includes(questionText.toLowerCase())).flat());
    const options = [];
    if (questionHistory.length > 0) {
        options.push({
            label: (_jsxs("div", Object.assign({ className: "flex justify-between text-neutral-700" }, { children: ["Question History", _jsx(BorderlessButton, Object.assign({ className: "text-accent-500", onClick: clearHistory }, { children: "Clear" }))] }))),
            options: take(questionHistory, 5).map(q => {
                return {
                    value: q,
                    label: (_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(ClockCircleOutlined, { className: "text-neutral-500 mr-1" }), renderFilteredQuestion(q, questionText)] })), _jsx(BorderlessButton, Object.assign({ onClick: e => {
                                    e.stopPropagation();
                                    removeQuestionFromHistory(q);
                                } }, { children: _jsx(Close, { className: "text-neutral-500" }) }))] }))),
                };
            }),
        });
    }
    if (backgroundKnowledge && suggestedQuestionsBeacon.questionCount > 0) {
        let suggestionOptions = [];
        Object.keys(suggestedQuestionsBeacon.questionGroups).forEach(name => {
            const suggestedQuestions = suggestedQuestionsBeacon.questionGroups[name];
            const nameOptions = partition(suggestedQuestions.filter(sq => !questionHistory.includes(sq.question)), // don't show questions that are already in history
            // don't show questions that are already in history
            sq => sq.question.toLowerCase().includes(questionText.toLowerCase()))
                .flat()
                .map(sq => {
                return {
                    value: sq.question,
                    label: (_jsx(Tooltip, Object.assign({ content: `Reason: ${sq.explanation}` }, { children: _jsx("span", { children: renderFilteredQuestion(sq.question, questionText, {
                                highlightString: name,
                                highlightClassName: "text-accent-500",
                            }) }) }))),
                };
            });
            suggestionOptions = [...suggestionOptions, ...nameOptions];
        });
        if (suggestionOptions.length > 0) {
            options.push({
                label: _jsx("span", Object.assign({ className: "text-neutral-700" }, { children: "Suggested Questions" })),
                options: take(suggestionOptions, 5),
            });
        }
    }
    return (_jsx("div", Object.assign({ className: "flex-1 flex space-x-2 items-center", style: { maxWidth: "860px", backgroundColor: colors.accent[1000] } }, { children: _jsxs("fieldset", Object.assign({ className: "flex flex-1 self-stretch" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex flex-row space-x-4" }, { children: _jsx(AutoComplete, Object.assign({ id: "QuestionInput", popupClassName: "QuestionInputDropdown bg-neutral-100", popupMatchSelectWidth: 500, style: { width: "100%" }, options: options, bordered: false, onChange: (value) => {
                            setQuestionText(value);
                        }, onSelect: (value) => {
                            inputRef.current.blur();
                            analyze(value);
                        }, onFocus: () => {
                            setDropdownOpen(true);
                        }, onBlur: () => {
                            setDropdownOpen(false);
                        }, listHeight: 430, value: questionText, open: dropdownOpen }, { children: _jsx(Input, { ref: inputRef, className: "flex-1", style: { backgroundColor: "transparent" }, placeholder: "Ask Cora a question\u2026", bordered: false, onChange: (e) => setQuestionText(e.target.value), value: questionText, onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                    inputRef.current.blur();
                                    analyze(questionText);
                                }
                                else if (!dropdownOpen) {
                                    setDropdownOpen(true);
                                }
                            }, onClick: () => {
                                if (!dropdownOpen) {
                                    setDropdownOpen(true);
                                }
                            } }) })) })), _jsxs("div", Object.assign({ className: "flex flex-row space-x-2 items-center" }, { children: [Boolean(questionText) && (_jsx(BorderlessButton, Object.assign({ onClick: () => {
                                setQuestionText("");
                                inputRef.current.focus({
                                    cursor: "all",
                                });
                            } }, { children: _jsx(Close, { className: "text-sm text-neutral-500" }) }))), _jsx(BorderlessButton, Object.assign({ className: "bg-accent-500 text-white px-1 font-bold text-md", disabled: !isValidQuestion(questionText), onClick: () => analyze(questionText), style: Object.assign({ height: 30 }, (isValidQuestion(questionText)
                                ? undefined
                                : {
                                    backgroundColor: "transparent",
                                    borderColor: getTwColor("gray-600"),
                                    color: "gray",
                                })) }, { children: "Search" }))] }))] })) })));
}
