import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Tag } from "antd";
import { classes } from "../../utils/classes";
import "./TagsList.less";
import { useIsOverflow } from "../../utils/hooks";
import { Tooltip } from "./Tooltip";
const hasMoreClass = "HasMoreButton";
export function HorizontalOverflow({ className, children, forceShowMore, onShowMoreClick, showMoreCaption, moreButtonCStyle, widthClass, setTagWidth = true, tooltipContent, }) {
    const [div, setDiv] = React.useState(null);
    const overflow = useIsOverflow(div, false) || false;
    return (_jsxs("div", Object.assign({ className: classes("HorizontalOverflowContainer relative flex", widthClass) }, { children: [_jsx("div", Object.assign({ className: classes("relative", className, setTagWidth && "w-[99%]"), style: { overflow: "hidden" }, ref: setDiv }, { children: children })), (overflow || forceShowMore) && (_jsx(Tooltip, Object.assign({ content: (tooltipContent === null || tooltipContent === void 0 ? void 0 : tooltipContent()) || children, placement: "auto", interactive: true, appendTo: () => document.body }, { children: _jsx(Tag, Object.assign({ className: classes(hasMoreClass, "text-black flex items-center", !overflow && "self-center", overflow && "cursor-pointer my-auto", forceShowMore && "cursor-pointer"), style: moreButtonCStyle ||
                        (overflow
                            ? {
                                right: 0,
                                top: 0,
                                height: "100%",
                                position: "absolute",
                                paddingTop: 1,
                                paddingBottom: 2,
                                marginRight: 0,
                            }
                            : {
                                right: 0,
                                top: 0,
                                height: 24,
                                zIndex: 3,
                                position: "sticky",
                                paddingTop: 2,
                                paddingBottom: 2,
                                marginRight: 0,
                            }), onMouseDownCapture: onShowMoreClick &&
                        (e => {
                            e.preventDefault();
                            e.stopPropagation();
                            onShowMoreClick === null || onShowMoreClick === void 0 ? void 0 : onShowMoreClick(e, div);
                        }) }, { children: forceShowMore
                        ? showMoreCaption === null || showMoreCaption === void 0 ? void 0 : showMoreCaption(overflow, div)
                        : showMoreCaption === null || showMoreCaption === void 0 ? void 0 : showMoreCaption(true, div) })) })))] })));
}
export function eventTargetIsHasMoreButton(e) {
    return e.target.classList.contains(hasMoreClass);
}
