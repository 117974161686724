var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEqual, keyBy, omit, pick, size } from "lodash";
import { getValue, identity } from "./util";
export function toPairs(array) {
    return array.reduce((acc, v, i, a) => {
        if (i < a.length - 1) {
            acc.push([a[i], a[i + 1]]);
        }
        return acc;
    }, []);
}
export function merge(value, updateOrFn, equals = false) {
    const update = getValue(value, updateOrFn);
    const acc = Object.assign({}, value);
    for (const k in update) {
        const v = update[k];
        if (v === undefined) {
            delete acc[k];
        }
        else {
            acc[k] = v;
        }
    }
    return equals && isEqual(acc, value) ? value : acc;
}
export function mergeObjects(...items) {
    return items.reduce((acc, item) => {
        return item ? Object.assign(Object.assign({}, acc), item) : acc;
    }, {});
}
export function toSet(...items) {
    const set = new Set();
    for (const iter of items) {
        for (const i of iter) {
            set.add(i);
        }
    }
    return set;
}
export function difference(a, ...arrays) {
    const set = toSet(...arrays);
    return a.filter(i => !set.has(i));
}
export function union(...arrays) {
    return unionUsingKey(identity, ...arrays);
}
export function unionUsingKey(keyGetter, ...arrays) {
    const result = [];
    const exists = new Set();
    arrays.forEach(arr => arr.forEach(i => {
        const key = keyGetter(i);
        if (!exists.has(key)) {
            result.push(i);
            exists.add(key);
        }
    }));
    return result;
}
export function intersection(a, b) {
    const set = toSet(b);
    return a.filter(i => set.has(i));
}
export function arrDelta(newValue, oldValue, keyGetter = a => a) {
    const oldSet = toSet(oldValue.map(keyGetter));
    const newSet = toSet(newValue.map(keyGetter));
    return {
        added: newValue.filter(i => !oldSet.has(keyGetter(i))),
        removed: oldValue.filter(i => !newSet.has(keyGetter(i))),
        both: newValue.filter(i => oldSet.has(keyGetter(i))),
    };
}
export function applyChanges(original, toAdd, toRemove) {
    return union(difference(original, toRemove), toAdd);
}
export function intercalate(arr, toInsert) {
    return arr
        .map((item, idx) => !idx
        ? [item]
        : [
            typeof toInsert === "function"
                ? toInsert(idx - 1)
                : toInsert,
            item,
        ])
        .flat();
}
export function applyUpdate(items, mode, ...update) {
    return mode === "TOGGLE"
        ? union(difference(update, items), difference(items, update))
        : mode === "ADD"
            ? union(update, items)
            : mode === "REMOVE"
                ? difference(items, update)
                : update;
}
export function toggle(items, item) {
    return items.findIndex(i => isEqual(i, item)) >= 0
        ? items.filter(i => !isEqual(i, item))
        : items.concat([item]);
}
export function selectSingle(items, item) {
    return items.findIndex(i => isEqual(i, item)) >= 0
        ? items.filter(i => !isEqual(i, item))
        : (items = [item]);
}
export function removeItems(items, ...toRemove) {
    const set = toRemove.length ? new Set(toRemove) : undefined;
    return set ? items.filter(i => !set.has(i)) : items;
}
export function upsertArray(arr, upsert, keyGetter) {
    const byId = keyBy(upsert, keyGetter);
    const ids = new Set(arr.map(keyGetter));
    const replaced = arr.filter(f => byId[keyGetter(f)]);
    const added = upsert.filter(f => !ids.has(keyGetter(f)));
    const result = arr.map(f => byId[keyGetter(f)] || f).concat(added);
    return { result, added, replaced };
}
export function upsertDel(arr, upsert, keyGetter, keysToRemove = []) {
    const byId = keyBy(upsert, keyGetter);
    const ids = new Set(arr.map(keyGetter));
    const replaced = arr.filter(i => byId[keyGetter(i)]);
    const added = upsert.filter(i => !ids.has(keyGetter(i)));
    const toRemove = new Set(keysToRemove);
    const result = arr
        .filter(i => !toRemove.has(keyGetter(i)))
        .map(i => byId[keyGetter(i)] || i)
        .concat(added);
    return { result, added, replaced, removed: toRemove };
}
export function upsertOrRemove(items, idGetter, id, item) {
    return items.filter(i => id !== idGetter(i)).concat(item ? [item] : []);
}
export function binarySearch(list, key, comparator) {
    let low = 0;
    let high = list.length - 1;
    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const midVal = list[mid];
        const cmp = comparator(midVal, key);
        if (cmp < 0)
            low = mid + 1;
        else if (cmp > 0)
            high = mid - 1;
        else
            return mid; // key found
    }
    return -(low + 1); // key not found
}
export function updateArrayDictionary(dic, key, items) {
    const rest = omit(dic, key);
    return !(items === null || items === void 0 ? void 0 : items.length) ? rest : Object.assign(Object.assign({}, rest), { [key]: items });
}
export function toggleArrayDictionary(dic, key, item) {
    return updateArrayDictionary(dic, key, toggle(dic[key] || [], item));
}
export function mergeDictionaries(itemMerger, ...dics) {
    return dics.reduce((acc, dic) => Object.entries(dic).reduce((acc, [key, item]) => {
        acc[key] = !acc[key] ? item : itemMerger(acc[key], item);
        return acc;
    }, acc), {});
}
export function pickFromObject(value, ...keys) {
    return pick(value, keys);
}
export function notNully(values) {
    return values.filter(Boolean);
}
export function filterDic(dic, filter) {
    return Object.fromEntries(Object.entries(dic).filter(([k, v]) => filter(k, v)));
}
export function toggleFlag(flags, key) {
    const _a = flags, _b = key, flag = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    return flag ? rest : Object.assign(Object.assign({}, rest), { [key]: true });
}
export function getDiffKeys(original, latest) {
    const diff = [];
    Object.keys(latest).forEach(k => {
        if (!isEqual(latest[k], original[k]))
            diff.push(k);
    });
    return diff;
}
export function sameItems(a, b) {
    if (a.length != b.length)
        return false;
    const set = new Set(a);
    return !b.some(i => !set.has(i));
}
export function updateItems(items, update) {
    return items.map(item => {
        const up = typeof update === "function" ? update(item) : update;
        return up && size(up) ? Object.assign(Object.assign({}, item), up) : item;
    });
}
export function extract(items, key) {
    return items.map(item => item[key]);
}
