import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Banner } from "../../shared/components/Banner";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import React from "react";
import { ButtonWrapper } from "../../shared/components/buttons/Buttons";
import { classes } from "../../utils/classes";
import { limitLength } from "../../utils/util";
import { ShareFromSquare } from "./Icons";
import { TagsList } from "../../shared/components/TagsList";
import { filterMetadata, metadataValue } from "../utils/metatada";
import { hasHighlightsOnlyOutsideOfParse, highlightsOnlyOutsideOfParse, highlightWordsOutsideOfParse, uniqueHighlightsOnlyOutsideOfParse, } from "../utils/coraUtil";
import { CONCEPT_COLOR_BORDER, CONCEPT_COLOR_BG } from "./lf";
import Tippy from "@tippyjs/react";
import { getCaption, getId, getUrl, openUrl } from "../utils/url";
import { Truncated } from "../../shared/components/Truncated";
const MAX_TITLE_LEN = 100;
export function EvidenceFooter({ evidence, onMetadataClick, showHighlights, urlOpener, suffix, className, selected, metadataSorter, }) {
    var _a;
    const metadata = metadataSorter(((_a = evidence.instances[0]) === null || _a === void 0 ? void 0 : _a.metadata) || []);
    const hiddenHighlights = highlightsOnlyOutsideOfParse(evidence);
    const uniqueMatchIdHighlights = uniqueHighlightsOnlyOutsideOfParse(evidence);
    const items = filterMetadata(metadata, true);
    return (_jsxs("div", Object.assign({ className: classes("pt-1", className) }, { children: [showHighlights && hasHighlightsOnlyOutsideOfParse(evidence) && (_jsx("div", Object.assign({ className: classes("flex flex-1 space-x-2 border-b justify-start", "pb-3 mb-2", "border-b border-neutral-200") }, { children: _jsx(TagsList, { className: "w-fit py-0.5 px-1 font-medium", widthClass: "w-[calc(100%-8em)]", items: uniqueMatchIdHighlights, tagContent: item => (_jsxs("div", Object.assign({ className: "flex align-center" }, { children: [_jsx(ShareFromSquare, { className: "mt-1 mr-1" }), item.match_name] }))), tagStyle: () => ({
                        backgroundColor: CONCEPT_COLOR_BG,
                        borderColor: CONCEPT_COLOR_BORDER,
                    }), tagTooltip: item => "View in Document", onMouseDown: item => urlOpener === null || urlOpener === void 0 ? void 0 : urlOpener(evidence, evidence.instances[0], [
                        evidence.text,
                        ...hiddenHighlights.map(x => x.match_name),
                        item.match_name,
                    ]) }) }))), _jsxs("div", Object.assign({ className: "flex flex-row flex-1 justify-between w-full py-1" }, { children: [_jsx(TagsList, { className: "ml-0 py-0.5 px-1 font-medium", widthClass: "w-[calc(100%-8em)]", items: items, tagContent: item => {
                            const value = metadataValue(item, true);
                            if (value === undefined)
                                return "";
                            return typeof value === "boolean" ? (value ? (item.name) : ("Not " + item.name // this shoulnd't becuase they should get filtered
                            )) : (_jsx(Tippy, Object.assign({ content: _jsx(MetadataInfo, { items: [item] }) }, { children: _jsx("span", { children: limitLength(value.toString(), 40) }) })));
                        }, showMoreContent: () => _jsx(MetadataInfo, { truncate: true, items: items }), tagClassName: "my-auto", tagStyle: () => selected
                            ? {
                                backgroundColor: "transparent",
                            }
                            : {}, onMouseDown: item => onMetadataClick === null || onMetadataClick === void 0 ? void 0 : onMetadataClick(item), moreButtonCStyle: selected
                            ? {
                                background: "transparent",
                            }
                            : {} }), _jsx("div", Object.assign({ className: "flex flex-1 space-x-1 pl-20 justify-end" }, { children: suffix }))] }))] })));
}
function MetadataInfo({ items, truncate, }) {
    return (_jsx("table", Object.assign({ className: "text-tiny" }, { children: _jsx("tbody", { children: items.map((item, idx) => {
                var _a;
                return (_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "align-top p-1 font-semibold" }, { children: _jsx("div", { children: item.name }) })), _jsx("td", Object.assign({ className: "align-top p-1" }, { children: _jsx(Truncated, { className: "text-tiny", text: (_a = metadataValue(item, false)) === null || _a === void 0 ? void 0 : _a.toString(), maxLength: truncate ? 120 : 1024 }, "V" + idx) }))] }, idx));
            }) }) })));
}
export function DocumentHeader({ document, highlight = document.primary_evidence.text, suffix, className, selectCount, urlOpener, }) {
    const primaryEvidence = document.primary_evidence;
    const allEvidence = [primaryEvidence, ...document.additional_evidence];
    const allEvidenceText = allEvidence.map(x => x.text);
    const caption = getCaption(primaryEvidence.instances[0]);
    return (_jsx(Banner, Object.assign({ className: classes("flex-1 flex flex-row overflow-hidden px-2", className), suffix: suffix }, { children: _jsx(ButtonWrapper, Object.assign({ className: "flex text-sm font-bold text-ellipsis overflow-hidden items-center", onClick: () => {
                urlOpener === null || urlOpener === void 0 ? void 0 : urlOpener(document.primary_evidence, document.primary_evidence.instances[0], allEvidenceText, false);
            } }, { children: _jsxs("div", Object.assign({ className: "flex-1 text-ellipsis overflow-hidden text-base" }, { children: [_jsx("span", Object.assign({ className: "font-normal mr-1" }, { children: `(${selectCount
                            ? `+${selectCount}`
                            : document.additional_evidence.length + 1})` })), _jsx("span", { children: limitLength(caption, MAX_TITLE_LEN) })] })) })) })));
}
export function EvidenceHeader({ evidence, highlight = evidence.text, urlOpener, onSelectionChangeInDocView, suffix, metadataSorter, }) {
    const highlightWords = highlightWordsOutsideOfParse(evidence);
    return (_jsx(InfoRow, { highlight: [
            ...highlightWords,
            ...(!highlight
                ? []
                : Array.isArray(highlight)
                    ? highlight
                    : [highlight]),
        ], metadataSorter: metadataSorter, sources: evidence.instances, urlOpener: urlOpener &&
            (docId => urlOpener(evidence, evidence.instances.find(i => {
                return i.document_id === docId;
            }))), onSelectionChangeInDocView: onSelectionChangeInDocView, suffix: suffix }));
}
export function InfoRow({ sources, highlight, urlOpener = openUrl, suffix, onSelectionChangeInDocView, metadataSorter, }) {
    const count = sources.length || 0;
    const caption = getCaption(sources[0]);
    const button = (_jsx(Banner, Object.assign({ className: classes("flex-1 flex flex-row overflow-hidden px-0"), suffix: suffix }, { children: _jsx(ButtonWrapper, Object.assign({ className: "flex text-sm font-bold text-ellipsis overflow-hidden items-center", onClick: count === 1
                ? () => urlOpener === null || urlOpener === void 0 ? void 0 : urlOpener(getId(sources[0]), sources[0].corpus_id, getId(sources[0]), highlight, getCaption(sources[0]), onSelectionChangeInDocView, sources[0].metadata, metadataSorter, sources[0].external_url)
                : undefined }, { children: _jsx("div", Object.assign({ className: "flex-1 text-ellipsis overflow-hidden text-base" }, { children: count === 1
                    ? limitLength(caption, MAX_TITLE_LEN)
                    : `${count} different sources…` })) })) })));
    return count === 1 ? (button) : (_jsx(DropDownMenu, Object.assign({ entries: () => sources.map(source => ({
            caption: getCaption(source),
            action() {
                urlOpener(getId(source), source.corpus_id, getUrl(source), highlight, source.title, undefined, source.metadata, metadataSorter, source.external_url);
            },
        })) }, { children: button })));
}
