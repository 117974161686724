var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FeatureIdentifier, FeedbackType, } from "../api/types";
import { classes } from "../../utils/classes";
import { Button, Input, Select } from "antd";
import { TitledContainer } from "../../shared/components/TitledContainer";
import TextArea from "antd/es/input/TextArea";
import { addEvidenceToFinding, setFindingEvidence, updateFindingPayload, } from "../utils/findings";
import { showAttachSuggestedEvidence } from "./modals/AttachSuggestedEvidence";
import { FilteredEvidenceCardList } from "./evidence/FilteredEvidenceCardList";
import { useAllTags } from "../utils/allTags";
import { Robot } from "./Icons";
import { DropDownMenu } from "../../shared/components/DropDownMenu";
import { ActionButton } from "../../shared/components/buttons/Buttons";
import { sendFeedback } from "../utils/feedback";
import { showNotification } from "../../shared/components/notifications";
import { getEvidenceCorpusFilter } from "../utils/evidence";
import { sortMetadata } from "../utils/metatada";
import { useCurrentSource } from "../utils/sourceManagement";
import { updateCurrentSession, useCurrentSession, } from "../utils/sessionManagement";
import { CollapseButton } from "./facet/facetButtons";
import { toggleFlag } from "../../utils/collections";
import { showSelectionMenu } from "./contextMenuEntries";
import { getUrl, openUrl } from "../utils/url";
import { useQueryState } from "../utils/queryState/queryState";
export function FindingEditor({ finding, coraState, setFinding, disabled, }) {
    const { findingCollapsedSections: collapsed, structuredQuery: sq, showStructuredQuery, } = useCurrentSession();
    const structuredQuery = sq && showStructuredQuery;
    const source = useCurrentSource(coraState);
    const tags = useAllTags(finding.payload.tags);
    const { suggestFindings } = finding.payload;
    const corpus_filter = getEvidenceCorpusFilter(coraState);
    const [queryState] = useQueryState();
    const addEvidenceToNewFinding = (unusedFinding, evidence) => setFinding(finding => addEvidenceToFinding(finding, evidence));
    return (_jsxs("div", Object.assign({ className: classes("FindingEditor flex-1 flex flex-col items-stretch p-4 radius-xl bg-white border rounded-lg space-y-4"
        // columnWidthClass4
        ) }, { children: [_jsx(CollapsableSection, Object.assign({ title: "Title" }, { children: _jsx(Input, { value: finding.title, onChange: e => setFinding(f => (Object.assign(Object.assign({}, f), { title: e.target.value }))), placeholder: "Title" }) })), _jsx(CollapsableSection, Object.assign({ title: "Finding" }, { children: _jsxs("div", Object.assign({ className: "flex items-start space-x-2" }, { children: [_jsx(TextArea, { className: "flex1", value: finding.payload.selectedText, autoSize: false, onChange: e => setFinding(f => updateFindingPayload(f, { selectedText: e.target.value })), placeholder: "Finding Required" }), ((suggestFindings === null || suggestFindings === void 0 ? void 0 : suggestFindings.length) || 0) > 1 && (_jsx(DropDownMenu, Object.assign({ captionClassName: "max-w-[600px] !whitespace-normal text-ellipsis", entries: () => suggestFindings.map(e => ({
                                caption: e.text,
                                action() {
                                    setFinding(f => updateFindingPayload(f, { selectedText: e.text }));
                                },
                            })) }, { children: _jsx(ActionButton, { children: _jsx(Robot, { className: "animate-pulse" }) }) })))] })) })), _jsx(FilteredEvidenceCardList, { items: finding.payload.evidence, noItemsMessage: "No Evidence", metadataSorter: sortMetadata(source), containerStyleClass: " ", onEvidenceTextSelected: ({ evidence, rect, text }) => showSelectionMenu(rect, {
                    text,
                    coraState,
                    evidence,
                    instance: evidence.instances[0],
                    context: evidence.text,
                    structuredQuery,
                    queryState,
                }), headerActions: _jsx(Button, Object.assign({ disabled: disabled, onClick: () => showAttachSuggestedEvidence({
                        coraState,
                        finding,
                        addEvidenceToFinding: addEvidenceToNewFinding,
                        onSelect(selected) {
                            return __awaiter(this, void 0, void 0, function* () {
                                yield sendFeedback(FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/suggest/findings", FeedbackType.IMPLICIT_POSITIVE, selected, corpus_filter);
                                addEvidenceToNewFinding(finding, selected);
                                showNotification("Feedback sent");
                            });
                        },
                    }) }, { children: "Suggest additional Evidence" })), onRemove: item => setFinding(finding => setFindingEvidence(finding, finding.payload.evidence.filter(f => f !== item))), urlOpener: (evidence, instance) => {
                    openUrl(getUrl(instance), instance.corpus_id, instance.document_id, evidence.text, instance.title, undefined, instance.metadata, sortMetadata(source), instance.external_url);
                } }), _jsxs("div", Object.assign({ className: classes(collapsed["tags"] && collapsed["notes"]
                    ? "flex flex-row space-x-4"
                    : "flex flex-col items-stretch space-y-4") }, { children: [_jsxs(CollapsableSection, Object.assign({ title: "Tags", collapsed: collapsed["tags"], onToggle: () => updateCurrentSession({
                            findingCollapsedSections: toggleFlag(collapsed, "tags"),
                        }) }, { children: [_jsx(Select, Object.assign({ mode: "tags", allowClear: true, style: { width: "100%" }, placeholder: "Finding Tags", defaultValue: finding.payload.tags, value: finding.payload.tags, onChange: tags => setFinding(f => {
                                    return updateFindingPayload(f, { tags });
                                }) }, { children: tags === null || tags === void 0 ? void 0 : tags.map(tag => (_jsx(Select.Option, Object.assign({ value: tag }, { children: tag }), tag))) })), " "] })), _jsx(CollapsableSection, Object.assign({ title: "Notes", collapsed: collapsed["notes"], onToggle: () => updateCurrentSession({
                            findingCollapsedSections: toggleFlag(collapsed, "notes"),
                        }) }, { children: _jsx(TextArea, { value: finding.payload.notes, onChange: e => setFinding(f => updateFindingPayload(f, { notes: e.target.value })) }) }))] }))] })));
}
function CollapsableSection({ onToggle, collapsed, title, children, }) {
    return (_jsx(TitledContainer, Object.assign({ headerLeft: _jsxs("div", Object.assign({ className: "flex space-x-2 items-cente" }, { children: [_jsx("div", { children: title }), onToggle && (_jsx(CollapseButton, { collapsed: collapsed, onToggle: onToggle }))] })), noScroll: true, containerStyleClass: " ", bgClassName: "bg-transparent" }, { children: !collapsed && children })));
}
