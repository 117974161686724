var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import DP from "react-datepicker";
import React from "react";
import moment from "moment";
import Lazy from "lazy.js";
import { Left, Right } from "../../Icons";
import { DivButton } from "../../../../shared/components/buttons/Buttons";
import "./DatePicker.less";
const CALENDAR_DATE_FORMAT = "MMM d yyyy";
const DATE_FORMAT = "MMM D YYYY";
const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export function DatePicker(_a) {
    var _b, _c, _d, _e;
    var { selectedRange, field, onSelectionChange, range } = _a, props = __rest(_a, ["selectedRange", "field", "onSelectionChange", "range"]);
    const isStart = field === "start";
    const minMax = rangeToDates(range);
    const startYear = (_c = (_b = minMax.start) === null || _b === void 0 ? void 0 : _b.getFullYear()) !== null && _c !== void 0 ? _c : 2000;
    const endYear = (_e = (_d = minMax.end) === null || _d === void 0 ? void 0 : _d.getFullYear()) !== null && _e !== void 0 ? _e : new Date().getFullYear();
    const dateSelection = rangeToDates(selectedRange);
    return (_jsx(DP, Object.assign({}, props, { selectsStart: isStart, selectsEnd: !isStart, isClearable: !!dateSelection[field], calendarClassName: "shadow-xl rounded-xl", dateFormat: CALENDAR_DATE_FORMAT, showFullMonthYearPicker: true, placeholderText: isStart ? "From…" : "To…", disabled: !range, selected: dateSelection[field], openToDate: (!dateSelection[field] && minMax[field]) || undefined, onChange: date => onSelectionChange(toDateRange(isStart ? date : dateSelection.start, !isStart ? date : dateSelection.end, isStart)), startDate: dateSelection.start, endDate: dateSelection.end, minDate: minMax.start, maxDate: minMax.end, renderCustomHeader: ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => (_jsxs("div", Object.assign({ className: "px-4 flex space-x-2 justify-between items-center text-base" }, { children: [_jsx(DivButton, Object.assign({ disabled: prevMonthButtonDisabled, onMouseDown: e => {
                        e.preventDefault();
                        decreaseMonth();
                    } }, { children: _jsx(Left, {}) })), _jsx("select", Object.assign({ className: "font-bold text-base", style: { outline: "none" }, value: months[date.getMonth()], onChange: ({ target: { value } }) => changeMonth(months.indexOf(value)) }, { children: months.map(option => (_jsx("option", Object.assign({ value: option }, { children: option }), option))) })), _jsx("select", Object.assign({ className: "font-bold text-base", style: { outline: "none" }, value: date.getFullYear(), onChange: ({ target: { value } }) => changeYear(Number(value)) }, { children: Lazy.range(startYear, endYear + 1)
                        .map(option => (_jsx("option", Object.assign({ value: option }, { children: option }), option)))
                        .toArray() })), _jsx(DivButton, Object.assign({ disabled: nextMonthButtonDisabled, onMouseDown: e => {
                        e.preventDefault();
                        increaseMonth();
                    } }, { children: _jsx(Right, {}) }))] }))) }, { children: range && (_jsxs("div", Object.assign({ className: "font-tiny ml-2 italic text-gray-500" }, { children: ["Range: ", rangeToString(range)] }))) })));
}
function rangeToDates(range) {
    return {
        start: (range === null || range === void 0 ? void 0 : range.start) ? moment(range.start).toDate() : null,
        end: (range === null || range === void 0 ? void 0 : range.end) ? moment(range.end).toDate() : null,
    };
}
function toDateRange(start, end, updatingStart) {
    if (!start && !end)
        return null;
    if (start && end && start > end) {
        if (updatingStart) {
            end = start;
        }
        else {
            start = end;
        }
    }
    return Object.assign(Object.assign({}, (end && {
        end: moment(end).format(SERVER_DATE_FORMAT),
    })), (start && {
        start: moment(start).format(SERVER_DATE_FORMAT),
    }));
}
export function rangeToString(range) {
    return `${range.start ? moment(range.start).format(DATE_FORMAT) : " "} → ${range.end ? moment(range.end).format(DATE_FORMAT) : " "}`;
}
const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
